import { Flex, Typography } from "antd";
import { FatButton } from "components/FatButton";
import { useTranslate } from "@refinedev/core";
import {
  Calendar,
  Download,
  EnvelopeSimple,
  Link,
  PhoneCall,
} from "@phosphor-icons/react";

export const CTAMenu = ({ onSelect, disabledItems = [] }) => {
  const t = useTranslate();
  const menuItems = [
    {
      key: "ExternalLink",
      icon: <Link weight="duotone" size={40} />,
      title: t("media.ctas.create.externalLink"),
      content: t("media.ctas.create.addExternalLink"),
    },
    {
      key: "CalendarAppointment",
      icon: <Calendar weight="duotone" size={40} />,
      title: t("media.ctas.create.calendarAppointment"),
      content: t("media.ctas.create.addCalendarAppointment"),
    },
    {
      key: "DocumentDownload",
      icon: <Download weight="duotone" size={40} />,
      title: t("media.ctas.create.documentDownload"),
      content: t("media.ctas.create.addDocumentDownload"),
    },
    {
      key: "Email",
      icon: <EnvelopeSimple weight="duotone" size={40} />,
      title: t("media.ctas.create.email"),
      content: t("media.ctas.create.addEmail"),
    },
    {
      key: "Phone",
      icon: <PhoneCall weight="duotone" size={40} />,
      title: t("media.ctas.create.phone"),
      content: t("media.ctas.create.addPhone"),
    },
  ];

  return (
    <Flex
      vertical
      gap={5}
      wrap
      style={{
        width: "100%",
      }}
    >
      <Typography.Title level={5} style={{ margin: 0 }}>
        {t("media.ctas.create.addA")}
      </Typography.Title>
      <Flex wrap gap="middle">
        {menuItems.map((item) => (
          <FatButton
            key={item.key}
            icon={item.icon}
            title={item.title}
            content={item.content}
            disabled={disabledItems?.includes(item.key)}
            onClick={() => onSelect?.(item.key)}
            style={{
              width: 250,
            }}
          />
        ))}
      </Flex>
    </Flex>
  );
};
