export const OTHER_VALUE = "other";

export enum Department {
  LEARNING_DEVELOPMENT = "learningDevelopment",
  HR_OPERATIONS = "hrOperations",
  IT_INFORMATION_SECURITY = "itInformationSecurity",
  COMPLIANCE_LEGAL = "complianceLegal",
  CUSTOMER_SUPPORT = "customerSupport",
  CUSTOMER_SUCCESS = "customerSuccess",
  PRODUCT_MANAGEMENT = "productManagement",
  MARKETING = "marketing",
  SALES = "sales",
  ACADEMIA_TEACHER_STUDENT = "academiaTeacherStudent",
  FREELANCER = "freelancer",
  PERSONAL = "personal",
  OTHER = "other",
}

export enum Usage {
  PRODUCT_DEMO = "productDemo",
  PRODUCT_UPDATE = "productUpdate",
  KNOWLEDGE_BASE = "knowledgeBase",
  SALES_ENABLEMENT = "salesEnablement",
  CUSTOMER_TRAINING = "customerTraining",
  HOW_TO_GUIDE = "howToGuide",
  CUSTOMER_ONBOARDING = "customerOnboarding",
  CUSTOMER_COMMS = "customerComms",
  OTHER = "other",
}

export enum Business {
  COMPANY = "company",
  INDIVIDUAL = "individual",
  OTHER = "other",
}

export enum Source {
  WORD_OF_MOUTH = "wordOfMouth",
  GOOGLE = "google",
  LINKEDIN = "linkedin",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  TIKTOK = "tiktok",
  YOUTUBE = "youtube",
  G2_REVIEWS = "g2Reviews",
  ARTICLE = "article",
  OTHER = "other",
}
export interface OnboardingState {
  department: {
    answers: Department | null;
    other: string | null;
  };
  usage: {
    answers: Usage[];
    other: string | null;
  };
  business: {
    answers: Business | null;
    other: string | null;
  };
  website: {
    answers: string | null;
    other: string | null;
  };
  source: {
    answers: Source | null;
    other: string | null;
  };
  organization: string | null;
  finish: OnboardingState["department"];
  currentStep: Step;
}
export interface OnboardingSlice {
  onboardingState: OnboardingState;
  setOnboardingState: (state: OnboardingState) => void;
  setStep: (value: Partial<Steps[keyof Steps]> | string | null) => void;
  resetOnboardingState: () => void;
  goBack: () => void;
  goForward: () => void;
  setCurrentStep: (value: Step) => void;
}
export type Steps = Omit<OnboardingState, "currentStep">;
export type Step = keyof Steps;
export type MultipleChoiceStep = Steps["usage"];

export type MultiStepWithDescription = OnboardingState["usage"];
export type MultiStepWithDescriptionKey = Extract<
  {
    [K in Step]: Steps[K] extends MultiStepWithDescription ? K : never;
  }[keyof Steps],
  string
>;
export type MultipleChoiceStepKey = Extract<
  {
    [K in Step]: Steps[K] extends MultipleChoiceStep ? K : never;
  }[keyof Steps],
  string
>;
export type StepWithDescription =
  | Steps["business"]
  | Steps["source"]
  | Steps["usage"]
  | Steps["department"]
  | Steps["website"];

export type StepWithDescriptionKey = Extract<
  {
    [K in Step]: Steps[K] extends StepWithDescription ? K : never;
  }[keyof Steps],
  string
>;
export type StepWithSingleAnswerAndDescription = Extract<
  {
    [K in Step]: Steps[K] extends { answers: string | null } ? K : never;
  }[keyof Steps],
  string
>;

export type NormalizedAnswer = {
  answers: Steps[keyof Steps];
  other?: string | null;
};

export type SingleAnswerStepKey =
  | "business"
  | "source"
  | "department"
  | "website";
export type SingleAnswerStep = {
  [K in keyof Steps as K extends SingleAnswerStepKey ? K : never]: Steps[K];
};
