import {
  Authenticated,
  useCustomMutation,
  useNavigation,
  useOne,
  useTranslate,
} from "@refinedev/core";
import { useParams } from "react-router-dom";
import { ChatMode, MediaProjectResponse } from "../types";
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Row,
  Switch,
  theme,
  Typography,
} from "antd";
import { ProjectSubPageHeader } from "./components/ProjectSubPageHeader";
import { ChatTeardrop, EnvelopeSimple } from "@phosphor-icons/react";
import { ProjectChatHistory } from "./components/ProjectChatHistory";
import { useAntTheme } from "hooks/useAntTheme";

export const ProjectFeaturePage = () => {
  const t = useTranslate();
  const { show } = useNavigation();
  const { theme } = useAntTheme();
  const params = useParams<{ id: string; feature?: string }>();
  const id = params?.id;
  const feature = params.feature;

  const { data } = useOne<MediaProjectResponse>({
    resource: `media/projects`,
    id,
  });
  const project = data?.data;

  return (
    <Authenticated key="project_feature">
      {project && (
        <ProjectSubPageHeader
          projectId={project.id}
          projectTitle={project.title}
          page={feature}
        />
      )}
      <Typography.Title>
        {t(`projects.components.ProjectFeature.${feature}`)}
      </Typography.Title>

      {feature === "chat" && project && (
        <Flex vertical gap={16}>
          <ChatActivation
            chatMode={project.settings.chat_mode}
            projectId={project.id}
            onSuccess={() => show(`media_projects`, project.id)}
          />
          <Row gutter={[24, 24]}>
            <Col span={20}>
              <Card
                title={
                  <Flex align="center" gap={12}>
                    <ChatTeardrop size={32} weight="duotone" />
                    <Typography.Text strong style={{ fontSize: 20 }}>
                      {t("media.projects.analytics.chatHistory")}
                    </Typography.Text>
                  </Flex>
                }
                styles={{
                  header: { backgroundColor: theme.colorPrimaryBgHover },
                }}
                extra={[
                  <Button
                    disabled
                    type="text"
                    key={"export"}
                    icon={<EnvelopeSimple size={32} weight="duotone" />}
                  >
                    {t("media.projects.analytics.exportToEmail")}
                  </Button>,
                ]}
              >
                <ProjectChatHistory projectId={id} enabled={true} />
              </Card>
            </Col>
          </Row>
        </Flex>
      )}
      {feature === "animation" && project && (
        <AnimationActivation
          enabled={project.settings.include_document_animations}
          projectId={project.id}
          onSuccess={() => show(`media_projects`, project.id)}
        />
      )}
    </Authenticated>
  );
};

const ChatActivation = ({
  chatMode,
  projectId,
  onSuccess,
}: {
  chatMode: ChatMode;
  projectId: string;
  onSuccess?: () => void;
}) => {
  const { mutateAsync, isLoading } = useCustomMutation();
  const url = `/media/projects/${projectId}/settings`;
  const t = useTranslate();

  const onFinish = async (values) => {
    const chat_mode: ChatMode =
      values.chatMode === true ? "FullChat" : "Disabled";
    await mutateAsync({ url, method: "patch", values: { chat_mode } });
    onSuccess?.();
  };
  return (
    <Form
      colon={false}
      initialValues={{ chatMode: chatMode !== "Disabled" }}
      onFinish={onFinish}
    >
      <Form.Item<boolean>
        label={t(`projects.components.ProjectFeature.enabled`)}
        name="chatMode"
      >
        <Switch />
      </Form.Item>
      <Form.Item label={null}>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={isLoading}
          shape="round"
          size="large"
        >
          {t("buttons.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

const AnimationActivation = ({
  enabled,
  projectId,
  onSuccess,
}: {
  enabled: boolean;
  projectId: string;
  onSuccess?: () => void;
}) => {
  const { mutateAsync, isLoading } = useCustomMutation();
  const url = `/media/projects/${projectId}/settings`;
  const t = useTranslate();

  const onFinish = async (values) => {
    const include_document_animations = values.enabled;
    await mutateAsync({
      url,
      method: "patch",
      values: { include_document_animations },
    });
    onSuccess?.();
  };
  return (
    <Form colon={false} initialValues={{ enabled }} onFinish={onFinish}>
      <Form.Item<boolean>
        label={t(`projects.components.ProjectFeature.enabled`)}
        name="enabled"
      >
        <Switch />
      </Form.Item>
      <Form.Item label={null}>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={isLoading}
          shape="round"
          size="large"
        >
          {t("buttons.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};
