import { NewImage, NewPresenter, TemplateState, TemplateType } from "./types";
import { StateCreator } from "zustand";
import { nanoid } from "nanoid";
import { CombinedVolatileSlice } from "store/types";
import { isEqual } from "lodash";

export const initialState: TemplateState = {
  view: TemplateType.CHAPTER,
  subtitlesAnimation: false,
  avatarClose: false,
  presenter: {
    presenter: undefined,
    position: { x: 50, y: 330 },
    size: { width: 0.55, height: 0.31 },
    background: "#ffffff",
  },
  textStyle: {
    bold: false,
    italic: false,
    underline: false,
    fontSize: 12,
    textFont: undefined,
    subtitles_color: undefined,
    subtitles_stroke_color: undefined,
    position: {
      x: 30,
      y: 80,
    },
    size: {
      width: 0.69,
      height: 0.13,
    },
    showSubtitles: true,
  },
  titleStyle: {
    bold: false,
    italic: false,
    underline: false,
    fontSize: 20,
    titleFont: undefined,
    title_color: undefined,
    title_stroke_color: undefined,
    position: {
      x: 15,
      y: 200,
    },
    size: {
      width: 0.83,
      height: 0.13,
    },
  },
  intro: {
    enable: false,
    view: "Video",
  },
  transition: {
    enable: false,
    view: "Color",
  },
  closing: {
    enable: false,
    view: "Video",
  },
  images: [],
  background: { bodyView: "Color", headerView: "Color" },
  updates: 0,
};

export interface TemplateSlice {
  templateState: TemplateState;
  setTemplateState: (state: TemplateState) => void;
  resetTemplateState: () => void;
  setView: (view: TemplateState["view"]) => void;
  setAvatarClose: (animation: TemplateState["avatarClose"]) => void;
  setSubtitlesAnimation: (
    animation: TemplateState["subtitlesAnimation"]
  ) => void;
  setTextStyle: (newTextStyle: Partial<TemplateState["textStyle"]>) => void;
  setTitleStyle: (newTextStyle: Partial<TemplateState["titleStyle"]>) => void;
  setPresenter: (newPresenter: Partial<TemplateState["presenter"]>) => void;
  setCurrentChapter: (currentChapter: TemplateState["currentChapter"]) => void;
  setIntro: (updatedIntro: Partial<TemplateState["intro"]>) => void;
  setTransition: (
    updatedTransition: Partial<TemplateState["transition"]>
  ) => void;
  setClosing: (updatedClosing: Partial<TemplateState["closing"]>) => void;
  setCurrentTemplateId: (
    currentTemplateId: TemplateState["currentTemplateId"]
  ) => void;
  addImage: (newImage: Partial<NewImage>) => void;
  updateImage: (update: Partial<NewImage>) => void;
  deleteImage: (id: string) => void;
  setBackground: (color: Partial<TemplateState["background"]>) => void;
  clearImages: () => void;
  addUpdate: () => void;
  resetUpdates: () => void;
  setCurrentItem: (item: TemplateState["currentItem"]) => void;
  setActiveTab: (tab: TemplateState["activeTab"]) => void;
}

export const createTemplateSlice: StateCreator<
  CombinedVolatileSlice,
  //https://github.com/pmndrs/zustand/issues/980#issuecomment-1162289836
  [["zustand/devtools", never], ["zustand/immer", never]],
  [],
  TemplateSlice
> = (set) => {
  return {
    templateState: initialState,
    setTemplateState: (value) =>
      set({ templateState: value }, undefined, "setTemplateState"),
    resetTemplateState: () =>
      set({ templateState: initialState }, undefined, "resetTemplateState"),
    setPresenter: (newPresenterPartial: Partial<NewPresenter>) => {
      set(
        ({ templateState }) => {
          const newPresenter = {
            ...templateState.presenter,
            ...newPresenterPartial,
          };
          if (!isEqual(templateState.presenter, newPresenter)) {
            templateState.presenter = newPresenter;
          }
        },
        undefined,
        "setPresenter"
      );
    },
    setTextStyle: (property) =>
      set(
        ({ templateState }) => {
          const newTextStyle = {
            ...templateState.textStyle,
            ...property,
          };

          if (!isEqual(templateState.textStyle, newTextStyle)) {
            templateState.textStyle = newTextStyle;
          }
        },
        undefined,
        "setTextStyle"
      ),
    setTitleStyle: (property) =>
      set(
        ({ templateState }) => {
          const newTitleStyle = {
            ...templateState.titleStyle,
            ...property,
          };
          if (!isEqual(templateState.titleStyle, newTitleStyle)) {
            templateState.titleStyle = newTitleStyle;
          }
        },
        undefined,
        "setTitleStyle"
      ),
    setView: (view) =>
      set(
        ({ templateState }) => {
          templateState.view = view;
        },
        undefined,
        "setView"
      ),
    setAvatarClose: (avatarClose) =>
      set(
        ({ templateState }) => {
          templateState.avatarClose = avatarClose;
        },
        undefined,
        "setAvatarClose"
      ),
    setSubtitlesAnimation: (hasAnimation) =>
      set(
        ({ templateState }) => {
          templateState.subtitlesAnimation = hasAnimation;
        },
        undefined,
        "setSubtitlesAnimation"
      ),
    setCurrentChapter: (currentChapter) =>
      set(
        ({ templateState }) => {
          templateState.currentChapter = currentChapter;
        },
        undefined,
        "setCurrentChapter"
      ),
    setIntro: (updatedIntro) =>
      set(
        ({ templateState }) => {
          const newIntro = {
            ...templateState.intro,
            ...updatedIntro,
          };

          if (!isEqual(templateState.intro, newIntro)) {
            templateState.intro = newIntro;
          }
        },
        undefined,
        "setIntro"
      ),
    setTransition: (updatedTransition) =>
      set(
        ({ templateState }) => {
          const newTransition = {
            ...templateState.transition,
            ...updatedTransition,
          };
          if (!isEqual(templateState.transition, newTransition)) {
            templateState.transition = newTransition;
          }
        },
        undefined,
        "setTransition"
      ),
    setClosing: (updatedClosing) =>
      set(
        ({ templateState }) => {
          const newClosing = {
            ...templateState.closing,
            ...updatedClosing,
          };
          if (!isEqual(templateState.closing, newClosing)) {
            templateState.closing = newClosing;
          }
        },
        undefined,
        "setClosing"
      ),
    setCurrentTemplateId: (currentTemplateId) =>
      set(
        ({ templateState }) => {
          templateState.currentTemplateId = currentTemplateId;
        },
        undefined,
        "setCurrentTemplateId"
      ),
    setBackground: (property) =>
      set(
        ({ templateState }) => {
          const background = templateState.background;
          const newBackground = {
            ...templateState.background,
            ...property,
          };
          if (!isEqual(background, newBackground)) {
            templateState.background = newBackground;
          }
        },
        undefined,
        "setBackground"
      ),
    addImage: (newImage: Partial<NewImage>) => {
      set(
        ({ templateState }) => {
          templateState.images.push({
            id: nanoid(),
            assetId: newImage.assetId,
            position: newImage.position ?? { x: 160, y: 5 },
            size: newImage.size ?? { width: 0.9, height: 0.09 },
            path: newImage.path,
            ...newImage,
          });
        },
        undefined,
        "addImage"
      );
    },
    updateImage: (update) => {
      set(
        ({ templateState }) => {
          templateState.images = templateState.images.map((logo) =>
            logo.id === update.id ? { ...logo, ...update } : logo
          );
        },
        undefined,
        "updateImage"
      );
    },
    deleteImage: (id) => {
      set(
        ({ templateState }) => {
          templateState.images = templateState.images.filter(
            (logo) => logo.id !== id
          );
        },
        undefined,
        "deleteImage"
      );
    },
    clearImages: () => {
      set(
        ({ templateState }) => {
          templateState.images = [];
        },
        undefined,
        "clearImages"
      );
    },
    addUpdate: () => {
      set(
        ({ templateState }) => {
          templateState.updates += 1;
        },
        undefined,
        "addUpdate"
      );
    },
    resetUpdates: () => {
      set(
        ({ templateState }) => {
          templateState.updates = 0;
        },
        undefined,
        "resetUpdates"
      );
    },
    setCurrentItem: (currentItem) => {
      set(
        ({ templateState }) => {
          templateState.currentItem = currentItem;
        },
        undefined,
        "setCurrentItem"
      );
    },
    setActiveTab: (tab) => {
      set(
        ({ templateState }) => {
          if (templateState.activeTab !== tab) {
            templateState.activeTab = tab;
          }
        },
        undefined,
        "setActiveTab"
      );
    },
  };
};
