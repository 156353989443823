import {
  BaseRecord,
  CustomResponse,
  useCustomMutation,
  useTranslate,
} from "@refinedev/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Authenticated,
  IResourceComponentsProps,
  useGo,
  useInvalidate,
  useUpdate,
} from "@refinedev/core";
import {
  Button,
  Flex,
  Form,
  GlobalToken,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import { MediaProjectResponse } from "../types";
import { Show, DateField } from "@refinedev/antd";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { SettingOutlined } from "@ant-design/icons";
import "simplebar-react/dist/simplebar.min.css";
import styled from "styled-components";
import { useAntTheme } from "hooks/useAntTheme";
import { addDateTz } from "../utils";
import { useOne } from "@refinedev/core";
import { User } from "types";

import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import {
  MediaContactLanguage,
  useProjectMedia,
} from "./components/useProjectMedia";
import { useProjectDetails } from "./hooks/useProjectDetails";
import { ProjectPageMenu } from "./components/ProjectPageMenu";
import { ProjectHeader } from "./components/ProjectHeader";
import { ProjectLanguageAndContact } from "./components/ProjectLanguageAndContact";
import { ProjectStatus } from "./components/ProjectStatus";
import { ProjectSecondaryMenu } from "./components/ProjectSecondaryMenu";
import { Play } from "@phosphor-icons/react";
import backgroundImg from "../../../assets/background_3dmesh_white.jpg";
import ReactPlayer from "react-player";
import { useVolatileBoundStore } from "store";

const MAX_GENERATION_REFETCH_TIMEOUT = 1800000; // 30 min

export type ProjectModal = "AssetUpload" | "DocumentUpload" | "MediaUpload";

export const ProjectDetail: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const params = useParams();
  const go = useGo();
  const invalidate = useInvalidate();
  const loadTime = useRef<number>(Date.now());
  const { mutateAsync: updateMutationAsync, isLoading: updateMutationLoading } =
    useUpdate<MediaProjectResponse>({});

  const { theme } = useAntTheme();
  const projectId = params.id;

  const queryResult = useProjectDetails({ projectId });

  const {
    data,
    isLoading,
    isError,
    refetch: refetchProjectDetail,
  } = queryResult;

  const { currentMenuIndex, currentProjectId, setCurrentProjectId } =
    useVolatileBoundStore((store) => ({
      currentMenuIndex: store.projectState.currentMenuIndex,
      currentProjectId: store.projectState.currentProjectId,
      setCurrentProjectId: store.setCurrentProjectId,
    }));

  const project = data?.data;
  const projectRoute = `/media/projects/${project?.id}`;
  const { requestNewMedia, isCreatingMedia, mediaContactIds } = useProjectMedia(
    { project }
  );
  const storyboardLanguages = project?.storyboards
    ?.map((x) => x.language)
    .sort();
  const missingMultilanguageMedia = useMemo(() => {
    const result: MediaContactLanguage[] = [];
    if (!project || !mediaContactIds || !storyboardLanguages) return result;

    mediaContactIds?.forEach((contactId) => {
      const contactMedia = project.media.filter(
        (y) => y.contact_id === contactId
      );
      storyboardLanguages.forEach((language) => {
        if (!contactMedia.find((y) => y.language === language)) {
          result.push({ contactId, language });
        }
      });
    });
    return result;
  }, [project, storyboardLanguages, mediaContactIds]);

  const [isEditing, setIsEditing] = useState(false);
  const [openedModal, setOpenedModal] = useState<ProjectModal>();
  const [form] = Form.useForm();
  // const { dispatch: appDispatch } = useContext(AppContext);

  dayjs.extend(duration);
  dayjs.extend(utc);

  // Statuses
  const settingsApproved = project?.settings.status === "Approved";

  // Calculate time since creation
  const now = dayjs();
  const projectCreationDate = dayjs(addDateTz(project?.created));
  const projectDiffDurationTime = dayjs.duration(
    -now.diff(projectCreationDate)
  );

  useEffect(() => {
    if (project && project?.id != currentProjectId)
      setCurrentProjectId(project.id);
  }, [project]);

  // useEffect(() => {
  //   appDispatch({ type: "setSidebarCollapsed", payload: true });
  //   return () => {
  //     appDispatch({ type: "setSidebarCollapsed", payload: false });
  //   };
  // }, []);

  // const handleApprove = async () => {
  //   await approveSettings({
  //     url: `media/projects/${projectId}/settings/approve`,
  //     method: "post",
  //     values: {},
  //   });
  //   invalidate({
  //     resource: "media/projects",
  //     id: project?.id,
  //     invalidates: ["detail"],
  //   });
  // };

  // const handleEdit = () => {
  //   setIsEditing(true);
  //   // setProjectData(project);
  // };

  // www.geeksforgeeks.org/how-to-convert-3-digit-color-code-to-6-digit-color-code-using-javascript/
  const colorBgBase6 = theme.colorBgBase
    .split("")
    .map((item) => {
      if (item == "#") {
        return item;
      }
      return item + item;
    })
    .join("");

  const handleCancel = () => {
    setIsEditing(false);
  };

  const openModal = (modal: ProjectModal) => {
    setOpenedModal(modal);
  };
  const closeModal = () => setOpenedModal(undefined);

  const handleGenerateMultilanguageMedia = useCallback(async () => {
    await requestNewMedia(missingMultilanguageMedia);
    invalidate({
      resource: "media/projects",
      id: project?.id,
      invalidates: ["detail"],
    });
  }, [missingMultilanguageMedia]);

  const handleSave = async (values: MediaProjectResponse) => {
    if (!project?.id) return;
    await updateMutationAsync({
      id: project?.id,
      resource: "media/projects",
      values: { ...project, ...values },
    });
    setIsEditing(false);
  };

  useEffect(() => {
    if (project && form) {
      // https://stackoverflow.com/questions/75416804/ant-design-v5-2-0-date-locale-is-not-a-function
      form.setFieldsValue({
        ...project,
        deadline: project.deadline ? dayjs(project.deadline) : null,
      });
    }
  }, [project, form]);

  const handleEditCuePoints = (mediaId: string) => {
    // go({ to: `./media/${mediaId}/binder` });
    go({ to: `./media/${mediaId}/animation` });
  };
  const handleDeleteSuccess = () => {
    go({ to: `../` });
  };

  const headerButtons = isLoading ? (
    <></>
  ) : (
    <Space>
      {/* <Tooltip
          title={settingsApproved ? "Cannot edit settings after approval!" : ""}
        > */}
      {/* {!settingsApproved && (
        <Button
          size="large"
          shape="round"
          disabled={settingsApproved}
          icon={<SettingOutlined />}
          onClick={() => go({ to: `${projectRoute}/setup` })}
        >
          {t("media.projects.detail.setup")}
        </Button>
      )} */}
      <ProjectSecondaryMenu project={project} />

      {/* </Tooltip> */}
      {/* {!isEditing && (
          <Button size="large" shape="round" onClick={handleEdit}>
            Edit
          </Button>
        )}
        {isEditing && (
          <>
            <Button
              loading={updateMutationLoading}
              size="large"
              shape="round"
              type="primary"
              onClick={() => project && handleSave(project!)}
            >
              Save
            </Button>
          </> */}
      {/* )} */}
    </Space>
  );
  // const headerButtons = <ProjectStatus project={project} />;

  const { data: owner } = useOne<User>({
    resource: `/api/users`,
    id: project?.owner_id,
    queryOptions: {
      enabled: !!project?.owner_id,
    },
  });

  const ownerDetails = owner?.data
    ? `${owner.data.first_name?.charAt(0) ?? ""}${owner.data.last_name?.charAt(0) ?? ""}`.toLocaleUpperCase()
    : project?.owner_id;

  const editingMedia =
    project?.media.filter((media) => media.status !== "Published") || [];
  const publishedPreviewedMedia =
    project?.media.filter(
      (media) => media.status === "Published" || media.status === "Preview"
    ) || [];
  const currentMedia = project?.media?.[0];

  const areDocumentsReady = useMemo(() => {
    if (!project?.documents || project.documents.length === 0) return false;
    return project.documents.some(
      (document) => document.status === "ContentExtracted"
    );
  }, [project?.documents]);

  return (
    <Authenticated key="project_detail">
      <StyledWrapper>
        <Show
          goBack={null}
          // wrapperProps={{ className: "show-wrapper" }}
          contentProps={{
            style: {
              background: "transparent",
              padding: 0,
              boxShadow: "none",
              borderRadius: 0,
            },
          }}
          isLoading={isLoading}
          // breadcrumb={null}
          // breadcrumb={
          //   <div
          //     style={{
          //       paddingLeft: 24,
          //     }}
          //   >
          //     <Breadcrumb />
          //   </div>
          // }
          title={
            project ? (
              <ProjectHeader project={project} owner={owner?.data} />
            ) : (
              <></>
            )
          }
          headerButtons={headerButtons}
        >
          {project && (
            <Flex gap={24} vertical>
              <Flex
                justify="space-between"
                style={{
                  marginLeft: -24,
                  paddingLeft: 24,
                  paddingTop: 12,
                  paddingBottom: 12,
                  paddingRight: 24,
                  // borderBottom: `1px solid ${theme.colorBorder}`,
                  // boxShadow: `0px 5px 10px 0px ${theme.colorBorder}`,
                  backgroundColor: theme.colorPrimaryBg,
                }}
              >
                <ProjectLanguageAndContact project={project} />
                <ProjectStatus project={project} />
              </Flex>
              <Flex gap={50}>
                {["Published", "Ready", "Preview"].includes(
                  currentMedia?.status
                ) &&
                  currentMenuIndex === null && (
                    <ProjectMedia
                      refetchProjectDetail={refetchProjectDetail}
                      projectId={projectId}
                      id={project?.media[0].id}
                      url={project?.media[0]?.url}
                      created={project?.media[0]?.created}
                    />
                  )}

                <Flex
                  vertical
                  gap={20}
                  align="center"
                  style={{ width: "100%" }}
                >
                  <GradientText strong>
                    {t("media.projects.detail.toDoNext")}
                  </GradientText>
                  {/* <Flex justify="center" style={{ width: "100%" }}> */}
                  <ProjectPageMenu project={project} />
                  {/* </Flex> */}
                </Flex>
              </Flex>
            </Flex>
          )}
        </Show>
      </StyledWrapper>
    </Authenticated>
  );
};

const ProjectMedia = ({
  id,
  url,
  created,
  projectId,
  refetchProjectDetail,
}: {
  id: string;
  url: string;
  created: string;
  projectId: string;
  refetchProjectDetail: () => void;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const t = useTranslate();
  const { theme } = useAntTheme();
  const invalidate = useInvalidate();
  const { mutateAsync: regenerateMedia, isLoading: isRegenerating } =
    useCustomMutation<CustomResponse<BaseRecord>>();
  const handleRegenerate = async () => {
    try {
      await regenerateMedia({
        url: `/media/media/${id}/regenerate`,
        method: "post",
        values: {},
        successNotification: {
          type: "success",
          message: t("projects.components.ProjectMedias.regenerateSuccess"),
        },
      });

      refetchProjectDetail();
      // invalidate publications, as regenerating removes old publications
      invalidate({
        resource: `media/projects/${projectId}/publications`,
        invalidates: ["list"],
      });
    } catch (e) {
      console.debug(e);
    }
  };

  return (
    <Flex vertical align="center" gap={6}>
      <Typography.Text type="secondary" style={{ fontSize: "90%" }}>
        {t("media.projects.detail.generatedAt")}{" "}
        <DateField
          value={created}
          type="secondary"
          format="LLL"
          style={{ fontSize: "90%" }}
        />
      </Typography.Text>
      <Flex
        style={{
          border: "7px solid",
          borderColor: theme.colorOutlineBorder,
          borderRadius: 35,
          height: 470,
          aspectRatio: 0.5,
          backgroundImage: isPlaying ? "none" : `url(${backgroundImg})`,
          backgroundColor: "#000",
          backgroundSize: "cover",
        }}
        align="center"
        justify="center"
      >
        {isPlaying && (
          <ReactPlayer
            url={url}
            width="100%"
            height="100%"
            playing={isPlaying}
            style={{
              borderRadius: 35,
              overflow: "hidden",
            }}
            controls
          />
        )}

        {!isPlaying && (
          <Play
            size={70}
            color="white"
            weight="fill"
            onClick={() => {
              setIsPlaying(true);
            }}
          />
        )}
      </Flex>
      <Flex vertical>
        <Typography.Text
          type="secondary"
          style={{ fontSize: "90%", lineHeight: 1.1, textAlign: "center" }}
        >
          {t("media.projects.detail.regenerateVideoHint")}
        </Typography.Text>
        <Popconfirm title={t("buttons.confirm")} onConfirm={handleRegenerate}>
          <Button
            // icon={<StarFour size={12} weight="fill" />}
            style={{ marginTop: 6 }}
            size="middle"
            shape="round"
            loading={isRegenerating}
            disabled={isRegenerating}
          >
            {t("media.projects.detail.regenerateVideo")}
          </Button>
        </Popconfirm>
      </Flex>
    </Flex>
  );
};

const StyledWrapper = styled.div<{
  theme: GlobalToken;
}>`
  margin: -24px;
  // padding-top: 24px;

  .ant-page-header .ant-page-header-heading-title {
    overflow: visible;
  }
  .ant-breadcrumb {
    padding-top: 24px;
  }
  .ant-page-header {
  }
  .ant-page-header-heading,
  .ant-breadcrumb {
    background: ${({ theme }) => theme.colorPrimaryBg};
  }
  //corner gradient
  // background: linear-gradient(
  //   to top right,
  //   transparent,
  //   transparent 70%,
  //   ${({ theme }) => theme.colorPrimary} 150%
  // );
  .ant-page-header-content {
    padding-top: 0px;
    .ant-card-body {
      // padding: 0 !important;
      padding-top: 0px;
    }
  }
  .ant-page-header-content .ant-spin-container > .ant-card > .ant-card-body {
    // padding-left: 0;
    padding-right: 0; // right panel until frame
  }

  .ant-breadcrumb {
    padding-left: 24px;
  }
  // header buttons
  .ant-page-header-heading-extra {
    padding-right: 24px;
    display: flex;
  }

  div[data-resize-handle-active="keyboard"],
  div[data-resize-handle-active="pointer"] {
    width: 3px;
    margin: 0;
    background: ${({ theme }) => theme.colorPrimary};
    > div {
      background: none;
    }
  }
  .ant-card-actions > li:not(:last-child) {
    border: 0;
  }

  .ant-card-hoverable .ant-card-actions li {
    opacity: 0;
  }
  .ant-card-hoverable:hover .ant-card-actions li {
    opacity: 1;
  }
`;

const GradientText = styled(Typography.Text)<{
  theme: GlobalToken;
}>`
  display: inline-block;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colorText},
    ${({ theme }) => theme.colorPrimary}
  );
  font-size: 30px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
