import { useTranslate } from "@refinedev/core";
import { Flex, Popover, Button, Typography, Avatar, Divider } from "antd";
import { useGo } from "@refinedev/core";
import { useState } from "react";
import { User } from "types";
import { useAntTheme } from "hooks/useAntTheme";
import { UserOutlined } from "@ant-design/icons";
import { Buildings } from "@phosphor-icons/react";

export const UserMenu = ({
  collapsed = false,
  user,
  logout,
}: {
  collapsed: boolean;
  user: User | undefined;
  logout: false | JSX.Element | undefined;
}) => {
  const t = useTranslate();
  const go = useGo();
  const { theme } = useAntTheme();
  const [showUserMenu, setShowUserMenu] = useState(false);

  const handleUserMenuClick = (open: boolean, link: string) => {
    go({
      to: link,
    });

    setShowUserMenu(open);
  };

  return (
    <Flex
      align="center"
      justify="space-between"
      style={{
        padding: collapsed ? 12 : 15,
        position: "fixed",
        left: 0,
        bottom: "45px",
        background: `${theme.colorBgContainer}`,
        width: collapsed ? 60 : 250,
        height: "fit-content",
        transition: "width 0.2s ease",
      }}
    >
      <Popover
        placement="topRight"
        arrow={false}
        trigger="click"
        open={showUserMenu}
        onOpenChange={() => setShowUserMenu((prev) => !prev)}
        style={{ zIndex: 9999 }}
        title={
          <Flex
            vertical
            style={{
              paddingLeft: "15px",
            }}
          >
            <Typography.Text>
              {user?.first_name} {user?.last_name}
            </Typography.Text>
            <Typography.Text type="secondary" style={{ fontWeight: "500" }}>
              {user?.email}
            </Typography.Text>
          </Flex>
        }
        content={
          <Flex vertical align="start">
            <Divider plain style={{ margin: "10px 0px" }} />
            <Button
              block
              type="text"
              style={{ justifyContent: "start" }}
              icon={
                <span className="anticon">
                  <Buildings />
                </span>
              }
              onClick={() => handleUserMenuClick(false, "/organization")}
            >
              {t("src.components.UserMenu.organizationSettings")}
            </Button>
            <Button
              block
              type="text"
              style={{ justifyContent: "start" }}
              icon={<UserOutlined />}
              onClick={() => handleUserMenuClick(false, "/settings")}
            >
              {t("src.components.UserMenu.profileDetails")}
            </Button>
            {logout}
          </Flex>
        }
      >
        <Flex align="center" gap={10} style={{ cursor: "pointer" }}>
          <Avatar
            style={{
              backgroundColor: "#fde3cf",
              color: "#f56a00",
              minWidth: 36,
              minHeight: 36,
            }}
          >
            {user?.first_name?.[0]?.toUpperCase()}
          </Avatar>
          {!collapsed && (
            <Typography.Text type="secondary">
              {user?.first_name}
            </Typography.Text>
          )}
        </Flex>
      </Popover>
    </Flex>
  );
};
