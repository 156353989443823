import { motion } from "motion/react";
import { useEffect, useState } from "react";

interface FadeInFadeOutWrapperProps {
  children: React.ReactNode;
  fadeIn?: boolean;
  fadeOut?: boolean;
}

export const FadeInFadeOutWrapper: React.FC<FadeInFadeOutWrapperProps> = ({
  children,
  fadeIn = false,
  fadeOut = false,
}) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prev) => prev + 1);
  }, [fadeIn]);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <motion.div
      key={key}
      initial={fadeIn ? "hidden" : "visible"}
      animate={fadeOut ? "hidden" : "visible"}
      exit="visible"
      transition={{
        duration: fadeIn ? 2 : 1,
      }}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};
