import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Button, Space, Table, TableColumnsType } from "antd";
import { Authenticated, useList, CanAccess } from "@refinedev/core";
import { type PresenterProfileResponse } from "../types";
import { useOrganization } from "hooks/useOrganization";
import { UUID } from "../../../components/UUID";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { CreateButton, DateField, DeleteButton } from "@refinedev/antd";
import { List } from "@refinedev/antd";
import { addDateTz } from "../utils";
import { PresenterProfilePreviewView } from "../components/PresenterProfilePreviewView";
import { timestampPresenter } from "../components/ContactWithPresenter/helpers";
import { errorNotificationWithEntityIdsHandler } from "pages/onboarding/helpers";

export const PresenterProfilesListPage = () => {
  const t = useTranslate();
  const { organization } = useOrganization({});
  const { data, isLoading, refetch, isRefetching } =
    useList<PresenterProfileResponse>({
      resource: `media/${organization?.id}/presenter_profiles`,
    });

  const columns: TableColumnsType<PresenterProfileResponse> = [
    {
      title: t("media.presenters.index.id"),
      dataIndex: "id",
      key: "id",
      render: (id: string) => <UUID id={id} />,
    },
    {
      title: t("media.presenters.index.name"),
      dataIndex: "name",
      key: "name",
      render: (name, record: PresenterProfileResponse) =>
        timestampPresenter(name, record.created) || "N/A",
    },
    {
      title: t("media.presenters.index.voiceType"),
      dataIndex: ["voice_profile", "category"],
      key: "voice_category",
      render: (category) => category || "N/A",
    },
    {
      title: t("media.presenters.index.faceType"),
      dataIndex: ["face_profile", "category"],
      key: "face_category",
      render: (category) => category || "N/A",
    },
    {
      title: t("media.presenters.index.preview"),
      key: "preview_asset_path",
      render: (_, record: PresenterProfileResponse) => {
        return (
          <PresenterProfilePreviewView
            presenter={record}
            style={{ width: 100, height: 100 }}
          />
        );
      },
    },
    {
      title: t("media.presenters.index.creationDate"),
      dataIndex: "created",
      key: "created",
      render: (text) => <DateField value={addDateTz(text)} format="LLL" />,
    },
    {
      title: t("media.presenters.index.actions"),
      key: "actions",
      render: (_: any, _record: PresenterProfileResponse) => (
        <CanAccess resource="media_presenters" action="delete">
          <Space>
            <DeleteButton
              size="small"
              shape="round"
              resource={`media/${_record.organization_id}/presenter_profiles`}
              accessControl={{ enabled: false }}
              recordItemId={_record.id}
              errorNotification={(error) => {
                const result = errorNotificationWithEntityIdsHandler(
                  error,
                  "contact_ids"
                );
                return result;
              }}
            >
              {t("media.presenters.index.delete")}
            </DeleteButton>
          </Space>
        </CanAccess>
      ),
    },
  ];

  return (
    <Authenticated key="presenter_list">
      <List
        headerButtons={({ createButtonProps }) => (
          <Space>
            <Button
              icon={<SyncOutlined />}
              onClick={() => refetch()}
              loading={isRefetching}
              shape="circle"
              type="text"
            />
            {createButtonProps && (
              <CreateButton
                {...createButtonProps}
                icon={<PlusOutlined />}
                shape="round"
              >
                {t("media.presenters.index.createAPresenter")}
              </CreateButton>
            )}
          </Space>
        )}
      >
        <Table<PresenterProfileResponse>
          loading={isLoading || isRefetching}
          dataSource={data?.data}
          columns={columns}
          pagination={{ pageSize: 50 }}
        />
      </List>
    </Authenticated>
  );
};

export default PresenterProfilesListPage;
