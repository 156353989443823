import { useTranslate } from "@refinedev/core";
import { GoogleDriveLogo } from "@phosphor-icons/react";
import { Button, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { fetchText } from "utils";

export const DocumentViewer = ({
  url,
  allow = false,
}: {
  url: string;
  allow?: boolean;
}) => {
  const t = useTranslate();
  const viewerUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(url)}`;
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);
  const [allowExternalPreview, setAllowExternalPreview] = useState(allow);
  const isHtml = url.toLowerCase().includes(".html");
  const contentUrl = isHtml ? undefined : viewerUrl;
  const [srcDoc, setSrcDoc] = useState<string | undefined>();

  // https://stackoverflow.com/questions/50949201/iframe-downloads-the-html-source-instead-of-displaying-it

  useEffect(() => {
    if (isHtml) {
      setAllowExternalPreview(true);
      (async () => {
        setSrcDoc(await fetchText(url));
      })();
    }
  }, [isHtml, url]);

  useEffect(() => {
    if (allowExternalPreview && iframeRef?.contentWindow && !isHtml) {
      // hack but not always working also
      if (contentUrl) {
        iframeRef.contentWindow.location.href = contentUrl;
      }
    }
  }, [allowExternalPreview, iframeRef?.contentWindow, url, contentUrl, isHtml]);

  if (!srcDoc && isHtml) return <Spin fullscreen />;

  return (
    <>
      {!allowExternalPreview ? (
        <Result
          icon={<GoogleDriveLogo size="100" />}
          subTitle={t("src.components.DocumentViewer.allowGoogle")}
          extra={
            <Button
              onClick={() => setAllowExternalPreview(true)}
              shape="round"
              size="large"
            >
              {t("src.components.DocumentViewer.allowAccessTo")}
            </Button>
          }
        />
      ) : (
        <iframe
          key={url}
          srcDoc={srcDoc}
          // sometimes not working ..weird
          src={contentUrl}
          ref={setIframeRef}
          title={t("src.components.DocumentViewer.preview")}
          height={"100%"}
          width={"100%"}
          style={{ border: 0 }}
        />
      )}
    </>
  );
};
