import { forwardRef } from "react";
import { TemplateType } from "./types";
import { useVolatileBoundStore } from "store";

export const IntroductionClosingPreview = forwardRef<HTMLVideoElement>(
  (props, ref) => {
    const { view, storeIntro, storeClosing } = useVolatileBoundStore(
      (store) => ({
        view: store.templateState.view,
        storeIntro: store.templateState.intro,
        storeClosing: store.templateState.closing,
      })
    );

    const showIntro = storeIntro.enable && view === TemplateType.INTRODUCTION;
    const showClosing = storeClosing.enable && view === TemplateType.CLOSING;
    const videoPath = showIntro ? storeIntro.assetPath : storeClosing.assetPath;

    return (
      <>
        {(showIntro || showClosing) && (
          <video
            ref={ref}
            src={videoPath}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
            }}
          >
            <track kind="captions" src="" />
          </video>
        )}
      </>
    );
  }
);

IntroductionClosingPreview.displayName = "IntroductionClosingPreview";
