import { useTranslate } from "@refinedev/core";
import { Button, Flex } from "antd";
import { BrandKit } from "pages/media/types";
import { useEffect, useRef, useState } from "react";
import { motion } from "motion/react";
import { FontPreviewText } from "pages/media/brandkit/components/FontPreview";
import styled from "styled-components";
import { Play } from "@phosphor-icons/react";

export const TransitionPreview = ({
  brandKit,
  hasTransition,
  reloadTrigger,
}: {
  brandKit?: BrandKit;
  hasTransition: boolean;
  reloadTrigger: boolean;
}) => {
  const assets = brandKit?.assets;
  const palette = brandKit?.palette;

  const videoRef = useRef<HTMLVideoElement>(null);

  const logo = assets?.find((asset) => asset.purpose === "Logo");
  const banner = assets?.find((asset) => asset.purpose === "Banner");
  const titleFont = assets?.find((asset) => asset.purpose === "Title");
  const textFont = assets?.find((asset) => asset.purpose === "Text");
  const background = assets?.find((asset) => asset.purpose === "Background");
  const transition = assets?.find((asset) => asset.purpose === "Transition");

  const PREVIEW_WIDTH = 200;
  const PREVIEW_HEIGHT = 370;

  const [showTransition, setShowTransition] = useState(true);
  const [key, setKey] = useState(0);

  const t = useTranslate();

  const handleReload = () => {
    setKey((prev) => prev + 1);
    setShowTransition(true);
  };

  useEffect(() => {
    //Show transition when item clicked
    if (reloadTrigger) {
      handleReload();
    }
  }, [reloadTrigger]);

  useEffect(() => {
    // video cut at first load, hide it !
    // TODO improve
    if (key === 0) return;

    const video = videoRef.current;
    if (video) {
      video.volume = 0.1;
      video.play();
    }

    const timer = setTimeout(() => {
      setShowTransition(false);
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    }, 4000);

    return () => clearTimeout(timer);
  }, [key]);

  return (
    <TransitionPreviewContainer
      key={key}
      width={PREVIEW_WIDTH}
      height={PREVIEW_HEIGHT}
      backgroundColor={palette?.primary_color ?? "#FFFFFFF"}
      backgroundUrl={background ? background.path : "unset"}
    >
      {hasTransition && (
        <Button
          type="text"
          size="large"
          icon={<Play weight="fill" height={40} />}
          onClick={(e) => {
            e.preventDefault(); // present select list item on replay
            handleReload();
          }}
          style={{ zIndex: 10, position: "absolute", top: -10, right: -60 }}
        />
      )}
      {hasTransition && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            opacity: showTransition ? 1 : 0,
            transition: "opacity 1s ease-out",
            borderRadius: 25,
            overflow: "hidden",
            top: 0,
            zIndex: 9,
            backgroundColor: palette?.primary_color ?? "#ffffff",
          }}
        >
          {transition && (
            <video
              preload="none"
              ref={videoRef}
              src={transition.path}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          )}
        </div>
      )}
      {hasTransition && (
        <Flex
          align="center"
          justify="center"
          style={{
            position: "absolute",
            top: 0,
            zIndex: 9,
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <motion.div
            initial={{ x: "-300px" }}
            animate={{ x: "0px" }}
            transition={{ duration: 1 }}
          >
            <motion.div
              animate={{ x: "300px" }}
              transition={{
                duration: 1,
                delay: 3,
              }}
            >
              <FontPreviewText
                font={titleFont?.path ?? ""}
                text={t("media.brandkit.mockup.title")}
                style={{
                  textAlign: "center",
                  width: PREVIEW_WIDTH * 0.8,
                  fontSize: (185 * 40) / PREVIEW_WIDTH,
                  letterSpacing: 2,
                  color: palette?.title_color ?? "#000",
                  WebkitTextStroke: `2px ${palette?.title_stroke_color ?? ""}`,
                }}
              />
            </motion.div>
          </motion.div>
        </Flex>
      )}
      {logo?.path && (
        <Flex
          justify="center"
          align="center"
          style={{
            height: PREVIEW_HEIGHT * 0.1,
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            overflow: "hidden",
            zIndex: 5,
            borderRadius: "28px 28px 0px 0px",
            backgroundColor: palette?.secondary_color ?? "#ffffff",
            backgroundImage: banner ? `url(${banner?.path})` : undefined,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <></>
          <img
            src={logo.path}
            alt="logo"
            style={{
              height: "90%",
              width: "auto",
              maxWidth: "100%",
            }}
          />
        </Flex>
      )}

      <Flex
        style={{
          position: "absolute",
          top: PREVIEW_HEIGHT * 0.2,
          left: -10,
          zIndex: 2,
          width: PREVIEW_WIDTH,
        }}
        justify="center"
      >
        <FontPreviewText
          style={{
            fontSize: (185 * 20) / PREVIEW_WIDTH,
            letterSpacing: 1.2,
            width: PREVIEW_WIDTH * 0.9,
            height: PREVIEW_HEIGHT * 0.35,
            textAlign: "center",
            color: palette?.subtitle_color ?? "#000",
            WebkitTextStroke: `1px ${palette?.subtitle_stroke_color ?? ""}`,
          }}
          font={textFont?.path ?? ""}
          text={t("media.brandkit.mockup.subtitles")}
        />
      </Flex>
    </TransitionPreviewContainer>
  );
};

interface TransitionPreviewContainerProps {
  width: number;
  height: number;
  backgroundColor: string;
  backgroundUrl: string;
}

const TransitionPreviewContainer = styled.div<TransitionPreviewContainerProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: 10px solid black;
  border-radius: 38px;
  background-color: ${(props) => props.backgroundColor};
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`;
