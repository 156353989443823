import { MediaProjectResponse } from "pages/media/types";
import { Space, Typography } from "antd";
import { ReactNode } from "react";
import { File, Play, StarFour, WarningDiamond } from "@phosphor-icons/react";
import { useTranslate } from "@refinedev/core";
import Icon, {
  Loading3QuartersOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useAntTheme } from "hooks/useAntTheme";
import { CascadingText } from "components/CascadingText";

type Status = {
  id: number;
  icon?: ReactNode;
  text: string;
  link?: string;
};

export const ProjectStatus = ({
  project,
  mediaIndex = 0,
}: {
  project: MediaProjectResponse;
  mediaIndex?: number;
}) => {
  const t = useTranslate();
  const { theme } = useAntTheme();

  const statuses: Status[] = [
    {
      id: 1,
      icon: (
        <div style={{ position: "relative", width: 24 }}>
          <File size={14} weight="fill" />
          <LoadingOutlined
            spin
            style={{ position: "absolute", top: -4, left: -5, fontSize: 24 }}
          />
        </div>
      ),
      text: t("media.projects.detail.processingDocument"),
    },
    {
      id: 2,
      icon: (
        <div style={{ position: "relative", width: 24 }}>
          <Play size={14} weight="fill" />
          <Loading3QuartersOutlined
            spin
            style={{ position: "absolute", top: -4, left: -5, fontSize: 24 }}
          />
        </div>
      ),
      text: t("media.projects.detail.generatingVideo"),
    },
    {
      id: 3,
      icon: <StarFour size={24} weight="fill" />,
      text: t("media.projects.detail.videoGenerated"),
    },
    {
      id: 3,
      icon: <WarningDiamond size={24} weight="fill" color={theme.colorError} />,
      text: t("projects.components.ProgressHelp.anErrorOccurred"),
    },
  ];

  // Statuses
  const settingsDraft = project?.settings.status === "Draft";
  const settingsApproved = project?.settings.status === "Approved";
  const documentStored = project?.documents?.[0]?.status === "Stored";
  const documentExpected = project?.documents?.[0]?.status === "Expected";
  const documentReady =
    project?.documents?.some(
      (document) => document.status === "ContentExtracted"
    ) ?? false;
  const documentInProgress =
    project?.documents?.[0]?.status === "ExtractingContent" && !documentReady;
  const storyboardPending = project?.storyboards?.[0]?.status === "Pending";
  const storyboardGenerating =
    project?.storyboards?.[0]?.status === "Generating";
  const storyboardReviewing = project?.storyboards?.[0]?.status === "Reviewing";
  const storyboardApproved = project?.storyboards?.[0]?.status === "Approved";

  const mediaInDraft = project?.media?.[mediaIndex]?.status === "Draft";
  const mediaInPending = project?.media?.[mediaIndex]?.status === "Pending";
  const mediaInProgress = project?.media?.[mediaIndex]?.status === "Generating";
  const mediaReady = project?.media?.[mediaIndex]?.status === "Ready";
  const mediaPublished = project?.media?.[mediaIndex]?.status === "Published";
  const mediaPreview = project?.media?.[mediaIndex]?.status === "Preview";
  const mediaError = project?.media?.[mediaIndex]?.status === "Error";
  const mediaCancelled = project?.media?.[mediaIndex]?.status === "Cancelled";

  const getStatus = (): Status => {
    if (documentInProgress || documentExpected || documentStored) {
      return statuses[0];
    }
    if (
      (storyboardApproved && mediaInProgress) ||
      ((mediaInDraft || mediaInPending) && storyboardApproved)
    ) {
      return statuses[1];
    }
    if (mediaReady || mediaPublished || mediaPreview) {
      return statuses[2];
    }
    if (mediaError) return statuses[3];
    return;
  };

  const currentStatus = getStatus();

  return (
    <>
      {currentStatus && (
        <Space align="center">
          <div style={{ marginTop: 5 }}>{currentStatus.icon}</div>
          <Typography.Text style={{ fontSize: 20 }} strong>
            <CascadingText>{currentStatus.text}</CascadingText>
          </Typography.Text>
        </Space>
      )}
    </>
  );
};
