import { DraggableComponent } from "./DraggableComponent";
import { forwardRef } from "react";
import { FontPreviewText } from "pages/media/brandkit/components/FontPreview";
import { useVolatileBoundStore } from "store";
import { FadeInFadeOutWrapper } from "./FadeInFadeOutWrapper";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./TemplatePreview";
import { TabKeys } from "./types";

export const TransitionPreview = forwardRef<HTMLVideoElement>((props, ref) => {
  const {
    titleStyle,
    currentChapter,
    setTitleStyle,
    setCurrentItem,
    storeTransition,
    setActiveTab,
  } = useVolatileBoundStore((store) => ({
    titleStyle: store.templateState.titleStyle,
    currentChapter: store.templateState.currentChapter,
    currentTemplateId: store.templateState.currentTemplateId,
    setBackground: store.setBackground,
    setTitleStyle: store.setTitleStyle,
    setCurrentItem: store.setCurrentItem,
    storeTransition: store.templateState.transition,
    setActiveTab: store.setActiveTab,
  }));

  return (
    <>
      {storeTransition.enable && storeTransition.view === "Video" && (
        <video
          ref={ref}
          src={storeTransition.assetPath}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        >
          <track kind="captions" src="" />
        </video>
      )}
      <DraggableComponent
        position={{
          x: titleStyle.position.x,
          y: titleStyle.position.y,
        }}
        size={{ width: "fit-content", height: "fit-content" }}
        resize={true}
        drag={true}
        onDragStop={setTitleStyle}
        onFocus={() => {
          setCurrentItem({ type: "Title" });
          setActiveTab(TabKeys.text);
        }}
      >
        <FadeInFadeOutWrapper
          fadeIn={titleStyle?.fadeIn}
          fadeOut={titleStyle?.fadeOut}
        >
          <div className="font-rect">
            <FontPreviewText
              font={titleStyle?.titleFont}
              text={currentChapter}
              style={{
                width: titleStyle.size.width * DEFAULT_WIDTH,
                height: titleStyle.size.height * DEFAULT_HEIGHT,
                fontSize: titleStyle.fontSize * 2,

                fontWeight: titleStyle.bold ? "bold" : "",
                fontStyle: titleStyle.italic ? "italic" : "",
                textDecoration: titleStyle.underline ? "underline" : "",
                backgroundColor: titleStyle.titleBackground,
                padding: 5,
                letterSpacing: 2,
                color: titleStyle.title_color,
                WebkitTextStroke: `2px ${titleStyle.title_stroke_color}`,
              }}
            />
          </div>
        </FadeInFadeOutWrapper>
      </DraggableComponent>
    </>
  );
});

TransitionPreview.displayName = "TransitionPreview";
