import { Flex, Typography } from "antd";
import { TDetailedStoryboard } from "pages/media/types";

export const Present = ({
  storyboard,
}: {
  storyboard: TDetailedStoryboard;
}) => {
  return (
    <Flex vertical style={{ paddingTop: 10 }} gap={0}>
      {/* <Flex justify="space-between">
        <Space size="small">
          {canApprove && (
            <Popconfirm
              overlayStyle={{ maxWidth: 400 }}
              title={t(
                "components.storyboard.StoryboardEditor.storyboardApproval"
              )}
              description={t(
                "components.storyboard.StoryboardEditor.validation",
                {
                  estimatedGenerationTime,
                }
              )}
              onConfirm={handleStoryboardApproval}
              placement="topLeft"
            >
              <Button
                icon={
                  <span className="anticon">
                    <ArrowRight weight="bold" />
                  </span>
                }
                shape="round"
                size="large"
                type="primary"
                loading={isStoryboardApprovalLoading}
                disabled={isStoryboardApprovalLoading}
              >
                {t("components.storyboard.StoryboardEditor.approve")}
              </Button>
            </Popconfirm>
          )}
          <Button
            icon={<EditOutlined />}
            shape="round"
            size="large"
            onClick={() => setDisplayMode("compact")}
          >
            {t("components.storyboard.StoryboardEditor.edit")}
          </Button>
        </Space>
      </Flex> */}

      {storyboard?.sections?.map((section) => {
        return (
          <Flex key={section.id} vertical>
            <Typography.Title level={4}>{section.topic}</Typography.Title>
            <Typography.Text style={{ fontSize: 18 }}>
              {section.script?.content}
            </Typography.Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
