import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { Alert, AlertProps, Flex, Tooltip, Typography } from "antd";
import { useAntTheme } from "hooks/useAntTheme";
import { useState } from "react";
import { StyledMarkdownFieldWrapper } from "./StyledMarkdownFieldWrapper";
import { MarkdownField } from "@refinedev/antd";

type ExpandableAlertProps = AlertProps & {
  maxHeight?: number;
  onExpand?: () => void;
};

export const ExpandableAlert: React.FC<ExpandableAlertProps> = ({
  type = "info",
  icon,
  message,
  description,
  maxHeight = 200,
  onExpand,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useAntTheme();
  const t = useTranslate();

  const colorType = {
    success: {
      bg: theme.colorSuccessBg,
      button: theme.colorSuccessActive,
    },
    warning: {
      bg: theme.colorWarningBg,
      button: theme.colorWarningActive,
    },
    error: {
      bg: theme.colorErrorBg,
      button: theme.colorErrorActive,
    },
    info: {
      bg: theme.colorInfoBg,
      button: theme.colorInfoActive,
    },
  };

  const expandingIconBackground = `linear-gradient(
    to bottom,
    ${colorType[type].bg}00 0%,
    ${colorType[type].bg}80 20%,
    ${colorType[type].bg}FF 100%
)`;

  return (
    <Flex
      style={{
        width: "100%",
        // https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css
        // maxHeight: isExpanded ? 1000 : maxHeight,
        height: isExpanded ? "auto" : maxHeight,
        maxHeight: isExpanded ? 1000 : maxHeight,
        overflow: "hidden",
        position: "relative",
        transition: "max-height 0.5s ease-in",
        marginTop: 20,
        ...rest.style,
      }}
    >
      <Alert
        icon={icon}
        type={type}
        message={message}
        description={description}
        {...rest}
        action={
          <Flex
            justify="center"
            align="end"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              paddingBottom: 4,
              background: expandingIconBackground,
              width: "100%",
              height: 60,
              color: colorType[type].button,
              cursor: "pointer",
            }}
            onClick={() => {
              setIsExpanded((prev) => !prev);
              setIsOpen(false);
              if (onExpand) {
                onExpand();
              }
            }}
          >
            <Tooltip
              title={
                isExpanded
                  ? t("src.components.ExpandableAlert.less")
                  : t("src.components.ExpandableAlert.more")
              }
              autoAdjustOverflow
              open={isOpen}
              arrow={false}
            >
              <div
                role="button"
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
              >
                {isExpanded ? (
                  <UpCircleOutlined style={{ fontSize: 24 }} />
                ) : (
                  <DownCircleOutlined style={{ fontSize: 24 }} />
                )}
              </div>
            </Tooltip>
          </Flex>
        }
        style={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
          paddingBottom: isExpanded ? 60 : 0,
        }}
      />
    </Flex>
  );
};

export const ExpandableAlertInfoMarkdown = ({
  title,
  text,
  style,
  ...rest
}: ExpandableAlertProps & {
  title: string;
  text: string;
  style?: React.CSSProperties;
}) => {
  const { theme } = useAntTheme();

  return (
    <ExpandableAlert
      description={
        <StyledMarkdownFieldWrapper color={theme.colorInfoActive}>
          <Typography.Title level={5} style={{ margin: 0, marginBottom: 10 }}>
            {title}
          </Typography.Title>
          <MarkdownField value={text} />
        </StyledMarkdownFieldWrapper>
      }
      {...rest}
    />
  );
};
