import { useGetLocale, useTranslate } from "@refinedev/core";
import {
  Card,
  Space,
  Flex,
  Typography,
  Empty,
  Row,
  Col,
  Modal,
  Tooltip,
  GlobalToken,
  CardProps,
} from "antd";
import { ArrowRight, BookOpenText } from "@phosphor-icons/react";
import { MediaProjectResponse } from "pages/media/types";
import { ProjectSettingForm } from "./ProjectSettingForm";
import { useState } from "react";
import styled from "styled-components";
import { StoryboardStatusTag } from "pages/media/components/StoryboardStatus";
import { getLanguageName, getLocaleCountryCode } from "pages/media/utils";
import { useGo } from "@refinedev/core";
import { useAntTheme } from "hooks/useAntTheme";
import { ProjectProgress } from "./ProjectProgress";

export const ProjectStoryboards = ({
  project,
}: {
  project: MediaProjectResponse;
}) => {
  const [editing, setEditing] = useState(false);
  const go = useGo();
  const { theme } = useAntTheme();
  const t = useTranslate();
  const getLocale = useGetLocale();
  const currentLanguage = getLocale();

  const hasSBApproved = project?.storyboards.some(
    (sb) => sb.status === "Approved"
  );

  return (
    <Flex vertical gap={20}>
      <Flex align="center" justify="space-between">
        <Space size="middle">
          <BookOpenText height={50} width={50} color={"grey"} />
          <Typography.Title level={4} style={{ margin: 0 }}>
            {t("projects.components.ProjectStoryboards.storyboards")}
          </Typography.Title>
        </Space>
      </Flex>
      {project && project.storyboards.length === 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t(
            "projects.components.ProjectStoryboards.noExistingStoryboards"
          )}
        />
      ) : (
        <Flex vertical gap={30}>
          <Row gutter={[20, 20]}>
            {project.storyboards.map((storyboard, index) => {
              const storyboardIsReady = ["Reviewing", "Approved"].includes(
                storyboard?.status
              );
              return (
                <Col
                  key={index}
                  xl={{ flex: "33%" }}
                  md={{ flex: "50%" }}
                  xs={{ flex: "100%" }}
                >
                  <StoryboardCard
                    bordered
                    hoverable
                    onClick={
                      !storyboard.asset_id
                        ? () =>
                            go({
                              to: `/media/projects/${project?.id}/storyboard/loading`,
                            })
                        : () =>
                            go({
                              to: `/media/projects/${project?.id}/storyboard/${storyboard.asset_id}`,
                            })
                    }
                  >
                    <Flex justify="space-between" wrap="wrap" gap={10}>
                      <Space>
                        <Tooltip
                          title={storyboard.language}
                          showArrow={false}
                          placement="left"
                          mouseEnterDelay={1}
                        >
                          <div
                            className={`fi-${getLocaleCountryCode(storyboard.language)}`}
                            style={{
                              width: "24px",
                              height: "14px",
                              backgroundSize: "cover",
                            }}
                          />
                        </Tooltip>
                        <Typography.Text strong ellipsis={true}>
                          {currentLanguage &&
                            getLanguageName(
                              storyboard.language,
                              currentLanguage
                            )}
                        </Typography.Text>
                      </Space>
                      <StoryboardStatusTag
                        status={storyboard.status}
                        tooltip={
                          storyboard.status === "Reviewing"
                            ? t(`media.projects.detail.clickToReviewSB`)
                            : null
                        }
                      />
                    </Flex>
                    {storyboardIsReady && (
                      <Flex
                        align="center"
                        justify="end"
                        gap={10}
                        style={{ marginTop: 30 }}
                      >
                        <Typography.Text
                          type="secondary"
                          strong
                          style={{ fontSize: 14 }}
                        >
                          {t(
                            "projects.components.ProgressHelp.reviewStoryboard"
                          )}
                        </Typography.Text>
                        <ArrowRight
                          size={20}
                          weight="bold"
                          style={{
                            color: theme.colorTextSecondary,
                          }}
                        />
                      </Flex>
                    )}
                    {storyboard?.status === "Generating" && (
                      <Flex align="center" style={{ marginTop: 20 }}>
                        <ProjectProgress
                          creationTime={""}
                          processDuration={3}
                        />
                      </Flex>
                    )}
                  </StoryboardCard>
                </Col>
              );
            })}
            {/* Button is hidden until feature is ready */}
            {/* {hasSBApproved && (
              <Col
                xl={{ flex: "33%" }}
                md={{ flex: "50%" }}
                xs={{ flex: "100%" }}
              >
                <StoryboardCard
                  bordered
                  hoverable
                  onClick={() => {
                    setEditing(true);
                  }}
                  style={{
                    textAlign: "center",
                    borderStyle: "dashed",
                    borderColor: theme.colorBorder,
                    background: "none",
                    cursor: "pointer",
                  }}
                >
                  <PlusOutlined />
                  {t("projects.components.ProjectStoryboards.addALanguage")}
                </StoryboardCard>
              </Col>
            )} */}
            <Modal
              open={editing}
              onCancel={() => setEditing(false)}
              footer={null}
              styles={{ body: { padding: 30 } }}
            >
              <ProjectSettingForm<string | null>
                projectId={project.id}
                title={t("projects.components.ProjectStoryboards.addALanguage")}
                subtitle={t(
                  "projects.components.ProjectStoryboards.chooseALanguage"
                )}
                icon={<BookOpenText size={24} />}
                value={null}
                type="NewStoryboard"
                onClose={() => setEditing(false)}
              />
            </Modal>
          </Row>
        </Flex>
      )}
    </Flex>
  );
};

const StoryboardCard = styled(Card)<
  CardProps & {
    theme: GlobalToken;
  }
>`
  // background-color: rgba(217, 217, 217, 0.4);
  cursor: pointer;
  min-width: 300px;

  &.ant-card-hoverable:hover {
    border-color: ${({ theme }) => theme.colorPrimary} !important;
    box-shadow: none;
  }
`;
