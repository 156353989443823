import { useCustom } from "@refinedev/core";
import { getPublicationUrl } from "pages/media/utils";
import { useEffect, useState } from "react";

export const useOpenPublication = ({
  projectId,
  publicationId,
  requiresAccessToken = false,
}: {
  projectId: string;
  publicationId: string;
  requiresAccessToken?: boolean;
}) => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data: accessToken } = useCustom({
    method: "get",
    url: `/media/projects/${projectId}/publications/${publicationId}/access_token`,
    queryOptions: {
      enabled: Boolean(projectId) && Boolean(publicationId) && shouldFetch,
    },
  });
  const openPublication = () => {
    if (requiresAccessToken) {
      setShouldFetch(true);
    } else {
      const url = getPublicationUrl({
        projectId,
        publicationId,
      });
      window.open(url);
    }
  };

  useEffect(() => {
    if (shouldFetch && accessToken?.data) {
      const url = getPublicationUrl({
        projectId,
        publicationId,
        accessToken: accessToken?.data as unknown as string,
      });
      window.open(url);
      setShouldFetch(false);
    }
  }, [accessToken, shouldFetch]);

  return { accessToken, openPublication };
};
