import { ArrowRight, Lock, Pencil } from "@phosphor-icons/react";
import { Flex, Space, Typography } from "antd";
import { ReactNode, useRef } from "react";
import { useHover } from "usehooks-ts";

export function SettingsItem<T>({
  title,
  content,
  icon,
  onClick,
  editable = false,
  locked = false,
  ...props
}: {
  title: string;
  content: ReactNode;
  icon?: ReactNode;
  onClick?: (() => void) | null;
  editable?: boolean;
  locked?: boolean;
}) {
  const ref = useRef(null);
  const isHover = useHover(ref);

  const handleClick = () => {
    onClick?.();
  };
  return (
    <Flex
      gap={10}
      ref={ref}
      align="center"
      style={{
        height: 30,
        cursor: "pointer",
      }}
      justify="space-between"
      onClick={handleClick}
    >
      <Space size={"middle"} align="center">
        <Space size={"small"} align="center" style={{ width: 150 }}>
          <span style={{ opacity: 0.6 }} className="anticon">
            {icon}
          </span>
          <Typography.Text style={{ fontWeight: "bold", fontSize: "0.9em" }}>
            {title}
          </Typography.Text>
        </Space>
        <Typography.Text
          style={{ fontSize: "0.9rem", overflow: "visible" }}
          ellipsis={true}
        >
          {content}
        </Typography.Text>
      </Space>
      <span style={{ opacity: 0.7 }}>
        {isHover && onClick && !editable && <ArrowRight size={26} />}
        {isHover && editable && !locked && <Pencil size={26} />}
        {isHover && locked && <Lock size={26} />}
      </span>
    </Flex>
  );
}
