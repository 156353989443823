import React, { useMemo, useState } from "react";
import {
  Authenticated,
  useTranslate,
  useGo,
  CanAccess,
  useList,
} from "@refinedev/core";
import { CreateButton, List, DeleteButton } from "@refinedev/antd";
import {
  Table,
  Typography,
  Space,
  Flex,
  Segmented,
  Card,
  Row,
  Col,
} from "antd";
import {
  PlusOutlined,
  MenuOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { Swatches } from "@phosphor-icons/react";
import { errorNotificationWithEntityIdsHandler } from "pages/onboarding/helpers";
import styled from "styled-components";
import { ColumnsType } from "antd/es/table";
import { BrandKit, BrandKitAsset } from "../types";
import { useOrganization } from "hooks/useOrganization";
import { ExpandableAlertInfoMarkdown } from "components/ExpandableAlert";
import { BrandKitMockup } from "./components/BrandKitMockup";
import { BrandKitPreview } from "../components/BrandKitPreview";
import { BrandKitAssetResponseWithTypedPurpose } from "./components/BrandKitAssets";

const { Text } = Typography;

type ViewMode = "table" | "grid";

export const colorKeys = [
  "primary_color",
  "secondary_color",
  "title_color",
  "title_stroke_color",
  "subtitle_color",
  "subtitle_stroke_color",
  "highlight_color",
];

const ColorRect = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border: 1px solid #d9d9d9;
  background-color: ${(props) => props.color};
`;

export const BrandKitList: React.FC = () => {
  const [viewMode, setViewMode] = useState<ViewMode>("grid");
  const t = useTranslate();
  const go = useGo();
  const { organization } = useOrganization({});
  const organizationId = organization?.id;

  const { data, isLoading } = useList<BrandKit>({
    resource: `media/${organizationId}/brand_kits`,
  });
  const sortedData = useMemo(
    () => data?.data?.sort((a, b) => a.name.localeCompare(b.name)),
    [data?.data]
  );
  const columns: ColumnsType<BrandKit> = [
    {
      title: t("media.brandkit.index.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("media.brandkit.index.primaryColor"),
      dataIndex: ["palette", "primary_color"],
      key: "primary_color",
      render: (color: string) => (
        <Space align="center">
          <ColorRect color={color} />
          <Text>{color}</Text>
        </Space>
      ),
    },
    {
      title: t("media.brandkit.index.secondaryColor"),
      dataIndex: ["palette", "secondary_color"],
      key: "secondary_color",
      render: (color: string | undefined) =>
        color ? (
          <Space align="center">
            <ColorRect color={color} />
            <Text>{color}</Text>
          </Space>
        ) : null,
    },
    {
      title: t("media.brandkit.index.assets"),
      dataIndex: "assets",
      key: "assets",
      render: (assets: BrandKitAsset[]) => assets.length,
    },
    {
      title: t("media.brandkit.index.actions"),
      key: "actions",
      render: (_: unknown, record: BrandKit) => (
        <CanAccess resource="media_brandkits" action="delete">
          <Space>
            <DeleteButton
              size="small"
              shape="round"
              resource={`media/${organizationId}/brand_kits`}
              recordItemId={record.id}
              accessControl={{ enabled: false }}
              errorNotification={(error) => {
                const result = errorNotificationWithEntityIdsHandler(
                  error,
                  "project_ids"
                );
                return result;
              }}
            >
              {t("media.brandkit.index.delete")}
            </DeleteButton>
          </Space>
        </CanAccess>
      ),
    },
  ];

  const BrandKitGrid = () => (
    <Row gutter={[16, 16]}>
      {sortedData?.map((item) => (
        <Col
          xl={6}
          xs={12}
          key={item.id}
          style={{ display: "flex" }} // to make all cards same height
        >
          <Card
            onClick={(e) => {
              const target = e.target as HTMLElement;

              if (
                target.closest(".ant-radio-button") ||
                target.closest(".ant-radio-group") ||
                target.closest(".ant-btn")
              ) {
                return;
              }
              go({
                to: {
                  resource: "media_brandkits",
                  id: item.id,
                  action: "show",
                },
              });
            }}
            hoverable
            style={{ flex: 1 }}
            styles={{
              body: {
                height: "100%",
              },
            }}
          >
            <Flex
              vertical
              justify="space-between"
              gap={10}
              style={{ height: "100%" }}
            >
              <Flex gap={10} vertical>
                <BrandKitPreview brandkit={item} />
                <Flex justify="center">
                  <BrandKitMockup
                    assets={
                      item?.assets as BrandKitAssetResponseWithTypedPurpose[]
                    }
                    colors={item?.palette}
                    width={200}
                  />
                </Flex>
              </Flex>
              <CanAccess resource="media_brandkits" action="delete">
                <Space>
                  <DeleteButton
                    size="small"
                    shape="round"
                    resource={`media/${organizationId}/brand_kits`}
                    recordItemId={item.id}
                    accessControl={{ enabled: false }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {t("media.brandkit.index.delete")}
                  </DeleteButton>
                </Space>
              </CanAccess>
            </Flex>
          </Card>
        </Col>
      ))}
    </Row>
  );

  return (
    <Authenticated key="brandkits">
      <List
        title={t("media.brandkit.index.brandKits")}
        headerButtons={({ createButtonProps }) => (
          <Flex gap={16} align="center">
            <Segmented<ViewMode>
              options={[
                {
                  value: "table",
                  icon: <MenuOutlined />,
                },
                {
                  value: "grid",
                  icon: <AppstoreOutlined />,
                },
              ]}
              value={viewMode}
              onChange={(value) => setViewMode(value)}
            />
            {createButtonProps && (
              <CreateButton
                {...createButtonProps}
                icon={<PlusOutlined />}
                shape="round"
              >
                {t("media.brandkit.index.createABrandkit")}
              </CreateButton>
            )}
          </Flex>
        )}
      >
        <Flex vertical gap={30}>
          <ExpandableAlertInfoMarkdown
            title={t(
              "projects.components.ProjectCreateWizard.brandKitHint.title"
            )}
            text={t(
              "projects.components.ProjectCreateWizard.brandKitHint.text"
            )}
            icon={<Swatches size={60} />}
            showIcon
          />

          {viewMode === "grid" ? (
            <BrandKitGrid />
          ) : (
            <Table<BrandKit>
              dataSource={sortedData}
              columns={columns}
              rowKey="id"
              loading={isLoading}
              onRow={(record) => ({
                onClick: (event) => {
                  const target = event.target as HTMLElement;
                  if (!target.closest(".ant-btn")) {
                    go({
                      to: {
                        resource: "media_brandkits",
                        id: record.id,
                        action: "show",
                      },
                    });
                  }
                },
                style: { cursor: "pointer" },
              })}
            />
          )}
        </Flex>
      </List>
    </Authenticated>
  );
};
