import { useTranslate } from "@refinedev/core";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Slider,
  Flex,
  ConfigProvider,
  Button,
  Radio,
  Tooltip,
  Space,
  Typography,
} from "antd";
import { BrandKitAssetResponseWithTypedPurpose } from "./BrandKitAssets";
import { FontPreviewText } from "./FontPreview";
import { PaletteResponse } from "pages/media/types";
import {
  ArrowFatLinesRight,
  Exclude,
  HandWaving,
  ImageSquare,
  Pause,
  Play,
} from "@phosphor-icons/react";
import styled from "styled-components";
import { useAntTheme } from "hooks/useAntTheme";

interface BrandKitMockupProps {
  assets?: BrandKitAssetResponseWithTypedPurpose[];
  colors?: PaletteResponse;
  style?: React.CSSProperties;
  width?: number;
  noPlayer?: boolean; // Don't show the video or audio or controls at all
}
// iphone ratio
export const BRANDKIT_HEIGHT_TO_WIDTH_RATIO = 2;
export const BrandKitMockup: React.FC<BrandKitMockupProps> = ({
  assets,
  colors,
  style,
  width,
  noPlayer: noVideoOrAudio,
}) => {
  const t = useTranslate();
  const { theme } = useAntTheme();

  const videoRefs = {
    intro: useRef<HTMLVideoElement>(null),
    transition: useRef<HTMLVideoElement>(null),
    closing: useRef<HTMLVideoElement>(null),
  };

  // memoized assets
  const {
    logo,
    titleFont,
    textFont,
    audio,
    background,
    intro,
    transition,
    banner,
    closing,
  } = useMemo(
    () => ({
      logo: assets?.find((asset) => asset.purpose === "Logo"),
      titleFont: assets?.find((asset) => asset.purpose === "Title"),
      textFont: assets?.find((asset) => asset.purpose === "Text"),
      audio: assets?.find((asset) => asset.purpose === "Music"),
      background: assets?.find((asset) => asset.purpose === "Background"),
      intro: assets?.find((asset) => asset.purpose === "Introduction"),
      transition: assets?.find((asset) => asset.purpose === "Transition"),
      banner: assets?.find((asset) => asset.purpose === "Banner"),
      closing: assets?.find((asset) => asset.purpose === "Closing"),
    }),
    [assets]
  );

  const hasVideo = Boolean(intro || transition || closing) && !noVideoOrAudio;

  const [videoView, setVideoView] = useState<
    BrandKitAssetResponseWithTypedPurpose | undefined
  >(intro ?? transition ?? closing);
  const [showVideo, setShowVideo] = useState(hasVideo);

  const getCurrentVideo = () => {
    if (videoView?.asset_id === intro?.asset_id) return videoRefs.intro.current;
    if (videoView?.asset_id === transition?.asset_id)
      return videoRefs.transition.current;
    if (videoView?.asset_id === closing?.asset_id)
      return videoRefs.closing.current;
  };

  const handleVideoEnd = () => {
    // To detect next video in a loop
    const videoTransitions = {
      Introduction: transition ?? closing ?? intro,
      Transition: closing ?? intro ?? transition,
      Closing: intro ?? transition ?? closing,
    };

    const currentPurpose = videoView?.purpose as keyof typeof videoTransitions;
    const nextVideo = videoTransitions[currentPurpose];

    if (nextVideo) {
      setVideoView(nextVideo);
    } else {
      const currentVideoRef = getCurrentVideo();
      if (currentVideoRef) {
        currentVideoRef.currentTime = 0;
      }
    }
  };

  useEffect(() => {
    // Apply event for each video
    Object.values(videoRefs).forEach((ref) => {
      if (ref.current) {
        ref.current.addEventListener("ended", handleVideoEnd);
      }
    });

    return () => {
      Object.values(videoRefs).forEach((ref) => {
        if (ref.current) {
          ref.current.removeEventListener("ended", handleVideoEnd);
        }
      });
    };
  }, [videoView]);

  useEffect(() => {
    // Reset and control video playback
    Object.values(videoRefs).forEach((ref) => {
      if (ref.current) {
        ref.current.pause();
        ref.current.currentTime = 0;
      }
    });
  }, [videoView]);

  const previewWidth = width ?? 300;
  // Calculate height based on ratio
  const previewHeight = previewWidth * BRANDKIT_HEIGHT_TO_WIDTH_RATIO;
  // Item borders should have a smaller radius than the parent to match
  const itemBorderRadius = previewWidth * 0.09;

  const containerStyle = {
    width: previewWidth,
    height: previewHeight,
    // https://stackoverflow.com/a/15885486/7459528
    // marginLeft: 20,
    borderRadius: previewWidth * 0.15,

    position: "relative",
    overflow: "hidden",
    // ...style,
  } as CSSProperties;

  const renderVideo = (
    videoAsset: typeof intro | typeof transition | typeof closing,
    ref?: React.RefObject<HTMLVideoElement>
  ) => {
    return (
      <video
        preload="metadata"
        src={videoAsset?.path}
        ref={ref}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          borderRadius: itemBorderRadius,
          display:
            videoView?.asset_id === videoAsset?.asset_id ? "block" : "none",
        }}
      />
    );
  };

  const iconSize = 24; // (20 * previewWidth) / 185;

  const radioGroupItems = [
    {
      item: intro,
      icon: <HandWaving size={iconSize} />,
    },
    {
      item: transition,
      icon: <Exclude size={iconSize} />,
    },
    {
      item: closing,
      icon: <ArrowFatLinesRight size={iconSize} />,
    },
    {
      item: { purpose: "NoVideo" },
      icon: <ImageSquare size={iconSize} />,
    },
  ];

  return (
    <Flex
      vertical
      gap={10}
      style={{ ...style, height: "fit-content" }}
      justify="center"
      align="center"
    >
      <StyledBrandKitContainer
        $width={previewWidth}
        $height={previewHeight}
        style={containerStyle}
      >
        <Flex
          vertical
          align="center"
          justify="center"
          style={{
            maxHeight: "100%",
            borderStyle: "solid",
            borderColor: theme.colorOutlineBorder,
            borderWidth: 10,
            borderRadius: previewWidth * 0.15,
            boxSizing: "border-box",
            position: "absolute",
            zIndex: 10,
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          {hasVideo && (
            <div
              style={{
                position: "absolute",
                top: 0,
                visibility: showVideo ? "visible" : "hidden",
                zIndex: 8,
                height: "100%",
                width: "100%",
              }}
            >
              {renderVideo(intro, videoRefs?.intro)}
              {renderVideo(transition, videoRefs?.transition)}
              {renderVideo(closing, videoRefs?.closing)}
            </div>
          )}

          {logo?.path && (
            <Flex
              justify="center"
              align="center"
              style={{
                height: "10%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                overflow: "hidden",
                zIndex: 5,
                backgroundColor: colors?.secondary_color ?? "#ffffff",
                backgroundImage: banner ? `url(${banner?.path})` : "none",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <img
                src={logo.path}
                alt="logo"
                style={{
                  height: "90%",
                  width: "auto",
                  maxWidth: "100%",
                }}
              />
            </Flex>
          )}
          <Flex
            align="center"
            justify="center"
            vertical
            style={{
              backgroundColor: colors?.primary_color,
              backgroundImage: background ? `url(${background?.path})` : "",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              padding: 0,
            }}
          >
            {!showVideo && (
              <Flex
                style={{
                  position: "absolute",
                  top: "20%",
                  zIndex: 2,
                  width: "100%",
                }}
                justify="center"
              >
                <FontPreviewText
                  style={{
                    // Seb's formula to calculate relative font size
                    fontSize: (15 * previewWidth) / 185,
                    letterSpacing: 1.2,
                    width: "90%",
                    height: "35%",
                    textAlign: "center",
                    color: colors?.subtitle_color ?? "#000",
                    WebkitTextStroke: `1px ${colors?.subtitle_stroke_color ?? ""}`,
                  }}
                  font={textFont?.path ?? ""}
                  text={t("media.brandkit.mockup.subtitles")}
                />
              </Flex>
            )}
            {(videoView?.asset_id === transition?.asset_id || !showVideo) && (
              <Flex
                align="center"
                justify="center"
                style={{
                  top: 0,
                  zIndex: 9,
                  width: "100%",
                  height: "100%",
                }}
              >
                <FontPreviewText
                  font={titleFont?.path ?? ""}
                  text={t("media.brandkit.mockup.title")}
                  style={{
                    textAlign: "center",
                    width: "80%",
                    // Seb's formula to calculate relative font size
                    fontSize: (30 * previewWidth) / 185,
                    letterSpacing: 2,
                    color: colors?.title_color ?? "#000",
                    WebkitTextStroke: `2px ${colors?.title_stroke_color ?? ""}`,
                  }}
                />
              </Flex>
            )}
          </Flex>

          {(hasVideo || audio) && !noVideoOrAudio && (
            <PlayerWrapper
              src={audio?.path}
              video={getCurrentVideo()}
              showVideo={showVideo}
              previewWidth={previewWidth}
            />
          )}
        </Flex>
      </StyledBrandKitContainer>
      {hasVideo && (
        <StyledRadioGroup
          size="large"
          value={videoView}
          optionType="button"
          onChange={(e) => {
            // e.stopPropagation(); // not working :(
            const value = e.target.value;
            if (value.purpose === "NoVideo") {
              setShowVideo(false);
              setVideoView(undefined);
              return;
            }
            setVideoView(e.target.value);
            setShowVideo(true);
          }}
        >
          {radioGroupItems.map((option) => {
            if (!option.item) return;
            if (option.item.purpose === "NoVideo") {
              return (
                <Radio.Button key={option.item.purpose} value={option.item}>
                  <Tooltip
                    title={t(
                      `brandkit.components.BrandKitMockup.${option.item.purpose}`
                    )}
                  >
                    {option.icon}
                  </Tooltip>
                </Radio.Button>
              );
            }
            return (
              <Radio.Button key={option.item.purpose} value={option.item}>
                <Tooltip
                  title={t(
                    `brandkit.components.BrandKitMockup.${option.item.purpose}`
                  )}
                >
                  {option.icon}
                </Tooltip>
              </Radio.Button>
            );
          })}
        </StyledRadioGroup>
      )}
    </Flex>
  );
};

const StyledRadioGroup = styled(Radio.Group)`
  // position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  // bottom: -70px;

  label {
    display: flex;
    align-items: center;
  }
  .ant-radio-button-wrapper span {
    display: inline-flex;
  }
`;

const StyledBrandKitContainer = styled.div<{ $width: number; $height: number }>`
  flex: 0 1 ${(props) => props.$height}px;
  height: ${(props) => props.$height}px;
  // height: ${(props) => props.$height + 70}px;
  // position: sticky;
  // top: 0px;
`;

export const PlayerWrapper = ({
  src,
  video,
  showVideo,
  previewWidth,
}: {
  src?: string;
  video?: HTMLVideoElement | null;
  showVideo: boolean;
  previewWidth: number;
}) => {
  const player = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const { theme } = useAntTheme();

  const playbackPromiseRef = useRef<Promise<void> | null>(null);

  const iconsSize = 24; //(15 * previewWidth) / 185;

  useEffect(() => {
    handleDuration();
  }, [video]);

  const handleTimeUpdate = useCallback(() => {
    if (player.current) {
      setCurrentTime(player.current.currentTime);
    } else if (video) {
      setCurrentTime(video.currentTime);
    }
  }, [src, video]);

  const handleDuration = useCallback(() => {
    if (player.current) {
      setDuration(player.current.duration);
    } else if (video) {
      setDuration(video.duration);
    }
  }, [video]);

  const handleSliderChange = useCallback(
    (value: number) => {
      try {
        if (player.current) {
          player.current.currentTime = value;
          if (video) {
            video.currentTime = value;
          }
        } else if (video) {
          video.currentTime = value;
        }
      } catch (error) {
        console.error("Error updating time:", error);
      }
    },
    [video]
  );

  const handleMediaElement = React.useCallback(
    async (element: HTMLMediaElement | null) => {
      if (!element) return;

      if (isPlaying) {
        // If there's a pending play operation, wait for it to complete before pausing
        // To prevent "The play() request was interrupted by a call to pause()"
        if (playbackPromiseRef.current) {
          try {
            await playbackPromiseRef.current;
          } catch (error) {
            console.error("Playback promise error:", error);
          }
        }
        element.pause();
      } else {
        element.volume = 0.1;
        try {
          const playPromise = element.play();
          if (playPromise !== undefined) {
            playbackPromiseRef.current = playPromise;
            await playPromise;
            playbackPromiseRef.current = null;
          }
        } catch (error) {
          console.error("Play error:", error);
          setIsPlaying(false);
        }
      }
    },
    [isPlaying]
  );

  const togglePlayback = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation(); // to not bubble in list selections
      try {
        await handleMediaElement(player.current);
        if (showVideo && video) {
          await handleMediaElement(video);
        }
        setIsPlaying(!isPlaying);
      } catch (error) {
        console.error("Playback error:", error);
        setIsPlaying(false);
      }
    },
    [isPlaying, showVideo, video, handleMediaElement]
  );

  useEffect(() => {
    if (video && !player.current) {
      video.addEventListener("timeupdate", handleTimeUpdate);
      video.addEventListener("loadedmetadata", handleDuration);
      video.addEventListener("play", () => setIsPlaying(true));
      video.addEventListener("pause", () => setIsPlaying(false));

      return () => {
        video.removeEventListener("timeupdate", handleTimeUpdate);
        video.removeEventListener("loadedmetadata", handleDuration);
        video.removeEventListener("play", () => setIsPlaying(true));
        video.removeEventListener("pause", () => setIsPlaying(false));
      };
    }
  }, [video, handleTimeUpdate, handleDuration]);

  useEffect(() => {
    const handleEnded = () => {
      if (player.current) {
        player.current.pause();
        setCurrentTime(0);
        setIsPlaying(false);
      } else if (video) {
        video.pause();
        setCurrentTime(0);
        setIsPlaying(false);
      }
    };

    const currentPlayer = player.current;
    if (currentPlayer) {
      currentPlayer.addEventListener("ended", handleEnded);
    } else if (video) {
      video.addEventListener("ended", handleEnded);
    }

    return () => {
      if (currentPlayer) {
        currentPlayer.removeEventListener("ended", handleEnded);
      } else if (video) {
        video.removeEventListener("ended", handleEnded);
      }
    };
  }, [video]);

  // show time in min:sec
  const formatTime = (seconds: number): string => {
    if (!seconds || isNaN(seconds)) return "0:00";
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <Flex
      justify="center"
      align="center"
      vertical
      gap={0}
      style={{
        maxHeight: 85,
        width: "100%",
        background: theme.colorOutlineBorder,
        position: "absolute",
        bottom: 0,
        zIndex: 10,
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <Flex align="center" justify="space-between" style={{ width: "100%" }}>
        <Space size={"small"}>
          {src && (
            <audio
              ref={player}
              src={src}
              onTimeUpdate={handleTimeUpdate}
              onLoadedMetadata={handleDuration}
            />
          )}
          <Button
            type="text"
            onClick={togglePlayback}
            icon={
              isPlaying ? (
                <Pause weight="fill" size={iconsSize} />
              ) : (
                <Play weight="fill" size={iconsSize} />
              )
            }
            style={{ color: "#fff" }}
          />
          <div style={{ color: "#fff" }}>
            {formatTime(currentTime)}{" "}
            <Typography.Text style={{ color: "#fff", opacity: 0.4 }}>
              / {formatTime(duration)}
            </Typography.Text>
          </div>
        </Space>
      </Flex>
      <ConfigProvider
        theme={{
          components: {
            Slider: {
              railBg: "rgba(255,255,255,0.3)",
            },
          },
        }}
      >
        <div style={{ width: "90%", marginTop: -5 }}>
          <Slider
            value={currentTime}
            onChange={handleSliderChange}
            max={duration}
            tooltip={{ open: false }}
            styles={{
              tracks: {
                background: "#fff",
              },
            }}
          />
        </div>
      </ConfigProvider>
    </Flex>
  );
};
