import { Badge, Flex, Tooltip } from "antd";
import { useEffect } from "react";
import { Message } from "./Message";
import { useChatbot } from "hooks/useChatbot";
import ScrollToBottom, {
  useScrollToBottom,
  useSticky,
} from "react-scroll-to-bottom";
import styles from "./Chat.module.css";
import { ChatMessage } from "./types";
import { PromptForm } from "../PromptHistory/PromptForm";

export const Chat = ({
  documentIds,
  organizationId,
  stock,
}: {
  documentIds: string[];
  organizationId: string;
  stock?: string;
}) => {
  const {
    init,
    sendQuery,
    getConnectionStatus,
    messageHistory,
    isConnected,
    isBusy,
    suggestions,
  } = useChatbot({
    organizationId,
    documentIds,
  });
  const scrollToBottom = useScrollToBottom();
  const [sticky] = useSticky();

  useEffect(() => {
    init();
  }, []);

  const handleChange = (value: string) => {
    sendQuery(value);
  };

  // console.debug("messageHistory", messageHistory);

  return (
    <div style={{ position: "relative", height: "calc(100vh - 30px - 50px)" }}>
      <ScrollToBottom
        followButtonClassName="scrollToBottomButton"
        className={styles.root}
      >
        <Flex vertical gap={40}>
          {messageHistory.map((message: ChatMessage) => (
            <Message
              key={message.queryId}
              message={message}
              sendQuery={sendQuery}
            />
          ))}
        </Flex>
      </ScrollToBottom>

      <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <PromptForm
          disabled={isBusy || !isConnected}
          onChange={handleChange}
          value=""
        />
      </div>
      <Tooltip title={getConnectionStatus()}>
        <Badge
          dot
          color={isConnected ? "green" : "red"}
          style={{ position: "absolute", bottom: 70, left: 0 }}
        />
      </Tooltip>
    </div>
  );
};
