// import ReactPlayer from "react-player";
import { Row, Col } from "antd";
import { useParams } from "react-router-dom";
import { useList, useOne } from "@refinedev/core";
import type { ContactResponse, PresenterProfileResponse } from "../types";
import { useOrganization } from "hooks/useOrganization";
import { Show } from "@refinedev/antd";
import { FaceProfilePreview } from "../components/FaceProfilePreview";

export const PresenterPreview = ({
  presenterId,
  organizationId,
  fullSize,
  style,
}: {
  presenterId: string;
  fullSize?: boolean;
  style?: React.CSSProperties;
  organizationId: string;
}) => {
  const { data } = useOne<PresenterProfileResponse>({
    resource: `media/${organizationId}/presenter_profiles`,
    id: presenterId,
    queryOptions: {
      enabled: !!presenterId && !!organizationId,
    },
  });
  const faceProfile = data?.data.face_profile;
  return (
    <div style={{ width: "100%", height: "100%", ...style }}>
      {faceProfile && (
        <FaceProfilePreview
          fullSize={fullSize}
          assetPath={faceProfile.extracted_asset_path}
        />
      )}
    </div>
  );
};

// export const ContactDetailPage = () => {
export const ContactDetailPage = () => {
  const { organization } = useOrganization({});
  const { id } = useParams<{ id: string }>();
  // const { data, isLoading } = useOne<ContactResponse>({
  //   resource: `media/${organization?.id}/contacts`,
  //   id,
  // });
  const { data, isLoading, refetch, isRefetching } = useList<ContactResponse>({
    resource: `media/${organization?.id}/contacts`,
  });

  const contact = data?.data.find((x) => x.id === id);
  // const assetIds = Object.keys(voiceProfile?.assets ?? {});

  return (
    <Show
      title={contact?.firstname + " " + contact?.lastname}
      isLoading={isLoading}
      headerButtons={<></>}
    >
      <Row justify="center">
        <Col span={16}>
          {contact && (
            <>
              <PresenterPreview
                presenterId={contact.presenter_id}
                organizationId={contact.organization_id}
              />
            </>
          )}
          {/* {voiceProfile && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Typography.Text strong>Type:</Typography.Text>{" "}
                <Typography.Text>{voiceProfile.category}</Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text strong>Creation date:</Typography.Text>{" "}
                <DateField value={voiceProfile.created} format="LLL" />
              </Col>
            </Row>
          )} */}
        </Col>
      </Row>
    </Show>
  );
};
