import { Users } from "@phosphor-icons/react";
import { GlobalToken, Select, SelectProps } from "antd";
import { useAntTheme } from "hooks/useAntTheme";
import { useWorkspace } from "hooks/useWorkspace";
import styled from "styled-components";

export const WorkspaceSwitch = () => {
  const { theme } = useAntTheme();
  const { workspaces, workspaceId, changeWorkspace } = useWorkspace();

  if (!workspaces || workspaces.length < 1) {
    return null;
  }
  return (
    <StyledSelect
      data-testid="WorkspaceSwitch"
      showSearch
      optionFilterProp="label"
      theme={theme}
      size="large"
      disabled={workspaces?.length < 2}
      onChange={(value) =>
        changeWorkspace({ workspace: workspaces.find((x) => x.id === value) })
      }
      value={workspaceId}
      options={workspaces?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        };
      })}
      style={{ width: "100%" }}
      virtual={false} // disable for automated pass to find test org
      suffixIcon={<Users size={20} weight="bold" />}
    />
  );
};

const StyledSelect = styled(Select)<SelectProps & { theme: GlobalToken }>`
  &:not(.ant-select-focused) {
    .ant-select-selector {
      color: ${({ theme }) => theme.colorPrimary} !important;
    }
  }
  .ant-select-selector {
    border-width: 2px !important;
    border-color: ${({ theme }) => theme.colorBorderSecondary} !important;
    box-shadow: 0 !important;
  }
`;
