import { GlobalToken, Slider } from "antd";
import styled from "styled-components";

// TODO rename with more specific name after all merges
export const StyledListWrapper = styled.div<{
  theme: GlobalToken;
  columnSize?: number;
}>`
  .ant-list-items {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(${({ columnSize }) => columnSize ?? 250}px, 1fr)
    );
    gap: 1rem;
  }

  .ant-list-item:last-child {
    border-block-end: 2px solid;
    border-color: ${({ theme }) => theme.colorSecondaryBg};
  }

  .ant-list-grid .ant-col > .ant-list-item {
    border-block-end: solid !important;
    border-color: ${({ theme }) => theme.colorSecondaryBg} !important;
  }
  .ant-list-grid .ant-col > .ant-list-item.active {
    border-color: ${(props) => props.theme.colorPrimary} !important;
  }
  .ant-list-item {
    border: 2px solid transparent;
    border-radius: ${({ theme }) => theme.borderRadius}px;
    box-sizing: border-box;
    border-color: ${({ theme }) => theme.colorSecondaryBg};
    background-color: ${({ theme }) => theme.colorSecondaryBg};
    max-height: 450px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    // padding: 40px 0px !important;

    .ant-list-item-meta .ant-list-item-meta-title {
      font-size: 20px;
      font-weight: normal;
    }
    .ant-list-item-meta .ant-list-item-meta-content {
      width: auto;
    }
    .ant-list-item-meta-description {
      padding-right: 5px;
    }
    &:hover {
      cursor: pointer;
      transition: 500ms;
      background-color: ${(props) => props.theme.colorBgElevated} !important;
    }
    &.active {
      border-color: ${(props) => props.theme.colorPrimary};
      background-color: ${(props) => props.theme.colorPrimaryBg};
      .ant-list-item-meta-title {
        color: ${(props) => props.theme.colorPrimary};
      }
      .ant-list-item-meta-avatar {
        color: ${(props) => props.theme.colorPrimary};
      }
    }
  }
`;

export const StyledSegmentedWrapper = styled.div<{
  theme: GlobalToken;
}>`
  .ant-segmented {
    background: ${({ theme }) => theme.colorBgContainer};
    border: 1px solid ${({ theme }) => theme.colorBorder};
    padding: 0;
  }
  .ant-segmented-item {
    padding: 2px 0;
    color: ${({ theme }) => theme.colorTextSecondary};
    border: 1px solid transparent;
  }
  .ant-segmented-thumb {
    background-color: ${({ theme }) => theme.colorPrimaryBg};
    border: 1px solid ${({ theme }) => theme.colorPrimary};
  }
  .ant-segmented-item-selected {
    background-color: ${({ theme }) => theme.colorPrimaryBg};
    // transition: color 0.2s;
    color: ${({ theme }) => theme.colorText};
    border: 1px solid ${({ theme }) => theme.colorPrimary};
  }
`;
export const StyledSegmentedCardWrapper = styled.div<{
  theme: GlobalToken;
  itemWidth?: string;
  size?: "middle" | "large";
}>`
  .ant-segmented {
    background: none;
    border: none;
    padding: 0;
  }
  .ant-segmented-group {
    gap: 30px;
  }
  .ant-segmented-item {
    flex: 1;
    // width: ${({ itemWidth }) => itemWidth ?? "unset"};
    padding: ${({ size }) => (size === "middle" ? "10px 0px" : "40px 0px")};
    color: ${({ theme }) => theme.colorTextSecondary};
    border: 1px solid ${({ theme }) => theme.colorSecondaryBg};
    background: ${({ theme }) => theme.colorSecondaryBg};
    border-radius: 15px;
  }
  .ant-segmented-thumb {
    background-color: ${({ theme }) => theme.colorPrimaryBg};
    border: 1px solid ${({ theme }) => theme.colorPrimary};
  }
  .ant-segmented-item-selected {
    background-color: ${({ theme }) => theme.colorPrimaryBg};
    // transition: color 0.2s;
    // color: ${({ theme }) => theme.colorText};
    border: 1px solid ${({ theme }) => theme.colorPrimary};
    color: ${(props) => props.theme.colorPrimary};
  }
  .ant-segmented-item-label {
    font-size: 20px;
    white-space: normal;
    // subtitle
    .ant-typography-secondary {
      line-height: 1.1;
    }
  }
`;

export const StyledDurationSlider = styled(Slider)`
  .ant-slider-rail {
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colorSecondaryBg} 0%,
      ${({ theme }) => theme.colorSecondaryBg} 100%
    );
    border-radius: 100px;
  }
  .ant-slider-track {
    border-radius: 100px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colorPrimaryBorderHover} 0%,
      ${({ theme }) => theme.colorPrimaryBorderHover} 100%
    );
  }
  &:hover {
    .ant-slider-track {
      // background: red;
    }
  }
  .ant-slider-handle:after {
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colorPrimary} 0%,
      ${({ theme }) => theme.colorPrimary} 100%
    ) !important;
    border: 0;
    box-shadow: 0 0 0 5px ${({ theme }) => theme.colorPrimaryBorderHover} !important;
  }
  .ant-slider-handle:hover:after {
    box-shadow: 0 0 0 5px ${({ theme }) => theme.colorPrimaryBorderHover} !important;
  }
`;
