import { Button, Flex } from "antd";
import React from "react";
import { useTranslate } from "@refinedev/core";
import { UserCircleGear, UserCirclePlus } from "@phosphor-icons/react";
import { GridOptionList } from "./GridOptionList";
const NOFACE = true;

const ContactPreStep: React.FC<{
  onClickCreateContact: (noFace?: boolean) => void;
  onClickSelectExisting: () => void;
}> = ({ onClickCreateContact, onClickSelectExisting }) => {
  const t = useTranslate();
  const options = [
    {
      label: t(
        "projects.components.ProjectSettingForm.contactPreStep.selectExisting"
      ),
      icon: <UserCircleGear size={80} weight={"duotone"} />,
      onClick: onClickSelectExisting,
    },
    {
      label: t(
        "projects.components.ProjectSettingForm.contactPreStep.createNew"
      ),
      icon: <UserCirclePlus size={80} weight={"duotone"} />,
      onClick: () => onClickCreateContact(),
    },
  ];
  return (
    <Flex vertical gap={15}>
      <GridOptionList options={options} />
      {/* <Button
        onClick={() => onClickCreateContact(NOFACE)}
        type="default"
        size="large"
      >
        {t("projects.components.ProjectSettingForm.contactPreStep.skip")}
      </Button> */}
    </Flex>
  );
};

ContactPreStep.displayName = "ContactPreStep";

export default ContactPreStep;
