import { t } from 'i18next';
import { Button, Result, Space } from 'antd';
import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { AppLogo } from 'components/AppLogo';
interface ErrorFallbackProps {
  error: Error | unknown;
  resetError: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetError }) => {
  const emailSupport = () => {
    window.open(
      'mailto:support@libertify.com?subject=Error at ' + window.location,
    );
  };
  return (
    <>
    <Result
      status="error"
      icon={<AppLogo width={80}/>}
      title={t('src.App.somethingWentWrong')}
      subTitle={(error as Error).message}
      extra={
        <Space size="large">
          <Button shape="round" onClick={emailSupport}>
            {t('src.App.contactSupport')}
          </Button>
          <Button shape="round" type="primary" onClick={resetError}>
            {t('src.App.reloadPage')}
          </Button>
        </Space>
      }
    />
    </>
  );
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
  onReset?: () => void;
}
// const SimulateError = () => {
//   const [shouldThrow, setShouldThrow] = useState(false);

//   if (shouldThrow) {
//     throw new Error('Simulated error!');
//   }

//   return <button onClick={() => setShouldThrow(true)}>Simulate Error</button>;
// };

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ onReset, children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <ErrorFallback error={error} resetError={resetError} />
      )}
      onReset={
        onReset
          ? onReset
          : () => {
              // TODO: Actually implement the reset instead of reloading.
              //       This should also report the error to us.
              window.location.reload();
            }
      }
    >
      {/* <SimulateError /> */}
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
