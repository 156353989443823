import { Flex, List } from "antd";
import { MediaAsset } from "pages/media/types";
import { Dispatch, SetStateAction } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

export const InfiniteScrollList = ({
  dataSource,
  assetLength = 0,
  children,
  setPage,
  hasMore,
  scrollableTarget,
}: {
  dataSource: Partial<MediaAsset>[];
  assetLength?: number;
  children: (item: Partial<MediaAsset>, index: number) => React.ReactNode;
  setPage?: Dispatch<SetStateAction<number>>;
  hasMore: boolean;
  scrollableTarget: string;
}) => {
  const filteredDataSource = dataSource.filter(
    (item) => item !== undefined && item !== null
  );

  return (
    <InfiniteScroll
      style={{
        overflow: "auto",
      }}
      scrollableTarget={scrollableTarget}
      loader={<h4>Loading...</h4>}
      dataLength={assetLength}
      next={() => setPage((page) => page + 1)}
      hasMore={hasMore}
    >
      <List
        grid={{
          gutter: 16,
          xs: 1,
          md: 2,
          lg: 3,
        }}
        dataSource={filteredDataSource}
        rowKey="id"
        renderItem={(item, index) => {
          return (
            <List.Item>
              <Flex
                vertical
                style={{
                  position: "relative",
                  paddingTop: 30,
                }}
                key={item.id}
              >
                {children(item, index)}
              </Flex>
            </List.Item>
          );
        }}
      />
    </InfiniteScroll>
  );
};
