import { CanAccess, useTranslate } from "@refinedev/core";
import { LinkOutlined, SettingFilled } from "@ant-design/icons";
import { useCustomMutation, useInvalidate, useOne } from "@refinedev/core";
import {
  Button,
  ButtonProps,
  Flex,
  Form,
  Modal,
  notification,
  Result,
  Space,
  Spin,
  Switch,
  Typography,
} from "antd";
import { useMediaAssetsStorage } from "hooks/useMediaAssetsStorage";
import {
  MediaProjectResponse,
  MediaProjectPublicationResponse,
  MediaProjectPublicationRequest,
  UpdatePublicationRequest,
} from "pages/media/types";
import { useEffect, useState } from "react";
import ShareMedia from "./ShareMedia";
import { Confetti, Globe, ShareNetwork } from "@phosphor-icons/react";
import { getPublicationUrl } from "pages/media/utils";
import ShareButtons from "./ShareButtons";

export type PublicationFeatures = "video_doc" | "video_doc_chat" | "video";

export type FormValues = Pick<
  MediaProjectPublicationRequest,
  "public" | "title" | "project_publication_id"
> & {
  attachAllDocuments: boolean;
  live: boolean;
  features: PublicationFeatures;
  enable_chat_answers: boolean;
};

const DEFAULT_FEATURES: PublicationFeatures = "video_doc_chat";

export const SimpleProjectMediaPublish = ({
  project,
  mediaId,
  publicationId: publicationIdToEdit,
  ...rest
}: {
  project: MediaProjectResponse;
  mediaId: string;
  publicationId?: string;
} & ButtonProps) => {
  const t = useTranslate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm<FormValues>();
  // const [loadPublication, setLoadPublication] = useState(false);
  const formValues = Form.useWatch([], form);
  const [api, contextHolder] = notification.useNotification();
  const [selectedDocumentsIds, setSelectedDocumentsIds] = useState<string[]>(
    []
  );
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const [expert, setExpert] = useState(false);
  const [displayDocument, setDisplayDocument] = useState<string | undefined>();
  const [publicationId, setPublicationId] = useState<string | undefined>();
  const media = project.media.find((x) => x.id === mediaId);
  const isEdit = Boolean(publicationId);
  const { data: publicationData, isLoading: isLoadingPublication } =
    useOne<MediaProjectPublicationResponse>({
      resource: `media/projects/${project.id}/publications`,
      id: publicationId,
      queryOptions: {
        enabled: Boolean(publicationId),
        onError: (error) => {
          api.error({
            message: t("projects.components.SimpleProjectMediaPublish.error"),
            // TODO: Something is really wrong here, the error is supposed
            // to be HTTPError, but instead it's an AxiosError extended
            // with {message: {message: unknown}}
            description:
              (
                error as unknown as {
                  message: {
                    message: string;
                  };
                }
              )?.message?.message ??
              t("projects.components.ProjectDocumentUpload.somethingWentWrong"),
          });
        },
      },
    });
  // const {
  //   data: mediaData,
  //   isLoading: isLoadingMedia,
  //   isRefetching: isRefetchingMedia,
  // } = useOne({
  //   resource: "media/media",
  //   id: mediaId,
  // });
  // const media = mediaData?.data;
  const publication = publicationData?.data;
  const { mutateAsync, isLoading: isMutating } = useCustomMutation({});
  const { projectAssets, isLoadingProjectAssets } = useMediaAssetsStorage({
    projectId: project.id,
    mediaId,
    enabled: isModalOpen,
  });
  const invalidate = useInvalidate();
  const [publicationUpdateSuccess, setIsPublicationUpdateSuccess] =
    useState(false);
  const documents = project.documents;
  const assets = projectAssets;
  const projectDocumentsIds = documents.map(
    (document) => document.id as string
  );
  const publicationDocumentsIds = Object.values(
    publication?.documents ?? {}
  ).map((document) => document!.id);
  const publicationAssetsIds = Object.values(publication?.assets ?? {})
    .filter((x) => x?.id !== undefined)
    .map((x) => x?.id!);
  const primaryDocument = documents.find(
    (document) => document.id === project.settings.primary_document_id
  );
  const isAllDocumentsSelected = (
    publication: string[],
    documents: string[]
  ): boolean => {
    // If the project has more documents than the publication, it is not all selected

    return publication.sort().join(",") === documents.sort().join(",");
  };

  const toggleAllDocuments = (attachAllDocuments: boolean) => {
    setSelectedDocumentsIds(
      attachAllDocuments ? projectDocumentsIds : publicationDocumentsIds
    );
  };

  const setInitialValues = () => {
    if (publication) {
      setSelectedDocumentsIds(publicationDocumentsIds);
      setSelectedAssetIds(publicationAssetsIds);
      setDisplayDocument(
        publicationDocumentsIds[0] ??
          (primaryDocument?.name?.toLocaleLowerCase().endsWith("pdf")
            ? (primaryDocument.id as string)
            : // get the first PDF from project documents
              ((documents.find((document) =>
                document.name?.toLocaleLowerCase().endsWith("pdf")
              )?.id as string) ?? undefined))
      );
      const features: PublicationFeatures = (() => {
        if (publication.enable_chat && publication.enable_video_cuepoints) {
          return "video_doc_chat";
        } else if (
          !publication.enable_chat &&
          publication.enable_video_cuepoints
        ) {
          return "video_doc";
        } else {
          return "video";
        }
      })();
      form.setFieldsValue({
        title: publication.title,
        attachAllDocuments: isAllDocumentsSelected(
          publicationDocumentsIds,
          projectDocumentsIds
        ),
        public: publication.public,
        project_publication_id: publication.project_publication_id,
        enable_chat_answers: publication.enable_chat_answers,
        features,
      });
    } else {
      setSelectedDocumentsIds(projectDocumentsIds);
      setSelectedAssetIds([]);
      setDisplayDocument(
        primaryDocument?.name?.toLocaleLowerCase().endsWith("pdf")
          ? (primaryDocument.id as string)
          : // get the first PDF from project documents
            ((documents.find((document) =>
              document.name?.toLocaleLowerCase().endsWith("pdf")
            )?.id as string) ?? undefined)
      );

      form.setFieldsValue({
        attachAllDocuments: true,
        public: true,
        title: project.title,
        enable_chat_answers: true,
        features: DEFAULT_FEATURES,
      });
    }
  };

  const approvePublication = async () => {
    const action = "ApprovePublication";
    await mutateAsync({
      url: `media/projects/${project.id}/publications/${publicationId}/status?action=${encodeURIComponent(action)}`,
      method: "post",
      values: {},
    });
    api.success({
      message: t("projects.components.SimpleProjectMediaPublish.goLive"),
      icon: <Globe />,
      description: t(
        "projects.components.SimpleProjectMediaPublish.yourVideoIs"
      ),
      placement: "bottomRight",
    });
    // project media to get new updated status
    invalidate({
      resource: `media/projects`,
      id: project.id,
      invalidates: ["detail"],
    });
  };

  const publish = async () => {
    const {
      title,
      public: isPublic,
      enable_chat_answers,
      features,
    } = form.getFieldsValue();
    let publicationResponse: MediaProjectPublicationResponse;
    const enable_video_cuepoints = features.includes("doc");
    const enable_chat = features.includes("chat");
    const flags = {
      enable_chat_answers: enable_chat_answers as boolean, //to type the request
      enable_chat,
      enable_video_cuepoints,
    };
    const isDocumentRequired = flags.enable_video_cuepoints === true;

    // todo add more checks
    const selectedDocumentIds = selectedDocumentsIds;
    if (selectedDocumentIds.length === 0 && isDocumentRequired) {
      return;
    }
    let pdfDocumentPlaced = false;
    // It should not publish if there is no PDF document selected
    const publishedDocuments = !isDocumentRequired
      ? []
      : project.documents.reduce<string[][]>((acc, item, index) => {
          if (selectedDocumentIds.includes(item.id)) {
            if (
              !pdfDocumentPlaced &&
              displayDocument &&
              displayDocument === item.id
            ) {
              acc.unshift(["Document_0", displayDocument as string]);
              pdfDocumentPlaced = true;
            } else {
              const adjustedIndex = pdfDocumentPlaced ? index : index + 1;
              acc.push(["Document_" + adjustedIndex, item.id]);
            }
          }
          return acc;
        }, []);

    // don't check if no document displayed in experience
    if (!pdfDocumentPlaced && isDocumentRequired) {
      return api.error({
        message: t(
          "projects.components.SimpleProjectMediaPublish.noPdfDocument"
        ),
        description: t("projects.components.SimpleProjectMediaPublish.aPdfIs"),
        placement: "topRight",
        duration: 5,
      });
    }

    const assetsIndexes: Record<string, number> = {};
    const publishedAssets =
      projectAssets
        ?.filter((x) => selectedAssetIds.includes(x.id))
        // todo keep index by asset type
        ?.map((item) => {
          if (assetsIndexes[item.asset_type] === undefined) {
            assetsIndexes[item.asset_type] = 0;
          }
          const assetIndex = assetsIndexes[item.asset_type]++;
          return [item.asset_type + "_" + assetIndex, item.id];
        }) ?? [];

    try {
      if (!isEdit) {
        const values: MediaProjectPublicationRequest = {
          project_publication_id: mediaId.substring(0, 8),
          title,
          media_id: mediaId,
          // public: isPublic, // todo
          public: true,
          assets: Object.fromEntries(publishedAssets),
          documents: Object.fromEntries(publishedDocuments),
          ...flags,
        };
        console.debug("publish", { formValues, values });

        const response = await mutateAsync({
          url: `media/projects/${project.id}/publications`,
          method: "post",
          values,
        });
        publicationResponse = response?.data as MediaProjectPublicationResponse;

        // load new publication data
        setPublicationId(publicationResponse.project_publication_id);
      } else {
        const values: UpdatePublicationRequest = {
          title,
          media_id: mediaId,
          // public: isPublic, /// todo
          assets: Object.fromEntries(publishedAssets ?? []),
          documents: Object.fromEntries(publishedDocuments),
          ...flags,
        };
        console.debug("publish", { formValues, values });

        const response = await mutateAsync({
          url: `media/projects/${project.id}/publications/${publicationId}`,
          method: "patch",
          values,
        });
        if (formValues.live) {
          await approvePublication();
        }
        publicationResponse = response?.data as MediaProjectPublicationResponse;
        invalidate({
          resource: `media/projects/${project.id}/publications`,
          id: publicationId,
          invalidates: ["detail"],
        });
      }
      api.success({
        message: t(
          "projects.components.SimpleProjectMediaPublish.saveSuccessful"
        ),
        description: t(
          "projects.components.SimpleProjectMediaPublish.yourChangesHave"
        ),
        placement: "bottomRight",
      });

      await invalidate({
        resource: `media/projects/${project.id}/publications`,
        invalidates: ["list"],
      });

      // project media to get new updated status
      await invalidate({
        resource: `media/projects`,
        id: project.id,
        invalidates: ["detail"],
      });
      setIsPublicationUpdateSuccess(true);
    } catch (e) {
      console.error(e);
    }
  };

  const showModal = () => {
    setExpert(false);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setInitialValues();
  }, [publication]);

  useEffect(
    () => toggleAllDocuments(form.getFieldValue("attachAllDocuments")),
    [form.getFieldValue("attachAllDocuments")]
  );
  useEffect(() => {
    if (publicationIdToEdit) setPublicationId(publicationIdToEdit);
  }, [publicationIdToEdit]);

  return (
    <>
      {contextHolder}
      <Button
        onClick={showModal}
        icon={
          <span className="anticon">
            <ShareNetwork style={{ fontSize: 24 }} weight="bold" />
          </span>
        }
        type="text"
        size="small"
        disabled={isMutating}
        {...rest}
      >
        {rest.title ?? "Publish"}
      </Button>

      <Modal
        title={
          <Space size={"large"}>
            <span>
              {t("projects.components.SimpleProjectMediaPublish.shareVideo")}
            </span>
            {/* TODO more specific access for admins */}
            <CanAccess resource="media_generation_steps" action="show">
              <Switch
                checkedChildren={<SettingFilled />}
                unCheckedChildren={<SettingFilled />}
                onChange={(value) => setExpert(value)}
              />
            </CanAccess>
          </Space>
        }
        destroyOnClose
        style={{ minWidth: `min(700px, 100vw)` }}
        styles={{ body: { paddingTop: 20 } }}
        open={isModalOpen}
        afterClose={() => {
          setIsPublicationUpdateSuccess(false);
        }}
        onCancel={handleCancel}
        footer={null}
      >
        {publicationUpdateSuccess && publication ? (
          <PublicationSuccess
            continueEditing={() => {
              setIsModalOpen(false);
              return setIsPublicationUpdateSuccess(false);
            }}
            publicationData={publication}
          />
        ) : (isEdit && isLoadingPublication) ||
          isLoadingProjectAssets ||
          !media ? (
          <Flex
            align="center"
            justify="center"
            style={{ width: "100%", height: "33vw" }}
          >
            <Spin />
          </Flex>
        ) : (
          <ShareMedia
            displayDocument={displayDocument}
            setDisplayDocument={setDisplayDocument}
            documents={documents}
            assets={assets ?? []}
            setSelectedDocumentsIds={setSelectedDocumentsIds}
            selectedDocumentsIds={selectedDocumentsIds}
            setSelectedAssetIds={setSelectedAssetIds}
            selectedAssetIds={selectedAssetIds}
            project={project}
            onFinish={publish}
            approvePublication={approvePublication}
            media={media}
            publication={publication}
            form={form}
            expert={expert}
            isMutating={isMutating}
          />
        )}
      </Modal>
    </>
  );
};

export const ProjectMediaPublishForm = ({
  project,
  mediaId,
  publicationId: publicationIdToEdit,
  ...rest
}: {
  project: MediaProjectResponse;
  mediaId: string;
  publicationId?: string;
}) => {
  const t = useTranslate();
  const [form] = Form.useForm<FormValues>();
  // const [loadPublication, setLoadPublication] = useState(false);
  const formValues = Form.useWatch([], form);
  const [api, contextHolder] = notification.useNotification();
  const [selectedDocumentsIds, setSelectedDocumentsIds] = useState<string[]>(
    []
  );
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const [expert, setExpert] = useState(false);
  const [displayDocument, setDisplayDocument] = useState<string | undefined>();
  const [publicationId, setPublicationId] = useState<string | undefined>();

  const media = project.media.find((x) => x.id === mediaId);
  const isEdit = Boolean(publicationId);
  const {
    data: publicationData,
    isLoading: isLoadingPublication,
    isSuccess,
  } = useOne<MediaProjectPublicationResponse>({
    resource: `media/projects/${project.id}/publications`,
    id: publicationId,
    queryOptions: {
      enabled: Boolean(publicationId),
      onError: (error) => {
        api.error({
          message: t("projects.components.SimpleProjectMediaPublish.error"),
          // TODO: Something is really wrong here, the error is supposed
          // to be HTTPError, but instead it's an AxiosError extended
          // with {message: {message: unknown}}
          description:
            (
              error as unknown as {
                message: {
                  message: string;
                };
              }
            )?.message?.message ??
            t("projects.components.ProjectDocumentUpload.somethingWentWrong"),
        });
      },
    },
  });
  // const {
  //   data: mediaData,
  //   isLoading: isLoadingMedia,
  //   isRefetching: isRefetchingMedia,
  // } = useOne({
  //   resource: "media/media",
  //   id: mediaId,
  // });
  // const media = mediaData?.data;
  const publication = publicationData?.data;
  const { mutateAsync, isLoading: isMutating } = useCustomMutation({});
  const { projectAssets, isLoadingProjectAssets } = useMediaAssetsStorage({
    projectId: project.id,
    mediaId,
    enabled: true, // to improve to load only on demand
  });
  const invalidate = useInvalidate();
  const [publicationUpdateSuccess, setIsPublicationUpdateSuccess] =
    useState(false);
  const documents = project.documents;
  const assets = projectAssets;
  const projectDocumentsIds = documents.map(
    (document) => document.id as string
  );
  const publicationDocumentsIds = Object.values(
    publication?.documents ?? {}
  ).map((document) => document!.id);
  const publicationAssetsIds = Object.values(publication?.assets ?? {})
    .filter((x) => x?.id !== undefined)
    .map((x) => x?.id!);
  const primaryDocument = documents.find(
    (document) => document.id === project.settings.primary_document_id
  );
  const isAllDocumentsSelected = (
    publication: string[],
    documents: string[]
  ): boolean => {
    // If the project has more documents than the publication, it is not all selected

    return publication.sort().join(",") === documents.sort().join(",");
  };

  const toggleAllDocuments = (attachAllDocuments: boolean) => {
    setSelectedDocumentsIds(
      attachAllDocuments ? projectDocumentsIds : publicationDocumentsIds
    );
  };

  const setInitialValues = () => {
    if (publication) {
      setSelectedDocumentsIds(publicationDocumentsIds);
      setSelectedAssetIds(publicationAssetsIds);
      setDisplayDocument(
        publicationDocumentsIds[0] ??
          (primaryDocument?.name?.toLocaleLowerCase().endsWith("pdf")
            ? (primaryDocument.id as string)
            : // get the first PDF from project documents
              ((documents.find((document) =>
                document.name?.toLocaleLowerCase().endsWith("pdf")
              )?.id as string) ?? undefined))
      );
      const features: PublicationFeatures = (() => {
        if (publication.enable_chat && publication.enable_video_cuepoints) {
          return "video_doc_chat";
        } else if (
          !publication.enable_chat &&
          publication.enable_video_cuepoints
        ) {
          return "video_doc";
        } else {
          return "video";
        }
      })();
      form.setFieldsValue({
        title: publication.title,
        attachAllDocuments: isAllDocumentsSelected(
          publicationDocumentsIds,
          projectDocumentsIds
        ),
        public: publication.public,
        project_publication_id: publication.project_publication_id,
        enable_chat_answers: publication.enable_chat_answers,
        features,
      });
    } else {
      setSelectedDocumentsIds(projectDocumentsIds);
      setSelectedAssetIds([]);
      setDisplayDocument(
        primaryDocument?.name?.toLocaleLowerCase().endsWith("pdf")
          ? (primaryDocument.id as string)
          : // get the first PDF from project documents
            ((documents.find((document) =>
              document.name?.toLocaleLowerCase().endsWith("pdf")
            )?.id as string) ?? undefined)
      );

      form.setFieldsValue({
        attachAllDocuments: true,
        public: true,
        title: project.title,
        enable_chat_answers: true,
        features: DEFAULT_FEATURES,
      });
    }
  };

  const approvePublication = async () => {
    const action = "ApprovePublication";
    await mutateAsync({
      url: `media/projects/${project.id}/publications/${publicationId}/status?action=${encodeURIComponent(action)}`,
      method: "post",
      values: {},
    });
    api.success({
      message: t("projects.components.SimpleProjectMediaPublish.goLive"),
      icon: <Globe />,
      description: t(
        "projects.components.SimpleProjectMediaPublish.yourVideoIs"
      ),
      placement: "bottomRight",
    });
    // project media to get new updated status
    invalidate({
      resource: `media/projects`,
      id: project.id,
      invalidates: ["detail"],
    });
  };

  const publish = async () => {
    const {
      title,
      public: isPublic,
      enable_chat_answers,
      features,
    } = form.getFieldsValue();
    let publicationResponse: MediaProjectPublicationResponse;
    const enable_video_cuepoints = features.includes("doc");
    const enable_chat = features.includes("chat");
    const flags = {
      enable_chat_answers: enable_chat_answers as boolean, //to type the request
      enable_chat,
      enable_video_cuepoints,
    };
    const isDocumentRequired = flags.enable_video_cuepoints === true;

    // todo add more checks
    const selectedDocumentIds = selectedDocumentsIds;
    if (selectedDocumentIds.length === 0 && isDocumentRequired) {
      return;
    }
    let pdfDocumentPlaced = false;
    // It should not publish if there is no PDF document selected
    const publishedDocuments = !isDocumentRequired
      ? []
      : project.documents.reduce<string[][]>((acc, item, index) => {
          if (selectedDocumentIds.includes(item.id)) {
            if (
              !pdfDocumentPlaced &&
              displayDocument &&
              displayDocument === item.id
            ) {
              acc.unshift(["Document_0", displayDocument as string]);
              pdfDocumentPlaced = true;
            } else {
              const adjustedIndex = pdfDocumentPlaced ? index : index + 1;
              acc.push(["Document_" + adjustedIndex, item.id]);
            }
          }
          return acc;
        }, []);

    // don't check if no document displayed in experience
    if (!pdfDocumentPlaced && isDocumentRequired) {
      return api.error({
        message: t(
          "projects.components.SimpleProjectMediaPublish.noPdfDocument"
        ),
        description: t("projects.components.SimpleProjectMediaPublish.aPdfIs"),
        placement: "topRight",
        duration: 5,
      });
    }

    const assetsIndexes: Record<string, number> = {};
    const publishedAssets =
      projectAssets
        ?.filter((x) => selectedAssetIds.includes(x.id))
        // todo keep index by asset type
        ?.map((item) => {
          if (assetsIndexes[item.asset_type] === undefined) {
            assetsIndexes[item.asset_type] = 0;
          }
          const assetIndex = assetsIndexes[item.asset_type]++;
          return [item.asset_type + "_" + assetIndex, item.id];
        }) ?? [];

    try {
      if (!isEdit) {
        const values: MediaProjectPublicationRequest = {
          project_publication_id: mediaId.substring(0, 8),
          title,
          media_id: mediaId,
          // public: isPublic, // todo
          public: true,
          assets: Object.fromEntries(publishedAssets),
          documents: Object.fromEntries(publishedDocuments),
          ...flags,
        };
        console.debug("publish", { formValues, values });

        const response = await mutateAsync({
          url: `media/projects/${project.id}/publications`,
          method: "post",
          values,
        });
        publicationResponse = response?.data as MediaProjectPublicationResponse;

        // load new publication data
        setPublicationId(publicationResponse.project_publication_id);
      } else {
        const values: UpdatePublicationRequest = {
          title,
          media_id: mediaId,
          // public: isPublic, /// todo
          assets: Object.fromEntries(publishedAssets ?? []),
          documents: Object.fromEntries(publishedDocuments),
          ...flags,
        };
        console.debug("publish", { formValues, values });

        const response = await mutateAsync({
          url: `media/projects/${project.id}/publications/${publicationId}`,
          method: "patch",
          values,
        });
        if (formValues.live) {
          await approvePublication();
        }
        publicationResponse = response?.data as MediaProjectPublicationResponse;
        invalidate({
          resource: `media/projects/${project.id}/publications`,
          id: publicationId,
          invalidates: ["detail"],
        });
      }
      api.success({
        message: t(
          "projects.components.SimpleProjectMediaPublish.saveSuccessful"
        ),
        description: t(
          "projects.components.SimpleProjectMediaPublish.yourChangesHave"
        ),
        placement: "bottomRight",
      });

      await invalidate({
        resource: `media/projects/${project.id}/publications`,
        invalidates: ["list"],
      });

      // project media to get new updated status
      await invalidate({
        resource: `media/projects`,
        id: project.id,
        invalidates: ["detail"],
      });
      setIsPublicationUpdateSuccess(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setInitialValues();
  }, [publication]);

  useEffect(
    () => toggleAllDocuments(form.getFieldValue("attachAllDocuments")),
    [form.getFieldValue("attachAllDocuments")]
  );
  useEffect(() => {
    if (publicationIdToEdit) setPublicationId(publicationIdToEdit);
  }, [publicationIdToEdit]);

  return (
    <>
      <Typography.Title level={4}>
        {t("projects.components.SimpleProjectMediaPublish.shareVideo")}
      </Typography.Title>

      {publicationUpdateSuccess && publication ? (
        <PublicationSuccess
          continueEditing={() => {
            return setIsPublicationUpdateSuccess(false);
          }}
          publicationData={publication}
        />
      ) : (isEdit && isLoadingPublication) ||
        isLoadingProjectAssets ||
        !media ? (
        <Flex
          align="center"
          justify="center"
          style={{ width: "100%", height: "33vw" }}
        >
          <Spin />
        </Flex>
      ) : (
        <ShareMedia
          displayDocument={displayDocument}
          setDisplayDocument={setDisplayDocument}
          documents={documents}
          assets={assets ?? []}
          setSelectedDocumentsIds={setSelectedDocumentsIds}
          selectedDocumentsIds={selectedDocumentsIds}
          setSelectedAssetIds={setSelectedAssetIds}
          selectedAssetIds={selectedAssetIds}
          project={project}
          onFinish={publish}
          approvePublication={approvePublication}
          media={media}
          publication={publication}
          form={form}
          expert={expert}
          isMutating={isMutating}
        />
      )}
    </>
  );
};

function PublicationSuccess({
  publicationData,
  continueEditing,
}: {
  publicationData: MediaProjectPublicationResponse;
  continueEditing: () => void;
}) {
  const t = useTranslate();
  const url = getPublicationUrl({
    projectId: publicationData.project_id,
    publicationId: publicationData.project_publication_id,
  });

  return (
    <Result
      icon={<Confetti size={60} />}
      status={"success"}
      title={t(
        "projects.components.SimpleProjectMediaPublish.publishSuccess.title"
      )}
      extra={
        <Flex vertical justify="center" align="center" gap={16}>
          <Button
            icon={<LinkOutlined />}
            type="primary"
            size={"large"}
            shape="round"
            href={url}
            target="_blank"
          >
            {t(
              "projects.components.SimpleProjectMediaPublish.publishSuccess.linkButton"
            )}
          </Button>

          <Button
            size="large"
            onClick={() => continueEditing()}
            type="text"
            shape="round"
          >
            {t(
              "projects.components.SimpleProjectMediaPublish.publishSuccess.continueEditing"
            )}
          </Button>

          <Flex
            align="center"
            justify="space-between"
            gap={30}
            style={{ marginTop: 60 }}
          >
            <Typography.Text strong>
              {t("projects.components.ShareMedia.share.shareTo")}
            </Typography.Text>
            <ShareButtons url={url} iconSize={36} />
          </Flex>
        </Flex>
      }
    />
  );
}
