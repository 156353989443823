import { isDocustream } from "helpers/utils";

export const FUND_MAX_TICKER_LENGTH = 10;
export const CDN_URL = process.env.REACT_APP_CDN_URL || "";
export const CHATBOT_WS_URL = process.env.REACT_APP_CHATBOT_WS_URL || "";
export const STORYBOARD_WS_URL = process.env.REACT_APP_STORYBOARD_WS_URL || "";

let NEW_MEDIAPLAYER_URL = process.env.REACT_APP_MEDIAPLAYER_URL || "";
if (isDocustream()) {
  NEW_MEDIAPLAYER_URL = NEW_MEDIAPLAYER_URL.replace(
    "libertify.com",
    "docustream.ai"
  );
}
export const MEDIAPLAYER_URL = NEW_MEDIAPLAYER_URL;
export const ALLOW_REGISTRATION = JSON.parse(
  process.env.REACT_APP_ALLOW_REGISTRATION ?? "false"
);
