import { useTranslate } from "@refinedev/core";
import { useForm } from "@refinedev/antd";
import { Button, Flex, Form, Input, Space, Typography } from "antd";
import { HttpError, useCustomMutation, useGo } from "@refinedev/core";
import styled from "styled-components";
import { useAntTheme } from "hooks/useAntTheme";
import { AiReadyMediaBrain } from "components/icons";
import { useContext, useEffect } from "react";
import { AppContext } from "appContext";

type FormData = {
  code: string;
  email?: string;
};
export const EmailVerificationPage = () => {
  const t = useTranslate();
  const { dispatch: appDispatch } = useContext(AppContext);
  const { mutate: resendCode, isLoading: isResending } = useCustomMutation();

  const { theme } = useAntTheme();
  // decode base64 email in url (cf authProvider login or email deeplink)
  let email;
  try {
    email = atob(
      new URLSearchParams(document.location.search).get("email") ?? ""
    );
  } catch {
    email = "";
  }
  const go = useGo();
  const { onFinish, formProps, form, formLoading } = useForm<
    FormData,
    HttpError,
    FormData
  >({
    action: "create",
    redirect: false,
    resource: `/api/users/confirm_register`,
    successNotification: {
      message: t("pages.auth.verification.accountActivated"),
      description: t("pages.auth.verification.pleaseLoginWith"),
      type: "success",
    },
    onMutationSuccess: async () => {
      go({ to: "/login?email=" + btoa(email) });
    },
  });
  const handleFinish = (values: FormData) => {
    // add email to request
    onFinish({ ...values, email });
  };
  const handleResendCode = () => {
    resendCode({
      url: "/api/users/resend_code",
      method: "post",
      values: { email },
      successNotification: {
        message: t("pages.auth.verification.verificationCodeResent"),
        description: t("pages.auth.verification.pleaseCheckYour"),
        type: "success",
      },
    });
  };

  useEffect(() => {
    appDispatch({ type: "setSidebarCollapsed", payload: true });

    return () => {
      appDispatch({ type: "setSidebarCollapsed", payload: false });
    };
  }, [appDispatch]);

  const code = Form.useWatch("code", form);
  return (
    <Flex justify="space-between">
      <Flex
        vertical
        style={{
          maxWidth: 600,
          paddingRight: 50,
          paddingLeft: 50,
          paddingTop: 100,
        }}
      >
        <StyledFormWrapper>
          <Typography.Title level={3}>
            {t("pages.auth.verification.verifyYourAccount")}
          </Typography.Title>
          <Typography.Text type="secondary">
            {t("pages.auth.verification.a6DigitPin")}
          </Typography.Text>
          <Form
            layout="vertical"
            {...formProps}
            onFinish={handleFinish}
            style={{ marginTop: 50 }}
          >
            <Form.Item name="code">
              <Input.OTP
                size="large"
                onChange={() => !formLoading && form.submit()}
              />
            </Form.Item>
            <Space style={{ marginTop: 50 }} size="large">
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                shape="round"
                loading={formLoading}
                // shouldn't need to click with autosubmit on OTP change
                // but it didn't work at Steve, but let's check we have the 6 figures
                disabled={formLoading || (code ?? "").length < 6}
              >
                {t("pages.auth.verification.continue")}
              </Button>
              <Button
                size="large"
                shape="round"
                type="text"
                onClick={handleResendCode}
                loading={isResending}
                disabled={formLoading || isResending}
              >
                {t("pages.auth.verification.resendCode")}
              </Button>
            </Space>
          </Form>
        </StyledFormWrapper>
      </Flex>
      <StyledSide
        vertical
        align="center"
        style={{
          margin: -theme.paddingLG, //24
          width: "24vw",
          minWidth: "40vw",
          maxWidth: 600,
          padding: 100,
          paddingTop: 100,
          textAlign: "center",
          height: "100%",
          minHeight: "100vh",
          background: "linear-gradient(to bottom, #000000 0%, #000000cc 100%)",
        }}
        gap={30}
      >
        <Flex align="center" vertical style={{ color: "#fff" }}>
          <AiReadyMediaBrain />
          <Typography.Text
            style={{
              fontSize: 36,
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            {t("pages.auth.verification.aiReadyMedia")}
          </Typography.Text>
        </Flex>

        <Typography.Text
          style={{
            fontSize: 36,
            fontWeight: "normal",
            color: "#fff",
            opacity: 0.8,
            lineHeight: 1.3,
          }}
        >
          {t("pages.auth.verification.weBringBack")}
        </Typography.Text>
      </StyledSide>
    </Flex>
  );
};

const StyledFormWrapper = styled.div`
  .ant-otp-input {
    height: 80px;
    font-size: 36px;
    margin: 0 5px; /* Adjust spacing */
  }
`;

const StyledSide = styled(Flex)`
  position: relative;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: url("https://images.unsplash.com/photo-1689267305146-155a1459752c?q=80&w=3840&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-position: center;
  }
  > * {
    z-index: 1;
  }
`;
