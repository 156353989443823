import { IHighlight, NewHighlight } from "react-pdf-highlighter";
import { create } from "zustand";
import { produce } from "immer";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const getNextId = () => {
  //improve readability
  // const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  // const nanoid = customAlphabet(alphabet, 8);
  // return nanoid();
  // make id looks like the one from the backend
  return [...Array(32)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join("");
};

type State = {
  highlights: IHighlight[];
  highlightIdToSetup?: string;
  currentHighlight?: IHighlight;
  isEditing: boolean;
};

type Action = {
  setHighlights: (highlights: State["highlights"]) => void;
  setHighlightIdToSetup: (
    highlightIdToSetup: State["highlightIdToSetup"]
  ) => void;
  setCurrentHighlight: (currentHighlight: State["currentHighlight"]) => void;
  updateHighlight: (
    highlightId: string,
    position: {
      x1?: number;
      y1?: number;
      x2?: number;
      y2?: number;
      left?: number;
      top?: number;
    },
    content: {
      text: string;
      image?: string;
      scriptText?: string;
    }
  ) => void;
  addHighlight: (highlight: NewHighlight) => IHighlight;
  deleteHighlight: (highlightId?: string) => void;
  deleteAllHighlights: () => void;
  setIsEditing: (isEditing: State["isEditing"]) => void;
};

export const useHighlightsStore = create<State & Action>()(
  devtools(
    immer((set) => ({
      isEditing: false,
      setIsEditing: (isEditing) => set(() => ({ isEditing })),
      highlights: [],
      highlightIdToSetup: "",
      currentHighlight: undefined,
      setHighlights: (highlights) => set(() => ({ highlights })),
      setHighlightIdToSetup: (highlightIdToSetup) =>
        set(() => ({ highlightIdToSetup })),
      setCurrentHighlight: (currentHighlight) => {
        if (!currentHighlight) {
          set(() => ({ currentHighlight }));
        } else {
          set((state) => ({
            currentHighlight: state.highlights.find(
              (highlight) => highlight.id === currentHighlight.id
            ),
          }));
        }
      },
      updateHighlight: (highlightId, positionUpdate, contentUpdate) =>
        set(
          produce((state) => {
            const highlight = state.highlights.find(
              (h) => h.id === highlightId
            );
            if (!highlight) return state;

            return {
              highlights: state.highlights.map((highlight) =>
                highlight.id === highlightId
                  ? {
                      ...highlight,
                      position: { ...highlight.position, ...positionUpdate },
                      content: { ...highlight.content, ...contentUpdate },
                    }
                  : highlight
              ),
            };
          })
        ),
      addHighlight: (highlight) => {
        const id = getNextId();
        const newHighlight = { ...highlight, id };

        set((state) => {
          state.setCurrentHighlight(newHighlight);
          return { highlights: [...state.highlights, newHighlight] };
        });

        return newHighlight;
      },
      deleteHighlight: (highlightId) =>
        set(
          produce((state) => {
            state.highlights = state.highlights.filter(
              (highlight) => highlight.id !== highlightId
            );
          })
        ),
      deleteAllHighlights: () => set(() => ({ highlights: [] })),
    }))
  )
);
