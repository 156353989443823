import { useCustom, useTranslate } from "@refinedev/core";
import { Badge, Card, Flex, Space, Typography } from "antd";
import { useAntTheme } from "hooks/useAntTheme";
import { CSSProperties, ReactNode, useEffect, useState } from "react";
import { MediaTiming } from "types";
import { useTemplates } from "./hooks/useTemplates";
import { TemplateType } from "./types";
import { useVolatileBoundStore } from "store";
import {
  ArrowFatLinesRight,
  Exclude,
  TextAlignLeft,
  Video,
} from "@phosphor-icons/react";

interface ChaptersProps {
  mediaId: string;
  projectId: string;
  onSave: () => Promise<boolean>;
}

export const Chapters: React.FC<ChaptersProps> = ({
  mediaId,
  projectId,
  onSave,
}) => {
  const { data: stepsData } = useCustom<MediaTiming>({
    url: `media/media/${mediaId}/timings`,
    method: "get",
  });
  const { organizationTemplates, mediaSettings } = useTemplates({
    projectId,
  });

  const t = useTranslate();

  // If user has added intro/transition/closing, steps will have empty chapters
  const chapters = stepsData?.data?.chapters?.filter(
    (chapter) => chapter.subtitles
  );

  const [picked, setPicked] = useState<string | null>(
    chapters?.[0]?.chapter_name
  );

  const {
    view,
    setView,
    currentChapter,
    setCurrentChapter,
    storeIntro,
    storeTransition,
    storeClosing,
    setCurrentTemplateId,
    resetUpdates,
    updates,
  } = useVolatileBoundStore((store) => ({
    view: store.templateState.view,
    presenter: store.templateState.presenter,
    setView: store.setView,
    setCurrentChapter: store.setCurrentChapter,
    storeIntro: store.templateState.intro,
    storeTransition: store.templateState.transition,
    storeClosing: store.templateState.closing,
    setCurrentTemplateId: store.setCurrentTemplateId,
    avatarClose: store.templateState.avatarClose,
    updates: store.templateState.updates,
    resetUpdates: store.resetUpdates,
    currentTemplateId: store.templateState.currentTemplateId,
    currentChapter: store.templateState.currentChapter,
  }));

  const { theme } = useAntTheme();

  const settingName = view.toLocaleLowerCase();
  const commonTemplate = mediaSettings?.[`${settingName}_template_id`];

  const onCardClick = async (type: string, chapterName?: string) => {
    if (updates > 0) {
      //  if no font, onSave will not be called, user should not leave the current template
      const success = await onSave();
      if (success) {
        onSuccess(type, chapterName);
      }
    } else {
      onSuccess(type, chapterName);
    }
  };

  const onSuccess = (type: string, chapterName?: string) => {
    let name;

    switch (type) {
      case TemplateType.INTRODUCTION:
        name = `${type}-${mediaId}`;
        break;
      case TemplateType.CLOSING:
        name = `${type}-${mediaId}`;
        break;
      case TemplateType.TRANSITION:
        name = `Transition-${chapterName}`;
        break;
      default:
        name = chapterName;
    }

    setView(type);
    setPicked(name);
    setCurrentChapter(name);

    const templateId = organizationTemplates?.find(
      (template) => template.name === name
    )?.id;
    const id = templateId ?? commonTemplate;

    setCurrentTemplateId(id);
    resetUpdates();
  };

  useEffect(() => {
    if (!chapters) return;

    if (!currentChapter) {
      setCurrentChapter(chapters?.[0]?.chapter_name);
      setView(TemplateType.CHAPTER);
      setPicked(chapters?.[0]?.chapter_name);
    }
  }, [
    chapters,
    currentChapter,
    setCurrentChapter,
    setCurrentTemplateId,
    setView,
    setPicked,
  ]);

  if (!chapters) return null;

  return (
    <Flex
      key={1}
      vertical
      gap={35}
      style={{
        maxHeight: "100%",
        overflow: "scroll",
        padding: 24,
      }}
    >
      {storeIntro.enable && (
        <ChapterCard
          key={storeIntro?.assetId}
          onClick={() => onCardClick(TemplateType.INTRODUCTION)}
          picked={view === TemplateType.INTRODUCTION}
          title={t("components.templates.Introduction")}
          icon={<Video weight="bold" size={24} />}
          tail={true}
        />
      )}

      {chapters.map((chapter, index) => {
        const isPicked =
          view === TemplateType.CHAPTER && chapter.chapter_name === picked;

        const isTransitionPicked =
          `Transition-${chapter.chapter_name}` === picked &&
          view === TemplateType.TRANSITION;

        return (
          <>
            {storeTransition.enable && (
              <Badge.Ribbon
                key={`Transition-${chapter.chapter_name}`}
                text={t("components.templates.Transition")}
                color={
                  isTransitionPicked ? theme.colorPrimary : theme.colorBorder
                }
                style={{ top: 5 }}
              >
                <ChapterCard
                  onClick={() =>
                    onCardClick(TemplateType.TRANSITION, chapter.chapter_name)
                  }
                  title={chapter.chapter_name}
                  picked={isTransitionPicked}
                  icon={<Exclude weight="bold" size={24} />}
                  tail={true}
                  style={{ paddingTop: 15 }}
                />
              </Badge.Ribbon>
            )}
            <ChapterCard
              key={chapter.chapter_name}
              onClick={() =>
                onCardClick(TemplateType.CHAPTER, chapter.chapter_name)
              }
              picked={isPicked}
              title={
                storeIntro.enable
                  ? `Paragraph ${index + 1}`
                  : chapter.chapter_name
              }
              icon={<TextAlignLeft weight="bold" size={24} />}
              tail={index === chapters.length - 1 ? storeClosing.enable : true}
            />
          </>
        );
      })}
      {storeClosing.enable && (
        <ChapterCard
          key={storeClosing.assetId}
          onClick={() => onCardClick(TemplateType.CLOSING)}
          picked={view === TemplateType.CLOSING}
          title={t("components.templates.Closing")}
          icon={<ArrowFatLinesRight weight="bold" size={24} />}
        />
      )}
    </Flex>
  );
};

const ChapterCard = ({
  onClick,
  picked,
  title,
  icon,
  tail,
  style,
}: {
  onClick: () => void;
  picked: boolean;
  title?: string;
  icon?: ReactNode;
  tail?: boolean;
  style?: CSSProperties;
}) => {
  const { theme } = useAntTheme();
  const secondaryColor = picked ? theme.colorPrimary : theme.colorBorder;
  const detailsColor = picked ? theme.colorTextBase : theme.colorTextSecondary;

  return (
    <Card
      size="small"
      hoverable
      onClick={onClick}
      style={{
        backgroundColor: picked ? `${theme.colorPrimary}60` : "unset",
        borderWidth: 3,
        borderColor: secondaryColor,
        borderRadius: 15,
        paddingTop: 5,
        paddingBottom: 5,
        ...style,
      }}
    >
      <Space style={{ color: detailsColor }} align="center">
        {icon}
        <Typography.Text
          style={{
            color: detailsColor,
            fontSize: 18,
            lineHeight: 1.1,
          }}
        >
          {title}
        </Typography.Text>
      </Space>
      {tail && (
        <Flex
          vertical
          style={{
            position: "absolute",
            bottom: -45,
            left: "50%",
            zIndex: 2,
          }}
          align="center"
        >
          <ChainDot color={secondaryColor} />
          <div
            style={{
              width: 3,
              height: 20,
              backgroundColor: secondaryColor,
            }}
          />
          <ChainDot color={secondaryColor} />
        </Flex>
      )}
    </Card>
  );
};

const ChainDot = ({ color }: { color: string }) => {
  return (
    <div
      style={{
        width: 15,
        height: 15,
        borderRadius: "50%",
        backgroundColor: color,
      }}
    />
  );
};
