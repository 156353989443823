import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React, { useState, useRef, useEffect, CSSProperties } from "react";
import { Button, ButtonProps, Modal, Space } from "antd";
import { Play } from "@phosphor-icons/react";

type PreviewVideoProps = {
  url: string;
  buttonStyles?: CSSProperties;
  iconStyles?: CSSProperties;
} & ButtonProps;

const PreviewVideoButton: React.FC<PreviewVideoProps> = ({
  url,
  size = "small",
  children,
  buttonStyles,
  iconStyles,
}) => {
  const t = useTranslate();
  const [visible, setVisible] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
    // const getFileExtension = (url: string): string | undefined => {
    //   const filename = url.split("?")[0];
    //   return filename.split(".").pop()?.toLowerCase();
    // };
    // const isVideoFile = (url: string | undefined): boolean => {
    //   if (!url) return false;
    //   const extension = getFileExtension(url);
    //   console.log(extension);
    //   return extension === "mp4" || extension === "avi";
    // };

    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };
  useEffect(() => {
    if (visible && videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  }, [visible]);
  return (
    <Space>
      <Button
        type="text"
        size={size}
        icon={
          <span className="anticon">
            <Play style={{ fontSize: 24, ...iconStyles }} weight="bold" />
          </span>
        }
        onClick={showModal}
        style={{
          color: "white",
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          ...buttonStyles,
        }}
      >
        {children}
      </Button>
      <Modal open={visible} onCancel={handleCancel} footer={null}>
        {url && (
          <video ref={videoRef} controls style={{ maxHeight: "85vh" }}>
            <source src={url} type="video/mp4" />
            {t("projects.components.PreviewVideoButton.yourBrowserDoes")}
          </video>
        )}
      </Modal>
    </Space>
  );
};

export default PreviewVideoButton;
