import {
  CheckCircleOutlined,
  DownOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  DatePicker,
  Empty,
  Flex,
  Modal,
  Space,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useAntTheme } from "hooks/useAntTheme";
import { ReactNode, useEffect, useState } from "react";
import { ChatHistoryResponse } from "types";
import { DateField, MarkdownField } from "@refinedev/antd";
import dayjs from "dayjs";
import { t } from "i18n";
import { useChatHistory } from "../hooks/useChatHistory";
import { MiniLabel } from "components/MiniLabel";

const STATUS_COLORS: Record<string, string> = {
  Answered: "success",
  NotAnswered: "error",
};

const STATUS_ICON: Record<string, ReactNode> = {
  Answered: <CheckCircleOutlined />,
  NotAnswered: <MinusCircleOutlined />,
};

const PAGE_SIZE = 10;

export const ProjectChatHistory = ({
  projectId,
  enabled = true, // load data on render
}: {
  projectId: string;
  enabled: boolean;
}) => {
  const { theme } = useAntTheme();
  const [chatHistory, setChatHistory] = useState<ChatHistoryResponse[]>([]);
  const [showAnswer, setShowAnswer] = useState<string | null>();
  const [currentSession, setCurrentSession] = useState<string>();
  const [params, setParams] = useState<{
    page: number;
    size: number;
    from_datetime?: string;
    to_datetime?: string;
  }>({
    page: 1,
    size: PAGE_SIZE,
  });

  const { data, isLoading, isFetching } = useChatHistory({
    projectId,
    params,
    enabled,
  });
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (data) {
      if (params.page > 1) {
        setChatHistory((prev) => [...prev, ...data]);
      } else {
        setChatHistory(data);
      }

      if (data.length === PAGE_SIZE) {
        setShowMore(true); // there might be another page !
      } else {
        setShowMore(false);
      }

      if (data.length) {
        setCurrentSession(data?.[0].session_id);
      }
    }
  }, [data]);

  const handleMore = () => {
    setParams((prev) => ({
      ...prev,
      page: params.page + 1,
    }));
  };

  const items = chatHistory?.map((session, index) => ({
    label: (
      <Space>
        <DateField
          value={session.history[0].timestamp}
          format="L LT"
          style={{
            color:
              currentSession === session.session_id
                ? theme.colorPrimary
                : undefined,
          }}
        />
        <Badge
          count={session.history.length}
          size="small"
          color={
            currentSession === session.session_id
              ? theme.colorPrimary
              : theme.colorText
          }
        ></Badge>
      </Space>
    ),
    key: session.session_id,
    children: (
      <Flex
        vertical
        style={{
          padding: 20,
          paddingLeft: 0,
          height: 500,
          overflowY: "scroll",
        }}
        gap={20}
      >
        {session.history.map((chat) => {
          return (
            <Card
              key={chat.timestamp}
              size="small"
              type="inner"
              title={
                <DateField
                  value={chat.timestamp}
                  format="LLL"
                  style={{ fontSize: 12, color: theme.colorTextSecondary }}
                />
              }
              extra={
                <Tag
                  style={{ border: 0, background: "none" }}
                  icon={STATUS_ICON[chat.status]} // Adjust based on session status
                  color={STATUS_COLORS[chat.status]} // Adjust based on session status
                >
                  {t(`projects.components.ProjectChatHistory.${chat.status}`)}
                </Tag>
              }
            >
              <Flex vertical gap={20}>
                <Flex gap={5} vertical>
                  <Flex gap={10}>
                    <Typography.Text strong style={{ width: 70 }}>
                      {t("projects.components.ProjectChatHistory.question")}
                    </Typography.Text>
                    <Typography.Text style={{ width: "80%" }}>
                      {chat.question}
                    </Typography.Text>
                  </Flex>
                  {showAnswer && showAnswer === chat.timestamp && (
                    <Flex gap={10}>
                      <Typography.Text strong style={{ width: 70 }}>
                        {t("projects.components.ProjectChatHistory.answer")}
                      </Typography.Text>
                      <Typography.Text style={{ width: "80%", marginTop: -10 }}>
                        <MarkdownField value={chat.answer} />
                      </Typography.Text>
                    </Flex>
                  )}
                </Flex>
                <Space>
                  <Button
                    size="small"
                    onClick={() =>
                      setShowAnswer(
                        showAnswer === chat.timestamp ? null : chat.timestamp
                      )
                    }
                  >
                    <Typography.Text type="secondary">
                      {showAnswer === chat.timestamp
                        ? t("projects.components.ProjectChatHistory.hideAnswer")
                        : t(
                            "projects.components.ProjectChatHistory.showAnswer"
                          )}
                    </Typography.Text>
                  </Button>
                </Space>
              </Flex>
            </Card>
          );
        })}
      </Flex>
    ),
  }));

  return (
    <Flex vertical style={{ minHeight: 600 }}>
      <Flex
        justify="space-between"
        style={{
          borderBottom: `1px solid ${theme.colorBorderSecondary}`,
          padding: 24,
          paddingTop: 0,
          marginTop: -10,
          marginLeft: -24,
          marginRight: -24,
        }}
      >
        <Flex vertical>
          {chatHistory && (
            <Typography.Text type="secondary">
              {t("projects.components.ProjectChatHistory.chat", {
                count: chatHistory?.length,
              })}
            </Typography.Text>
          )}
        </Flex>
        <Space>
          <Flex vertical>
            <MiniLabel>
              {t("projects.components.ProjectChatHistory.from")}
            </MiniLabel>
            <DatePicker
              onChange={(date) => {
                if (date !== null) {
                  const formattedDate = dayjs(date).toISOString();
                  setParams((prev) => ({
                    ...prev,
                    from_datetime: formattedDate,
                    page: 1,
                  }));
                } else {
                  setParams((prev) => ({
                    ...prev,
                    from_datetime: undefined,
                    page: 1,
                  }));
                }
              }}
            />
          </Flex>
          <Flex vertical>
            <MiniLabel>
              {t("projects.components.ProjectChatHistory.to")}
            </MiniLabel>
            <DatePicker
              onChange={(date) => {
                if (date !== null) {
                  const formattedDate = dayjs(date).toISOString();
                  setParams((prev) => ({
                    ...prev,
                    to_datetime: formattedDate,
                    page: 1,
                  }));
                } else {
                  setParams((prev) => ({
                    ...prev,
                    to_datetime: undefined,
                    page: 1,
                  }));
                }
              }}
            />
          </Flex>
        </Space>
      </Flex>
      {isLoading && (
        <Flex align="center" justify="center" style={{ flex: 1 }}>
          <Spin />
        </Flex>
      )}
      {chatHistory?.length > 0 && (
        <Tabs
          style={{ marginLeft: -24, marginRight: -24 }}
          tabPosition="left"
          tabBarGutter={0} // more compact list
          size="small"
          items={items}
          onTabClick={(key) => setCurrentSession(key)}
          tabBarExtraContent={
            showMore && (
              <Button
                size="small"
                shape="round"
                onClick={handleMore}
                icon={<DownOutlined />}
                loading={isFetching}
              >
                {t("projects.components.ProjectChatHistory.loadMore")}
              </Button>
            )
          }
        />
      )}
      {chatHistory?.length === 0 && (
        <Flex align="center" justify="center" style={{ flex: 1 }}>
          <Empty
            description={t("projects.components.ProjectChatHistory.noChat")}
          />
        </Flex>
      )}
    </Flex>
  );
};
