import { useTranslate } from "@refinedev/core";
import { Flex, Tabs } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
interface VoiceSelectorProps {
  custom: React.ReactNode;
  premade: React.ReactNode;
  activeKey: string;
}

const StyledFlex = styled(Flex)`
  & .ant-tabs {
    height: 100%;
    width: 100%;
  }
  & .ant-tabs-tabpane-active {
    height: 100%;
  }
  & .ant-tabs-content-holder {
    display: flex;
  }
`;

export const VoiceAndFaceSelector = ({
  custom,
  premade,
  ...props
}: VoiceSelectorProps) => {
  const t = useTranslate();
  const [activeKey, setActiveKey] = React.useState(props.activeKey);

  useEffect(() => {
    setActiveKey(props.activeKey);
  }, [props.activeKey]);

  const tabs = [
    {
      label: t("components.ContactWithPresenter.VoiceAndFaceSelector.presets"),
      key: "1",
      children: premade,
    },
    {
      label: t("components.ContactWithPresenter.VoiceAndFaceSelector.custom"),
      key: "2",
      children: custom,
    },
  ];
  return (
    <StyledFlex style={{ height: 400, width: "100%" }}>
      <Tabs
        items={tabs}
        activeKey={activeKey}
        onTabClick={(key) => setActiveKey(key)}
      />
    </StyledFlex>
  );
};

export default VoiceAndFaceSelector;
