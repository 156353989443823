import { StateCreator } from "zustand";
import { CombinedVolatileSlice } from "store/types";

type ProjectState = {
  currentMenuIndex: number | null;
  currentProjectId: string | null;
};

export const initialState: ProjectState = {
  currentMenuIndex: null,
  currentProjectId: null,
};

export interface ProjectSlice {
  projectState: ProjectState;
  setCurrentMenuIndex: (currentMenuIndex: number | null) => void;
  setCurrentProjectId: (currentProjectId: string | null) => void;
}

export const createProjectSlice: StateCreator<
  CombinedVolatileSlice,
  //https://github.com/pmndrs/zustand/issues/980#issuecomment-1162289836
  [["zustand/devtools", never], ["zustand/immer", never]],
  [],
  ProjectSlice
> = (set) => {
  return {
    projectState: initialState,
    setCurrentMenuIndex: (currentMenuIndex) =>
      set(
        (state) => ({
          projectState: { ...state.projectState, currentMenuIndex },
        }),
        undefined,
        "setCurrentMenuIndex"
      ),
    setCurrentProjectId: (currentProjectId) =>
      set(
        (state) => ({
          projectState: {
            ...state.projectState,
            currentProjectId,
            // reset project page menu when changing project
            currentMenuIndex: null,
          },
        }),
        undefined,
        "setCurrentProjectId"
      ),
  };
};
