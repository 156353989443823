import {
  Authenticated,
  useGo,
  useNavigation,
  useOne,
  useShow,
} from "@refinedev/core";
import { Link, useParams } from "react-router-dom";
import { Button, Flex, Space, Spin, Tabs, Typography } from "antd";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Organization } from "types";
import { useAntTheme } from "hooks/useAntTheme";
import { Chat } from "../components/chat/Chat";
import { Document, MediaDocument, MediaProjectResponse } from "../types";
import { DocumentViewer } from "components/DocumentViewer";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { PdfViewer } from "components/PdfViewer";
import { t } from "i18next";
import { sortedDocumentsPrimaryFirst } from "./helpers/helper";

export const AskDocs = ({ organization }: { organization?: Organization }) => {
  const params = useParams();
  const projectId = params.id;
  const { theme } = useAntTheme();
  const { show } = useNavigation();

  const { queryResult } = useShow<MediaProjectResponse>({
    resource: "media/projects",
    id: projectId,
  });
  const { data, isLoading, isError } = queryResult;
  const project = data?.data;
  const documents = data?.data.documents;

  const primaryDocumentId = project?.settings?.primary_document_id;

  let sortedDocuments: Document[] = [];

  if (documents) {
    sortedDocuments = sortedDocumentsPrimaryFirst(primaryDocumentId, documents);
  }

  const documentsTab = sortedDocuments?.map((doc: any) => {
    return {
      key: doc.id,
      label: doc.name,
      children: project?.organization_id && (
        <DocumentWrapper
          documentId={doc.id}
          organizationId={project?.organization_id}
        />
      ),
    };
  });

  const docsIds = documents?.map((doc: any) => doc.id);

  const onChange = (key: string) => {
    // console.log("changed " + key);
  };

  const handleBackClick = () => {
    show("media_projects", project?.id);
  };

  return (
    <Authenticated key="projectDocumentsChat">
      {/* <Show title={project?.title} isLoading={isLoading}> */}
      <Flex vertical>
        <Flex
          style={{
            width: "100vw",
            padding: 8,
            borderBottom: "1px solid",
            borderColor: theme.colorBorder,
            margin: -24,
            marginBottom: 0,
          }}
          align="center"
        >
          <Space style={{ flex: "0 40%" }}>
            <Button
              type="text"
              size="large"
              icon={<ArrowLeftOutlined />}
              onClick={handleBackClick}
            >
              {t("buttons.backToProject")}
            </Button>
          </Space>
          <Space>
            <Typography.Text type="secondary">
              {t(`src.App.projects`)} /{" "}
            </Typography.Text>
            <Link
              to={"/media/projects/" + project?.id}
              style={{ color: theme.colorText }}
            >
              {project?.title}
            </Link>
            <Typography.Text type="secondary">
              / {t("media.projects.detail.askAi")}
            </Typography.Text>
          </Space>
        </Flex>
        <PanelGroup autoSaveId="project_documents_chat" direction="horizontal">
          <Panel defaultSize={60} minSize={20}>
            <Tabs
              defaultActiveKey="1"
              items={documentsTab}
              onChange={onChange}
            />
          </Panel>

          <PanelResizeHandle
            style={{
              width: 2,
              background: `${theme.colorBorder}50`,
              borderRadius: 10,
              marginLeft: 10,
              cursor: "col-resize",
            }}
          />
          <Panel
            defaultSize={40}
            minSize={20}
            style={{ paddingLeft: 10, paddingTop: 10 }}
          >
            {project?.organization_id && docsIds && (
              <Chat
                documentIds={docsIds}
                organizationId={project?.organization_id}
              />
            )}
          </Panel>
        </PanelGroup>
      </Flex>
      {/* </Show> */}
    </Authenticated>
  );
};

const DocumentWrapper = ({
  documentId,
  organizationId,
}: {
  documentId: string;
  organizationId: string;
}) => {
  const { data } = useOne<MediaDocument>({
    resource: `media/documents/${organizationId}`,
    id: documentId,
    queryOptions: {
      enabled: !!organizationId,
    },
  });
  const document = data?.data;
  const isPdf = document?.name.toLocaleLowerCase().endsWith(".pdf");
  return (
    <div
      style={{
        overflowY: "auto",
        height: "calc(100vh - 120px)",
      }}
    >
      {!document?.url ? (
        <Flex
          justify="center"
          align="center"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Spin indicator={<LoadingOutlined />} />
        </Flex>
      ) : isPdf ? (
        <PdfViewer url={document.url} />
      ) : (
        <DocumentViewer url={document.url} />
      )}
    </div>
  );
};
