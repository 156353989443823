import { useEffect, useState } from "react";
import { useVolatileBoundStore } from "store";

export const useAutoSave = (
  saveFunction: () => Promise<boolean>,
  intervalMs: number = 5000
) => {
  const { updates, resetUpdates } = useVolatileBoundStore((store) => ({
    updates: store.templateState.updates,
    resetUpdates: store.resetUpdates,
  }));

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Don't start interval if no updates
    if (updates === 0) return;

    const save = async () => {
      setIsLoading(true);
      const success = await saveFunction();
      if (success) {
        resetUpdates();
      }
      setIsLoading(false);
    };

    const intervalId = setInterval(save, intervalMs);

    return () => clearInterval(intervalId);
  }, [updates, saveFunction, resetUpdates, intervalMs]);

  return { isSavingTemplate: isLoading };
};
