import { Form, Input, Button, Space, Result } from "antd";
import {
  useCreate,
  useGo,
  useTranslate,
  Authenticated,
  CanAccess,
} from "@refinedev/core";
import { useOrganization } from "hooks/useOrganization";
import { Create } from "@refinedev/antd";

const ProductCreatePage = () => {
  const t = useTranslate();
  const { currentOrganization } = useOrganization({});
  const { mutate: createProduct, isLoading } = useCreate();
  const go = useGo();

  const gotoList = () => {
    go({
      to: { resource: "media_products", action: "list" },
    });
  };
  const onFinish = async (values: any) => {
    const formattedValues = {
      ...values,
    };

    await createProduct({
      resource: `media/${currentOrganization?.id}/products`,
      values: formattedValues,
      successNotification: {
        message: t("media.products.create.productCreated"),
        type: "success",
      },
      errorNotification: {
        message: t("media.products.create.productCreateError"),
        type: "error",
      },
    });
    gotoList();
  };

  return (
    <Authenticated key="product-create">
      <CanAccess
        resource="media_products"
        action="create"
        fallback={
          <Result
            status={403}
            title={t("media.media.create.accessForbidden")}
          />
        }
      >
        <Create title={t("media.products.index.create")} footerButtons={<></>}>
          <Form
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              price: 0,
            }}
          >
            <Form.Item
              label={t("media.products.index.sku")}
              name="sku"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("media.products.index.name")}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("media.products.create.category")}
              name="category"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("media.products.create.subCategory")}
              name="sub_category"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("media.products.create.brandName")}
              name="brand_name"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("media.products.index.url")}
              name="url"
              rules={[
                {
                  required: true,
                },
                {
                  type: "url",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("media.products.index.price")}
              name="price"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Space>
              <Button onClick={gotoList} size="large" shape="round">
                {t("buttons.cancel")}
              </Button>
              <Button
                shape="round"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                size="large"
                disabled={isLoading}
              >
                {t("buttons.submit")}
              </Button>
            </Space>
          </Form>
        </Create>
      </CanAccess>
    </Authenticated>
  );
};

export default ProductCreatePage;
