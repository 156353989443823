import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Authenticated,
  IResourceComponentsProps,
  useGo,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import { ProjectKB } from "./components/ProjectKB";
import { useProjectDetails } from "./hooks/useProjectDetails";
import { MediaProjectResponse } from "pages/media/types";
import { useAntTheme } from "hooks/useAntTheme";
import { useProjectMedia } from "./components/useProjectMedia";
import { Flex, Spin, Button, Space } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { ChatTeardropText } from "@phosphor-icons/react";
import { ProjectSubPageHeader } from "./components/ProjectSubPageHeader";

export const ProjectKbPage: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  type ProjectModal = "AssetUpload" | "DocumentUpload" | "MediaUpload";
  const params = useParams();
  const projectId = params.id;
  const [openedModal, setOpenedModal] = useState<ProjectModal>();
  const go = useGo();
  const queryResult = useProjectDetails({ projectId });
  const { data, isLoading } = queryResult;
  const project = data?.data;
  useProjectMedia({ project });
  const openModal = (modal: ProjectModal) => {
    setOpenedModal(modal);
  };
  const closeModal = () => setOpenedModal(undefined);

  if (isLoading) {
    return <Spin fullscreen />;
  }

  return (
    <Authenticated key="projectKb">
      {project && (
        <Flex vertical style={{ width: "100%" }}>
          <ProjectSubPageHeader
            projectId={project.id}
            projectTitle={project.title}
            page="kb"
          />
          <ProjectKB
            project={project}
            projectId={projectId}
            openModal={openModal}
            openedModal={openedModal}
            closeModal={closeModal}
          />
          <Flex
            justify="start"
            align="center"
            style={{ marginTop: 8 }}
            gap={10}
          >
            <Button
              shape="round"
              size="large"
              type="primary"
              icon={<PaperClipOutlined />}
              onClick={() => openModal("DocumentUpload")}
            >
              {t("media.documents.create.uploadDocument")}
            </Button>
            <Button
              size="large"
              shape="round"
              icon={<ChatTeardropText size={16} weight="fill" />}
              onClick={() => {
                go({ to: `/media/projects/${project.id}/chat` });
              }}
            >
              {t("media.projects.detail.askAi")}
            </Button>
          </Flex>
        </Flex>
      )}
    </Authenticated>
  );
};
