import { Flex } from "antd";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  // FacebookMessengerIcon,
  // FacebookMessengerShareButton,
  LineShareButton,
  LineIcon,
  XIcon,
} from "react-share";
const social = {
  Facebook: FacebookShareButton,
  Twitter: TwitterShareButton,
  Linkedin: LinkedinShareButton,
  Telegram: TelegramShareButton,
  Whatsapp: WhatsappShareButton,
  // messenger: FacebookMessengerShareButton,
  Line: LineShareButton,
  Email: EmailShareButton,
};
const socialIcons = {
  Facebook: FacebookIcon,
  Twitter: XIcon,
  Linkedin: LinkedinIcon,
  Telegram: TelegramIcon,
  Whatsapp: WhatsappIcon,
  // messenger: FacebookMessengerIcon,
  Line: LineIcon,
  Email: EmailIcon,
};

export default function ShareButtons({
  url,
  iconSize = 32,
}: {
  url: string;
  iconSize?: number;
}) {
  return (
    <Flex wrap="wrap" justify="space-between" gap={15}>
      {Object.entries(social).map(([key, Component]) => {
        const Icon = socialIcons[key];
        return (
          <Component key={key} url={url}>
            <Icon size={iconSize} round />
            {/* <Typography.Text style={{ display: "block", margin: "0 auto" }}>
              {key}
            </Typography.Text> */}
          </Component>
        );
      })}
    </Flex>
  );
}
