import { Flex } from "antd";
import React, { CSSProperties } from "react";
import { useAntTheme } from "hooks/useAntTheme";

interface FaceProfilePreviewViewProps {
  name?: string;
  isProcessing: boolean;
  ghost?: boolean;
  path?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  selected?: boolean;
  fullSize?: boolean;
}

const FaceProfilePreviewView: React.FC<FaceProfilePreviewViewProps> = ({
  name,
  isProcessing,
  ghost,
  path,
  style,
  onClick,
  selected,
  fullSize,
}) => {
  const { theme } = useAntTheme();

  const [borderColor, setBorderColor] = React.useState(theme.colorPrimary);
  const defaultOutContainerStyle = {
    minHeight: 100,
    minWidth: 100,
    height: "100%",
    width: "100%",
    borderRadius: 100,
    overflow: "hidden",
    border: "3px",
    borderStyle: isProcessing ? "dotted" : "solid",
    borderColor: selected ? borderColor : "transparent",
    ...style,
  };
  const fullSizeOutContainerStyle: CSSProperties = {
    ...defaultOutContainerStyle,
    overflow: "unset",
    width: "100%",
    height: "100%",
    border: "none",
  };
  const defaultInnerContainerStyle = {
    height: "calc(100% - 6px)",
    width: "calc(100% - 6px)",
    borderRadius: 100,
    backgroundImage: !ghost
      ? "transparent"
      : `linear-gradient(150deg, #DBFFB8 0%, #FEEDA4 100%)`,
  };
  const fullSizeInnerContainerStyle = {
    ...defaultInnerContainerStyle,
    height: "100%",
    width: "100%",
    borderRadius: 20,
    overflow: "hidden",
    backgroundColor: undefined,
    backgroundSize: undefined,
    backgroundPosition: undefined,
  };

  const outerContainerStyle = fullSize
    ? fullSizeOutContainerStyle
    : defaultOutContainerStyle;
  const innerContainerStyle = fullSize
    ? fullSizeInnerContainerStyle
    : defaultInnerContainerStyle;
  const defaultImageContainerStyle: CSSProperties = {
    width: "100%",
    height: "100%",
    borderRadius: 100,
    backgroundImage: path ? `url(${path})` : "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    overflow: "hidden",
  };
  const fullSizeImageContainerStyle: CSSProperties = {
    ...defaultImageContainerStyle,
    backgroundPosition: "bottom",
    backgroundSize: "contain",
    width: "100%",
    bottom: 10,
    height: "40%", // The backend ratio of the presenter to video
    backgroundRepeat: "no-repeat",
    backgroundOrigin: "border-box",
    borderRadius: "unset",
    position: "absolute",
  };
  const imageContainerStyle = fullSize
    ? fullSizeImageContainerStyle
    : defaultImageContainerStyle;
  return (
    <Flex
      align="center"
      justify="center"
      aria-label={name}
      style={outerContainerStyle}
      onClick={() => {
        setBorderColor(theme.colorPrimary);
        onClick?.();
      }}
    >
      <Flex align="center" justify="center" style={innerContainerStyle}>
        <div style={imageContainerStyle}></div>
      </Flex>
    </Flex>
  );
};

export default FaceProfilePreviewView;
