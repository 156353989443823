import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { useGo, useNavigation } from "@refinedev/core";
import { Button, Flex, Result, Skeleton, Spin, Typography } from "antd";
import { useAntTheme } from "hooks/useAntTheme";
import { t } from "i18next";
import { useProjectDetails } from "pages/media/projects/hooks/useProjectDetails";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

export const ProjectStepLoadingPage = () => {
  const params = useParams();
  const projectId = params.id;
  const { data } = useProjectDetails({ projectId });
  const { theme } = useAntTheme();

  const storyboard = data?.data?.storyboards?.[0];
  const storyboardIsReady = ["Reviewing", "Approved"].includes(
    storyboard?.status ?? ""
  );

  const go = useGo();
  const { show } = useNavigation();

  const gotoStoryboard = useCallback(
    (id: string) => {
      go({
        to: `/media/projects/${projectId}/storyboard/${id}`,
      });
    },
    [go, projectId]
  );

  useEffect(() => {
    if (!storyboardIsReady) return;

    setTimeout(() => {
      if (storyboard?.asset_id) {
        gotoStoryboard(storyboard.asset_id);
      }
    }, 3000);
  }, [storyboard, storyboardIsReady, gotoStoryboard]);

  const goBackToProject = () => {
    if (projectId) {
      show("media_projects", projectId);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        padding: 24,
        position: "relative",
      }}
    >
      {storyboardIsReady ? (
        <Flex
          align="center"
          justify="center"
          style={{ width: "100%", height: "100%" }}
        >
          <Result
            status="success"
            title={t("media.projects.loading.ready", { asset: "Storyboard" })}
          />
        </Flex>
      ) : (
        <>
          <Flex justify="space-between">
            <Flex style={{ flex: "0 40%" }} vertical>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Flex>
            <Flex style={{ flex: "0 50%" }}>
              <Skeleton.Node style={{ width: "40vw", height: "90vh" }} active>
                <div></div>
              </Skeleton.Node>
            </Flex>
          </Flex>
          <Flex
            align="center"
            justify="center"
            vertical
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: theme.colorBgBase,
              opacity: 0.5,
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 48, color: theme.colorTextSecondary }}
                  spin
                />
              }
            />
            <Typography.Title level={3} style={{ fontWeight: "normal" }}>
              {t("media.projects.loading.loading", { asset: "Storyboard" })}
            </Typography.Title>
          </Flex>
          <Button
            type="primary"
            size="large"
            icon={<ArrowLeftOutlined />}
            onClick={goBackToProject}
            style={{ position: "absolute", top: 24, left: 24 }}
          >
            {t("media.projects.detail.exploreMore")}
          </Button>
        </>
      )}
    </div>
  );
};
