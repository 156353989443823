import { useTranslate } from "@refinedev/core";
import { Descriptions, Typography } from "antd";
import { MediaStep } from "../types";
import { DescriptionsProps } from "antd/lib";
import { MediaAssetPreview } from "../components/MediaAssetPreview";

export const MediaStepPreview = ({
  step,
  mediaId,
}: {
  step: MediaStep;
  mediaId: string;
}) => {
  const t = useTranslate();
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: t("media.media.MediaStepPreview.id"),
      children: <Typography.Text copyable>{step.id}</Typography.Text>,
    },
    {
      key: "2",
      label: t("media.media.MediaStepPreview.outputType"),
      children: step.output_type,
    },
    {
      key: "3",
      label: t("media.media.MediaStepPreview.chapterName"),
      children: step.chapter_name,
    },
    {
      key: "4",
      label: t("media.media.MediaStepPreview.status"),
      children: step.status,
    },
    {
      key: "5",
      label: t("media.media.MediaStepPreview.stepType"),
      children: step.step_type,
    },
  ];

  return (
    <div style={{ paddingLeft: 30, paddingRight: 30 }}>
      <Typography.Title level={4} style={{ marginTop: 0 }}>
        {t("media.media.MediaStepPreview.step")}
      </Typography.Title>
      <Descriptions
        title=""
        items={items}
        bordered
        layout="vertical"
        size="small"
      />
      <Typography.Title level={4}>
        {t("media.media.MediaStepPreview.asset")}
      </Typography.Title>
      {step.asset && <MediaAssetPreview asset={step.asset} mediaId={mediaId} />}
    </div>
  );
};
