import { Flex, Typography } from "antd";
import { ReactNode } from "react";

export const WizardStepHeader = ({
  title,
  subtitle,
}: {
  title: ReactNode;
  subtitle?: ReactNode;
}) => {
  return (
    <Flex
      vertical
      gap={0}
      style={{ marginTop: -140, zIndex: 10, height: 100 }}
      justify="center"
    >
      <Typography.Title level={2} style={{ margin: 0, color: "#fff" }}>
        {title}
      </Typography.Title>
      {subtitle && (
        <Typography.Text strong style={{ fontSize: "1.1rem", color: "#fff" }}>
          {subtitle}
        </Typography.Text>
      )}
    </Flex>
  );
};
