import React from "react";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";
import { Spin } from "antd";
import "react-indiana-drag-scroll/dist/style.css";
import * as Sentry from "@sentry/react";
import ErrorBoundary from "components/ErrorBoundary";

if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "staging"
) {
  Sentry.init({
    dsn: "https://b98cf44ee3841381b2c77207979e1569@o4504212769931264.ingest.us.sentry.io/4508244579188736",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled

    tracePropagationTargets: ["localhost", /^https:\/\/api.libertify.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

async function enableMocking() {
  if (process.env.NODE_ENV !== "development") {
    return;
  }

  const { worker } = await import("./mocks/browser");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

// enableMocking().then(() => {
root.render(
  // <React.StrictMode>
  <React.Suspense fallback={<Spin />}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.Suspense>
  // </React.StrictMode>
);
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
