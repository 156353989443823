import { Authenticated } from "@refinedev/core";

import { ProjectCreateWizard } from "./components/ProjectCreateWizard";

export const ProjectCreate = () => {
  return (
    <Authenticated key="projectCreate">
      <ProjectCreateWizard />
    </Authenticated>
  );
};
