import { useTranslate } from "@refinedev/core";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Badge, Col, Flex, Popover, Row, Tooltip } from "antd";
import { ReactNode } from "react";
import type { Maybe } from "types";
import type { DisplayMetrics } from "pages/media/types";

import styled from "styled-components";

const InfoTooltip = ({ children }: { children: ReactNode }) => {
  return (
    <Popover
      autoAdjustOverflow
      overlayInnerStyle={{ padding: 20, whiteSpace: "pre-line" }}
      overlayStyle={{
        maxWidth: 500,
      }}
      content={children}
      trigger="click"
    >
      <InfoCircleOutlined size={24} style={{ opacity: 0.6 }} />
    </Popover>
  );
};

const getMetricColor = (score: Maybe<number>) => {
  if (score === undefined || score === null || score < 4) {
    return "red";
  }
  if (score >= 8) {
    return "green";
  } else if (score >= 4) {
    return "orange";
  }
};

export const StoryboardMetrics = ({ metrics }: { metrics: DisplayMetrics }) => {
  const t = useTranslate();

  return (
    <Flex vertical gap={12}>
      <StyledScoreElement wrap={true}>
        <Col span={3}>
          <InfoTooltip>
            {t("storyboard.editor.StoryboardMetrics.polarity")}
          </InfoTooltip>
        </Col>
        <Col span={10}>
          <Tooltip
            title={
              <Flex vertical>
                <Row>
                  {t("storyboard.editor.StoryboardMetrics.negative")}
                  {metrics.polarity.negative}
                </Row>
                <Row>
                  {t("storyboard.editor.StoryboardMetrics.neutral")}
                  {metrics.polarity.neutral}
                </Row>
                <Row>
                  {t("storyboard.editor.StoryboardMetrics.positive")}
                  {metrics.polarity.positive}
                </Row>
              </Flex>
            }
          >
            {t("storyboard.editor.StoryboardMetrics.balance")}
          </Tooltip>
        </Col>
        <Col span={6}>
          <Badge
            showZero
            color={getMetricColor(metrics.polarity.score)}
            count={metrics.polarity.score}
          />
        </Col>
      </StyledScoreElement>
      <StyledScoreElement wrap={true}>
        <Col span={3}>
          <InfoTooltip>
            {t("storyboard.editor.StoryboardMetrics.similarity")}
          </InfoTooltip>
        </Col>
        <Col span={10}>
          <Tooltip
            title={
              <Flex vertical gap={0}>
                <Row>
                  {t("storyboard.editor.StoryboardMetrics.recall")}
                  {metrics.similarity.recall}
                </Row>
                <Row>
                  {t("storyboard.editor.StoryboardMetrics.precision")}
                  {metrics.similarity.precision}
                </Row>
                <Row>
                  {t("storyboard.editor.StoryboardMetrics.semantic")}
                  {metrics.similarity.semantic_similarity}
                </Row>
              </Flex>
            }
          >
            {t("storyboard.editor.StoryboardMetrics.accuracy")}
          </Tooltip>
        </Col>
        <Col span={6}>
          <Badge
            showZero
            color={getMetricColor(metrics.similarity.score)}
            count={metrics.similarity.score}
          />
        </Col>
      </StyledScoreElement>
      <StyledScoreElement wrap={true}>
        <Col span={3}>
          <InfoTooltip>
            {t("storyboard.editor.StoryboardMetrics.readability")}
          </InfoTooltip>
        </Col>
        <Col span={10}>
          <Tooltip
            title={
              <Flex vertical>
                <Row>
                  {t("storyboard.editor.StoryboardMetrics.ease")}
                  {/* {metrics.readability.ease} */}
                  {t(
                    `storyboard.editor.StoryboardMetrics.${metrics.readability.ease}`
                  )}
                </Row>
                <Row>
                  {t("storyboard.editor.StoryboardMetrics.level")}
                  {/* {metrics.readability.grade_level} */}
                  {t(
                    `storyboard.editor.StoryboardMetrics.${metrics.readability.grade_level}`
                  )}
                </Row>
              </Flex>
            }
          >
            {t("storyboard.editor.StoryboardMetrics.clarity")}
          </Tooltip>
        </Col>
        <Col span={6}>
          <Badge
            showZero
            color={getMetricColor(metrics.readability.score)}
            count={metrics.readability.score}
          />
        </Col>
      </StyledScoreElement>
    </Flex>
  );
};

const StyledScoreElement = styled(Row)``;
