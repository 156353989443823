import { useTranslate } from "@refinedev/core";
import React, { useRef, useEffect } from "react";
import { Input, InputProps, InputRef } from "antd";

interface SearchInputProps {
  onSearch: (searchText: string, isLive: boolean) => void;
  value: string;
}

const SearchInput: React.FC<SearchInputProps & InputProps> = ({
  onSearch,
  value,
  ...rest
}) => {
  const t = useTranslate();
  const searchInput = useRef<InputRef>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    isLive = true
  ) => {
    const value = e.target.value;
    onSearch(value, isLive);
  };

  useEffect(() => {
    const handleKeyPressShortcut = (e: KeyboardEvent) => {
      if (e.key === "/" || (e.ctrlKey && e.key === "f")) {
        e.preventDefault();
        if (searchInput.current) {
          searchInput.current.focus();
        }
      }
    };
    document.addEventListener("keydown", handleKeyPressShortcut);
    return () => {
      document.removeEventListener("keydown", handleKeyPressShortcut);
    };
  }, []);

  return (
    <Input.Search
      ref={searchInput}
      size="large"
      placeholder={t("projects.components.SearchInput.typeTo")}
      value={value}
      onChange={handleChange}
      onSearch={(value) => {
        handleChange(
          {
            target: { value },
          } as React.ChangeEvent<HTMLInputElement>,
          false
        );
      }}
      allowClear
      {...rest}
    />
  );
};

export default SearchInput;
