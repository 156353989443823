import { Button, Popconfirm } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useTranslate, useCustomMutation } from "@refinedev/core";
import { FC } from "react";
import { BrandKit } from "pages/media/types";

interface DuplicateBrandkitButtonProps {
  brandkitId: string;
  organizationId: string;
  onSuccess?: (brandkit: BrandKit) => void;
  brandkitName: string;
}

export const DuplicateBrandkitButton: FC<DuplicateBrandkitButtonProps> = ({
  brandkitId,
  brandkitName,
  organizationId,
  onSuccess,
}) => {
  const t = useTranslate();
  const newBrandkitName = t("brandkit.components.duplicate.copyOf", {
    name: brandkitName,
  });

  const { mutateAsync, isLoading } = useCustomMutation<BrandKit>();

  const handleDuplicate = async () => {
    const { data } = await mutateAsync({
      url: `media/${organizationId}/brand_kits/duplicate`,
      method: "post",
      values: {
        source_brandkit_id: brandkitId,
        name: newBrandkitName,
      },
    });
    onSuccess?.(data);
  };

  return (
    <Popconfirm
      overlayStyle={{ maxWidth: 400 }}
      title={t("brandkit.components.duplicate.title")}
      description={t("brandkit.components.duplicate.confirmMessage", {
        name: brandkitName,
      })}
      onConfirm={handleDuplicate}
      okText={t("projects.components.ProjectCreateWizard.step.Confirm")}
      cancelText={t("buttons.cancel")}
      placement="top"
      okButtonProps={{ loading: isLoading }}
    >
      <Button icon={<CopyOutlined />} shape="round">
        {t("brandkit.components.duplicate.copy")}
      </Button>
    </Popconfirm>
  );
};
