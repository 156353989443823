import { useNavigation } from "@refinedev/core";
import { useEffect } from "react";

// workaround to support breadcrumb link to first item
export const MediaIndexPage = () => {
  const { list } = useNavigation();
  useEffect(() => {
    if (list) list("media_projects");
  }, [list]);
  return null;
};
