import { Input, Space, Select, Checkbox, Form } from "antd";
import { useGo, useTranslate, Authenticated } from "@refinedev/core";
import { Create, SaveButton, useForm } from "@refinedev/antd";
import { languages } from "data/languages";
import { getCountryOptions } from "../utils";
import { useEffect } from "react";

const OrganizationCreatePage = () => {
  const t = useTranslate();
  const go = useGo();
  const countriesOptions = getCountryOptions();
  const { formProps, saveButtonProps, form } = useForm<any>({
    action: "create",
    resource: "media/organizations",
    onMutationSuccess: () => {
      go({
        to: { resource: "organization", action: "list" },
      });
    },
  });

  const languageOptions = languages.map((lang) => {
    const [languageName, countryName] = lang.value.split("-");
    return {
      label:
        t(`nativeLanguageNames.${languageName}`) +
        (countryName ? ` (${t(`nativeCountryNames.${countryName}`)})` : ""),
      value: lang.value,
    };
  });

  return (
    <Authenticated key="organization-create">
      <div style={{ maxWidth: 800, margin: "auto" }}>
        <Create
          resource="organization"
          saveButtonProps={saveButtonProps}
          title={t("media.organizations.create.createOrganizationTitle")}
          footerButtons={({ saveButtonProps }) => (
            <Space>
              <SaveButton
                {...saveButtonProps}
                shape="round"
                // loading={isLoading}
                size="large"
                // disabled={isLoading}
              >
                {t("buttons.submit")}
              </SaveButton>
            </Space>
          )}
        >
          <Form
            form={form}
            {...formProps}
            // onFinish={onFinish}
            layout="vertical"
            initialValues={{
              add_current_user: true,
              city: "",
              language: "en-US",
            }}
          >
            <Form.Item
              label={t("pages.users.admin.organization")}
              name="name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("media.organizations.create.logo")}
              name="logo"
              rules={[{ type: "url" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("media.organizations.create.address")}
              name="address"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("media.organizations.create.address") + " 2"}
              name="address2"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("media.organizations.create.zipCode")}
              name="zip_code"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("pages.user.edit.country")}
              name="country"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                options={countriesOptions}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item label={t("media.organizations.create.city")} name="city">
              <Input />
            </Form.Item>
            <Form.Item
              label={t("media.organizations.index.defaultLanguage")}
              name="language"
              rules={[{ required: true }]}
            >
              <Select options={languageOptions} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item name="add_current_user" valuePropName="checked">
              <Checkbox>
                {t("media.organizations.create.addCurrentUser")}
              </Checkbox>
            </Form.Item>
          </Form>
        </Create>
      </div>
    </Authenticated>
  );
};

export default OrganizationCreatePage;
