import {
  useTranslate,
  useTable,
  CanAccess,
  Authenticated,
} from "@refinedev/core";
import { Table, Space, Button, Result } from "antd";
import { ColumnsType } from "antd/es/table";
import { DateField, CreateButton, List } from "@refinedev/antd";
import { LinkOutlined, PlusOutlined } from "@ant-design/icons";
import { useOrganization } from "hooks/useOrganization";
import { UUID } from "components/UUID";
import { NumberField } from "@refinedev/antd";
import { useEffect, useState } from "react";
import { ProductResponse } from "types";

// const formatCurrency = (price: string | number, currency: string = "EUR") => {
//   const locale = navigator.language;
//   return new Intl.NumberFormat(locale, {
//     style: "currency",
//     currency: currency,
//   }).format(Number(price));
// };

const ProductsList = () => {
  const t = useTranslate();
  const { currentOrganization } = useOrganization({});
  const [total, setTotal] = useState(0);

  const {
    tableQueryResult: { data, isLoading, isFetching },
    current,
    pageSize,
    setCurrent,
    // setPageSize,
  } = useTable<ProductResponse>({
    resource: `media/${currentOrganization?.id}/products`,
    pagination: {
      mode: "server",
      // pageSize: 50, 20 max
    },
  });

  useEffect(() => {
    const dataLength = data?.data.length ?? 0;
    const totalLength = dataLength + pageSize * (current - 1);
    setTotal(dataLength < pageSize ? totalLength : totalLength + pageSize);
  }, [data, current, pageSize]);

  const columns: ColumnsType<ProductResponse> = [
    {
      title: t("media.products.index.id"),
      dataIndex: "id",
      key: "id",
      render: (id: string) => <UUID id={id} />,
      width: 150,
    },
    {
      title: t("media.products.index.sku"),
      dataIndex: "sku",
      key: "sku",
      width: 200,
    },
    {
      title: t("media.products.index.name"),
      dataIndex: "name",
      key: "name",
      render: (name: string, record) => (
        <Space size="small">
          <Button
            type="link"
            icon={<LinkOutlined />}
            href={record.url}
            target="_blank"
          />
          {name}
        </Space>
      ),
    },
    {
      title: t("media.products.index.price"),
      dataIndex: "price",
      key: "price",
      ellipsis: true,
      render: (price: string) => (
        <NumberField
          value={price}
          options={{
            style: "currency",
            currency: "EUR",
          }}
        />
      ),
    },
    {
      title: t("media.products.index.createdAt"),
      dataIndex: "created",
      key: "created",
      render: (date: string) => <DateField value={date} format="LLL" />,
    },
    // {
    //   title: t("media.products.index.actions"),
    //   key: "actions",
    //   render: (_, record) => (
    //     <Space>
    //       <CanAccess resource="media_products" action="edit">
    //         <EditButton hideText size="small" recordItemId={record.id} />
    //       </CanAccess>
    //       <CanAccess resource="media_products" action="delete">
    //         <DeleteButton hideText size="small" recordItemId={record.id} />
    //       </CanAccess>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <Authenticated key="products">
      <CanAccess
        resource="media_products"
        action="list"
        fallback={
          <Result
            status={403}
            title={t("media.media.create.accessForbidden")}
          />
        }
      >
        <List
          headerButtons={({ createButtonProps }) => (
            <CreateButton
              {...createButtonProps}
              icon={<PlusOutlined />}
              shape="round"
            >
              {t("media.products.index.create")}
            </CreateButton>
          )}
        >
          <Table
            columns={columns}
            dataSource={data?.data}
            loading={isLoading || isFetching}
            rowKey="sku"
            pagination={{
              current,
              pageSize,
              total,
              onChange: (page) => {
                setCurrent(page);
                // setPageSize(size);
              },
            }}
          />
        </List>
      </CanAccess>
    </Authenticated>
  );
};
export default ProductsList;
