import { Stop, Waveform } from "@phosphor-icons/react";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { useHover } from "usehooks-ts";

export const VoiceProfilePreview = ({
  audioPath,
  size = 20,
  style,
}: {
  audioPath: string;
  size?: number;
  style?: React.CSSProperties;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const ref = useRef(null);
  const isHovered = useHover(ref);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("ended", () => setIsPlaying(false));
    }
    return () => {
      audioRef.current?.remove();
    };
  }, []);

  const handlePlayClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation(); // don't bubble the click to parent components
    // it might conflict with item selection in list (project wizard, etc)

    if (audioRef.current && audioRef.current.src) {
      setIsPlaying((prev) => !prev);
      if (!isPlaying) {
        audioRef.current.currentTime = 0; // restart
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    } else {
      console.error("Audio source is not set or invalid.");
    }
  };

  return (
    <>
      <div ref={ref} onClick={handlePlayClick} style={{ ...style }}>
        {isHovered || isPlaying ? (
          isPlaying ? (
            <Stop size={size} weight="fill" />
          ) : (
            <Waveform size={size} />
          )
        ) : (
          <Waveform size={size} style={{ opacity: 0.6 }} />
        )}
      </div>
      <audio ref={audioRef} src={audioPath} />
    </>
  );
};
