import React, { useContext, useState, useEffect } from "react";
import { List, TextField } from "@refinedev/antd";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Authenticated, useTranslate } from "@refinedev/core";
import { AppContext } from "appContext";
import { Organization } from "types";

export const MediaOrganizationsList: React.FC = () => {
  const t = useTranslate();
  const {
    state: { organizations },
  } = useContext(AppContext);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const handleTableChange = (page: number, pageSize: number) => {
    setPagination({
      current: page,
      pageSize: pageSize,
    });
  };

  useEffect(() => {
    console.log("Organizations data:", organizations);
  }, [organizations]);

  const columns: ColumnsType<Organization> = [
    {
      title: t("media.organizations.index.organizationName"),
      dataIndex: "name",
      key: "name",
      render: (value: string) => <TextField value={value} />,
    },
    {
      title: t("pages.user.edit.country"),
      dataIndex: "country",
      key: "country",
      render: (value: string) => <TextField value={value} />,
    },
  ];

  return (
    <Authenticated key="organization-list">
      <List
        resource="organization"
        title={t("media.organizations.list.organizationList")}
      >
        <Table<Organization>
          dataSource={organizations}
          columns={columns}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            showSizeChanger: true,
            total: organizations.length,
            onChange: handleTableChange,
          }}
        />
      </List>
    </Authenticated>
  );
};

export default MediaOrganizationsList;
