import React, { useMemo } from "react";
import {
  Card,
  Col,
  ColorPicker,
  Flex,
  Form,
  FormInstance,
  Row,
  ColorPickerProps,
  Button,
  Space,
} from "antd";
import {
  green,
  red,
  cyan,
  blue,
  purple,
  orange,
  magenta,
} from "@ant-design/colors";
import { PaletteResponse } from "pages/media/types";
import styled from "styled-components";
import { nanoid } from "nanoid";
import { useTranslate } from "@refinedev/core";
import { StopOutlined } from "@ant-design/icons";

const colorPickerProps: ColorPickerProps = {
  showText: true,
  format: "hex",
  size: "large",
  disabledAlpha: true,
};

export const ColorPickerCard = ({
  colorKey,
  allowClear,
  defaultValue,
  form,
  setColors,
  value,
  required,
}: {
  colorKey: string;
  allowClear?: boolean;
  defaultValue?: string;
  form: FormInstance;
  setColors: React.Dispatch<React.SetStateAction<PaletteResponse>>;
  value?: ColorPickerProps["value"];
  required?: boolean;
}) => {
  const t = useTranslate();
  const resetColor = (colorKey: string) => {
    form.setFieldValue(["colors", colorKey], null);
    setColors((prev) => ({
      ...prev,
      [colorKey]: undefined,
    }));
  };

  const genPresets = useMemo(
    () => [
      { label: nanoid(), colors: red },
      { label: nanoid(), colors: green },
      { label: nanoid(), colors: cyan },
      { label: nanoid(), colors: blue },
      { label: nanoid(), colors: purple },
      { label: nanoid(), colors: orange },
      { label: nanoid(), colors: magenta },
    ],
    []
  );

  const customPanelRender: ColorPickerProps["panelRender"] = (
    _,
    { components: { Picker, Presets } }
  ) => (
    <Row justify="space-between" wrap={false}>
      <Col flex="auto" xs={24} sm={11}>
        <Flex vertical gap={5}>
          {allowClear && (
            <Space>
              <Button
                shape="round"
                size="small"
                icon={<StopOutlined />}
                onClick={() => resetColor(colorKey)}
              >
                {t("components.ColorPicker.resetColor")}
              </Button>
            </Space>
          )}
          <Picker />
        </Flex>
      </Col>
      <StyledPickerCol flex="auto" xs={0} sm={11}>
        <Presets />
      </StyledPickerCol>
    </Row>
  );

  return (
    <Card
      style={{
        width: "100%",
      }}
      size="small"
    >
      <Flex gap={5}>
        <Form.Item
          initialValue={value}
          label={t(`media.brandkit.create.${colorKey}`)}
          name={["colors", colorKey]}
          rules={
            required
              ? [{ required: true, message: t("forms.requiredField") }]
              : []
          }
        >
          <ColorPicker
            {...colorPickerProps}
            defaultValue={defaultValue}
            size="large"
            presets={genPresets}
            styles={{
              popupOverlayInner: { minWidth: "calc(min(520px, 80vw))" },
            }}
            panelRender={customPanelRender}
            onChange={(color) => {
              form.setFieldValue(["colors", colorKey], color.toHexString());
              setColors((prev) => ({
                ...prev,
                [colorKey]: color.toHexString(),
              }));
            }}
            showText={(color) =>
              !color.cleared
                ? color.toHexString().toUpperCase()
                : t(`media.brandkit.detail.defaultColor`)
            }
          />
        </Form.Item>
      </Flex>
    </Card>
  );
};

const StyledPickerCol = styled(Col)`
  .ant-color-picker-presets {
    .ant-collapse-header {
      // force hide preset name
      display: none !important;
    }
    .ant-color-picker-presets-color {
      width: 18px !important;
      height: 18px !important;
      &:before {
        width: 22px !important;
        height: 22px !important;
      }
      &:after {
        // checkmark
        height: 10px !important;
      }
    }
  }
`;
