import { useCustom } from "@refinedev/core";
import { ReactNode } from "react";

type FeatureFlags = Record<string, boolean>;

export const useFeature = (flagName: string) => {
  const { data, isLoading, error, refetch } = useCustom<FeatureFlags>({
    url: `/api/system/features`,
    method: "get",
    queryOptions: {
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60, // 1 hour
    },
    errorNotification: false, // silent in ui
  });

  if (error) {
    console.error("Error fetching feature flags:", error);
    return {
      isLoading,
      isEnabled: false,
      refreshFlag: refetch,
    };
  }
  const value = data?.data?.[flagName];

  return {
    isLoading,
    isEnabled: value === true,
    refreshFlag: refetch,
  };
};

export const Feature = ({
  name,
  children,
}: {
  name: string;
  children: ReactNode;
}) => {
  const { isEnabled } = useFeature(name);
  return isEnabled ? <>{children}</> : null;
};
