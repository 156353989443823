import { useOne } from "@refinedev/core";
import { t } from "i18next";
import { MediaProjectResponse } from "pages/media/types";
import { useRef } from "react";

const MAX_GENERATION_REFETCH_TIMEOUT = 1800000; // 30 min

export const useProjectDetails = ({ projectId }: { projectId?: string }) => {
  const loadTime = useRef<number>(Date.now());

  const data = useOne<MediaProjectResponse>({
    resource: "media/projects",
    id: projectId,
    errorNotification: {
      type: "error",
      message: t("media.projects.detail.errorLoadingProject"),
      description: t("media.projects.detail.pleaseTryAgain"),
    },
    queryOptions: {
      enabled: !!projectId,
      refetchInterval: (data) => {
        const isProcessingDocument = data?.data.documents.some((document) =>
          ["Uploaded", "Stored", "Expected", "ExtractingContent"].includes(
            document.status
          )
        );

        const isProcessingStoryboard =
          data?.data.storyboards.some((storyboard) =>
            ["Generating", "Pending"].includes(storyboard.status)
          ) && data?.data.documents[0].status === "ContentExtracted";

        const isProcessingMedia = data?.data.media.some((media) => {
          const isProcessing = [
            "Pending",
            "Draft",
            "Requested",
            "Generating",
          ].includes(media.status);
          const storyboardReady = data?.data.storyboards.some(
            (sb) => sb.language === media.language && sb.status === "Approved"
          );
          return isProcessing && storyboardReady;
        });

        const getProcessingTime = () => {
          if (isProcessingDocument || isProcessingStoryboard) return 5000; // 5 sec
          if (isProcessingMedia) return 10000; // 10sec
          return false;
        };

        // Stop refetching after 30 minutes or when we don't have any
        // generating/pending storyboards/media.
        if (loadTime) {
          if (Date.now() - loadTime.current > MAX_GENERATION_REFETCH_TIMEOUT) {
            return false;
          }
        }
        return getProcessingTime();
      },
    },
  });

  return data;
};
