import { components as mediaComponents } from "media";
import { Maybe } from "types";
export type RangeValue<T> = any;
export type Prompt = {
  id: string;
  prompt: string;
};
export type Completion = {
  id: string;
  promptId: string;
  completion: string;
};
export type Message = Prompt | Completion;

export type MediaAsset = mediaComponents["schemas"]["MediaAsset"];
export type MediaAssetType = mediaComponents["schemas"]["MediaAssetType"];
export type StoryboardAsset = MediaAsset & { asset_id: string };

export type MediaStep = mediaComponents["schemas"]["MediaStepResponse"];
export type MediaStepsResponse =
  mediaComponents["schemas"]["MediaStepsResponse"];
export type MediaStepNode = MediaStep & {
  children?: MediaStepNode[];
  level?: number;
};
export type MediaControl = mediaComponents["schemas"]["MediaControl"];
export type MediaAssetCategory =
  mediaComponents["schemas"]["MediaAssetCategory"];
export type MediaDescriptor = mediaComponents["schemas"]["MediaDescriptor"];
export type MediaProject = mediaComponents["schemas"]["MediaProjectResponse"];
export type MediaProjectResponse =
  mediaComponents["schemas"]["MediaProjectResponse"] & {
    tags?: ProjectTag[];
  };
export type ProjectMediaResponse =
  mediaComponents["schemas"]["ProjectMediaResponse"];
export type MediaDocument = mediaComponents["schemas"]["DocumentResponse"];
export type Document = mediaComponents["schemas"]["Document"];
export type Media = mediaComponents["schemas"]["Media"];
export type MediaProjectPublicationRequest =
  mediaComponents["schemas"]["MediaProjectPublicationRequest"];
export type UpdatePublicationRequest =
  mediaComponents["schemas"]["UpdatePublicationRequest"];
export type MediaProjectPublicationResponse =
  mediaComponents["schemas"]["MediaProjectPublicationResponse"];
export type MediaProjectPublicationBase =
  mediaComponents["schemas"]["MediaProjectPublicationBase"];
export type ProjectSettingsRequest =
  mediaComponents["schemas"]["ProjectSettingsRequest"];

export type AssetUploadResponse =
  mediaComponents["schemas"]["AssetUploadResponse"];

// TODO: Run codegen again?
export type VoiceProfileResponse =
  mediaComponents["schemas"]["VoiceProfileResponse"] & {
    assets?: Record<string, string>;
  };

export type VoiceProfileDetail =
  mediaComponents["schemas"]["VoiceProfileDetail"];
export type VoiceProfileCreationRequest =
  mediaComponents["schemas"]["VoiceProfileCreationRequest"];
export type VoiceProfileCreationResponse =
  mediaComponents["schemas"]["VoiceProfileCreationResponse"];
export type FaceProfileResponse =
  mediaComponents["schemas"]["FaceProfileResponse"];
export type FaceProfileCreationResponse =
  mediaComponents["schemas"]["FaceProfileCreationResponse"];
export type FaceProfileCreationRequest =
  mediaComponents["schemas"]["FaceProfileCreationRequest"];
export type PresenterProfileResponse =
  mediaComponents["schemas"]["PresenterProfileResponse"];
export type ContactResponse = mediaComponents["schemas"]["ContactResponse"];

export type ToneResponse = mediaComponents["schemas"]["ToneResponse"];

export type StoryboardStatus = mediaComponents["schemas"]["StoryboardStatus"];
export type ProjectSettingsStatus =
  mediaComponents["schemas"]["ProjectSettingsStatus"];
export type PronunciationResponse =
  mediaComponents["schemas"]["PronunciationResponse"];
export type SavePronunciationRequest =
  mediaComponents["schemas"]["SavePronunciationRequest"];

export type ChatMode = mediaComponents["schemas"]["ChatMode"];
export type MediaGenerationMode =
  mediaComponents["schemas"]["MediaGenerationMode"];

// cf https://github.com/LibertyFi/Libertyfi.api/blob/main/py/libs/models/libertify_models/media/storyboard_data_structure.py#L32
export type TKeyInsight = {
  main_idea: string;
  description: Maybe<string>;
  id: string;
};

export type TScript = {
  content: Maybe<any>;
  selected: Maybe<boolean>;
  revisions?: { content: string; prompt: string }[];
};

export type TDetailedScript = TScript;

export type TDetailedSection = {
  topic: string;
  key_insights: TKeyInsight[];
  detailed_summary: Maybe<string>;
  script: Maybe<TDetailedScript>;
  scripts?: Maybe<TScript>[];
  sources: DocumentExtract[];
  speech?: {
    value: string;
    loading: boolean;
  };
  id: string;
};

export type TDetailedSectionData = Omit<TDetailedSection, "id"> & {
  section_id: string;
};

export type TDetailedStoryboard = {
  title: string;
  sections: TDetailedSection[];
  // final_script: Maybe<string>;
};

export type TSection = {
  topic: string;
  script?: Maybe<TDetailedScript>;
  id: string;
};

export type TSectionOutline = {
  section_id: string; // FIXME Maybe<string>;
  topic: string;
  key_insights: TKeyInsight[];
  detailed_summary: Maybe<string>;
  script: Maybe<TDetailedScript>;
};

export type TStoryboard = {
  title: string;
  sections: TSection[];
};

export type SectionUpdate = {
  key_insights: TKeyInsight[];
  script?: string;
};

type DisplaySimilarity = {
  score: number;
  precision: number;
  recall: number;
  semantic_similarity: number;
};

type ReadabilityMetrics = {
  score: Maybe<number>;
  ease: string;
  grade_level: string;
};

type DisplayPolarity = {
  score: number;
  negative: number;
  neutral: number;
  positive: number;
};

export type DisplayMetrics = {
  readability: ReadabilityMetrics;
  polarity: DisplayPolarity;
  similarity: DisplaySimilarity;
};

//todo
// export type TDraftDetailedStoryboard = WithId<TDetailedStoryboard> & {
//   sections: WithId<TDetailedSection>[];
// };

type WithId<T, P = string> = T & { id: P };

// type Locale = "fr-FR" | "en-US";
// export type CallToAction = {
//   type:
//     | "DocumentDownload"
//     | "CalendarAppointment"
//     | "ExternalLink"
//     | "Email"
//     | "Phone";
//   labels: Record<Locale, string>;
//   url?: string;
//   color?: string;
// };
export type CallToAction = mediaComponents["schemas"]["CallToAction"];
export type CallToActions = {
  actions: CallToAction[];
};

export type CreateAssetRequest =
  mediaComponents["schemas"]["CreateAssetRequest"];
export type CreateDocumentAssetRequest =
  mediaComponents["schemas"]["CreateDocumentAssetRequest"];
export type CreateMediaAssetRequest =
  mediaComponents["schemas"]["CreateMediaAssetRequest"];
export type CreateProjectMediaAssetRequest =
  mediaComponents["schemas"]["CreateProjectMediaAssetRequest"];
export type ProjectMedia = mediaComponents["schemas"]["ProjectMedia"];

export type BrandKit = mediaComponents["schemas"]["BrandKitResponse"];

export type Storyboard =
  mediaComponents["schemas"]["ProjectStoryboardResponse"];

export type BrandKitAsset = mediaComponents["schemas"]["BrandKitAsset"];
export type CreateBrandKitAssetRequest =
  mediaComponents["schemas"]["CreateBrandKitAssetRequest"];
export type CreateBrandKitRequest =
  mediaComponents["schemas"]["CreateBrandKitRequest"];
export type CreateToneRequest = mediaComponents["schemas"]["CreateToneRequest"];
export type UploadDocumentRequest =
  mediaComponents["schemas"]["UploadDocumentRequest"];
export type CreateVoiceAssetRequest =
  mediaComponents["schemas"]["CreateVoiceAssetRequest"];
export type CreateUploadableAssetRequest =
  | CreateBrandKitAssetRequest
  | CreateVoiceAssetRequest
  | CreateMediaAssetRequest
  | CreateDocumentAssetRequest
  | UploadDocumentRequest
  | CreateProjectMediaAssetRequest
  | AddAssetToAssetLibraryRequest;
export type BrandKitAssetResponse =
  mediaComponents["schemas"]["BrandKitResponse"]["assets"][number];
export enum AssetSource {
  UIGenerated = "UIGenerated",
}
export type PresenterProfileCreationRequest =
  mediaComponents["schemas"]["PresenterProfileCreationRequest"];
export type ContactCreationRequest =
  mediaComponents["schemas"]["ContactCreationRequest"];
export type DocumentExtract = {
  document_id: string;
  text: string;
  occurrences: DocumentChunk[];
};

export type DocumentChunk = {
  chunk: string;
  page_number: number;
  coordinates: [number, number, number, number];
};

export type OnboardingAnswer = mediaComponents["schemas"]["OnboardingAnswer"];
export type CreateOnboardingAnswerRequest =
  mediaComponents["schemas"]["CreateOnboardingAnswerRequest"];
export type CreateOrganizationRequest =
  mediaComponents["schemas"]["OrganizationCreationRequest"];
export type UpdateOrganizationRequest =
  mediaComponents["schemas"]["UpdateOrganizationRequest"];
export type Transitions = mediaComponents["schemas"]["Transitions"];
export type PaletteResponse = mediaComponents["schemas"]["PaletteResponse"];
export type ProjectTag = {
  tag_key: string;
  tag_value: string;
};

export type DocumentType = mediaComponents["schemas"]["DocumentType"];
export type AssetLibrary = mediaComponents["schemas"]["AssetLibraryResponse"];
export type CreateAssetResponse =
  mediaComponents["schemas"]["AssetUploadResponse"];
export type AddAssetToAssetLibraryRequest =
  mediaComponents["schemas"]["AddAssetToAssetLibraryRequest"];
