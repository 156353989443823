import { useTranslate } from "@refinedev/core";
import React, { createRef, useState } from "react";
import {
  Dropdown,
  Flex,
  List,
  MenuProps,
  Modal,
  Popconfirm,
  Tooltip,
  Typography,
  message,
  Tag,
} from "antd";
import {
  BuildOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Document } from "../../types";
import {
  useCan,
  useCustomMutation,
  useDelete,
  useInvalidate,
} from "@refinedev/core";
import { DateField } from "@refinedev/antd";
import DocumentStatus from "../../components/DocumentStatus";
import FileIcon from "components/FileIcon";
import { DotsThreeVertical } from "@phosphor-icons/react";
import { MiniLabel } from "components/MiniLabel";
import { useAntTheme } from "hooks/useAntTheme";
import { DocumentViewer } from "components/DocumentViewer";
import { addDateTz } from "pages/media/utils";
import PdfView from "components/PdfView";
import { sortedDocumentsPrimaryFirst } from "../helpers/helper";

interface DocumentListItemProps {
  document: Document;
  primaryDocumentId?: string;
  projectId: string;
}

const DocumentListItem: React.FC<DocumentListItemProps> = ({
  document,
  primaryDocumentId,
  projectId,
}) => {
  const t = useTranslate();
  const { mutateAsync: mutateReindex, isLoading: reindexIsLoading } =
    useCustomMutation();
  const isPdf = document.name.toLocaleLowerCase().endsWith(".pdf");
  const [isModalPreviewVisible, setIsModalPreviewVisible] = useState(false);
  const { mutateAsync: mutateDelete, isLoading: deleteIsLoading } = useDelete();
  const isPrimary = document.id === primaryDocumentId;
  const invalidate = useInvalidate();
  const linkRef = createRef<HTMLAnchorElement>();
  const { theme } = useAntTheme();
  const handleReindex = async () => {
    await mutateReindex({
      url: `media/documents/${document.organization}/${document.id}/reindexing`,
      method: "post",
      values: {},
    });
  };
  const handleDelete = async () => {
    await mutateDelete({
      resource: `media/projects/${projectId}/documents`,
      id: document.id,
    });
    await invalidate({
      resource: "media/projects",
      id: projectId,
      invalidates: ["detail"],
    });
  };

  const handleCopyId = () => {
    navigator.clipboard
      .writeText(document.id)
      .then(() => {
        message.success(
          `${t("projects.components.ProjectDocumentsList.idCopied")}: ${document.id}`
        );
      })
      .catch((error) => {
        message.error(
          t("projects.components.ProjectDocumentsList.idCopyFailed")
        );
        console.error("Copy to clipboard failed:", error);
      });
  };

  const handleDownload = () => {
    return linkRef.current?.click();
  };

  const handleDocumentPreview = () => {
    setIsModalPreviewVisible(true);
  };

  const handleModalClose = () => {
    setIsModalPreviewVisible(false);
  };

  const handleScrolltoStoryboards = () => {
    window.document.getElementById("storyboards")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const { data: canReindex } = useCan({
    resource: "media_documents",
    action: "document_index",
  });

  const { data: canDelete } = useCan({
    resource: "media_documents",
    action: "delete",
  });

  const menuItems: MenuProps["items"] = [
    // {
    //   key: "view",
    //   icon: <FileOutlined />,
    //   label: "View",
    //   onClick: handleDocumentPreview,
    // },
    ...(canReindex?.can
      ? [
          {
            key: "reindex",
            icon: reindexIsLoading ? (
              <LoadingOutlined spin />
            ) : (
              <BuildOutlined />
            ),
            label: t("projects.components.ProjectDocumentsList.reindex"),
            disabled: reindexIsLoading,
            onClick: handleReindex,
          },
        ]
      : []),
    {
      key: "download",
      icon: <DownloadOutlined />,
      label: t("projects.components.ProjectDocumentsList.download"),
      onClick: handleDownload,
    },
    {
      key: "copy-id",
      icon: <CopyOutlined />,
      label: (
        <>
          {t("projects.components.ProjectDocumentsList.copyId")}
          {/* <UUID id={document.id} copyable={false} /> */}
        </>
      ),
      onClick: handleCopyId,
    },
    ...(canDelete?.can && !isPrimary
      ? [
          {
            key: "delete",
            icon: deleteIsLoading ? (
              <LoadingOutlined spin />
            ) : (
              <DeleteOutlined />
            ),
            label: (
              <Popconfirm
                title={t("projects.components.ProjectDocumentsList.areYouSure")}
                onConfirm={handleDelete}
              >
                {t("projects.components.ProjectDocumentsList.delete")}
              </Popconfirm>
            ),
            danger: true,
          },
        ]
      : []),
  ];

  const menuProps = {
    items: menuItems,
  };

  return (
    <>
      <List.Item
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          flexWrap: "wrap",
          gap: 10,
          alignItems: "start",
        }}
        actions={[
          <Flex key="menu" style={{ marginRight: -12 }}>
            <DocumentStatus status={document.status} />
            {/* <Dropdown.Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleDocumentPreview();
                  }}
                  menu={menuProps}
                  icon={<DotsThreeVertical size={20} />}
                /> */}
            <Dropdown menu={menuProps} trigger={["click"]}>
              <DotsThreeVertical size={32} style={{ cursor: "pointer" }} />
            </Dropdown>
          </Flex>,
        ]}
      >
        <List.Item.Meta
          avatar={<FileIcon url={document.name} size={30} />}
          title={
            <Typography.Text
              style={{
                cursor: "pointer",
                // lineHeight: 1.1,
                fontWeight: "normal",
              }}
              onClick={handleDocumentPreview}
            >
              {document.name}
            </Typography.Text>
          }
          description={
            <Flex vertical align="start" style={{ marginTop: -5 }}>
              <DateField
                type="secondary"
                value={addDateTz(document.created)}
                format="LLL"
              />
              {isPrimary && (
                <Tooltip
                  title={t(
                    "projects.components.ProjectDocumentsList.thisPrimaryDocument"
                  )}
                >
                  <Tag
                    style={{
                      flexWrap: "nowrap",
                      marginTop: 4,
                    }}
                    color="default"
                  >
                    <MiniLabel>
                      {t(
                        "projects.components.ProjectDocumentsList.storyboardSource"
                      )}
                    </MiniLabel>
                  </Tag>
                </Tooltip>
              )}
            </Flex>
          }
        />
      </List.Item>

      <Modal
        title={t("projects.components.ProjectDocumentsList.documentPreview")}
        open={isModalPreviewVisible}
        onCancel={handleModalClose}
        footer={null}
        width={"80vw"}
        bodyStyle={{ height: "70vh", position: "relative" }}
        destroyOnClose
      >
        {isModalPreviewVisible && isPdf && (
          <PdfView
            paddingX={theme.paddingSM * 4}
            width={"80vw"}
            url={document.path}
          />
        )}
        {isModalPreviewVisible && !isPdf && (
          <DocumentViewer url={document.path} />
        )}
      </Modal>

      <a
        ref={linkRef}
        rel="noreferrer"
        target="_blank"
        style={{ display: "none" }}
        download
        href={document.path}
      >
        {t("projects.components.ProjectDocumentsList.download")}
      </a>
    </>
  );
};
interface ProjectDocumentsListProps {
  documents: Document[];
  primaryDocumentId?: string;
  projectId: string;
}

const ProjectDocumentsList: React.FC<ProjectDocumentsListProps> = ({
  documents,
  primaryDocumentId,
  projectId,
}) => {
  const sortedDocuments = sortedDocumentsPrimaryFirst(
    primaryDocumentId,
    documents
  );

  return (
    <List
      itemLayout="horizontal"
      dataSource={sortedDocuments}
      size="small"
      renderItem={(document, index) => (
        <DocumentListItem
          key={document.id}
          document={document}
          primaryDocumentId={primaryDocumentId}
          projectId={projectId}
        />
      )}
    />
  );
};

export default ProjectDocumentsList;
