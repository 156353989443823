import { List } from "antd";
import { ReactNode, MouseEventHandler } from "react";
import { StyledListWrapper } from "./styles";

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
type Option = {
  label: string;
  icon: ReactNode;
  onClick: MouseEventHandler<HTMLElement>;
};

export const GridOptionList = ({ options }: { options: Option[] }) => {
  return (
    <StyledListWrapper>
      <List<Option>
        dataSource={options}
        // grid={{ gutter: 16, column: 2 }}
        renderItem={(item) => {
          return (
            <List.Item
              onClick={item.onClick}
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
                borderRadius: 6,
              }}
            >
              {item.icon}
              <List.Item.Meta title={item.label} />
            </List.Item>
          );
        }}
      />
    </StyledListWrapper>
  );
};
