import React, { useState, useEffect } from "react";
import { Card, CardProps, Tooltip, Typography } from "antd";

interface ImagePreviewCardProps extends CardProps {
  image: string | File;
  fileName: string;
  cardWidth?: number | string;
  coverHeight?: number | string;
  onCardClick?: () => void;
}

export const PicturePreview: React.FC<ImagePreviewCardProps> = ({
  image,
  fileName,
  style,
  actions,
  cardWidth = 200,
  coverHeight = 200,
  onCardClick,
}) => {
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    if (image instanceof File) {
      const url = URL.createObjectURL(image);
      setImageUrl(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setImageUrl(image);
    }
  }, [image]);

  return (
    <Card
      style={{ ...style, width: cardWidth }}
      // hoverable
      cover={
        <img
          src={imageUrl}
          alt="Preview"
          style={{
            height: coverHeight,
            width: cardWidth,
          }}
        />
      }
      styles={{
        cover: {
          height: coverHeight,
          overflowY: "hidden",
          display: "flex",
        },
        actions: { padding: 10 },
      }}
      size="small"
      actions={actions}
      onClick={() => {
        onCardClick?.();
      }}
    >
      <Card.Meta
        description={
          <Tooltip title={fileName} arrow={false} placement="bottom">
            <Typography.Text
              style={{ width: "100%" }}
              ellipsis
              type="secondary"
            >
              {fileName}
            </Typography.Text>
          </Tooltip>
        }
      />
    </Card>
  );
};
