import { useParams } from "react-router-dom";
import {
  useOne,
  useTranslate,
  useGo,
  useNavigation,
  Authenticated,
} from "@refinedev/core";
import { Card, Flex, Result, Typography } from "antd";
import { ProjectSettingForm } from "./ProjectSettingForm";
import { MediaProjectResponse } from "pages/media/types";
import { Target, UsersThree, Swatches } from "@phosphor-icons/react";
import { ProjectSubPageHeader } from "./ProjectSubPageHeader";

const SETTINGS = {
  brandkit: {
    type: "BrandKit",
    icon: <Swatches size={32} weight="duotone" />,
    getTitle: (t) => t("projects.components.ProjectCreateWizard.BrandKit"),
    getSubtitle: (t) =>
      t("projects.components.ProjectSettings.chooseYourBrand"),
  },
  contact: {
    type: "Contacts",
    icon: <UsersThree size={32} weight="duotone" />,
    getTitle: (t) => t("projects.components.ProjectSettings.contacts"),
    getSubtitle: (t, isDraft) => (
      <>
        {t("projects.components.ProjectSettings.chooseTheContacts")}
        {isDraft && (
          <>
            <br />
            {t("projects.components.ProjectSettings.youCanAddMultiple")}
          </>
        )}
      </>
    ),
  },
  ctas: {
    type: "CallToActions",
    icon: <Target size={32} weight="duotone" />,
    getTitle: (t) => t("projects.components.ProjectSettings.callToActions"),
    getSubtitle: (t, _, go) => (
      <>
        {t("projects.components.ProjectSettings.pickAnExisting")}{" "}
        <Typography.Text
          style={{ cursor: "pointer" }}
          underline
          type="secondary"
          onClick={() =>
            go({ to: { resource: "media_ctas", action: "create" } })
          }
        >
          {t("projects.components.ProjectSettings.createANew")}
        </Typography.Text>
      </>
    ),
  },
} as const;

export const ProjectSettingsPage = () => {
  const { id, setting } = useParams();
  const { show } = useNavigation();
  const t = useTranslate();
  const go = useGo();

  const { data: projectData } = useOne<MediaProjectResponse>({
    resource: "media/projects",
    id: id,
    queryOptions: { enabled: !!id },
  });

  const config = setting ? SETTINGS[setting as keyof typeof SETTINGS] : null;

  if (!config) {
    return <Result status={404} title="Invalid settings" />;
  }

  const isDraft = projectData?.data?.settings?.status === "Draft";
  let value;
  if (config.type === "Contacts") {
    value = projectData?.data?.settings.contact_id;
  } else if (config.type === "BrandKit") {
    value = projectData?.data?.settings.brand_kit_id;
  }

  return (
    <Authenticated key="project_settings">
      {projectData && (
        <ProjectSubPageHeader
          projectId={projectData.data.id}
          projectTitle={projectData.data.title}
          page={config.type}
        />
      )}
      <Flex
        vertical
        justify="center"
        align="center"
        style={{
          padding: "2rem",
        }}
      >
        <Card style={{ width: "100%", maxWidth: 800 }}>
          <ProjectSettingForm
            type={config.type}
            icon={config.icon}
            title={config.getTitle(t)}
            subtitle={config.getSubtitle(t, isDraft, go)}
            projectId={id}
            value={value}
            onClose={() => show("media_projects", id)}
            cancellable={true}
          />
        </Card>
      </Flex>
    </Authenticated>
  );
};
