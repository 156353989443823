import { Buildings } from "@phosphor-icons/react";
import { GlobalToken, Select, SelectProps } from "antd";
import { AppContext } from "appContext";
import { useAntTheme } from "hooks/useAntTheme";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

export const OrganizationSwitch = () => {
  const {
    state: { organizations, organization },
    setOrganization,
  } = useContext(AppContext);
  const { theme } = useAntTheme();
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setTimeout(() => setReady(true), 1000); // hack to avoid picker value flickering on page load
  }, []);

  if (organization?.name === null) return null;
  return (
    <StyledSelect
      data-testid="OrganizationSwitch"
      showSearch
      optionFilterProp="label"
      loading={!organizations?.length && !ready}
      theme={theme}
      size="large"
      disabled={organizations?.length < 2}
      onChange={(value) => setOrganization(value)}
      value={ready && organization?.id}
      options={organizations?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        };
      })}
      style={{ width: "100%" }}
      virtual={false} // disable for automated pass to find test org
      suffixIcon={<Buildings size={20} weight="bold" />}
    />
  );
};

const StyledSelect = styled(Select)<SelectProps & { theme: GlobalToken }>`
  &:not(.ant-select-focused) {
    .ant-select-selector {
      color: ${({ theme }) => theme.colorPrimary} !important;
    }
  }
  .ant-select-selector {
    border-width: 2px !important;
    box-shadow: 0 !important;
  }
`;
