import {
  Button,
  Col,
  ColorPicker,
  Flex,
  Form,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Tooltip,
  Typography,
} from "antd";
import { useAntTheme } from "hooks/useAntTheme";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Cuepoint } from "../binder/types";
import { t } from "i18next";
import { useCuepointsStore } from "./useCuepointsStore";
import { useHighlightsStore } from "./useHighlightsStore";
import { IHighlight } from "react-pdf-highlighter";
import { ArrowUUpLeft } from "@phosphor-icons/react/dist/ssr";
import { CaretUp, DotsThreeVertical } from "@phosphor-icons/react";
import Draggable from "react-draggable";
import styled from "styled-components";
import { PaletteResponse } from "pages/media/types";
import { STORAGE_SETTINGS_KEY } from "./constants";

const DEFAULT_COLOR = "#1677FF"; // blue

export const InspectorToolbar = ({
  handleNewCuepoint,

  brandKitPalette,
  // onClickSave,
}: {
  handleNewCuepoint: (update?: Partial<Cuepoint>) => void;
  brandKitPalette?: PaletteResponse;
  // onClickSave: () => Promise<void>;
}) => {
  const { theme } = useAntTheme();
  const { setCurrentHighlight, deleteHighlight } = useHighlightsStore();

  const { currentHighlight, setIsEditing, setHighlights, highlights } =
    useHighlightsStore((store) => ({
      currentHighlight: store.currentHighlight,
      setIsEditing: store.setIsEditing,
      setHighlights: store.setHighlights,
      highlights: store.highlights,
    }));

  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [latestColor, setLatestColor] = useState<string>(
    brandKitPalette?.highlight_color ?? DEFAULT_COLOR
  );

  const paletteColors = Object.values(brandKitPalette || "{}").filter(
    (color): color is string => color !== null && color !== ""
  );

  const {
    cuepoints,
    updateCount,
    currentCuepoint,
    setUpdateCount,
    updateCuepoint,
    setCurrentCuepoint,
    deleteCuepoint,
  } = useCuepointsStore();

  const [updates, setUpdates] = useState(0);
  // const [saving, setSaving] = useState(false);

  const [form] = Form.useForm();
  const { Option } = Select;

  //save current settings anywhere in case user want to revert.
  useEffect(() => {
    if (!currentCuepoint) return;

    localStorage.setItem(
      STORAGE_SETTINGS_KEY,
      JSON.stringify(currentCuepoint.roughNotation)
    );
  }, []);

  useEffect(() => {
    // when new highlight added, create new cuepoint
    if (!currentCuepoint) {
      handleNewCuepoint();
      if (currentHighlight) {
        setCurrentCuepoint(currentHighlight.id);
      }
    } else {
      form.setFieldsValue(currentCuepoint.roughNotation);
    }
  }, [currentCuepoint, currentHighlight]);

  // when different highlight is selected without closing the toolbox, number of updates should be 0
  useEffect(() => {
    setUpdates(0);
  }, [currentHighlight]);

  const handleChange = (update: Partial<Cuepoint>, revert?: boolean) => {
    console.debug("updated properties", update);
    setUpdates((prev) => prev + 1);
    setHighlights([...highlights]);
    setIsEditing(true);
    setTimeout(() => {
      // hack to stop editing mode after scroll has been taken into account
      setIsEditing(false);
    }, 500);

    if (update.roughNotation) {
      if (update.roughNotation.color) {
        setLatestColor(update.roughNotation.color);
      }

      // if there is no cuepoint, create one with updated styles
      if (!currentCuepoint) {
        handleNewCuepoint(update);
        if (currentHighlight) {
          setCurrentCuepoint(currentHighlight.id);
        }
      } else {
        updateCuepoint(update.roughNotation, revert);
        setCurrentCuepoint(currentCuepoint.highlightId);
      }
    }
  };

  const removeCuepointsFromHighlight = (highlightId?: string) => {
    cuepoints.forEach((cue: Cuepoint) => {
      if (cue.highlightId === highlightId) {
        deleteCuepoint(highlightId, cue.start);
        setCurrentCuepoint(undefined);
      }
    });
  };

  const handleRemoveHighlight = (highlight?: IHighlight) => {
    removeCuepointsFromHighlight(highlight?.id);
    deleteHighlight(highlight?.id);
    setCurrentHighlight(undefined);
  };

  const handleClose = () => {
    setCurrentHighlight(undefined);
  };

  const handleRevert = () => {
    if (!updates) return;
    const updatesForSaving = updateCount - updates;
    setUpdateCount(updatesForSaving);

    const previousSettings = JSON.parse(
      localStorage.getItem(STORAGE_SETTINGS_KEY) ?? "{}"
    );

    handleChange(
      {
        roughNotation: previousSettings,
      },
      true
    );

    form.setFieldsValue(previousSettings);
    setUpdates(0);
  };

  const handleOnClickSave = async () => {
    // setSaving(true);
    // await onClickSave();
    // setSaving(false);
  };

  const ExpandIcon = showAdvancedSettings ? CaretUp : DotsThreeVertical;

  return (
    <Draggable
      handle=".handle"
      bounds="parent"
      defaultPosition={{ x: -300, y: 0 }}
      scale={1}
    >
      <Flex
        className="handle"
        gap={20}
        style={{
          width: "fit-content",
          position: "absolute",
          left: "50%",
          transform: "translate(-50%)",
          bottom: 40,
          zIndex: 5,
        }}
      >
        <Flex
          vertical={false}
          style={{
            backgroundColor: theme.colorBgBase,
            boxShadow: theme.boxShadowSecondary,
            border: `1px solid ${theme.colorBorder}`,
            borderRadius: 10,
            padding: 6,
            paddingBottom: 4,
            paddingTop: 0,
            minWidth: 600,
          }}
          align="center"
          justify="space-between"
        >
          <Form
            initialValues={currentCuepoint?.roughNotation}
            form={form}
            colon={false}
          >
            <Flex vertical justify="center">
              <Row gutter={16} align="middle">
                <Col span={6}>
                  <Label type="secondary">
                    {t(`components.binder.BinderDrawerConfig.type`)}
                  </Label>
                  <Form.Item
                    name="type"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: t(
                    //       `components.binder.BinderDrawerConfig.pleaseChooseThe`
                    //     ),
                    //   },
                    // ]}
                    style={{ margin: 0 }}
                  >
                    <Select
                      onChange={(value) =>
                        handleChange({ roughNotation: { type: value } })
                      }
                    >
                      {[
                        "highlight",
                        "box",
                        "bracket",
                        "circle",
                        "underline",
                        "strike-through",
                        "crossed-off",
                      ].map((type) => (
                        <Option value={type} key={type}>
                          {type === "strike-through"
                            ? t(
                                `components.binder.BinderDrawerConfig.strikeThrough`
                              )
                            : type === "crossed-off"
                              ? t(
                                  `components.binder.BinderDrawerConfig.crossedOff`
                                )
                              : t(
                                  `components.binder.BinderDrawerConfig.${type}`
                                )}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Label type="secondary">
                    {t(`components.binder.BinderDrawerConfig.strokeWidth`)}
                  </Label>
                  <Form.Item
                    name="strokeWidth"
                    // rules={[
                    //   {
                    //     required: false,
                    //     message: t(
                    //       `components.binder.BinderDrawerConfig.pleaseChooseA`
                    //     ),
                    //   },
                    // ]}
                    style={{ margin: 0 }}
                  >
                    <InputNumber
                      min={1}
                      max={100}
                      defaultValue={4}
                      onChange={(value) => {
                        handleChange({
                          roughNotation: {
                            type:
                              currentCuepoint?.roughNotation?.type ||
                              "highlight",
                            strokeWidth: value as number,
                          },
                        });
                      }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Label type="secondary">
                    {t(`components.binder.BinderDrawerConfig.padding`)}
                  </Label>
                  <Form.Item name="padding" style={{ margin: 0 }}>
                    <InputNumber
                      placeholder={t(
                        "components.binder.BinderDrawerConfig.pleaseEnterAPadding"
                      )}
                      min={0}
                      max={100}
                      onChange={(value) =>
                        handleChange({
                          roughNotation: {
                            type:
                              currentCuepoint?.roughNotation?.type ||
                              "underline",
                            padding: value as number,
                          },
                        })
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Label type="secondary">
                    {t(`components.binder.BinderDrawerConfig.brackets`)}
                  </Label>
                  <Form.Item
                    name="brackets"
                    // rules={[
                    //   {
                    //     required: false,
                    //     message: t(
                    //       `components.binder.BinderDrawerConfig.pleaseSelectWhich`
                    //     ),
                    //     type: "array",
                    //   },
                    // ]}
                    style={{ margin: 0 }}
                  >
                    <Select
                      // placeholder={t(
                      //   `components.binder.BinderDrawerConfig.brackets`
                      // )}
                      mode="multiple"
                      showSearch={false}
                      placement="topLeft"
                      maxTagCount="responsive"
                      onChange={(value) => {
                        handleChange({
                          roughNotation: {
                            type:
                              currentCuepoint?.roughNotation?.type ||
                              "underline",
                            brackets: value,
                          },
                        });
                      }}
                      style={{ height: 32 }}
                    >
                      {["left", "right", "top", "bottom"].map((side) => {
                        return (
                          <Option value={side} key={side}>
                            {t(`components.binder.BinderDrawerConfig.${side}`)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Label type="secondary">
                    {t(`components.binder.BinderDrawerConfig.color`)}
                  </Label>
                  <Form.Item name="color" style={{ margin: 0 }}>
                    <ColorPicker
                      showText
                      defaultValue={
                        currentCuepoint?.roughNotation?.color || latestColor
                      }
                      presets={[
                        {
                          label: "Palette",
                          colors: paletteColors,
                          defaultOpen: true,
                        },
                      ]}
                      onChange={(value) => {
                        handleChange({
                          roughNotation: {
                            type:
                              currentCuepoint?.roughNotation?.type ||
                              "underline",
                            color: value.toHexString(),
                          },
                        });
                      }}
                      style={{ marginTop: 2 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {showAdvancedSettings && (
                <Row gutter={16} align="middle">
                  <Col span={6}>
                    <Label type="secondary">
                      {t(`components.binder.BinderDrawerConfig.animation`)}
                    </Label>
                    <Form.Item name="animate">
                      <Select
                        placeholder={t(
                          "components.binder.BinderDrawerConfig.pleaseSelect"
                        )}
                        onChange={(value) =>
                          handleChange({
                            roughNotation: {
                              type:
                                currentCuepoint?.roughNotation?.type ||
                                "underline",
                              animate: value,
                            },
                          })
                        }
                      >
                        <Option value={true}>
                          {t("components.binder.BinderDrawerConfig.animated")}
                        </Option>
                        <Option value={false}>
                          {t(
                            "components.binder.BinderDrawerConfig.notAnimated"
                          )}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Label type="secondary">
                      {t(
                        `components.binder.BinderDrawerConfig.animationDuration`
                      )}
                    </Label>
                    <Form.Item name="animationDuration">
                      <InputNumber
                        min={0}
                        max={3000}
                        placeholder={t(
                          "components.binder.BinderDrawerConfig.pleaseEnterADuration"
                        )}
                        step={100}
                        onChange={(value) =>
                          handleChange({
                            roughNotation: {
                              type:
                                currentCuepoint?.roughNotation?.type ||
                                "underline",
                              animationDuration: value as number,
                            },
                          })
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Label type="secondary">
                      {t(`components.binder.BinderDrawerConfig.iterations`)}
                    </Label>
                    <Form.Item name="iterations">
                      <InputNumber
                        placeholder={t(
                          "components.binder.BinderDrawerConfig.pleaseEnterAn"
                        )}
                        onChange={(value) =>
                          handleChange({
                            roughNotation: {
                              type:
                                currentCuepoint?.roughNotation?.type ||
                                "underline",
                              iterations: value as number,
                            },
                          })
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Label type="secondary">
                      {t(`components.binder.BinderDrawerConfig.delay`)}
                    </Label>
                    <Form.Item name="animationDelay">
                      <InputNumber
                        placeholder={t(
                          "components.binder.BinderDrawerConfig.pleaseEnterADelay"
                        )}
                        step={100}
                        onChange={(value) =>
                          handleChange({
                            roughNotation: {
                              type:
                                currentCuepoint?.roughNotation?.type ||
                                "underline",
                              animationDelay: value as number,
                            },
                          })
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Label type="secondary">
                      {t(`components.binder.BinderDrawerConfig.multiline`)}
                    </Label>
                    <Form.Item name="multiline" style={{ width: "100%" }}>
                      <Select
                        // placeholder={t(
                        //   "components.binder.BinderDrawerConfig.pleaseSelect"
                        // )}
                        onChange={(value) =>
                          handleChange({
                            roughNotation: {
                              type:
                                currentCuepoint?.roughNotation?.type ||
                                "underline",
                              multiline: value,
                            },
                          })
                        }
                      >
                        <Option value={true}>
                          {t("components.binder.BinderDrawerConfig.multiline")}
                        </Option>
                        <Option value={false}>
                          {t(
                            "components.binder.BinderDrawerConfig.notMultiline"
                          )}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Flex>
          </Form>
          <ExpandIcon
            size={!showAdvancedSettings ? 30 : 20}
            weight="bold"
            style={{
              cursor: "pointer",
              marginRight: 4,
            }}
            onClick={() => setShowAdvancedSettings((value) => !value)}
          />
        </Flex>
        <Flex
          align="center"
          // gap={2}
          style={{
            backgroundColor: theme.colorBgBase,
            boxShadow: theme.boxShadowSecondary,
            border: `1px solid ${theme.colorBorder}`,
            borderRadius: 10,
            paddingRight: 7,
            paddingLeft: 7,
            maxHeight: 70,
          }}
        >
          <Tooltip title="Delete animation">
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => handleRemoveHighlight(currentHighlight)}
            >
              <Button type="text" size="large" danger icon={<DeleteOutlined />}>
                {/* {t("buttons.delete")} */}
              </Button>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Revert changes">
            <Flex style={{ backgroundColor: theme.colorBgBase }}>
              <Button
                disabled={updateCount === 0}
                type="text"
                size="large"
                icon={<ArrowUUpLeft weight="bold" />}
                onClick={handleRevert}
              >
                {/* {t("buttons.save")} */}
              </Button>
            </Flex>
          </Tooltip>
          <Tooltip title="Close settings">
            <Button
              type="text"
              size="large"
              onClick={handleClose}
              icon={<CloseOutlined />}
            >
              {/* {t("buttons.close")} */}
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    </Draggable>
  );
};

const Label = styled(Typography.Text)`
  font-size: 11px;
  font-weight: bold;
`;
