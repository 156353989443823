import React, { useEffect } from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { LayoutProps } from "@refinedev/core";
import { Layout as AntdLayout, Grid } from "antd";
import useSessionData from "hooks/useSessionData";

export const Layout: React.FC<LayoutProps> = ({
  children,
  Sider,
  Header,
  Footer,
  OffLayoutArea,
}) => {
  const { userData, user } = useSessionData();
  const breakpoint = Grid.useBreakpoint();
  console.log(userData?.data);

  useEffect(() => {
    console.log(userData?.data);

    if (user && userData?.data) {
      const { id, first_name, last_name, email, created } = userData.data;

      Intercom({
        app_id: "mcwfcpeh",
        user_id: String(id),
        name: first_name + " " + last_name,
        email: email,
        created_at: created ? new Date(created).getTime() / 1000 : 0,
      });
    }

    return () => {
      window?.Intercom?.("shutdown");
    };
  }, [userData?.data, user]);

  return (
    <AntdLayout style={{ minHeight: "100vh" }}>
      {Sider && <Sider />}
      <AntdLayout style={{ width: "100%" }}>
        {Header && <Header />}
        <AntdLayout.Content style={{ position: "relative" }}>
          <div
            style={{
              padding: breakpoint.sm ? 24 : 12,
              minHeight: 1360,
            }}
          >
            {children}
          </div>
          {OffLayoutArea && <OffLayoutArea />}
        </AntdLayout.Content>
        {Footer && <Footer />}
      </AntdLayout>
    </AntdLayout>
  );
};
