import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  GlobalOutlined,
  PauseOutlined,
  SyncOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { t } from "i18n";
import { ReactNode } from "react";

// no more enums in backend (statuses can be configured)
const STATUS_COLORS: Record<string, string> = {
  Ready: "success",
  Generating: "processing",
  Requested: "default",
  Error: "error",
  Draft: "processing",
  Preview: "warning",
  Published: "success",
  WaitingUserInput: "processing",
};
const STATUS_ICONS: Record<string, ReactNode> = {
  Ready: <CheckOutlined />,
  Generating: <SyncOutlined spin />,
  Requested: <ClockCircleOutlined />,
  Error: <CloseCircleOutlined />,
  Draft: <PauseOutlined />,
  Preview: <EyeOutlined />,
  Published: <GlobalOutlined />,
  WaitingUserInput: <UserOutlined />,
};
export const MediaStatus = ({
  status,
  label,
}: {
  status: string;
  label?: ReactNode;
}) => {
  const color = STATUS_COLORS[status] ?? undefined;
  const icon = STATUS_ICONS[status] ?? undefined;
  return (
    <>
      {label ? (
        <Tooltip title={t(`media.components.MediaStatus.${status}`)}>
          <Tag
            color={color}
            icon={icon}
            style={{ border: 0, borderRadius: 100 }}
          >
            {label}
          </Tag>
        </Tooltip>
      ) : (
        <Tag color={color} icon={icon} style={{ border: 0, borderRadius: 100 }}>
          {t(`media.components.MediaStatus.${status}`)}
        </Tag>
      )}
    </>
  );
};
