import { Document } from "pages/media/types";

export const sortedDocumentsPrimaryFirst = (
  primaryDocumentId: string | undefined,
  documents: Document[]
) => {
  return [...documents].sort((a, b) => {
    if (a.id === primaryDocumentId && b.id !== primaryDocumentId) return -1;
    if (b.id === primaryDocumentId && a.id !== primaryDocumentId) return 1;
    return new Date(a.created).getTime() - new Date(b.created).getTime();
  });
};
