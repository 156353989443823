import { QueryKey, useQueryClient } from "@tanstack/react-query";

export const useCustomInvalidate = () => {
  const queryClient = useQueryClient();

  const customInvalidate = async (queryKey: string | string[]) => {
    return queryClient.invalidateQueries(queryKey as QueryKey);
  };

  return { customInvalidate };
};
