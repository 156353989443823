import { Modal } from "antd";
import { t } from "i18n";
import { SimpleCreateContactWithoutLayout } from "pages/media/components/ContactWithPresenter/PageComponent";

export const ContactAddingModal = ({
  contactsModal,
  setContactsModal,
  newContactSaveCallback,
  onChange,
  setNewContactSaveCallback,
  onFinish,
}: {
  contactsModal: boolean;
  setContactsModal: React.Dispatch<React.SetStateAction<boolean>>;
  newContactSaveCallback: () => void;
  onChange?: (contact: string) => void;
  setNewContactSaveCallback: React.Dispatch<React.SetStateAction<() => void>>;
  onFinish?: () => void;
}) => {
  return (
    <Modal
      onCancel={() => setContactsModal(false)}
      afterOpenChange={(open) => {
        if (!open) {
          setContactsModal(false);
        }
      }}
      okText={t("buttons.save")}
      onOk={() => {
        setContactsModal(false);
        newContactSaveCallback();
        onFinish?.();
      }}
      title={t("projects.components.ProjectSettingForm.addANew")}
      width={"85vw"}
      open={contactsModal}
    >
      <SimpleCreateContactWithoutLayout
        onSuccess={(contact) => onChange?.(contact)}
        setSaveCallback={setNewContactSaveCallback}
      />
    </Modal>
  );
};
