import { UploadFile } from "antd";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { MediaProject } from "../types";

interface State {
  step: string;
  file: (UploadFile & { abort?: () => void }) | null;
  project: MediaProject | null;
}
export type Actions = {
  setStep: (step: string) => void;
  setFile: (file: (UploadFile & { abort?: () => void }) | null) => void;
  setProject: (project: MediaProject | null) => void;
  resetProjectCreateState: () => void;
};

const initialState = {
  step: "Upload",
  file: null,
  project: null,
};
export const useProjectWizardStore = create<State & Actions>()(
  devtools(
    immer((set) => {
      return {
        ...initialState,
        resetProjectCreateState: () => set({ ...initialState }),
        setStep: (step) =>
          set((state) => {
            state.step = step;
          }),
        setFile: (file) =>
          set((state) => {
            state.file = file;
          }),
        setProject: (project) =>
          set((state) => {
            state.project = project;
          }),
      };
    })
  )
);
