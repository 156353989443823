import { GlobalToken, Grid, theme as antdTheme } from "antd";
import type { CustomToken } from "contexts";

export const useAntTheme = () => {
  const { useToken } = antdTheme;
  const { token: theme } = useToken();
  const breakpoint = Grid.useBreakpoint();
  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  return {
    theme: theme as GlobalToken & CustomToken,
    isMobile,
    colorPrimary: theme.colorPrimary,
  };
};
