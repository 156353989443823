import { Authenticated, useList, useNavigation } from "@refinedev/core";
import { List } from "@refinedev/antd";
import { useOrganization } from "hooks/useOrganization";
import { AssetLibrary } from "pages/media/types";
import { Table, TableColumnsType } from "antd";

const AssetLibraries: React.FC = () => {
  // using uselist and the list detail in refine, we build our list component
  const { organization } = useOrganization({});
  const { data: assetLibraries, isLoading: isLoadingAssetLibraries } =
    useList<AssetLibrary>({
      resource: `/media/asset_libraries`,
      queryOptions: {
        enabled: !!organization?.id,
      },
    });

  const columns: TableColumnsType<AssetLibrary> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
  ];
  const { show } = useNavigation();
  const handleClickRow = async (record: AssetLibrary) => {
    await show(`media_asset_libraries`, record.id);
  };

  return (
    <Authenticated key="asset-libraries">
      <List>
        <Table
          columns={columns}
          dataSource={assetLibraries?.data}
          loading={isLoadingAssetLibraries}
          onRow={(record) => {
            return {
              onClick: () => {
                handleClickRow(record);
              },
            };
          }}
        ></Table>
      </List>
    </Authenticated>
  );
};

export default AssetLibraries;
