import { useTranslate } from "@refinedev/core";
import React from "react";
import { AuthPage } from "@refinedev/antd";
import { Typography } from "antd";
import { AppLogo } from "components/AppLogo";

export const ChangePassword: React.FC = () => {
  const t = useTranslate();
  return (
    <AuthPage
      type="updatePassword"
      contentProps={{
        title: (
          <Typography.Title level={3}>
            {t("pages.auth.change-password.changePassword")}
          </Typography.Title>
        ),
      }}
      title={<AppLogo width={64} />}
      renderContent={(content: React.ReactNode, title: React.ReactNode) => {
        return (
          <>
            {title}
            {content}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              <Typography.Text>
                {t("pages.auth.change-password.yourPasswordMust")}
              </Typography.Text>
            </div>
          </>
        );
      }}
    />
  );
};
