import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Flex, Space, Modal } from "antd";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { PresenterProfilePicker } from "pages/media/components/PresenterProfilePicker";
import { VoiceProfileCreatePage } from "pages/media/voices/create";
import { FaceProfileCreateForm } from "pages/media/components/FaceProfileCreateForm";
import {
  ContactResponse,
  FaceProfileCreationResponse,
  FaceProfileResponse,
  PresenterProfileResponse,
  VoiceProfileResponse,
} from "pages/media/types";
import {
  useList,
  useCreate,
  useOne,
  useUpdate,
  useNotification,
  useGo,
} from "@refinedev/core";
import { isInStorybook } from "helpers/utils";
import { useOrganization } from "hooks/useOrganization";
import { useForm } from "antd/es/form/Form";
import {
  Create,
  DeleteButton,
  Edit,
  ListButton,
  RefreshButton,
  SaveButton,
} from "@refinedev/antd";
import VoiceAndFaceContainer from "./VoiceAndFaceContainer";
import IdentityCard from "./IdentityCard";
import { ContactPageProvider } from "./hooks/useContactPage";
import { errorNotificationWithEntityIdsHandler } from "pages/onboarding/helpers";

export const SimpleCreateContactWithoutLayout = ({
  onSuccess,
  setSaveCallback,
  onValidate,
  id,
  noFace,
}: {
  onSuccess: (id: string) => void;
  setSaveCallback: Dispatch<SetStateAction<() => void>>;
  onValidate?: (value: boolean) => void;
  id?: string;
  noFace?: boolean;
}) => {
  return (
    <ContactView
      id={id}
      onValidate={onValidate}
      onSuccess={onSuccess}
      setSaveCallback={setSaveCallback}
      noFace={noFace}
    />
  );
};

export const SimpleCreateContactPage = () => {
  const go = useGo();
  const [saveCallback, setSaveCallback] = useState<() => void>(() => {});
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  return (
    <Create
      resource="media_contacts"
      saveButtonProps={{
        disabled: !submitButtonEnabled,
        onClick: () => saveCallback(),
      }}
    >
      <ContactView
        setSubmitButtonEnabled={setSubmitButtonEnabled}
        onSuccess={() => {
          go({
            to: "/media/contacts",
          });
        }}
        setSaveCallback={setSaveCallback}
      />
    </Create>
  );
};
export const SimpleEditContactPage = ({ id }: { id: string }) => {
  const [saveCallback, setSaveCallback] = useState<() => void>(() => {});
  const [refetch, setRefetch] = useState<() => void>(() => {});
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
  const { organization } = useOrganization({});
  const go = useGo();
  return (
    <Edit
      headerProps={{
        title: t("src.App.editContact"),
      }}
      footerButtons={[
        <DeleteButton
          disabled={false}
          type="primary"
          resource={`media/${organization?.id}/contacts`}
          onSuccess={() => {
            go({ to: `../` });
          }}
          key="3"
          errorNotification={(error) => {
            const result = errorNotificationWithEntityIdsHandler(
              error,
              // cf https://github.com/LibertyFi/Libertyfi.api/blob/d0ed8c3efab6fde3fa18f5f94a3f646accc27abf/py/projects/api/libertify/api/controllers/media/contact.py#L131
              ["media_ids", "project_ids"]
            );
            return result;
          }}
        />,

        <SaveButton
          key="4"
          {...{
            disabled: !submitButtonEnabled,
            onClick: async () => {
              await saveCallback();
              go({
                to: `../`,
              });
            },
          }}
        />,
      ]}
      headerButtons={[
        <ListButton key="1" />,
        <RefreshButton
          key="2"
          onClick={() => refetch()}
          resource={"media_contacts"}
          recordItemId={id}
        />,
      ]}
    >
      <ContactView
        setSubmitButtonEnabled={setSubmitButtonEnabled}
        setRefetch={setRefetch}
        id={id}
        setSaveCallback={setSaveCallback}
      />
    </Edit>
  );
};
type PageComponentProps = {
  id?: string;
  setSaveCallback?: Dispatch<SetStateAction<() => void>>;
  setRefetch?: Dispatch<SetStateAction<() => void>>;
  onSuccess?: (id: string) => void;
  onValidate?: (value: boolean) => void;
  style?: React.CSSProperties;
  setSubmitButtonEnabled?: Dispatch<SetStateAction<boolean>>;
  noFace?: boolean;
};
const ContactView: React.FC<PageComponentProps> = ({
  id,
  setSaveCallback,
  setRefetch,
  onSuccess,
  onValidate,
  style,
  setSubmitButtonEnabled,
  noFace,
}: PageComponentProps) => {
  const t = useTranslate();
  const { organization, changeOrganization } = useOrganization({});
  useEffect(() => {
    if (!isInStorybook) return;
    changeOrganization("libertify");
  }, []);
  const [form] = useForm();
  const onChangeForm = async () => {
    await validateForm();
  };

  const validateForm = useCallback(
    async (options: { validateOnly?: boolean } = {}) => {
      try {
        await form.validateFields(options);
      } catch (error) {
        // This is not an actual exception, but a form validation error
        if (
          (
            error as {
              errorFields: unknown[];
            }
          ).errorFields.length
        ) {
          onValidate?.(false);
          setSubmitButtonEnabled?.(false);
          return;
        }
      }
      onValidate?.(true);
      setSubmitButtonEnabled?.(true);
    },
    [form, onValidate, setSubmitButtonEnabled]
  );
  const isVoiceSelected = (showNotification = true) => {
    if (!selectedVoice) {
      if (showNotification)
        open?.({
          description: t("components.ContactWithPresenter.PageComponent.error"),
          message: t("components.ContactWithPresenter.PageComponent.youNeedTo"),
          type: "error",
        });
      return false;
    }
    if (!selectedVoice.preview_asset_path) {
      if (showNotification)
        open?.({
          description: t("components.ContactWithPresenter.PageComponent.error"),
          message: t(
            "components.ContactWithPresenter.PageComponent.voiceNotReady"
          ),
          type: "error",
        });
      setSubmitButtonEnabled?.(false);
      return false;
    }

    return true;
  };
  const [updatedPresenter, setUpdatedPresenter] =
    useState<PresenterProfileResponse>();
  const [selectedVoice, setSelectedVoice] = useState<VoiceProfileResponse>();
  const [selectedFace, setSelectedFace] = useState<FaceProfileResponse>();
  const [rerenderKey, setRerenderKey] = useState(0);
  const [isFaceDisabled, setIsFaceDisabled] = useState(noFace);

  useEffect(() => {
    if (noFace) {
      setIsFaceDisabled(true);
    } else {
      setIsFaceDisabled(false);
    }
  }, [noFace]);

  const [currentModal, setCurrentModal] = useState<
    "voice" | "face" | "presenter" | null
  >();
  const { open } = useNotification();
  // Those two are partial records that do not have assets yet
  const [clonedFaceResponse, setClonedFaceResponse] = useState<
    FaceProfileCreationResponse & {
      category: "Custom";
    }
  >();
  const [clonedVoiceResponse, setClonedVoiceResponse] =
    useState<VoiceProfileResponse>();
  const [existingPresenterId, setExistingPresenterId] = useState<string>();
  const { data: presenters } = useList<PresenterProfileResponse>({
    resource: `media/${organization?.id}/presenter_profiles`,
  });

  const { data: clonedVoice } = useOne<VoiceProfileResponse>({
    resource: `media/${organization?.id}/voice_profiles`,
    id: clonedVoiceResponse?.id,
    queryOptions: {
      enabled: !!clonedVoiceResponse,
    },
    errorNotification: false,
  });
  // Once we get the assets, we can use those to display on the frontend
  const { data: clonedFace } = useOne<FaceProfileResponse>({
    resource: `media/${organization?.id}/face_profiles`,
    id: clonedFaceResponse?.face_profile_id,
    queryOptions: {
      enabled: !!clonedFaceResponse,
      keepPreviousData: false,
    },
    errorNotification: false,
  });

  const { mutateAsync: mutateContact } = useUpdate<ContactResponse>();

  const updateContact = async (id: string) => {
    if (!isVoiceSelected()) return;
    let presenter;
    if (presenterDirty()) {
      presenter = await createPresenter();
      setUpdatedPresenter(presenter);
    }
    await mutateContact({
      resource: `media/${organization?.id}/contacts`,
      id,
      values: {
        ...form.getFieldsValue(),
        presenter_id: presenter?.id ?? existing?.presenter?.id,
      },
    });
  };

  const { mutateAsync: createNewPresenter } =
    useCreate<PresenterProfileResponse>();

  const {
    data: existing,
    isLoading: isLoadingExisting,
    isFetching: isFetchingExisting,
    refetch,
  } = useExistingContact(id);
  function presenterDirty() {
    if (!existing?.contact) return false;
    return [
      existing?.presenter?.voice_profile?.id !== selectedVoice?.id,
      existing?.presenter?.face_profile?.id !== selectedFace?.id,
    ].some((item) => item);
  }
  const { mutateAsync: createNewContact } = useCreate<ContactResponse>();
  const createPresenter = async () => {
    const presenter = await createNewPresenter({
      resource: `media/${organization?.id}/presenter_profiles`,
      values: {
        name:
          form.getFieldValue("firstname") +
          " " +
          form.getFieldValue("lastname"),
        face_profile_id: selectedFace?.id,
        voice_profile_id: selectedVoice!.id,
      },
    });
    if (!presenter.data) {
      throw "Error";
    }
    return presenter.data;
  };

  const pickedPresenter = useMemo(() => {
    return (
      presenters?.data.find(
        (presenter) => presenter.id === existingPresenterId
      ) ??
      updatedPresenter ??
      existing?.presenter
    );
  }, [existingPresenterId, updatedPresenter, existing, presenters]);
  const existingContact = existing?.contact;
  const existingPresenter = existing?.presenter;
  useEffect(() => {
    if (pickedPresenter) {
      setSelectedFace?.(pickedPresenter?.face_profile);
      setSelectedVoice?.(pickedPresenter?.voice_profile);
      if (
        existingContact &&
        !existingPresenter?.face_profile &&
        !pickedPresenter
      ) {
        setIsFaceDisabled(true);
      }
    }
  }, [pickedPresenter, existingContact, existingPresenter, rerenderKey]);

  useEffect(() => {
    form.setFieldsValue({
      ...existing?.contact,
      firstname: existing?.contact?.firstname,
      lastname: existing?.contact?.lastname,
    });
    if (!id) {
      form.resetFields();
    }
    validateForm({ validateOnly: true });
  }, [existing?.contact, id]);

  useEffect(() => {
    setSelectedFace(existing?.presenter?.face_profile);
    setSelectedVoice(existing?.presenter?.voice_profile);
  }, [rerenderKey]);

  const createContact = async (presenter: PresenterProfileResponse) => {
    const contact = await createNewContact({
      resource: `media/${organization?.id}/contacts`,
      values: {
        ...form.getFieldsValue(true),
        presenter_id: presenter.id,
      },
    });
    onSuccess?.(contact?.data?.id);
  };

  const createPresenterAndContact = async () => {
    if (!isVoiceSelected()) return;
    try {
      const presenter = pickedPresenter ?? (await createPresenter());
      await createContact(presenter);
    } catch (e) {
      console.error(e);
    }
  };

  // We set the save callback so that we can call the update/create
  // methods from outside such as when clicking a save button in
  // Edit/Create from Refine or when using this component inside a
  // modal.
  useEffect(() => {
    if (id) {
      setSaveCallback?.(() => () => updateContact(id));
      setRefetch?.(() => () => {
        setRerenderKey((prev) => prev + 1);
        refetch();
      });
      if (isFaceDisabled) {
        setSelectedFace(undefined);
      }
    } else {
      setSaveCallback?.(() => () => createPresenterAndContact());
    }
  }, [selectedFace, selectedVoice, existingPresenterId, refetch]);

  // When we clone a face, we don't get the whole profile from the face
  // create form. Instead we only get its id. We insert an empty face
  // into the list with the spinner and then we replace that one with
  // the real full face object once we have the processed cloned face
  // image.
  useEffect(() => {
    setSelectedFace(clonedFace?.data);
  }, [
    clonedFace?.data.extracted_asset_path,
    clonedFace?.data.custom_asset_path,
    clonedFace?.data.id,
  ]);

  useEffect(() => {
    (async () => {
      if (clonedVoice?.data.preview_asset_path) {
        setSelectedVoice(clonedVoice.data);
        setClonedVoiceResponse(undefined);
        setSubmitButtonEnabled?.(true);
      } else {
        setSubmitButtonEnabled?.(false);
      }
    })();
  }, [clonedVoice?.data.preview_asset_path]);

  return (
    <ContactPageProvider value={{ id, onChangeForm }}>
      <Flex vertical style={{ width: "100%", ...style }}>
        <Flex style={{ width: "100%" }}>
          <VoiceAndFaceContainer
            existingPresenter={isLoadingExisting ? null : existing?.presenter}
            setClonedFace={setClonedFaceResponse}
            setClonedVoice={setClonedVoiceResponse}
            cloneFaceResponse={clonedFaceResponse}
            isLoadingExisting={isLoadingExisting}
            cloneVoiceResponse={clonedVoiceResponse}
            clonedFace={clonedFace?.data}
            clonedVoice={clonedVoice?.data}
            setSelectedFace={setSelectedFace}
            selectedFace={selectedFace}
            selectedVoice={selectedVoice}
            setSelectedVoice={setSelectedVoice}
            setCurrentModal={setCurrentModal}
            isFaceDisabled={isFaceDisabled}
            disableFace={(value: boolean) => {
              if (value === false) {
                setSelectedFace(undefined);
              }
              return setIsFaceDisabled(!isFaceDisabled);
            }}
            hasPresenters={!!presenters?.data.length}
          />
          <IdentityCard
            presenter={pickedPresenter}
            form={form}
            selectedFace={selectedFace}
            selectedVoice={selectedVoice}
            isLoading={isLoadingExisting}
          />
        </Flex>
      </Flex>
      <Modal
        destroyOnClose
        maskClosable
        onCancel={() => {
          setCurrentModal(null);
        }}
        afterOpenChange={(open) => {
          if (!open) {
            setCurrentModal(null);
          }
        }}
        closable
        footer={false}
        open={!!currentModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {currentModal === "voice" && (
          <Space style={{ padding: 24 }}>
            <VoiceProfileCreatePage
              onSuccess={(voice: VoiceProfileResponse) => {
                // No notification here, the form does it by itself
                setSelectedVoice(undefined);
                setClonedVoiceResponse(voice);
                setCurrentModal(null);
              }}
            />
          </Space>
        )}
        {currentModal === "face" && (
          <Space style={{ padding: 24 }}>
            <FaceProfileCreateForm
              onSuccess={(face) => {
                open!({
                  message: t(
                    "components.ContactWithPresenter.PageComponent.thisMayTake"
                  ),
                  description: t(
                    "components.ContactWithPresenter.PageComponent.wereCloningYour"
                  ),
                  type: "success",
                });
                // setSelectedFace(undefined);
                setClonedFaceResponse({ ...face, category: "Custom" });

                setCurrentModal(null);
              }}
            />
          </Space>
        )}

        {currentModal === "presenter" && (
          <PresenterProfilePicker
            value={existingPresenterId}
            items={presenters?.data ?? []}
            onSuccess={(presenter) => {
              setIsFaceDisabled(false);
              setSelectedFace(undefined);
              setSelectedVoice(undefined);
              setExistingPresenterId(presenter);
              setCurrentModal(null);
            }}
          />
        )}
      </Modal>
    </ContactPageProvider>
  );
};
function useExistingContact(id?: string): {
  data?: {
    contact?: ContactResponse;
    presenter?: PresenterProfileResponse;
  };
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
} {
  const { organization } = useOrganization({});
  const {
    data: contactData,
    isLoading,
    isFetching,
    refetch,
  } = useOne<ContactResponse>({
    resource: `media/${organization?.id}/contacts`,
    id,
    queryOptions: {
      enabled: !!id,
    },
  });

  const contact = contactData?.data;
  const { data: presenterData } = useOne<PresenterProfileResponse>({
    resource: `media/${organization?.id}/presenter_profiles`,
    id: contact?.presenter_id,
    queryOptions: {
      enabled: !!contact?.presenter_id,
    },
  });
  const presenter = presenterData?.data;
  return {
    data: {
      contact,
      presenter,
    },
    isFetching,
    isLoading: !!id && isLoading,
    refetch,
  };
}

export default ContactView;
