import styled, { keyframes } from "styled-components";
import { Flex } from "antd"; 

interface RotatingBorderProps {
  children: React.ReactNode;
  color: string;
  speed?: number;
  borderRadius?: number;
  padding?: number;
  shadeColor?: string;
};

const RotatingBorder: React.FC<RotatingBorderProps> = ({
  children,
  color,
  speed = 2,
  borderRadius = 10,
  padding = 3,
  shadeColor = "white",
}) =>{
  return (
    <StyledBox
      color={color}
      speed={speed}
      borderRadius={borderRadius}
      padding={padding}
      shadeColor={shadeColor}
    >
      <Flex 
      justify="center"
      align="center"
        style={{
          position: "relative",
          zIndex: 1,
          borderRadius: borderRadius,
        }}
      >
        {children}
      </Flex>
    </StyledBox>
  );
}

const rotateAnimation = keyframes`
  from {
    transform: translate(-50%, -50%) scale(1.4) rotate(0turn);
  }
  to {
    transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
  }
`;

interface StyledBoxProps {
  padding: number;
  color: string;
  borderRadius: number;
  shadeColor: string;
  speed: number;
}

const StyledBox = styled.div<StyledBoxProps>`
  --offset: ${(props) => props.padding}px;
  position: relative;
  background: ${(props) => props.color};
  border-radius: ${(props) => props.borderRadius}px;
  padding: ${(props) => props.padding}px;
  overflow: hidden;
  display: inline-block;
  z-index: 1;

  &::before {
    content: "";
    background: conic-gradient(
      transparent 150deg,
      ${(props) => props.shadeColor},
      transparent
    );
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1;
    width: 100%;
    animation: ${rotateAnimation} ${(props) => props.speed}s linear infinite;
    z-index: 0;
  }

  &::after {
    content: "";
    background: inherit;
    border-radius: inherit;
    position: absolute;
    inset: var(--offset);
    height: calc(100% - 2 * var(--offset));
    width: calc(100% - 2 * var(--offset));
    z-index: 0;
  }
`;

export default RotatingBorder;
