import { Maybe, Workspace } from "types";
// import useSessionData from "./useSessionData";
import { useOrganization } from "./useOrganization";
import { useList } from "@refinedev/core";
import { useBoundStore } from "store";

export const useWorkspace = () => {
  const appState = useBoundStore((state) => state.appState);
  const setAppState = useBoundStore((state) => state.setAppState);
  const { organization } = useOrganization({});

  const { data: orgWorkspaces, isLoading: isLoadingOrgWorkspaces } =
    useList<Workspace>({
      resource: `api/${organization?.id}/workspaces`,
      queryOptions: {
        enabled: Boolean(organization?.id),
      },
    });

  const getWorkspace = (id: string): Maybe<Workspace> =>
    workspaces?.find((x) => x.id === id);

  const changeWorkspace = ({ workspace }) => {
    setAppState({ workspace });
  };
  const workspaces = orgWorkspaces?.data ?? [];
  // restore from store
  const currentWorkspace = appState.workspace ?? workspaces?.[0];
  const isLoading = isLoadingOrgWorkspaces;

  return {
    workspace: currentWorkspace,
    workspaceId: currentWorkspace?.id,
    workspaces,
    hasWorkspace: workspaces?.length > 0,
    isLoading,
    changeWorkspace,
    getWorkspace,
  };
};
