import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createProjectsSlice } from "pages/media/projects/projectsSlice";
import { createAppSlice } from "components/appSlice";
import { createOnboardingSlice } from "pages/onboarding/onboardingSlice";
import { CombinedSlice, CombinedVolatileSlice } from "./types";
import { createTemplateSlice } from "pages/media/components/templates/templatesSlice";
import { createStoryboardSlice } from "pages/media/components/storyboard/storyboardSlice";
import { createProjectSlice } from "pages/media/projects/projectSlice";

// Use this to reset ALL state,
// and use individual reset<SliceName> functions to reset
// only that slice.
export const sliceResetFns = new Set<() => void>();

export const resetAllSlices = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

export const useBoundStore = create<CombinedSlice>()(
  devtools(
    persist(
      immer((...a) => ({
        ...createProjectsSlice(...a),
        ...createAppSlice(...a),
        ...createOnboardingSlice(...a),
      })),
      { name: "state" }
    )
  )
);

export const useVolatileBoundStore = create<CombinedVolatileSlice>()(
  devtools(
    immer((...a) => ({
      ...createTemplateSlice(...a),
      ...createStoryboardSlice(...a),
      ...createProjectSlice(...a),
    }))
  )
);
