import { useTranslate } from "@refinedev/core";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { Pause, Play } from "@phosphor-icons/react";
import { Flex, Button, Typography, Slider, ConfigProvider } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAntTheme } from "hooks/useAntTheme";

type AudioSampleProps = {
  removeAudioSample?: () => void;
  audio: File | HTMLAudioElement | string; // String is path or base64 data URI
  canDelete?: boolean;
  children?: React.ReactNode;
  containerJustify?: React.CSSProperties["justifyContent"];
  onDemand?: boolean;
  onClickPlay?: () => void;
  loading?: boolean;
  playImmediately?: boolean;
  deleteFunc?: () => void;
};

export const AudioSample: React.FC<AudioSampleProps> = ({
  audio,
  removeAudioSample,
  canDelete = true,
  children,
  containerJustify = "space-between",
  onDemand,
  onClickPlay,
  loading,
  playImmediately,
  deleteFunc
}) => {
  const t = useTranslate();
  const [playing, setPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const animation = useRef(0);
  const [hasTouchedPlayBefore, setHasTouchedPlayBefore] =
    useState<boolean>(false);
  const { theme } = useAntTheme();

  const playSound = async (audio: HTMLAudioElement) => {
    if (currentTime === duration) {
      audioElement.currentTime = 0;
    }
    if (audioElement.paused) {
      setPlaying(true);
      await audioElement.play();
    } else {
      audioElement.pause();
      setPlaying(false);
    }
    setHasTouchedPlayBefore(true);
  };

  const [duration, setDuration] = useState<number>(
    Math.floor((audio as HTMLAudioElement)?.duration ?? 0)
  );

  // const assignDuration = () => {
  //   setDuration(audioElement.duration);
  // };

  const audioElement = useMemo<HTMLAudioElement>((): HTMLAudioElement => {
    if (typeof audio === "string") {
      try {
        return new Audio(URL.createObjectURL(base64toBlob({ data: audio })));
      } catch {
        // Treat as a URL
        return new Audio(audio);
      }
    } else if (audio instanceof File) {
      return new Audio(URL.createObjectURL(audio as Blob));
    } else {
      return audio;
    }
  }, [audio]);

  function handleEnded() {
    setPlaying(false);
    cancelAnimationFrame(animation.current);
    setCurrentTime(0);
  }

  useEffect(() => {
    if (onDemand) return;
    if (playImmediately) {
      playSound(audioElement);
    }

    function updateCurrentTime() {
      setCurrentTime(audioElement.currentTime);
      requestAnimationFrame(updateCurrentTime);
    }
    animation.current = requestAnimationFrame(updateCurrentTime);
    // stop animation when component unmounts
    return () => cancelAnimationFrame(animation.current);
  }, [audioElement]);

  useEffect(() => {
    // Reset the progress bar and once again play after the audio has loaded
    // from the server
    if (!audio) {
      setHasTouchedPlayBefore(false);
      setCurrentTime(0);
    }
  }, [audio]);

  useEffect(() => {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    if (!audioElement) return;

    const assignDuration = () => {
      const newDuration = audioElement.duration;
      if (!isNaN(newDuration)) {
        setDuration(newDuration);
      }
    };

    audioElement.addEventListener("loadedmetadata", assignDuration);
    if (isFirefox) {
      audioElement.addEventListener("canplaythrough", assignDuration);
    }
    audioElement.addEventListener("ended", handleEnded);

    return () => {
      audioElement.pause();
      audioElement.removeEventListener("loadedmetadata", assignDuration);
      audioElement.removeEventListener("canplaythrough", assignDuration);
      audioElement.removeEventListener("ended", handleEnded);
    };
  }, [audioElement]);

  function handleClick() {
    if (onDemand) {
      onClickPlay?.();
    } else {
      playSound(audioElement);
    }
  }

  const handleSeek = (value: number) => {
    const time = (value / 100) * duration;
    setCurrentTime(time);
    audioElement.currentTime = time;
  };

  const handleSliderChange = (value: number) => {
    handleSeek(value);
  };

  let icon = playing ? (
    <Pause size={20} weight="fill" />
  ) : (
    <Play size={20} weight="fill" />
  );

  if (loading) {
    icon = (
      <LoadingOutlined
        spin
        style={{
          fontSize: 24,
          opacity: 1,
        }}
      />
    );
  }

  return (
    <Flex
      gap={16}
      align="center"
      justify={containerJustify}
      style={{ width: "100%" }}
    >
      <Flex align="center">
        <Button
          shape="circle"
          style={{ position: "relative", left: "-10px", flex: 0 }}
          onClick={handleClick}
          icon={icon}
          type="text"
        />
        {children}
      </Flex>

      <ConfigProvider
        theme={{
          components: {
            Slider: {
              handleSize: 0,
              railSize: 4,
              handleSizeHover: 0,
              handleLineWidth: 0,
              handleLineWidthHover: 0,
              trackBg: theme.colorPrimary,
              trackHoverBg: theme.colorPrimary,
              railBg: theme.colorFillSecondary,
              handleColor: theme.colorPrimary,
            },
          },
        }}
      >
        <Slider
          value={onDemand ? 0 : (currentTime / duration) * 100}
          onChange={handleSliderChange}
          disabled={onDemand}
          tooltip={{
            formatter: null,
          }}
          style={{ flex: 1 }}
          min={0}
          max={100}
          step={0.1}
        />
      </ConfigProvider>

      <Flex gap={16} align="center" justify="flex-end">
        {!onDemand && (
          <Typography.Text
            style={{ flex: "1 0", textAlign: "right" }}
            type="secondary"
          >
            {`${Math.floor(currentTime)}${t("media.components.AudioSample.s")}`}{" "}
            / {Math.floor(duration)}
            {t("media.components.AudioSample.s")}
          </Typography.Text>
        )}
        {canDelete && (
          <Button
            style={{ flex: "0 1" }}
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => {
              removeAudioSample?.();
              deleteFunc?.();
              return audioElement.remove();
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};

type Base64 = {
  data: string;
  mime?: string;
};

function base64toBlob({ data, mime = "audio/mp3" }: Base64): Blob {
  const byteString = atob(data);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mime });
}
