import {
  useCustomMutation,
  useGo,
  useInvalidate,
  useNavigation,
  useTranslate,
  useOne,
} from "@refinedev/core";
import {
  Button,
  Dropdown,
  Flex,
  GlobalToken,
  Segmented,
  Select,
  Space,
  Tooltip,
  Typography,
  Modal,
  Alert,
} from "antd";
import { Ref, useCallback, useRef, useState, forwardRef } from "react";
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import { Link } from "react-router-dom";
import {
  BookOpenText,
  Lightning,
  PencilSimpleLine,
  Speedometer,
  StarFour,
  TextAa,
  Timer,
  VideoCamera,
} from "@phosphor-icons/react";

import { useAntTheme } from "hooks/useAntTheme";
import { PdfViewer } from "components/PdfViewer";
import { StoryboardEditor } from "./StoryboardEditor";
import {
  DocumentExtract,
  MediaDocument,
  MediaProjectResponse,
  TDetailedSection,
} from "pages/media/types";
import { DisplayMode } from "./editor/types";
import {
  ArrowLeftOutlined,
  CopyOutlined,
  DownOutlined,
  Loading3QuartersOutlined,
} from "@ant-design/icons";
import { StoryboardStatusTag } from "../StoryboardStatus";
import { getLocaleCountryCode } from "pages/media/utils";
import { StoryboardProvider, useStoryboard } from "./context/StoryboardContext";
import styled from "styled-components";
import { positionToXY } from "helpers/utils";
import { timePretty } from "./editor/helpers";
import { IHighlight } from "react-pdf-highlighter";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { StoryboardMetrics } from "./editor/StoryboardMetrics";
import { StyledSegmentedCardWrapper } from "pages/media/projects/components/styles";
import { useVolatileBoundStore } from "store";
import { useClipboard } from "hooks/useClipboard";
import { useFeature } from "hooks/useFeature";
dayjs.extend(duration);

export const StoryboardStudio = ({
  project,
  storyboardId,
}: {
  project?: MediaProjectResponse;
  storyboardId?: string;
}) => {
  const t = useTranslate();
  const { theme } = useAntTheme();
  const { show } = useNavigation();
  const { copy } = useClipboard();
  const [displayMode, setDisplayMode] = useState<DisplayMode>("present");
  const [highlightedSource, setHighlightedSource] = useState<string>();
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const { isEnabled: isMetricsEnabled } = useFeature(
    "features.admin.media.storyboard.metrics"
  );
  const { scriptsWordsCount, readingTime, metrics, storyboard } =
    useVolatileBoundStore((store) => ({
      scriptsWordsCount: store.storyboardState.scriptsWordsCount,
      readingTime: store.storyboardState.readingTime,
      metrics: store.storyboardState.metrics,
      storyboard: store.storyboardState.storyboard,
    }));
  // const [showPlan, setShowPlan] = useState(true);
  // const [storyboard, dispatch] = useReducer(
  //   storyboardReducer,
  //   sampleStoryboard
  // );
  const pdfViewerPanelRef = useRef<ImperativePanelHandle>(null);
  const pdfViewerRef = useRef<ImperativePanelHandle>(null);
  const sourcesRef = useRef<Record<string, HTMLElement>>({});
  const currentDocument = project?.settings.primary_document_id
    ? project.documents.find(
        (x) => x.id === project?.settings.primary_document_id
      )
    : project?.documents[0];
  // const { organization } = useOrganization({ id: project?.organization_id });

  const { data } = useOne<MediaDocument>({
    resource: `media/documents/${project?.organization_id}`,
    id: currentDocument?.id,
    queryOptions: {
      enabled: Boolean(project?.organization_id) && Boolean(currentDocument),
    },
  });
  const document = data?.data;

  // round to 30 seconds
  const estimatedGenerationTime = timePretty(
    Math.round((readingTime * 3) / 30000) * 30000
  );

  // console.debug({ document, currentDocument, project });

  // const { currentStep = "Plan" } = storyboard;
  // const steps = [
  //   {
  //     key: "Plan",
  //     title: "Storytelling",
  //   },
  //   {
  //     key: "Content",
  //     title: "Storyboard",
  //   },
  //   {
  //     key: "Timeline",
  //     title: "Timeline",
  //   },
  // ];

  // const handleStepChange = (index: number) => {
  //   dispatch({ type: "set_step", payload: steps[index].key });
  // };

  // const currentStepIndex = steps.findIndex((x) => x.key === currentStep);

  // const resetHighlights = () => {
  //   setHighlights([]);
  // };

  // const [highlights, setHighlights] = useState<IHighlight[]>([]);

  // const addHighlightToCurrentScene = (value: IHighlight) => {
  //   dispatch({
  //     type: "add_object",
  //     payload: {
  //       type: value.content.text ? "Text" : "Image",
  //       content: value.content.text || value.content.image,
  //     },
  //   });
  // };

  // useEffect(() => {
  //   console.debug({ storyboard });
  // }, [storyboard]);

  // useEffect(() => {
  //   if (currentStep === "Timeline") {
  //     pdfViewerPanelRef.current?.collapse();
  //     planPanelRef.current?.collapse();
  //     storyboardPanelRef.current?.expand();
  //     bottomPanelRef.current?.expand();
  //   } else if (currentStep === "Plan") {
  //     planPanelRef.current?.expand();
  //     storyboardPanelRef.current?.collapse();
  //     pdfViewerPanelRef.current?.expand();
  //     bottomPanelRef.current?.collapse();
  //   } else if (currentStep === "Content") {
  //     planPanelRef.current?.expand();
  //     storyboardPanelRef.current?.expand();
  //     pdfViewerPanelRef.current?.expand();
  //     bottomPanelRef.current?.collapse();
  //   }
  // }, [currentStep]);

  const handleDisplaySource = useCallback(
    (source: DocumentExtract) => {
      (pdfViewerRef?.current as any)?.scrollViewerTo(source);
    },
    [pdfViewerRef]
  );

  const handleClickHighlight = (sourceId: string) => {
    const sourceRef = sourcesRef.current[sourceId];
    if (!sourcesRef) return;
    sourceRef.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "end",
    });
    setHighlightedSource(sourceId);
  };

  const { storeStoryboard, setStoreStoryboard } = useVolatileBoundStore(
    (store) => ({
      storeStoryboard: store.storyboardState.storyboard,
      setStoreStoryboard: store.setStoryboard,
    })
  );

  const currentStoryboard = project?.storyboards.find(
    (x) => x.asset_id === storyboardId
  );

  const hasPublishedMedia = project?.media.some(
    (media) =>
      media.language === currentStoryboard?.language &&
      media.status === "Published"
  );

  const { mutateAsync: approve, isLoading: isStoryboardApprovalLoading } =
    useCustomMutation({});

  const invalidate = useInvalidate();

  const go = useGo();

  const handleStoryboardApproval = async () => {
    try {
      await approve({
        url: `/media/projects/${project?.id}/storyboards/${currentStoryboard?.language}/approve`,
        method: "post",
        values: {},
      });
      await invalidate({
        resource: "media_projects",
        id: project?.id,
        invalidates: ["detail"],
      });
      go({
        to: {
          resource: "media_projects",
          action: "show",
          id: project?.id,
        },
      });
    } catch (e) {
      console.debug(e);
    }
  };

  const handleGoToSettings = () => {
    go({
      to: `/media/projects/${project?.id}/setup/storyboard`,
    });
  };

  const getScriptWithTitles = () => {
    return storyboard?.sections?.reduce((acc, x) => {
      return `${acc}# ${x.topic}\n${x.script?.content}\n\n`;
    }, "");
  };

  const getScriptWithoutTitles = () => {
    return storyboard?.sections?.reduce((acc, x) => {
      return `${acc}${x.script?.content}\n\n`;
    }, "");
  };

  const handleCopyScriptMenuClick = ({ key }: { key: string }) => {
    const textToCopy =
      key === "withTitles" ? getScriptWithTitles() : getScriptWithoutTitles();
    copy(textToCopy);
  };

  const copyScriptItems = [
    {
      label: t("storyboard.editor.Present.copyFullScriptWithoutTitles"),
      key: "withoutTitles",
    },
  ];

  return (
    <StoryboardProvider
      value={{
        project: project!,
        handleDisplaySource,
        storyboard: storeStoryboard,
        highlights: storeStoryboard?.sections.reduce((acc, section) => {
          if (!section.sources) return acc;
          acc.push(...sourcesToHighlights(section));
          return acc;
        }, [] as IHighlight[]),
        setStoryboard: setStoreStoryboard,
        handleClickHighlight: () => null,
        sourcesRef,
        highlightedSource,
        setHighlightedSource,
      }}
    >
      <Flex vertical style={{ margin: -24 }}>
        <Flex
          justify="space-between"
          style={{
            borderBottom: "1px solid",
            borderBottomColor: theme.colorBorder,
            padding: 8,
            paddingRight: 16,
          }}
        >
          <Button
            type="text"
            size="large"
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              show("media_projects", project?.id);
            }}
          >
            {t("components.binder.index.backToProject")}
          </Button>

          <Space wrap={true}>
            <Typography.Text type="secondary">
              {t("components.storyboard.StoryboardStudio.projects")}
            </Typography.Text>
            <Link
              to={"/media/projects/" + project?.id}
              style={{ color: theme.colorText }}
            >
              {project?.title}
            </Link>
            <Typography.Text type="secondary">
              {t("components.storyboard.StoryboardStudio.storyboard")}{" "}
              {/* {storyboardId && <UUID id={storyboardId} type="secondary" />} */}
            </Typography.Text>
            {storyboard && (
              <Space size="small">
                <Tooltip
                  title={currentStoryboard.language}
                  showArrow={false}
                  placement="left"
                  mouseEnterDelay={1}
                >
                  <div
                    className={`fi-${getLocaleCountryCode(currentStoryboard.language)}`}
                    style={{
                      width: "24px",
                      height: "14px",
                      backgroundSize: "cover",
                    }}
                  />
                </Tooltip>
                <StoryboardStatusTag status={currentStoryboard.status} />
              </Space>
            )}
          </Space>
          <Space>
            <Select
              size="small"
              options={[{ value: document?.id, label: document?.name }]}
              value={document?.id}
            />
          </Space>
        </Flex>
        <StyledPanelGroupWrapper>
          <PanelGroup
            autoSaveId="storyboard_container"
            direction="vertical"
            style={{ height: "calc(100vh - 60px)" }}
          >
            <Panel defaultSize={75}>
              <PanelGroup autoSaveId="storyboard" direction="horizontal">
                <Panel order={1} defaultSize={55} minSize={20}>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      overflow: "auto",
                      padding: 15,
                      // paddingTop: 0,
                    }}
                  >
                    <StyledSegmentedCardWrapper size="middle">
                      <Segmented<DisplayMode>
                        value={displayMode}
                        onChange={(value) =>
                          setDisplayMode(value as DisplayMode)
                        }
                        style={{
                          width: "100%",
                        }}
                        options={[
                          {
                            label: (
                              <Flex vertical gap={0} align="center">
                                <BookOpenText size={40} weight="duotone" />
                                <span>
                                  {t(
                                    "components.storyboard.StoryboardStudio.view.title"
                                  )}
                                </span>
                                <Typography.Text type="secondary">
                                  {t(
                                    "components.storyboard.StoryboardStudio.view.description"
                                  )}
                                </Typography.Text>
                              </Flex>
                            ),
                            value: "present",
                          },
                          // {
                          //   label: t(
                          //     "components.storyboard.StoryboardStudio.chapters"
                          //   ),
                          //   value: "minimal",
                          //   icon: <OrderedListOutlined />,
                          // },
                          // {
                          //   label: "Summary",
                          //   value: "summary",
                          //   icon: <AlignLeftOutlined />,
                          // },
                          {
                            label: (
                              <Flex vertical gap={0} align="center">
                                <PencilSimpleLine size={40} weight="duotone" />
                                <span>
                                  {t(
                                    "components.storyboard.StoryboardStudio.edit.title"
                                  )}
                                </span>
                                <Typography.Text type="secondary">
                                  {t(
                                    "components.storyboard.StoryboardStudio.edit.description"
                                  )}
                                </Typography.Text>
                              </Flex>
                            ),
                            value: "compact",
                          },
                          {
                            label: (
                              <Flex vertical gap={0} align="center">
                                <Lightning size={40} weight="duotone" />
                                <span>
                                  {t(
                                    "components.storyboard.StoryboardStudio.advance.title"
                                  )}
                                </span>
                                <Typography.Text type="secondary">
                                  {t(
                                    "components.storyboard.StoryboardStudio.advance.description"
                                  )}
                                </Typography.Text>
                              </Flex>
                            ),
                            value: "detailed",
                          },
                        ]}
                      />
                    </StyledSegmentedCardWrapper>
                    <StoryboardEditor
                      project={project}
                      storyboardId={storyboardId}
                      displayMode={displayMode}
                      setDisplayMode={setDisplayMode}
                    />
                  </div>
                </Panel>
                <PanelResizeHandle>
                  <StyledPanelResizeHandle></StyledPanelResizeHandle>
                </PanelResizeHandle>
                <Panel
                  order={3}
                  collapsible
                  ref={pdfViewerPanelRef}
                  defaultSize={30}
                  maxSize={60}
                  style={{ position: "relative" }}
                >
                  {document?.url && (
                    <PdfViewerWrapper
                      ref={pdfViewerRef as unknown as Ref<void>}
                      url={document?.url}
                    />
                  )}
                </Panel>
              </PanelGroup>
            </Panel>
          </PanelGroup>
          <Flex
            vertical
            style={{
              padding: "24px 16px",
              borderLeft: `1px solid ${theme.colorBorder}`,
            }}
            gap={50}
          >
            <Flex vertical gap={16}>
              {!hasPublishedMedia && (
                <>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    loading={isStoryboardApprovalLoading}
                    disabled={isStoryboardApprovalLoading}
                    onClick={() => setIsApprovalModalOpen(true)}
                  >
                    <Space>
                      <div
                        style={{
                          marginRight: 10,
                          marginTop: 5,
                          position: "relative",
                        }}
                      >
                        <VideoCamera size={24} weight="bold" />
                        <StarFour
                          weight="fill"
                          width={10}
                          height={10}
                          style={{
                            position: "absolute",
                            top: -2,
                            right: -10,
                          }}
                        />
                      </div>
                      {t("components.templates.index.generateMedia")}
                    </Space>
                  </Button>
                  <Modal
                    title={t(
                      "components.storyboard.StoryboardEditor.storyboardApproval"
                    )}
                    open={isApprovalModalOpen}
                    onOk={() => {
                      handleStoryboardApproval();
                      setIsApprovalModalOpen(false);
                    }}
                    onCancel={() => setIsApprovalModalOpen(false)}
                    okText={t("buttons.ok")}
                    cancelText={t("buttons.cancel")}
                    centered
                    closable={false}
                    okButtonProps={{ size: "large", shape: "round" }}
                    cancelButtonProps={{ size: "large", shape: "round" }}
                  >
                    <Flex vertical gap={0} justify="center" align="center">
                      <div
                        style={{
                          marginRight: 10,
                          marginTop: 5,
                          position: "relative",
                        }}
                      >
                        <VideoCamera
                          size={100}
                          weight="duotone"
                          color={theme.colorPrimary}
                        />
                        <StarFour
                          weight="fill"
                          size={30}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 100,
                          }}
                          color={theme.colorPrimary}
                        />
                      </div>
                      <Typography.Text style={{ textAlign: "center" }}>
                        {t(
                          "components.storyboard.StoryboardEditor.validation",
                          {
                            estimatedGenerationTime,
                          }
                        )}
                      </Typography.Text>
                      <Alert
                        description={t(
                          "components.storyboard.StoryboardEditor.warning"
                        )}
                        showIcon
                        type="warning"
                        style={{
                          marginTop: 30,
                          marginBottom: 10,
                          width: "100%",
                        }}
                      />
                    </Flex>
                  </Modal>
                </>
              )}

              <StyledDropdownButton>
                <Dropdown.Button
                  size="large"
                  icon={<DownOutlined />}
                  menu={{
                    items: copyScriptItems,
                    onClick: handleCopyScriptMenuClick,
                  }}
                  onClick={() =>
                    handleCopyScriptMenuClick({ key: "withTitles" })
                  }
                >
                  <CopyOutlined /> {t("storyboard.editor.Present.copyScript")}
                </Dropdown.Button>
              </StyledDropdownButton>
              <Button size="large" shape="round" onClick={handleGoToSettings}>
                {t("storyboard.editor.Present.changeSettings")}
              </Button>
            </Flex>
            <Flex vertical gap={16}>
              <Space size="middle">
                <TextAa size={25} weight="bold" opacity={0.5} />
                <Typography.Text strong>
                  {scriptsWordsCount}{" "}
                  {t("components.storyboard.StoryboardEditor.words")}
                </Typography.Text>
              </Space>
              <Space size="middle">
                <Timer size={25} weight="bold" opacity={0.5} />
                <Typography.Text strong>
                  {timePretty(readingTime)}
                </Typography.Text>
              </Space>
              {isMetricsEnabled && (
                <>
                  <Space size="middle">
                    <Speedometer size={25} weight="bold" opacity={0.5} />
                    <Typography.Text strong>
                      {t("storyboard.editor.StoryboardMetrics.score")}
                    </Typography.Text>
                    {!metrics && <Loading3QuartersOutlined spin />}
                  </Space>
                  <div style={{ marginTop: -5, marginLeft: 40 }}>
                    {metrics && <StoryboardMetrics metrics={metrics} />}
                  </div>
                </>
              )}
            </Flex>
          </Flex>
        </StyledPanelGroupWrapper>
      </Flex>
    </StoryboardProvider>
  );
};

const PdfViewerWrapperBase = (
  {
    url,
  }: {
    url: string;
  },
  ref: React.Ref<void>
) => {
  const { highlights, highlightedSource, handleClickHighlight } =
    useStoryboard();

  return (
    <PdfViewer
      ref={ref}
      url={url}
      highlights={highlights}
      activeHighlightId={highlightedSource}
      onHighlightClick={handleClickHighlight}
      scrollToHighlightYOffset={50}
    />
  );
};

// to fix eslint Component definition is missing display name  react/display-name
const PdfViewerWrapper = forwardRef(PdfViewerWrapperBase);

const StyledPanelResizeHandle = styled.div<{
  theme: GlobalToken;
}>`
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.colorBorder};
  margin-left: 1px;
  margin-right: 1px;
`;

const StyledPanelGroupWrapper = styled(Flex)<{
  theme: GlobalToken;
}>`
  div[data-resize-handle-active="keyboard"],
  div[data-resize-handle-active="pointer"] {
    width: 3px;
    margin: 0;
    background: ${({ theme }) => theme.colorPrimary};
    > div {
      background: none;
    }
  }
`;

const StyledDropdownButton = styled.div`
  .ant-btn {
    border-radius: 30px;
  }

  .ant-btn-compact-first-item {
    width: 100%;
  }
`;

function sourcesToHighlights({
  sources,
  ...section
}: {
  [key in keyof TDetailedSection]: TDetailedSection[key];
}): IHighlight[] {
  return sources.flatMap((source, sourceIndex) => {
    const id = `${section.id}-${sourceIndex}`;
    const occurrence = source.occurrences[0];
    return {
      id,
      position: {
        pageNumber: occurrence.page_number + 1,
        usePdfCoordinates: true,
        boundingRect: {
          ...positionToXY(occurrence.coordinates),
          width: 0,
          height: 0,
          pageNumber: occurrence.page_number + 1,
        },
        rects: source.occurrences.map((occurrence) => ({
          ...positionToXY(occurrence.coordinates),
          width: 0,
          height: 0,
          pageNumber: occurrence.page_number + 1,
        })),
      },
      content: {
        text: occurrence.chunk,
      },
      comment: {
        text: source.text,
        emoji: "",
      },
    };
  });
}

// interface HighlightListProps {
//   highlights: Array<IHighlight>;
//   resetHighlights: () => void;
//   onPick: (value: IHighlight) => void;
//   vertical?: boolean;
// }

// const updateHash = (highlight: IHighlight) => {
//   global.document.location.hash = `highlight-${highlight.id}`;
// };

// const HighlightList = ({
//   highlights,
//   resetHighlights,
//   onPick,
//   vertical,
// }: HighlightListProps) => {
//   return (
//     <Flex vertical={vertical === true}>
//       {highlights.map((highlight, index) => (
//         <StyledHighlight
//           size="small"
//           key={index}
//           // className="sidebar__highlight"
//           onClick={() => {
//             updateHash(highlight);
//           }}
//         >
//           <StyledHighlightText>
//             <Space size={"middle"}>
//               <Button
//                 size="small"
//                 icon={<PlusOutlined />}
//                 onClick={() => onPick(highlight)}
//               />
//               <Typography.Text className="highlight__location">
//                 Page {highlight.position.pageNumber}
//               </Typography.Text>
//             </Space>
//             {/* <strong>{highlight.comment.text}</strong> */}
//             {highlight.content.text ? (
//               <div style={{ marginTop: "0.5rem" }}>
//                 {`${highlight.content.text.slice(0, 90).trim()}…`}
//               </div>
//             ) : null}
//             {highlight.content.image ? (
//               <div className="highlight__image" style={{ marginTop: "0.5rem" }}>
//                 <img
//                   src={highlight.content.image}
//                   alt={"Screenshot"}
//                   style={{ maxWidth: 200, maxHeight: 150 }}
//                 />
//               </div>
//             ) : null}
//           </StyledHighlightText>
//         </StyledHighlight>
//       ))}
//     </Flex>
//   );
//   {
//   }
//   {
//   }
// };

// const HighlightPopup = ({
//   comment,
// }: {
//   comment: { text: string; emoji: string };
// }) =>
//   comment.text ? (
//     <div className="Highlight__popup">
//       {comment.emoji} {comment.text}
//     </div>
//   ) : null;

// const PdfDocumentViewer = ({
//   document,
//   highlights,
//   setHighlights,
// }: {
//   document: any;
//   highlights: IHighlight[];
//   setHighlights: Dispatch<SetStateAction<IHighlight[]>>;
// }) => {
//   const getHighlightById = (id: string) => {
//     return highlights.find((highlight) => highlight.id === id);
//   };

//   const getNextId = () => String(Math.random()).slice(2);

//   const addHighlight = (highlight: NewHighlight) => {
//     console.log("Saving highlight", highlight);

//     setHighlights((value) => [{ ...highlight, id: getNextId() }, ...value]);
//   };

//   const updateHighlight = (
//     highlightId: string,
//     position: Object,
//     content: Object
//   ) => {
//     console.log("Updating highlight", highlightId, position, content);

//     setHighlights((value) =>
//       value.map((h) => {
//         const {
//           id,
//           position: originalPosition,
//           content: originalContent,
//           ...rest
//         } = h;
//         return id === highlightId
//           ? {
//               id,
//               position: { ...originalPosition, ...position },
//               content: { ...originalContent, ...content },
//               ...rest,
//             }
//           : h;
//       })
//     );
//   };

//   let scrollViewerTo = (highlight: any) => {};

//   const parseIdFromHash = () =>
//     global.document.location.hash.slice("#highlight-".length);

//   const resetHash = () => {
//     global.document.location.hash = "";
//   };

//   const scrollToHighlightFromHash = () => {
//     const highlight = getHighlightById(parseIdFromHash());

//     if (highlight) {
//       scrollViewerTo(highlight);
//     }
//   };

//   return (
//     <PdfLoader url={document?.url} beforeLoad={<Spin />}>
//       {(pdfDocument) => (
//         <PdfHighlighter
//           pdfDocument={pdfDocument}
//           enableAreaSelection={(event) => event.altKey}
//           onScrollChange={resetHash}
//           // pdfScaleValue="page-width"
//           scrollRef={(scrollTo) => {
//             scrollViewerTo = scrollTo;

//             scrollToHighlightFromHash();
//           }}
//           onSelectionFinished={(
//             position,
//             content,
//             hideTipAndSelection,
//             transformSelection
//           ) => (
//             <Tip
//               onOpen={transformSelection}
//               onConfirm={(comment) => {
//                 addHighlight({ content, position, comment });

//                 hideTipAndSelection();
//               }}
//             />
//           )}
//           highlightTransform={(
//             highlight,
//             index,
//             setTip,
//             hideTip,
//             viewportToScaled,
//             screenshot,
//             isScrolledTo
//           ) => {
//             const isTextHighlight = !Boolean(
//               highlight.content && highlight.content.image
//             );

//             const component = isTextHighlight ? (
//               <Highlight
//                 isScrolledTo={isScrolledTo}
//                 position={highlight.position}
//                 comment={highlight.comment}
//               />
//             ) : (
//               <AreaHighlight
//                 isScrolledTo={isScrolledTo}
//                 highlight={highlight}
//                 onChange={(boundingRect) => {
//                   updateHighlight(
//                     highlight.id,
//                     {
//                       boundingRect: viewportToScaled(boundingRect),
//                     },
//                     { image: screenshot(boundingRect) }
//                   );
//                 }}
//               />
//             );

//             return (
//               <Popup
//                 popupContent={<HighlightPopup {...highlight} />}
//                 onMouseOver={(popupContent) =>
//                   setTip(highlight, (highlight) => popupContent)
//                 }
//                 onMouseOut={hideTip}
//                 key={index}
//                 children={component}
//               />
//             );
//           }}
//           highlights={highlights}
//         />
//       )}
//     </PdfLoader>
//   );
// };
