import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React, { useState, useEffect, useRef } from "react";
import { Card } from "antd";
import { CSSProperties } from "react";
import { CardProps } from "antd/lib";
import { CascadingText } from "components/CascadingText";

interface FontPreviewCardProps extends CardProps {
  font: string | File;
  title: string;
  style?: CSSProperties;
}

export const FontPreviewText = ({
  font,
  text,
  style,
  animated,
}: {
  font: string | File;
  text?: string;
  style?: CSSProperties;
  animated?: boolean;
}) => {
  const [fontUrl, setFontUrl] = useState<string>("");
  const [fontLoaded, setFontLoaded] = useState(false);
  const fontPreviewName = useRef(Math.floor(Math.random() * 1000));
  useEffect(() => {
    if (font instanceof File) {
      const url = URL.createObjectURL(font);
      setFontUrl(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setFontUrl(font);
    }
  }, [font]);

  useEffect(() => {
    if (fontUrl) {
      const newFont = new FontFace(
        "PreviewFont-" + fontPreviewName.current,
        `url(${fontUrl})`
      );
      newFont
        .load()
        .then((loadedFont) => {
          document.fonts.add(loadedFont);
          setFontLoaded(true);
        })
        .catch((error) => {
          console.error("Font loading failed:", error);
        });
    }
  }, [fontUrl]);

  const fontFace = `
      @font-face {
        font-family: 'PreviewFont-${fontPreviewName.current}';
        src: url('${fontUrl}') format('opentype'), url('${fontUrl}') format('truetype');
        font-display: swap;
      }
    `;

  const previewStyle: CSSProperties = {
    fontFamily: fontLoaded
      ? `PreviewFont-${fontPreviewName.current}, sans-serif`
      : "sans-serif",
    fontSize: "48px",
    textAlign: "center",
    padding: "20px",
  };

  return (
    // div necessary to add child style
    <div>
      <style type="text/css">{fontFace}</style>
      {animated && (
        <div style={{ ...previewStyle, ...style }}>
          <CascadingText style={{ ...previewStyle, ...style }}>
            {text ?? "Aa"}
          </CascadingText>
        </div>
      )}
      {!animated && (
        <div style={{ ...previewStyle, ...style }}>{text ?? "Aa"}</div>
      )}
    </div>
  );
};

export const FontPreviewCard: React.FC<FontPreviewCardProps> = ({
  font,
  title,
  actions,
  style,
}) => {
  const t = useTranslate();
  return (
    <Card
      size="small"
      style={{ ...style, width: 200 }}
      styles={{
        actions: { padding: 10 },
      }}
      cover={
        <FontPreviewText
          text={t("brandkit.components.FontPreview.aa")}
          font={font}
        />
      }
      actions={actions}
    >
      <Card.Meta description={title} />
    </Card>
  );
};
