import { UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Flex,
  Popconfirm,
  Spin,
  Space,
  Badge,
  Typography,
  ConfigProvider,
  Slider,
} from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTemplates } from "./hooks/useTemplates";
import { useOne, useTranslate } from "@refinedev/core";
import { BodyView, TabKeys, TemplateType } from "./types";
import { IntroductionClosingPreview } from "./IntroductionClosingPreview";
import { TransitionPreview } from "./TransitionPreview";
import { ChapterPreview } from "./ChapterPreview";
import { MediaAsset, TSection } from "pages/media/types";

import {
  DEFAULT_TEXT_COLOR,
  useGetTemplateAssets,
} from "./hooks/useGetTemplateAssets";
import { useVolatileBoundStore } from "store";
import { DraggableComponent } from "./DraggableComponent";
import { FadeInFadeOutWrapper } from "./FadeInFadeOutWrapper";
import styled from "styled-components";
import { Pause, Play } from "@phosphor-icons/react";
import dayjs, { duration } from "dayjs";
import { useAntTheme } from "hooks/useAntTheme";
dayjs.extend(duration);

interface PreviewProps {
  projectId: string;
  handleApplyToAll: () => void;
  isSavingTemplate: boolean;
}
export const PREVIEW_ADJUSTMENT = 1.4;
export const DEFAULT_WIDTH = 480 / PREVIEW_ADJUSTMENT; // default template width from backend
export const DEFAULT_HEIGHT = 848 / PREVIEW_ADJUSTMENT; // default template height from backend

export const TemplatePreview: React.FC<PreviewProps> = ({
  projectId,
  handleApplyToAll,
  isSavingTemplate,
}) => {
  const {
    audio,
    logo,
    intro,
    closing,
    banner,
    subtitlesFont,
    brandKitPalette,
    organizationTemplates,
    background: brandKitBackground,
    transition,
    titleFont,
    mediaSettings,
    presenter: initialPresenter,
    contact,
    hasTransition,
    storyboardPath,

    publicFonts,
  } = useTemplates({
    projectId,
  });

  const [storyboardSections, setStoryboardSections] = useState<TSection[]>([]);

  useEffect(() => {
    if (!storyboardPath) return;

    const loadStoryboard = async () => {
      try {
        if (storyboardPath) {
          const response = await fetch(storyboardPath);
          const json = await response.json();

          setStoryboardSections(json?.sections);
        }
      } catch (error) {
        console.error("Issue while loading JSON:", error);
      }
    };

    loadStoryboard();
  }, [storyboardPath]);

  const {
    view,
    images,
    updateImage,
    background,
    setCurrentTemplateId,
    currentChapter,
    setCurrentItem,
    setTextStyle,
    currentTemplateId,
    addImage,
    setBackground,
    setPresenter,
    setTitleStyle,
    resetUpdates,
    setIntro,
    storeIntro,
    setTransition,
    setClosing,
    storeClosing,
    storeTransition,
    textStyle,
    titleStyle,
    setActiveTab,
  } = useVolatileBoundStore((store) => ({
    images: store.templateState.images,
    background: store.templateState.background,
    setCurrentTemplateId: store.setCurrentTemplateId,
    updateImage: store.updateImage,
    view: store.templateState.view,
    setCurrentItem: store.setCurrentItem,
    setTextStyle: store.setTextStyle,
    currentChapter: store.templateState.currentChapter,
    currentTemplateId: store.templateState.currentTemplateId,
    addImage: store.addImage,
    setBackground: store.setBackground,
    setPresenter: store.setPresenter,
    setTitleStyle: store.setTitleStyle,
    resetUpdates: store.resetUpdates,
    setIntro: store.setIntro,
    storeIntro: store.templateState.intro,
    setTransition: store.setTransition,
    setClosing: store.setClosing,
    storeClosing: store.templateState.closing,
    storeTransition: store.templateState.transition,
    textStyle: store.templateState.textStyle,
    titleStyle: store.templateState.titleStyle,
    setActiveTab: store.setActiveTab,
  }));

  const t = useTranslate();
  const { theme } = useAntTheme();

  const { templateData, resetTemplate, isDataLoading } = useGetTemplateAssets({
    projectId,
    templateId: currentTemplateId,
  });
  let hasFont = true;
  if (view === TemplateType.CHAPTER) {
    hasFont = !!textStyle.fontAssetId;
  } else if (view === TemplateType.TRANSITION) {
    hasFont = !!titleStyle.fontAssetId;
  }

  const video = useRef<HTMLVideoElement>(null);

  //PLayer functions
  const [isPlaying, setIsPlaying] = useState(false);
  const player = useRef<HTMLAudioElement>(null);
  const formatTime = (seconds: number) => {
    const duration = dayjs.duration(seconds, "seconds");
    const minutes = duration.minutes();
    const secs = duration.seconds();
    const formattedSecs = secs < 10 ? `0${secs}` : secs;
    return `${minutes}:${formattedSecs}`;
  };
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const handlePlayPause = () => {
    if (isPlaying) {
      video.current?.pause?.();
      player.current?.pause?.();
      setIsPlaying(false);
    } else {
      video.current?.play?.();
      player.current?.play?.();
      setIsPlaying(true);
    }
  };

  const settingName = view.toLocaleLowerCase();
  const commonTemplate = mediaSettings?.[`${settingName}_template_id`];
  const templateId = organizationTemplates?.find(
    (template) => template.name === currentChapter
  )?.id;

  const id = templateId ?? commonTemplate;

  //set templateId on first load to get the current template if chapter has one
  if (!currentTemplateId) {
    setCurrentTemplateId(id);
  }

  const textStyleObject = useMemo(
    () => ({
      textFont: subtitlesFont?.path ?? publicFonts?.[0]?.path,
      textBackground: undefined,
      subtitles_color: brandKitPalette?.subtitle_color ?? DEFAULT_TEXT_COLOR,
      subtitles_stroke_color: undefined,
      fontAssetId: subtitlesFont?.asset_id ?? publicFonts?.[0]?.id,
    }),
    [brandKitPalette?.subtitle_color, publicFonts, subtitlesFont]
  );

  const titleStyleObject = useMemo(
    () => ({
      titleFont: titleFont?.path ?? publicFonts?.[0]?.path,
      title_color: brandKitPalette?.title_color ?? DEFAULT_TEXT_COLOR,
      title_stroke_color: undefined,
      fontAssetId: titleFont?.asset_id ?? publicFonts?.[0]?.id,
    }),
    [brandKitPalette?.title_color, publicFonts, titleFont]
  );

  const bodyView: BodyView =
    (brandKitBackground?.type as "Picture" | "Video") ?? "Color";
  const headerView: "Picture" | "Color" = banner ? "Picture" : "Color";

  const backgroundObject = useMemo(() => {
    if (!brandKitPalette) return;
    return {
      bodyView,
      bodyImage: brandKitBackground?.path ?? undefined,
      bodyImageAsset: brandKitBackground?.asset_id ?? undefined,
      bodyColor: brandKitPalette?.primary_color,
      headerColor: brandKitPalette?.secondary_color || "#ffffff",
      headerView,
      headerImage: banner?.path,
      headerImageAsset: banner?.asset_id,
    };
  }, [banner, bodyView, headerView, brandKitBackground, brandKitPalette]);

  const presenterObject = useMemo(() => {
    if (!initialPresenter || !contact) return;
    return {
      avatarCircle: false,
      position: { x: 50, y: 330 },
      size: { width: 0.55, height: 0.31 },
      presenter: initialPresenter,
      contact_id: contact?.id,
    };
  }, [contact, initialPresenter]);

  const introObject = useMemo(() => {
    if (!intro) return;
    return {
      // if user turned on intro from other place
      enable: storeIntro.enable ? storeIntro.enable : !!intro,
      assetPath: intro?.path,
      assetId: intro?.asset_id,
    };
  }, [intro, storeIntro.enable]);

  const closingObject = useMemo(() => {
    if (!closing) return;
    return {
      // if user turned on intro from other place
      enable: storeClosing.enable ? storeClosing.enable : !!closing,
      assetPath: closing?.path,
      assetId: closing?.asset_id,
    };
  }, [closing, storeClosing.enable]);

  const transitionView: BodyView = transition?.path ? "Video" : "Color";
  const transitionObject = useMemo(() => {
    if (!transition) return;
    return {
      enable: storeTransition.enable ? storeTransition.enable : !!transition,
      assetPath: transition?.path,
      assetId: transition?.asset_id,
      view: transitionView,
    };
  }, [transition, transitionView, storeTransition.enable]);

  const handleResetTemplate = useCallback(() => {
    if (view === TemplateType.CHAPTER) {
      // Add logo to image array only on first load when images[] is empty
      if (logo && !images.length) {
        addImage({
          assetId: logo.asset_id,
        });
      }

      setTextStyle(textStyleObject);

      if (backgroundObject && presenterObject) {
        setBackground(backgroundObject);
      }

      if (presenterObject) {
        setPresenter(presenterObject);
      }
    }

    if (view === TemplateType.INTRODUCTION && introObject) {
      setIntro(introObject);
    }

    if (view === TemplateType.CLOSING && closingObject) {
      setClosing(closingObject);
    }

    if (view === TemplateType.TRANSITION) {
      setTitleStyle(titleStyleObject);
      
      if (transitionObject) {
        setTransition(transitionObject);
      }
    }
  }, [
    addImage,
    backgroundObject,
    closingObject,
    images.length,
    introObject,
    logo,
    presenterObject,
    setBackground,
    setClosing,
    setIntro,
    setPresenter,
    setTextStyle,
    setTitleStyle,
    setTransition,
    textStyleObject,
    titleStyleObject,
    transitionObject,
    view,
  ]);

  useEffect(() => {
    resetUpdates();
  }, [resetUpdates]);

  useEffect(() => {
    if (currentTemplateId && templateData) {
      resetTemplate();
    }
  }, [currentTemplateId, templateData, resetTemplate]);

  useEffect(() => {
    if (!currentTemplateId && !templateData) {
      handleResetTemplate();
    }
  }, [currentTemplateId, templateData, handleResetTemplate]);

  useEffect(() => {
    setTransition({
      enable: hasTransition,
    });
  }, [hasTransition, setTransition]);

  useEffect(() => {
    setIntro({
      enable: !!mediaSettings?.introduction_template_id,
    });
  }, [mediaSettings?.introduction_template_id, setIntro]);

  useEffect(() => {
    setClosing({
      enable: !!mediaSettings?.closing_template_id,
    });
  }, [mediaSettings?.closing_template_id, setClosing]);

  let backgroundImage = "unset";

  if (view === TemplateType.CHAPTER && background.bodyView === "Picture") {
    backgroundImage = `url(${background?.bodyImage})`;
  } else if (
    view === TemplateType.TRANSITION &&
    storeTransition.view === "Picture"
  ) {
    backgroundImage = `url(${storeTransition.assetId})`;
  }

  return (
    <Flex style={{ padding: 24 }} vertical gap={20}>
      <Flex style={{ width: "100%" }} justify="space-between" gap={10}>
        <Flex style={{ width: "70%" }} gap={20}>
          {(view === TemplateType.CHAPTER ||
            view === TemplateType.TRANSITION) && (
            <Popconfirm
              title={t("components.templates.templatePreview.applyToAll", {
                view,
              })}
              onConfirm={handleApplyToAll}
            >
              <Button disabled={!currentTemplateId}>
                {t("components.templates.templatePreview.saveAll", { view })}
              </Button>
            </Popconfirm>
          )}
          {hasFont ? (
            <Flex
              justify="start"
              style={{
                opacity: isSavingTemplate ? 1 : 0,
                transition: "opacity 1s ease-in-out",
              }}
            >
              <Button
                type="text"
                size="small"
                disabled={true}
                loading={isSavingTemplate}
              >
                {t("components.templates.index.savingChanges")}
              </Button>
            </Flex>
          ) : (
            <Space align="start">
              <Badge status="error" />
              <Flex vertical>
                <Typography.Text>
                  {t("components.templates.index.cantSaveChanges")}
                </Typography.Text>
                <Typography.Text>
                  {t("components.templates.index.chooseFont")}
                </Typography.Text>
              </Flex>
            </Space>
          )}
        </Flex>
        <Popconfirm
          title={t("buttons.confirm")}
          onConfirm={handleResetTemplate}
        >
          <Button icon={<UndoOutlined />} type="text">
            {t("media.projects.index.reset")}
          </Button>
        </Popconfirm>
      </Flex>
      <Flex align="center" justify="center" style={{ height: "100%" }} vertical>
        <Flex
          justify="center"
          align="center"
          vertical
          style={{
            height: 80,
            width: "50%",
          }}
          gap={2}
        >
          <Flex
            align="center"
            style={{ width: "100%", color: "#000", position: "relative" }}
            gap={5}
          >
            {audio && (
              <audio
                ref={player}
                src={audio.path}
                onTimeUpdate={() => {
                  if (player?.current) {
                    if (player.current.currentTime === duration) {
                      setCurrentTime(0);
                      setIsPlaying(false);
                    } else {
                      setCurrentTime(player.current.currentTime);
                    }
                  }
                }}
                onLoadedMetadata={() =>
                  setDuration(player?.current?.duration ?? 0)
                }
              >
                <track kind="captions" src="" />
              </audio>
            )}
            <Space style={{ paddingLeft: 24 }}>
              <Typography.Text>
                {formatTime(currentTime)} / {formatTime(duration)}
              </Typography.Text>
              <Button
                type="text"
                onClick={handlePlayPause}
                icon={
                  isPlaying ? (
                    <Pause size={24} weight="fill" />
                  ) : (
                    <Play size={24} weight="fill" />
                  )
                }
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </Space>
          </Flex>
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  railBg: theme.colorBorder,
                },
              },
            }}
          >
            <div style={{ width: "90%" }}>
              <Slider
                value={currentTime}
                onChange={(value: number) => {
                  if (player?.current) {
                    setCurrentTime(value);
                    player.current.currentTime = value;
                  }
                }}
                max={duration}
                tooltip={{ open: false }}
                styles={{
                  tracks: {
                    background: "#fff",
                  },
                }}
              />
            </div>
          </ConfigProvider>
        </Flex>
        <StyledWrapper
          style={{
            border: "20px solid black",
            borderRadius: 43,
            position: "relative",
            boxSizing: "border-box",
            overflow: "hidden",
          }}
        >
          <Spin spinning={isDataLoading} wrapperClassName="spin-wrapper">
            {images.length > 0 &&
              images.map((image) => (
                <DraggableComponent
                  key={image.id}
                  position={{ x: image.position.x, y: image.position.y }}
                  size={{
                    width: image.size.width * DEFAULT_WIDTH,
                    height: "auto",
                  }}
                  onDragStop={(params) => updateImage(params)}
                  saveRatio={true}
                  drag={true}
                  resize={true}
                  id={image.id}
                  onFocus={() => {
                    setCurrentItem({ type: "Image", id: image.id });
                    setActiveTab(TabKeys.images);
                  }}
                >
                  <FadeInFadeOutWrapper
                    fadeIn={image?.fadeIn}
                    fadeOut={image?.fadeOut}
                  >
                    <ImagePreview assetId={image.assetId} path={image?.path} />
                  </FadeInFadeOutWrapper>
                </DraggableComponent>
              ))}
            <div
              style={{
                width: DEFAULT_WIDTH,
                height: DEFAULT_HEIGHT,
                backgroundColor: background.bodyColor,
                backgroundImage,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              {(view === TemplateType.INTRODUCTION ||
                view === TemplateType.CLOSING) && (
                <IntroductionClosingPreview ref={video} />
              )}
              {view === TemplateType.TRANSITION && (
                <TransitionPreview ref={video} />
              )}
              {view === TemplateType.CHAPTER && (
                <ChapterPreview
                  ref={video}
                  text={
                    storyboardSections.find(
                      (section) => section?.topic === currentChapter
                    )?.script?.content
                  }
                />
              )}
            </div>
          </Spin>
        </StyledWrapper>
      </Flex>
    </Flex>
  );
};

const ImagePreview = ({ assetId, path }: { assetId: string; path: string }) => {
  const { data: imageAsset, isLoading } = useOne<MediaAsset>({
    resource: `media/media/assets`,
    id: assetId,
  });

  if (isLoading && !path) return null;
  return (
    <img
      src={imageAsset?.data?.path ?? path}
      draggable="false"
      style={{
        objectFit: "fill",
        width: "100%",
        height: "100%",
      }}
      alt="image"
    />
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  max-width: fit-content;
  height: 100%;
  & .spin-wrapper,
  & .ant-spin-container {
    height: 100%;
  }
`;
