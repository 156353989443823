import { t } from "i18next";
import { useGo, useList } from "@refinedev/core";
import { Result } from "antd";
import { useBoundStore } from "store";
import styled, { keyframes } from "styled-components";
import { Confetti } from "@phosphor-icons/react";
import { useAntTheme } from "hooks/useAntTheme";

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const AnimatedButton = styled.button`
  background: linear-gradient(270deg, #ff6ec4, #7873f5, #4ade80);
  background-size: 600% 600%;
  animation: ${gradientAnimation} 16s ease infinite;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 0px 30px;
  font-size: 20px;
  line-height: 50px;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
  }
`;

const Finish = () => {
  // const { show } = useNavigation();
  const { theme } = useAntTheme();
  const go = useGo();
  const { data: projects, isLoading: isLoadingProjects } = useList({
    resource: "/media/projects",
  });
  function handleClick() {
    if (isLoadingProjects || !projects?.data) return;
    const sortedProjects = projects?.data?.slice();
    sortedProjects?.sort((a, b) => {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });
    const newestProject = sortedProjects?.[0];
    // show("media_projects", newestProject.id);
    if (!newestProject) return;
    go({
      to: `/media/projects/${newestProject.id}/storyboard/loading`,
    });
  }

  useBoundStore((state) => {
    return {
      onboardingState: state.onboardingState,
      resetOnboardingState: state.resetOnboardingState,
    };
  });
  return (
    <Result
      style={{ paddingTop: "25vh" }}
      icon={<Confetti size={100} color={theme.colorPrimary} />}
      title={t("pages.onboarding.titles.finish")}
      extra={
        <AnimatedButton onClick={handleClick}>
          {t("pages.onboarding.buttons.finish")}
        </AnimatedButton>
      }
      status={"success"}
    />
  );
};

export default Finish;
