import { Flex } from "antd";
import { ProjectMediaResponse } from "pages/media/types";

export const AnimationVideo = ({ media }: { media: ProjectMediaResponse }) => {
  if (!media) return null;

  return (
    <Flex vertical align="center" style={{ marginTop: 30 }}>
      <video src={media?.url} controls={false} width="300px" height="auto">
        <source src={media?.url} type="video/mp4" />
        Your browser does not support the video tag, and captions are not
        available.
      </video>
    </Flex>
  );
};
