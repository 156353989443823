import { useTranslate } from "@refinedev/core";
import { Waveform, Sparkle } from "@phosphor-icons/react";
import { List, Typography, Flex, Button, GlobalToken } from "antd";
import { VoiceProfileResponse } from "pages/media/types";
import { useState } from "react";
import useVoiceSelector from "./hooks/useVoiceSelector";
import VoiceAndFaceSelector from "./VoiceAndFaceSelector";
import VoiceItem from "./VoiceItem";
import styled from "styled-components";
import { VoiceAndFaceContainerProps } from "./types";

export const VoiceSelector = (props: VoiceAndFaceContainerProps) => {
  const t = useTranslate();
  const { items, isLoading } = useVoiceSelector(props.selectedVoice);
  const [currentlyPlayedVoice, setCurrentlyPlayedVoice] =
    useState<VoiceProfileResponse>();

  const getItems = (items: VoiceProfileResponse[]) => (
    <StyledListWrapper
      style={{ height: "100%", overflow: "auto", paddingRight: 7 }}
    >
      <List<VoiceProfileResponse>
        loading={isLoading}
        renderItem={(voice) => (
          <VoiceItem
            key={voice.id}
            id={voice.id}
            voice={voice}
            isSelected={
              voice.id ===
              (props.selectedVoice?.id ?? props.cloneVoiceResponse?.id)
            }
            onClick={() => {
              props.setSelectedVoice(voice);
            }}
            currentlyPlayedVoice={currentlyPlayedVoice}
            onClickPlay={(voice) => {
              setCurrentlyPlayedVoice(voice);
            }}
          >
            <Text style={{ lineHeight: 1.1 }}>{voice.name}</Text>
          </VoiceItem>
        )}
        dataSource={items}
      />
    </StyledListWrapper>
  );
  const { Title, Text } = Typography;
  const isCustomVoice =
    props.cloneVoiceResponse ||
    !["Premade", "Professional"].includes(
      props.selectedVoice?.category ?? "Premade"
    );

  return (
    <Flex vertical style={props.style}>
      <Flex
        gap={5}
        style={{ marginBottom: 54 }}
        justify="flex-start"
        align="center"
      >
        <span className="anticon">
          <Waveform size="32" />
        </span>
        <Title style={{ margin: 0 }} level={3}>
          {t("components.ContactWithPresenter.VoiceSelector.voice")}
        </Title>
      </Flex>
      <VoiceAndFaceSelector
        activeKey={isCustomVoice ? "2" : "1"}
        premade={getItems(items.premadeVoices)}
        custom={getItems(items.customVoices)}
      />
      <Button
        style={{ margin: "20px auto", width: "max-content" }}
        icon={
          <span className="anticon">
            <Sparkle />
          </span>
        }
        shape="round"
        onClick={() => {
          props.setCurrentModal("voice");
        }}
      >
        {t("components.ContactWithPresenter.VoiceSelector.cloneANew")}
      </Button>
    </Flex>
  );
};

export default VoiceSelector;
const StyledListWrapper = styled.div<{
  theme: GlobalToken;
}>`
  .ant-list-item {
    border: 2px solid transparent;
    border-radius: 5px;
    box-sizing: border-box;
    .ant-list-item-meta-description {
      padding-right: 5px;
    }
    &:hover {
      cursor: pointer;
      transition: 500ms;
      background-color: ${(props) => props.theme.colorBgElevated};
    }
    &.active {
      border-block-end: 2px solid ${(props) => props.theme.colorPrimary};
      border-color: ${(props) => props.theme.colorPrimary};
      background-color: ${(props) => props.theme.colorPrimaryBg};
    }
  }
`;
