import { useGetLocale, useTranslate } from "@refinedev/core";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Typography, Form, Flex, message } from "antd";
import { useStepsForm } from "@refinedev/antd";
import styled from "styled-components";
import { useAntTheme } from "hooks/useAntTheme";
import { authProvider } from "authProvider";
import { RuleObject } from "antd/es/form";
import { useGo } from "@refinedev/core";
import { ArrowSquareUpRight } from "@phosphor-icons/react";
import { AppLogo } from "components/AppLogo";
import { AppContext } from "appContext";
import { isDocustream } from "helpers/utils";

const { Title, Text, Link } = Typography;

const emptyRequiredMark = (label: React.ReactNode) => <>{label}</>;

type FormValues = {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  confirmPassword: string;
};
const termsOfServiceLinks = isDocustream()
  ? {
      fr: "https://cdn.libertify.com/env/legal/fr/Docustream_Media_Platform_CGV_FR_20241015.pdf",
      en: "https://cdn.libertify.com/env/legal/en/Docustream_Media_Platform_TOS_EN_20241022.pdf",
    }
  : {
      fr: "https://cdn.libertify.com/env/legal/fr/Libertify_Media_Platform_CGV_FR_20241022.pdf",
      en: "https://cdn.libertify.com/env/legal/en/Libertify_Media_Platform_TOS_EN_20241022.pdf",
    };
const privacyPolicyLinks = isDocustream()
  ? {
      fr: "https://cdn.libertify.com/env/legal/fr/Docustream_Protection_des+donne%CC%81es_RGPD_20241108.pdf",
      en: "https://cdn.libertify.com/env/legal/en/Docustream_Data_protection+policy_Article28_GDPR_20241122.pdf",
    }
  : {
      fr: "https://cdn.libertify.com/env/legal/fr/Libertify_Protection_des+donne%CC%81es_RGPD_20241108.pdf",
      en: "https://cdn.libertify.com/env/legal/en/Libertify_Data_protection+policy_Article28_GDPR_20241122.pdf",
    };

const REGEX_UPPER_CASE = /[A-Z]+/;
const REGEX_LOWER_CASE = /[a-z]+/;
const REGEX_DIGIT = /[0-9]+/;

const validatePassword = async (_: RuleObject, value: string) => {
  if (value.length < 8) {
    return Promise.reject(
      new Error(t("pages.auth.new-register.passwordMustBe"))
    );
  }

  let countPasswordClass = 0;
  if (REGEX_UPPER_CASE.test(value)) countPasswordClass++;
  if (REGEX_LOWER_CASE.test(value)) countPasswordClass++;
  if (REGEX_DIGIT.test(value)) countPasswordClass++;

  if (countPasswordClass < 3) {
    return Promise.reject(
      new Error(t("pages.auth.new-register.passwordMustContain"))
    );
  }
  return Promise.resolve();
};
export const RegisterPage: React.FC = () => {
  const t = useTranslate();
  const { theme } = useAntTheme();
  const go = useGo();
  const [busy, setBusy] = useState(false);
  const { dispatch: appDispatch } = useContext(AppContext);

  const locale = useGetLocale();
  const lang = locale()?.split("-")[0];
  const termsOfServiceLink = termsOfServiceLinks[lang ?? "en"];
  const privacyPolicyLink = privacyPolicyLinks[lang ?? "en"];

  const { current, gotoStep, formProps, submit, form } =
    useStepsForm<FormValues>({
      async submit(values) {
        try {
          setBusy(true);
          const { confirmPassword, ...authValues } = values;
          const response = await authProvider.register?.(authValues);
          if (response?.success && response?.redirectTo) {
            go({ to: response?.redirectTo });
          } else if (response?.error) {
            message.error(response.error.message, 7);
          }
        } finally {
          setBusy(false);
        }
        return true;
      },
    });

  const validateConfirmPassword = async (_: RuleObject, value: string) => {
    if (value !== form.getFieldValue("password")) {
      return Promise.reject(
        new Error(t("pages.auth.new-register.passwordsDoNot"))
      );
    }
    return Promise.resolve();
  };

  useEffect(() => {
    appDispatch({ type: "setSidebarCollapsed", payload: true });

    return () => {
      appDispatch({ type: "setSidebarCollapsed", payload: false });
    };
  }, [appDispatch]);

  const formList = [
    <Flex vertical key="step1">
      <Typography.Text style={{ display: "block", textAlign: "center" }}>
        <AppLogo height={40} style={{ marginBottom: 50 }} />
      </Typography.Text>
      <Title level={2} style={{ marginBottom: 40 }}>
        {t("pages.auth.new-register.welcomeToLibertify")}
      </Title>
      {/* <Text type="secondary" style={{ marginBottom: 40 }}>
                    Use your work email for a better experience
                  </Text> */}
      <Form.Item
        label={t("pages.auth.new-register.workEmail")}
        name="email"
        rules={[
          {
            required: true,
            message: t("pages.auth.new-register.pleaseEnterYourEmail"),
          },
          {
            type: "email",
            message: t("pages.auth.new-register.pleaseEnterAValid"),
          },
        ]}
      >
        <Input
          size="large"
          type="email"
          placeholder={t("pages.auth.new-register.youYourcompanyCom")}
        />
      </Form.Item>
      <Form.Item>
        <Button
          size="large"
          htmlType="submit"
          type="primary"
          block
          onClick={async () => {
            try {
              await form.validateFields(["email"]);
              gotoStep(1);
            } catch {
              return;
            }
          }}
        >
          {t("pages.auth.new-register.createAccount")}
        </Button>
      </Form.Item>
    </Flex>,
    <Flex vertical key={"step2"}>
      <Title level={2} style={{ marginBottom: 0 }}>
        {t("pages.auth.new-register.welcomeToLibertify")}
      </Title>
      <Text type="secondary" style={{ marginBottom: 40 }}>
        {t("pages.auth.new-register.youAreSetting")}{" "}
        <strong>{form.getFieldValue("email")}</strong>
      </Text>
      <Form.Item
        label={t("pages.auth.new-register.firstName")}
        name="firstname"
        rules={[
          {
            required: true,
            message: t("pages.auth.new-register.pleaseEnterYourFirst"),
          },
        ]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        label={t("pages.auth.new-register.lastName")}
        name="lastname"
        rules={[
          {
            required: true,
            message: t("pages.auth.new-register.pleaseEnterYourLast"),
          },
        ]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        label={t("pages.auth.new-register.password")}
        name="password"
        validateFirst
        rules={[
          {
            required: true,
            message: t("pages.auth.new-register.pleaseEnterAPassword"),
          },
          { validator: validatePassword },
        ]}
      >
        <Input.Password
          size="large"
          placeholder={t("pages.auth.new-register.createAPassword")}
        />
      </Form.Item>
      <Form.Item
        label={t("pages.auth.new-register.confirmPassword")}
        name="confirmPassword"
        validateFirst
        rules={[
          {
            required: true,
            message: t("pages.auth.new-register.pleaseConfirmYour"),
          },
          { validator: validateConfirmPassword },
        ]}
      >
        <Input.Password
          size="large"
          placeholder={t("pages.auth.new-register.confirmYourPassword")}
        />
      </Form.Item>
      <Form.Item>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          block
          loading={busy}
          disabled={busy}
          onClick={async () => {
            try {
              await form.validateFields();
              submit();
            } catch {
              return false;
            }
          }}
        >
          {t("pages.auth.new-register.createAccount")}
        </Button>
      </Form.Item>
    </Flex>,
  ];

  return (
    <Flex justify="space-between" style={{ marginLeft: -theme.paddingLG }}>
      <Flex
        vertical
        style={{
          maxWidth: 500,
          paddingTop: 100,
          margin: "0 auto",
        }}
      >
        <Form {...formProps} layout="vertical" requiredMark={emptyRequiredMark}>
          {formList[current]}
        </Form>
        <Text type="secondary">
          {t("pages.auth.new-register.bySigningUp")}{" "}
          <Link href={termsOfServiceLink} target="_blank">
            {t("pages.auth.new-register.termsOfService")}
          </Link>
          <Button
            type="text"
            target="_blank"
            size="middle"
            icon={
              <span className="anticon">
                <ArrowSquareUpRight />
              </span>
            }
            href={termsOfServiceLink}
          ></Button>
          {t("pages.auth.new-register.and")}{" "}
          <Link href={privacyPolicyLink} target="_blank">
            {t("pages.auth.new-register.privacyPolicy")}
          </Link>{" "}
          <Button
            type="text"
            target="_blank"
            size="middle"
            icon={
              <span className="anticon">
                <ArrowSquareUpRight />
              </span>
            }
            href={privacyPolicyLink}
          ></Button>
        </Text>
      </Flex>
      <StyledSide
        vertical
        align="center"
        justify="center"
        style={{
          margin: -theme.paddingLG, //24
          width: "24vw",
          minWidth: "40vw",
          maxWidth: 600,
          padding: 100,
          // paddingTop: 100,
          textAlign: "center",
          height: "100%",
          minHeight: "100vh",
          background: `linear-gradient(to bottom, ${theme.colorPrimary} 50%, #000000 100%)`,
        }}
        gap={30}
      >
        <Flex vertical style={{ color: "#fff", height: "100%" }}>
          {/* <AiReadyMediaBrain /> */}
          <Typography.Text
            style={{
              fontSize: 40,
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            {t("pages.auth.new-register.aiReadyMedia")}
          </Typography.Text>
        </Flex>

        <Typography.Text
          style={{
            fontSize: 40,
            fontWeight: "normal",
            color: "#fff",
            opacity: 0.8,
            lineHeight: 1.3,
          }}
        >
          {t("pages.auth.new-register.weBringBack")}
        </Typography.Text>
      </StyledSide>
    </Flex>
  );
};

const StyledSide = styled(Flex)`
  position: relative;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-image: url("https://images.unsplash.com/photo-1689267305146-155a1459752c?q=80&w=3840&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-position: center;
  }
  > * {
    z-index: 1;
  }
`;
