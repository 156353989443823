import { MEDIAPLAYER_URL } from "../../constants";
import { getLanguageVariant } from "helpers/utils";
import { countries } from "countries-list";

export const getStatusColor = (status?: string) => {
  switch (status) {
    case "Error":
      return "error";
    case "Generating":
      return "processing";
    case "Cancelled":
      return "warning";
    case "Ready":
      return "success";
    default:
      return "default";
  }
};

// https://stackoverflow.com/questions/1322732/convert-seconds-to-hh-mm-ss-with-javascript
export const secToHHMMSS = (sec: number) => {
  const min = Math.floor(sec / 60);
  const secs = sec % 60;
  return (min < 10 ? "0" : "") + min + ":" + (secs < 10 ? "0" : "") + secs;
};

export function countWords(str: string) {
  return str.trim().split(/\s+/).length;
}

// https://stackoverflow.com/questions/1322732/convert-seconds-to-hh-mm-ss-with-javascript
export const toHHMMSS = (secs: number) => {
  const sec_num = parseInt("" + secs, 10);
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor(sec_num / 60) % 60;
  const seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};

export const guessProjectTitleFromDocument = (filenames: string[]): string => {
  const [filename] = filenames;
  const filenameWithoutExt = filename.substring(0, filename.lastIndexOf("."));
  // remove some chars
  return filenameWithoutExt
    .replace(/[._,-]/g, " ")
    .replace(/\((\d+)\)/g, "") // remove (1) (2) etc
    .trim();
};
export function transformKeysToCamelCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformKeysToCamelCase(item));
  } else if (obj !== null && obj.constructor === Object) {
    const newObj: { [key: string]: any } = {};
    Object.keys(obj).forEach((key) => {
      const camelCaseKey = key.replace(/(_\w)/g, (match) =>
        match[1].toUpperCase()
      );
      newObj[camelCaseKey] = transformKeysToCamelCase(obj[key]);
    });
    return newObj;
  }
  return obj;
}

export function transformKeysToSnakeCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformKeysToSnakeCase(item));
  } else if (obj !== null && obj.constructor === Object) {
    const newObj: { [key: string]: any } = {};
    Object.keys(obj).forEach((key) => {
      const snakeCaseKey = key.replace(
        /[A-Z]/g,
        (match) => "_" + match.toLowerCase()
      );
      newObj[snakeCaseKey] = transformKeysToSnakeCase(obj[key]);
    });
    return newObj;
  }
  return obj;
}

export const getLocaleCountryCode = (locale: string | undefined) => {
  // don't fail if empty language
  return locale?.split("-").pop()?.toLowerCase();
};

export const getCountryName = (countryCode: string, language: string) => {
  const regionNames = new Intl.DisplayNames(language, { type: "region" });
  const regionName = regionNames.of(countryCode);
  return regionName;
};

export const getLanguageName = (
  locale: string,
  language: string,
  withCustomCountry: boolean = false
) => {
  const [lang, country] = locale.split("-");
  // There are situations where the storyboard has no language which causes a runtime error
  // if (!lang) return "en-US";
  const languageNames = new Intl.DisplayNames(
    country ? lang : getLanguageVariant(lang),
    { type: "language" }
  );
  const languageName = languageNames.of(lang);
  if (
    withCustomCountry &&
    lang.toLocaleLowerCase() !== country.toLocaleLowerCase()
  ) {
    return `${languageName} (${getCountryName(country, language)})`;
  }
  if (!languageName) return "N/A";
  return languageName.slice(0, 1).toUpperCase() + languageName.slice(1);
};

export const addDateTz = (date: string | undefined) =>
  date?.charAt(date.length - 1) !== "Z" ? date + "Z" : date;

export const validateSelectedPages = (input: string): string | false => {
  // Regular expression to match numbers and ranges
  const pattern: RegExp = /^(\d+(-\d+)?)(,\s*\d+(-\d+)?)*$/;
  if (input === "") {
    return "";
  }
  if (!pattern.test(input)) {
    return false;
  }

  const elements: string[] = input.split(/,\s*/);

  const formattedParts: string[] = [];
  for (const element of elements) {
    if (element.includes("-")) {
      const [start, end] = element.split("-").map(Number);
      if (isNaN(start) || isNaN(end) || start > end) {
        return false;
      }
      formattedParts.push(`${start}-${end}`);
    } else {
      const num: number = parseInt(element, 10);
      if (isNaN(num)) {
        return false;
      }
      formattedParts.push(num.toString());
    }
  }

  return formattedParts.join(", ");
};

export function getPublicationUrl({
  projectId,
  publicationId,
  embed = false,
  accessToken,
}: {
  projectId: string;
  publicationId: string;
  embed?: boolean;
  accessToken?: string;
}) {
  const embedOrP = embed ? "embed" : "p";
  return (
    `${MEDIAPLAYER_URL}/${embedOrP}/${projectId}/${publicationId}` +
    (accessToken ? `?access_token=${accessToken}` : "")
  );
}
export const getCountryOptions = () => {
  return Object.entries(countries).map(([key, value]) => ({
    label: value.name,
    value: key,
  }));
};
