import { message } from "antd";
import { useTranslate } from "@refinedev/core";

export const useClipboard = () => {
  const t = useTranslate();

  const copy = (text: string) => {
    if (!text) {
      message.error(t("notifications.copyError"));
      return;
    }

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          message.success(t("notifications.copySuccess"));
        })
        .catch(() => {
          message.error(t("notifications.copyError"));
        });
    } else {
      message.error(t("notifications.copyError"));
    }
  };

  return { copy };
};
