import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { Flex, Form, Input, Space } from "antd";
import { Edit, SaveButton, useForm } from "@refinedev/antd";
import { useOrganization } from "hooks/useOrganization";
import { CanAccess, useList } from "@refinedev/core";
import { ContactResponse, PresenterProfileResponse } from "../types";
import { PresenterProfilePicker } from "../components/PresenterProfilePicker";
import { useParams } from "react-router-dom";
import { CalendarCheck } from "@phosphor-icons/react/dist/ssr";
import { Mailbox, Phone } from "@phosphor-icons/react";
import { DeleteButton } from "@refinedev/antd";
import { useNavigate } from "react-router-dom";
import { SimpleEditContactPage } from "pages/media/components/ContactWithPresenter/PageComponent";
import { StyledEditWrapper } from "./create";
import { errorNotificationWithEntityIdsHandler } from "pages/onboarding/helpers";

const ContactEditForm = ({ contactId }: { contactId: string }) => {
  const t = useTranslate();
  const { organization } = useOrganization({});
  const { formProps, saveButtonProps, queryResult } = useForm<ContactResponse>({
    resource: `media/${organization?.id}/contacts`,
    id: contactId,
    action: "edit",
  });
  const { data: presenters } = useList<PresenterProfileResponse>({
    resource: `media/${organization?.id}/presenter_profiles`,
    queryOptions: { enabled: !!organization?.id },
  });
  const navigate = useNavigate();
  const handleDeleteSuccess = () => {
    navigate("../");
  };
  const contact = queryResult?.data?.data;

  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      title={contact?.firstname}
      headerButtons={() => null}
      footerButtons={({ saveButtonProps }) => (
        <Flex justify="space-between">
          {/* todo check ACL scope */}
          {contact && (
            <CanAccess resource="media_contacts" action="delete">
              <DeleteButton
                size="large"
                shape="round"
                resource={`media/${organization?.id}/contacts`}
                recordItemId={contact.id}
                accessControl={{ enabled: false }}
                onSuccess={handleDeleteSuccess}
                errorNotification={(error) => {
                  const result = errorNotificationWithEntityIdsHandler(
                    error,
                    "organization_ids"
                  );
                  return result;
                }}
              >
                {t("media.contacts.edit.delete")}
              </DeleteButton>
            </CanAccess>
          )}
          <SaveButton
            {...saveButtonProps}
            type="primary"
            size="large"
            shape="round"
            icon={null}
          >
            {t("media.contacts.edit.update")}
          </SaveButton>
        </Flex>
      )}
    >
      <Form {...formProps} layout="vertical" initialValues={contact}>
        <Form.Item
          label={t("media.contacts.edit.presenter")}
          name="presenter_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {presenters?.data && (
            <PresenterProfilePicker items={presenters?.data} />
          )}
        </Form.Item>

        <Form.Item
          label={t("media.contacts.edit.firstName")}
          name="firstname"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("media.contacts.edit.lastName")}
          name="lastname"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={
            <Space align="start">
              <Mailbox size={20} />
              {t("media.contacts.edit.email")}
            </Space>
          }
          name="email"
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label={
            <Space align="start">
              <Phone size={20} />
              {t("media.contacts.edit.phone")}
            </Space>
          }
          name="phone"
        >
          <Input type="phone" />
        </Form.Item>
        <Form.Item
          label={
            <Space align="start">
              <CalendarCheck size={20} />
              {t("media.contacts.edit.calendar")}
            </Space>
          }
          name="calendar"
        >
          <Input type="url" />
        </Form.Item>
      </Form>
    </Edit>
  );
};

export const ContactEditPage = () => {
  const { id } = useParams();
  return (
    <StyledEditWrapper>
      {id && (
        <>
          <SimpleEditContactPage id={id} />
          {/* {isStudio ? (
                <SimpleEditContactPage id={id} />
              ) : (
                <ContactEditForm contactId={id} />
              )} */}
        </>
      )}
    </StyledEditWrapper>
  );
};
