import { Authenticated } from "@refinedev/core";
import { TemplateEditor } from "../components/templates";

export const MediaTemplatesEditor: React.FC = () => {
  return (
    <Authenticated key={"mediaCuepointsEditor"}>
      <TemplateEditor />
    </Authenticated>
  );
};
