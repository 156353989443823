import { isDocustream } from "helpers/utils";
import { DocustreamLogo, LibertifyLogo } from "./icons";
import { SVGAttributes } from "react";

export const AppLogo = (props: SVGAttributes<SVGElement>) => {
  if (isDocustream()) {
    return <DocustreamLogo {...props} />;
  } else {
    return <LibertifyLogo {...props} />;
  }
};
