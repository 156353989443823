import { useTranslate } from "@refinedev/core";
import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Space,
  Card,
  Spin,
  Button,
  Form,
  Select,
  Flex,
  Input,
} from "antd";
import {
  useCustomMutation,
  useOne,
  useList,
  useInvalidate,
  CanAccess,
} from "@refinedev/core";
import {
  IdentificationCard,
  Swatches,
  Translate,
  UsersThree,
} from "@phosphor-icons/react";
import { useOrganization } from "hooks/useOrganization";
import { SettingsItem } from "../projects/components/SettingsItem";
import { BrandKit, ContactResponse } from "pages/media/types";
import { languages } from "data/languages";
import { BrandKitPreview } from "../components/BrandKitPreview";
import { AppContext } from "appContext";

type FormData = {
  language?: string;
  contact_id?: string;
  brand_kit_id?: string;
  name?: string;
};

const { Title } = Typography;

export const OrganizationPreferencesPage: React.FC = () => {
  const t = useTranslate();
  const [form] = Form.useForm();
  const [editing, setEditing] = useState<string | null>(null);
  const { dispatch } = useContext(AppContext);
  const { organization } = useOrganization({});
  const invalidate = useInvalidate();
  const organizationId = organization?.id;

  const { data: fetchedPreferences, isLoading: preferencesLoading } = useOne({
    resource: "media/preferences",
    id: organizationId,
    queryOptions: { enabled: !!organizationId },
  });

  const { data: brandKits, isLoading: brandKitsLoading } = useList<BrandKit>({
    resource: `media/${organizationId}/brand_kits`,
    queryOptions: { enabled: !!organizationId },
  });

  const { data: contacts, isLoading: contactsLoading } =
    useList<ContactResponse>({
      resource: `media/${organizationId}/contacts`,
      queryOptions: { enabled: !!organizationId },
    });

  const updateMutation = useCustomMutation();

  useEffect(() => {
    if (fetchedPreferences?.data) {
      form.setFieldsValue({
        language: fetchedPreferences.data.language || "",
        contact_id: fetchedPreferences.data.contact_id || "",
        brand_kit_id: fetchedPreferences.data.brand_kit_id || "",
        name: organization?.name,
      });
    }
  }, [fetchedPreferences, form]);

  const languageOptions = languages.map((lang) => {
    const [languageName, countryName] = lang.value.split("-");
    const nativeLanguageName = t(`nativeLanguageNames.${languageName}`);
    const nativeCountryName = t(`nativeCountryNames.${countryName}`);
    return {
      label:
        nativeLanguageName +
        (nativeCountryName ? ` (${nativeCountryName})` : ""),
      value: lang.value,
    };
  });

  const handleUpdate = async (values: FormData) => {
    let url = `media/preferences/${organizationId}`;

    if (values.name) {
      url = `/media/organizations/${organizationId}`;
    }

    try {
      await updateMutation.mutateAsync({
        url,
        values,
        method: "patch",
        successNotification: {
          message: t(
            "media.organizations.index.preferencesUpdatedSuccessfully"
          ),
          type: "success",
          description: t("media.organizations.index.success"),
        },
      });
      setEditing(null);

      if (values.name) {
        dispatch({
          type: "setOrganization",
          payload: {
            name: values.name,
            id: organization!.id,
          },
        });
        await invalidate({
          resource: `api/users/me/organizations`,
          invalidates: ["all"],
        });
      }
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  if (!organizationId) {
    return <Spin size="large" />;
  }

  if (preferencesLoading || brandKitsLoading || contactsLoading) {
    return (
      <Flex justify="center" align="center" style={{ height: "100vh" }}>
        <Spin size="large" />
      </Flex>
    );
  }

  const handleCancel = () => {
    setEditing(null);
  };

  const getOptions = () => {
    switch (editing) {
      case "Language":
        return languageOptions;
      case "Contact":
        return (
          contacts?.data.map((contact) => ({
            label: `${contact.firstname} ${contact.lastname}`,
            value: contact.id,
            data: contact,
          })) || []
        );
      case "BrandKit":
        return (
          brandKits?.data.map((brandKit) => ({
            label: brandKit.name,
            value: brandKit.id,
          })) || []
        );
      default:
        return [];
    }
  };

  return (
    <Flex vertical gap="large" style={{ width: "100%" }}>
      <Title level={2}>
        {t("media.organizations.index.organizationSettings")}
      </Title>
      {editing ? (
        <Form
          form={form}
          layout="vertical"
          initialValues={fetchedPreferences?.data}
          onFinish={handleUpdate}
        >
          {editing === "OrganizationName" ? (
            <Form.Item name="name" rules={[{ required: true, message: "" }]}>
              <Input
                size="large"
                style={{
                  height: 40,
                  fontSize: 20,
                  maxWidth: "500px",
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item
              label={`Select default ${editing}`}
              name={
                editing === "Language"
                  ? "language"
                  : editing === "Contact"
                    ? "contact_id"
                    : "brand_kit_id"
              }
              rules={[{ required: true, message: `Please select a value` }]}
            >
              <Select
                style={{ maxWidth: 300 }}
                options={getOptions()}
                optionRender={(option) => (
                  <Space>
                    {editing === "BrandKit" && (
                      <span role="img" aria-label={option.data.label}>
                        <BrandKitPreview
                          brandkit={brandKits?.data.find(
                            (b) => b.id === option.data.value
                          )}
                          showName={false}
                        />
                      </span>
                    )}
                    {/* {editing === "Contact" && (
                    <span role="img" aria-label={option.data.label}>
                      <PresenterProfilePreviewView
                        presenter={option.data.data.presenter_profile}
                        style={{ height: 30 }}
                      />
                    </span>
                  )} */}
                    {option.data.label ?? option.label}
                  </Space>
                )}
              ></Select>
            </Form.Item>
          )}
          <Space>
            <Button onClick={handleCancel} size="large">
              {t("media.organizations.index.cancel")}
            </Button>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={updateMutation.isLoading}
              disabled={updateMutation.isLoading}
            >
              {t("media.organizations.index.save")}
            </Button>
          </Space>
        </Form>
      ) : (
        <>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <CanAccess resource="organization" action="edit">
              <Card>
                <SettingsItem
                  title={t("media.organizations.index.organizationName")}
                  icon={<IdentificationCard size={24} />}
                  content={organization.name}
                  onClick={() => setEditing("OrganizationName")}
                  editable
                />
              </Card>
            </CanAccess>
          </Space>
          <CanAccess resource="media_organization_preferences" action="edit">
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Card>
                <SettingsItem
                  title={t("media.organizations.index.defaultLanguage")}
                  icon={<Translate size={24} />}
                  content={t(
                    `nativeLanguageNames.${form.getFieldValue("language")?.split("-")[0]}`,
                    { defaultValue: "" }
                  )}
                  onClick={() => setEditing("Language")}
                  editable
                />
              </Card>
              <Card>
                <SettingsItem
                  title={t("media.organizations.index.defaultContact")}
                  icon={<UsersThree size={24} />}
                  content={(() => {
                    const contact = contacts?.data.find(
                      (c) => c.id === form.getFieldValue("contact_id")
                    );
                    return contact
                      ? `${contact.firstname} ${contact.lastname}`
                      : "Unknown";
                  })()}
                  onClick={() => setEditing("Contact")}
                  editable
                />
              </Card>
              <Card>
                <SettingsItem
                  title={t("media.organizations.index.defaultBrandKit")}
                  icon={<Swatches size={24} />}
                  content={
                    brandKits?.data.find(
                      (b) => b.id === form.getFieldValue("brand_kit_id")
                    )?.name
                  }
                  onClick={() => setEditing("BrandKit")}
                  editable
                />
              </Card>
            </Space>
          </CanAccess>
        </>
      )}
    </Flex>
  );
};
