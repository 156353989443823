import { Flex, Space, Typography } from "antd";
import { useGetLocale } from "@refinedev/core";
import { UserCircle } from "@phosphor-icons/react";
import { getLanguageName } from "pages/media/utils";
import { useOne } from "@refinedev/core";
import { MediaProjectResponse, ContactResponse } from "pages/media/types";
import { useAntTheme } from "hooks/useAntTheme";

export const ProjectLanguageAndContact = ({
  project,
}: {
  project: MediaProjectResponse;
}) => {
  const getLocale = useGetLocale();
  const currentLanguage = getLocale();
  const { theme } = useAntTheme();
  const shortLanguage = project.storyboards[0]?.language
    ?.split("-")[0]
    .toUpperCase();

  const { data: contact } = useOne<ContactResponse>({
    resource: `media/${project?.organization_id}/contacts`,
    id: project?.settings.contact_id,
    queryOptions: {
      enabled:
        Boolean(project?.organization_id) &&
        Boolean(project?.settings.contact_id),
    },
  });

  return (
    <Flex gap={30}>
      {project.storyboards[0]?.language && (
        <Space align="center">
          <Typography.Text
            strong
            style={{
              backgroundColor: theme.colorText,
              color: theme.colorBorderSecondary,
              borderRadius: 5,
              padding: "4px 6px",
            }}
          >
            {shortLanguage}
          </Typography.Text>

          <Typography.Text strong>
            {currentLanguage &&
              getLanguageName(project.storyboards[0].language, currentLanguage)}
          </Typography.Text>
        </Space>
      )}
      {contact && (
        <Space align="center">
          <UserCircle size={32} weight="bold" />
          <Typography.Text strong>
            {contact.data.firstname} {contact.data.lastname}
          </Typography.Text>
        </Space>
      )}
    </Flex>
  );
};
