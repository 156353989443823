import { useCustom, useList, useOne } from "@refinedev/core";
import {
  AssetLibrary,
  BrandKit,
  ContactResponse,
  MediaAsset,
  MediaProjectResponse,
  PresenterProfileResponse,
} from "pages/media/types";

import { MediaTemplateSettings, MediaTemplatesResponse } from "types";
import { PUBLIC_LIBRARY_ID } from "../constants";

export const useTemplates = ({ projectId }: { projectId?: string }) => {
  const { data: projectData, isLoading: isLoadingProject } =
    useOne<MediaProjectResponse>({
      resource: `media/projects`,
      id: projectId,
      queryOptions: {
        enabled: !!projectId,
      },
    });

  const organizationId = projectData?.data?.organization_id;
  const brandKitId = projectData?.data?.settings.brand_kit_id;

  const storyboardId = projectData?.data?.storyboards?.[0]?.asset_id;

  const { data: storyboardAsset } = useOne<MediaAsset>({
    resource: `media/media/assets`,
    id: storyboardId,
    queryOptions: {
      enabled: !!storyboardId,
    },
  });

  const storyboardPath = storyboardAsset?.data?.path;

  const { data: assetLibraries } = useList<AssetLibrary>({
    resource: `media/${organizationId}/asset_libraries`,
    queryOptions: {
      enabled: !!organizationId,
    },
    // there might be no asset libraries
    errorNotification: false,
  });
  // Sort by created and take the oldest
  const sortedAssetLibraries = [...(assetLibraries?.data ?? [])];
  sortedAssetLibraries.sort((a, b) => {
    return new Date(a.created).getTime() - new Date(b.created).getTime();
  });
  const assetLibrary = sortedAssetLibraries?.[0];
  const assetLibraryAssets = useList<MediaAsset>({
    resource: `media/asset_libraries/${assetLibrary?.id}/assets`,
    queryOptions: {
      enabled: !!organizationId && !!assetLibrary?.id,
    },
  });

  // useOne so we can use the useUpdate auto invalidate for this resource + id
  const { data: mediaSettingsResponse } = useOne<MediaTemplateSettings>({
    resource: `/media/${organizationId}/projects`,
    id: `${projectId}/media/settings`,
    queryOptions: {
      enabled: !!projectId && !!organizationId,
      queryKey: ["ProjectMediaSettings", projectId],
    },
    //there might be no media settings for new projects
    errorNotification: false,
  });

  const { data: brandKit } = useOne<BrandKit>({
    resource: `media/${organizationId}/brand_kits`,
    id: brandKitId,
    queryOptions: {
      enabled: !!brandKitId && !!organizationId,
    },
  });

  const { data: publicAssetsData } = useList<MediaAsset>({
    resource: `media/asset_libraries/${PUBLIC_LIBRARY_ID}/assets`,
    queryOptions: {
      enabled: !!PUBLIC_LIBRARY_ID,
    },
  });

  const publicFonts = publicAssetsData?.data?.filter(
    (asset) => asset !== undefined && asset.asset_type === "Font"
  );

  const brandKitPalette = brandKit?.data?.palette;
  const brandKitAssets = brandKit?.data?.assets;

  const logo = brandKitAssets?.find((asset) => asset.purpose === "Logo");
  const titleFont = brandKitAssets?.find((asset) => asset.purpose === "Title");
  const textFont = brandKitAssets?.find(
    (asset) => asset.purpose === "Subtitles"
  );
  const subtitlesFont = brandKitAssets?.find(
    (asset) => asset.purpose === "Subtitles"
  );
  const audio = brandKitAssets?.find((asset) => asset.purpose === "Music");
  const background = brandKitAssets?.find(
    (asset) => asset.purpose === "Background"
  );
  const intro = brandKitAssets?.find(
    (asset) => asset.purpose === "Introduction"
  );
  const transition = brandKitAssets?.find(
    (asset) => asset.purpose === "Transition"
  );

  const banner = brandKitAssets?.find((asset) => asset.purpose === "Banner");

  const closing = brandKitAssets?.find((asset) => asset.purpose === "Closing");

  const { data: contactData } = useOne<ContactResponse>({
    resource: `media/${organizationId}/contacts`,
    id: projectData?.data?.settings.contact_id,
  });

  const { data: initialPresenterData } = useOne<PresenterProfileResponse>({
    resource: `media/${organizationId}/presenter_profiles`,
    id: contactData?.data?.presenter_id,
    queryOptions: {},
  });

  const { data: projectPresenters } = useList<PresenterProfileResponse>({
    resource: `media/${organizationId}/presenter_profiles`,
  });

  const { data: templatesIdList } = useCustom<MediaTemplatesResponse>({
    url: `media/${organizationId}/templates`,
    method: "get",
    queryOptions: {
      queryKey: ["MediaTemplates", organizationId],
    },
  });

  const hasTransition =
    projectData?.data.settings.transitions !== "NoTransition";

  return {
    contact: contactData?.data,
    isLoadingProject,
    projectTitle: projectData?.data.title,
    organizationId,
    brandKitId,
    brandKitPalette,
    logo,
    banner,
    closing,
    titleFont,
    textFont,
    subtitlesFont,
    background,
    brandKitName: brandKit?.data.name,
    transition,
    intro,
    audio,
    presenter: initialPresenterData?.data,
    storyboardPath,
    hasTransition,
    projectPresenters: projectPresenters?.data,
    organizationTemplates: templatesIdList?.data?.templates,
    mediaSettings: mediaSettingsResponse?.data,
    assetLibrary,
    assetLibraryAssets,
    assetLibraries: assetLibraries?.data,
    publicFonts,
  };
};
