import { useNavigation, useTranslate, useCan } from "@refinedev/core";
import { Button, Dropdown, Flex, Space, Typography, Divider } from "antd";
import { DeleteButton, DateField } from "@refinedev/antd";
import { useAntTheme } from "hooks/useAntTheme";
import {
  BugDroid,
  Buildings,
  CopySimple,
  DotsThreeVertical,
  FilePdf,
  NotePencil,
  TrashSimple,
  ShareNetwork,
  DownloadSimple,
  BracketsCurly,
  Users,
} from "@phosphor-icons/react";
import { useOrganization } from "hooks/useOrganization";
import { useNavigate } from "react-router-dom";
import { MediaProjectResponse } from "pages/media/types";
import { useWorkspace } from "hooks/useWorkspace";
import { useClipboard } from "hooks/useClipboard";

export const ProjectSecondaryMenu = ({
  project,
}: {
  project: MediaProjectResponse;
}) => {
  const { theme } = useAntTheme();
  const t = useTranslate();
  const { list } = useNavigation();
  const { organization } = useOrganization({});
  const navigate = useNavigate();
  const { workspace } = useWorkspace();
  const { copy } = useClipboard();
  const { data: hasAccessToDebugMedia } = useCan({
    resource: "media_generation_steps",
    action: "show",
  });

  const editingMedia = project?.media.find(
    (media) => media.status !== "Published"
  );
  const media = project?.media?.[0];

  const organizationName = organization?.name;

  const handleDeleteSuccess = () => {
    list("media_projects");
  };

  const menuItems = [
    {
      key: "edit-storyboard",
      disabled: ["Pending", "Generating"].includes(
        project?.storyboards?.[0]?.status
      ),
      label: (
        <Button
          key="edit-storyboard"
          type="text"
          size="middle"
          style={{
            background: "none",
            paddingLeft: 0,
          }}
          icon={<NotePencil size={20} />}
        >
          {t("media.projects.projectMenu.editStoryboard")}
        </Button>
      ),
      onClick: () => {
        navigate(
          `/media/projects/${project.id}/storyboard/${project?.storyboards?.[0]?.asset_id}`
        );
      },
    },
    {
      key: "edit-video",
      disabled: !["Published"].includes(media?.status),
      label: (
        <Button
          key="edit-video"
          type="text"
          size="middle"
          style={{
            background: "none",
            paddingLeft: 0,
          }}
          icon={<NotePencil size={20} />}
        >
          {t("media.projects.projectMenu.editVideo")}
        </Button>
      ),
      onClick: () => {
        navigate(
          `/media/projects/${project.id}/media/${editingMedia?.id}/templates`
        );
      },
    },
    {
      key: "edit-document-animation",
      disabled: ["Draft", "Pending", "Generating"].includes(
        project?.media?.[0]?.status
      ),
      label: (
        <Button
          key="edit-document-animation"
          type="text"
          size="middle"
          style={{
            background: "none",
            paddingLeft: 0,
          }}
          icon={<NotePencil size={20} />}
        >
          {t("media.projects.projectMenu.documentAnimation")}
        </Button>
      ),
      onClick: () => {
        navigate(
          `/media/projects/${project.id}/media/${editingMedia?.id}/animation`
        );
      },
    },
    {
      key: "edit-kb",
      disabled: false,
      label: (
        <Button
          key="edit-kb"
          type="text"
          size="middle"
          style={{
            background: "none",
            paddingLeft: 0,
          }}
          icon={<NotePencil size={20} />}
        >
          {t("media.projects.projectMenu.editKbChatbot")}
        </Button>
      ),
      onClick: () => {
        navigate(`/media/projects/${project.id}/kb`);
      },
    },
    {
      key: "share",
      disabled: ["Draft", "Pending", "Generating", "Published"].includes(
        media?.status
      ),
      label: (
        <Button
          key="share"
          type="text"
          size="middle"
          style={{
            background: "none",
            paddingLeft: 0,
          }}
          icon={<ShareNetwork size={20} />}
        >
          {t("media.projects.projectMenu.shareExperience")}
        </Button>
      ),
      onClick: () => {
        navigate(`/media/projects/${project.id}/media/${media?.id}/share`);
      },
    },
    {
      key: "download",
      disabled: !media.url,
      label: (
        <Button
          key="download"
          type="text"
          size="middle"
          style={{
            background: "none",
            paddingLeft: 0,
          }}
          icon={<DownloadSimple size={20} />}
        >
          {t("media.projects.projectMenu.downloadVideo")}
        </Button>
      ),
      onClick: () => {
        navigate(`/media/projects/${project.id}/media/${media?.id}/download`);
      },
    },
    {
      key: "divider1",
      disabled: false,
      label: (
        <div style={{ overflow: "hidden", width: "100%" }}>
          <Divider
            style={{
              margin: 0,
              left: -5,
              position: "absolute",
              width: "102%",
            }}
          />
        </div>
      ),
    },
    {
      key: "copy-id",
      disabled: false,
      label: (
        <>
          <Button
            key="copy-id"
            type="text"
            size="middle"
            style={{
              background: "none",
              paddingLeft: 0,
            }}
            icon={<CopySimple size={20} />}
          >
            {t("media.projects.projectMenu.copyProjectId")}
          </Button>
        </>
      ),
      onClick: () => copy(project.id),
    },
    {
      key: "debug-project",
      disabled: false,
      label: (
        <Button
          key="debug"
          icon={<BracketsCurly size={20} />}
          type="text"
          size="middle"
          style={{ background: "none", paddingLeft: 0 }}
        >
          {t("media.projects.projectMenu.debugProject")}
        </Button>
      ),
      onClick: () => {
        navigate(`/media/projects/${project.id}/all`);
      },
    },
    {
      key: "debug-video",
      disabled: !hasAccessToDebugMedia?.can,
      label: (
        <Space>
          <Button
            key="debug"
            icon={<BugDroid size={20} />}
            type="text"
            size="middle"
            style={{ background: "none", paddingLeft: 0 }}
          >
            {t("media.projects.projectMenu.debugVideo")}
          </Button>
        </Space>
      ),
      onClick: () => {
        navigate(`/media/media/${media.id}`);
      },
    },
    {
      key: "delete",
      disabled: false,
      label: (
        <DeleteButton
          resource="media/projects"
          size="middle"
          recordItemId={project.id}
          accessControl={{ enabled: false }}
          onSuccess={handleDeleteSuccess}
          icon={<TrashSimple size={20} />}
          style={{
            margin: 0,
            padding: 0,
            border: "none",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          {t("media.projects.detail.deleteProject")}
        </DeleteButton>
      ),
    },
    {
      key: "divider2",
      disabled: false,
      label: (
        <div style={{ overflow: "hidden" }}>
          <Divider
            style={{
              margin: 0,
              left: -5,
              position: "absolute",
              width: "102%",
            }}
          />
        </div>
      ),
    },
    {
      key: "projectCreated",
      disabled: false,
      label: (
        <Typography.Text type="secondary">
          {t("media.projects.projectMenu.createdOn")}{" "}
          <DateField
            value={project.created}
            type="secondary"
            format="LLL"
            style={{ fontSize: "90%" }}
          />
        </Typography.Text>
      ),
    },
    {
      key: "document",
      disabled: false,
      label: (
        <Flex vertical>
          <Typography.Text type="secondary">
            <Flex align="center" gap={5}>
              <FilePdf size={20} />
              {t("media.projects.projectMenu.fromDocument")}
            </Flex>
          </Typography.Text>
          <Typography.Text>{project.documents[0].name}</Typography.Text>
        </Flex>
      ),
    },
    {
      key: "organization",
      disabled: false,
      label: (
        <>
          {organization && (
            <Typography.Text type="secondary">
              <Flex align="center" gap={5}>
                <Buildings size={20} />
                {t("media.projects.projectMenu.inOrganization", {
                  asset: organizationName,
                })}
              </Flex>
            </Typography.Text>
          )}
        </>
      ),
    },
    {
      key: "workspace",
      disabled: false,
      label: (
        <Typography.Text type="secondary">
          <Flex align="center" gap={5}>
            <Users size={20} />
            {t("media.projects.projectMenu.inWorkspace", {
              asset: workspace?.name,
            })}
          </Flex>
        </Typography.Text>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{ items: menuItems.filter((item) => item.disabled === false) }}
      trigger={["click"]}
    >
      <DotsThreeVertical size={32} weight="bold" color={theme.colorText} />
    </Dropdown>
  );
};
