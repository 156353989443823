import {
  useCustomMutation,
  useLogin,
  useLogout,
  useTranslate,
} from "@refinedev/core";
import useSessionData from "hooks/useSessionData";

export default function useDeleteAccount() {
  const { mutateAsync: deleteAccount, isLoading: isLoadingDelete } =
    useCustomMutation();
  const { mutate: logout } = useLogout();
  const { mutate: checkPassword, isLoading: isLoadingCheckPassword } = useLogin(
    {}
  );
  const t = useTranslate();
  const { userData: user } = useSessionData();
  const handleDeleteAccount = async (password) => {
    checkPassword(
      {
        email: user?.data.email,
        password,
        redirect: false,
      },
      {
        onSuccess: async (data) => {
          if (data.success) {
            try {
              await deleteAccount({
                url: `api/users/me`,
                method: "delete",
                values: {},
                errorNotification: {
                  type: "error",
                  message: t("pages.user.delete.failedToDeleteAccount"),
                },
              });
              logout();
            } catch (e) {
              // Do nothing, this is handled by Refine
            }
          }
        },
      }
    );
  };
  return {
    handleDeleteAccount,
    isLoading: isLoadingDelete || isLoadingCheckPassword,
  };
}
