import { useTranslate } from "@refinedev/core";
import { Alert, Card, Flex } from "antd";
import { BrandKitAssetPreview } from "./BrandKitAssetPreview";
import {
  AssetSource,
  BrandKitAssetResponse,
  CreateBrandKitAssetRequest,
} from "../../types";
import UploadFiles, { UploadFilesRef } from "components/UploadFiles";
import { useOrganization } from "hooks/useOrganization";
import { useEffect, useState } from "react";
import { InputHelperText } from "components/InputHelperText";
const CREATE_ASSET_REQUEST_TYPE: CreateBrandKitAssetRequest["type"] =
  "CreateBrandKitAsset";

export type BrandKitAssetResponseWithTypedPurpose = Omit<
  BrandKitAssetResponse,
  "purpose"
> & {
  purpose: AssetPurpose;
};

type BrandKitAssetsProps = {
  assets?: BrandKitAssetResponseWithTypedPurpose[];
  refs?: React.RefObject<UploadFilesRef>[];
  brandKitId?: string;
  validateFiles?: (
    assets: {
      purpose?: string;
    }[]
  ) => void;
};
export const MusicPurposes = ["Music" as const];
export const VideoPurposes = [
  "Introduction" as const,
  "Transition" as const,
  "Background" as const,
  "Closing" as const,
];
export const FontPurposes = [
  "Subtitles" as const,
  "Text" as const,
  "Title" as const,
];
export const PicturePurpose = [
  "Background" as const,
  "Logo" as const,
  "Banner" as const,
];
export const purposes: Record<string, string[]> = {
  Music: MusicPurposes,
  Video: VideoPurposes,
  Font: FontPurposes,
  Picture: PicturePurpose,
};
export type AssetPurpose =
  | (typeof MusicPurposes)[number]
  | (typeof VideoPurposes)[number]
  | (typeof FontPurposes)[number]
  | (typeof PicturePurpose)[number];
export const BrandKitAssets: React.FC<BrandKitAssetsProps> = ({
  assets,
  validateFiles,
  refs,
  brandKitId,
}) => {
  const t = useTranslate();
  const music = assets?.filter((asset) => asset.type === "Music");
  const videos = assets?.filter((asset) => asset.type === "Video");
  const fonts = assets?.filter((asset) => asset.type === "Font");
  const images = assets?.filter((asset) => asset.type === "Picture");
  const [stagedAssets, setStagedAssets] = useState<
    (Partial<BrandKitAssetResponse> & {
      uid: string;
    })[]
  >([]);
  const [uploadMusicRef, uploadFontsRef, uploadVideoRef, uploadPicturesRef] =
    refs ?? [];
  const isEditing = !!refs?.length;
  const { organization } = useOrganization({});

  useEffect(() => {
    if (validateFiles) {
      validateFiles(stagedAssets);
    }
  }, [stagedAssets]);
  // BrandKit can only have 1 asset per purpose.
  const maxPicturesUpload = purposes["Picture"].length - (images?.length ?? 0);
  const maxMusicUpload = purposes["Music"].length - (music?.length ?? 0);
  const maxFontsUpload = purposes["Font"].length - (fonts?.length ?? 0);
  const maxVideosUpload = purposes["Video"].length - (videos?.length ?? 0);
  return (
    <>
      {(!!images?.length || isEditing) && (
        <Card
          title={t("brandkit.components.BrandKitAssets.imageAssets")}
          type="inner"
        >
          <Flex vertical gap={16}>
            <Flex wrap={"wrap"} gap={10}>
              {images?.map((image) => (
                <BrandKitAssetPreview
                  brandKitId={brandKitId}
                  key={image.asset_id}
                  asset={image}
                />
              ))}
            </Flex>
            {!!maxPicturesUpload && uploadPicturesRef && (
              <UploadFiles
                maxCount={maxPicturesUpload}
                defaultItems={assets}
                setStagedItems={setStagedAssets}
                stagedItems={stagedAssets}
                //  image/svg, .svg, not supported in backend
                acceptedMimeTypes="image/jpeg, image/png, .png, .jpeg, .jpg"
                itemRequestProperties={{
                  type: CREATE_ASSET_REQUEST_TYPE,
                  organization_id: organization?.id,
                  category: "AssetImage",
                  source: AssetSource.UIGenerated,
                  asset: {
                    type: "Picture",
                  },
                }}
                renderPreview={(
                  file,
                  stagedAssets,
                  setStagedAssets,
                  assets
                ) => {
                  return (
                    <BrandKitAssetPreview
                      assets={assets as BrandKitAssetResponseWithTypedPurpose[]}
                      brandKitId={brandKitId}
                      setStagedAssets={setStagedAssets}
                      stagedAssets={stagedAssets}
                      isNew
                      asset={{
                        name: file.name,
                        type: "Picture",
                        uid: file.uid,
                        file: file.originFileObj as File,
                      }}
                    />
                  );
                }}
                ref={uploadPicturesRef}
              />
            )}
          </Flex>
        </Card>
      )}
      {(!!music?.length || isEditing) && (
        <Card
          title={t("brandkit.components.BrandKitAssets.musicAssets")}
          type="inner"
        >
          <Flex vertical gap={16}>
            <Flex wrap={"wrap"} gap={10}>
              {music?.map((audio) => (
                <BrandKitAssetPreview
                  brandKitId={brandKitId}
                  key={audio.asset_id}
                  asset={audio}
                />
              ))}
            </Flex>
            {!!maxMusicUpload && uploadMusicRef && (
              <UploadFiles
                maxCount={maxMusicUpload}
                defaultItems={assets}
                stagedItems={stagedAssets}
                setStagedItems={setStagedAssets}
                acceptedMimeTypes="audio/mp3, .mp3"
                itemRequestProperties={{
                  type: CREATE_ASSET_REQUEST_TYPE,
                  organization_id: organization?.id,
                  category: "Music",
                  source: AssetSource.UIGenerated,
                  asset: {
                    type: "Music",
                  },
                  purpose: "Music",
                }}
                ref={uploadMusicRef}
                renderPreview={(
                  file,
                  stagedAssets,
                  setStagedAssets,
                  assets
                ) => {
                  return (
                    <BrandKitAssetPreview
                      isNew
                      brandKitId={brandKitId}
                      assets={assets as BrandKitAssetResponseWithTypedPurpose[]}
                      setStagedAssets={setStagedAssets}
                      stagedAssets={stagedAssets}
                      asset={{
                        file: file.originFileObj as File,
                        name: file.name,
                        type: "Music",
                        uid: file.uid,
                        purpose: "Music", // only one purpose
                      }}
                    />
                  );
                }}
              />
            )}
          </Flex>
        </Card>
      )}
      {(!!fonts?.length || isEditing) && (
        <Card
          title={t("brandkit.components.BrandKitAssets.fontAssets")}
          type="inner"
        >
          <Flex vertical gap={16}>
            <Flex wrap={"wrap"} gap={10}>
              {fonts?.map((font) => (
                <BrandKitAssetPreview
                  brandKitId={brandKitId}
                  key={font.asset_id}
                  asset={font}
                />
              ))}
            </Flex>
            {!!maxFontsUpload && uploadFontsRef && (
              <UploadFiles
                maxCount={maxFontsUpload}
                defaultItems={assets}
                setStagedItems={setStagedAssets}
                acceptedMimeTypes="font/ttf, .ttf"
                stagedItems={stagedAssets}
                itemRequestProperties={{
                  type: CREATE_ASSET_REQUEST_TYPE,
                  organization_id: organization?.id,
                  category: "Font",
                  source: AssetSource.UIGenerated,
                  asset: {
                    type: "Font",
                  },
                }}
                ref={uploadFontsRef}
                renderPreview={(
                  file,
                  stagedAssets,
                  setStagedAssets,
                  assets
                ) => {
                  return (
                    <BrandKitAssetPreview
                      brandKitId={brandKitId}
                      isNew
                      setStagedAssets={setStagedAssets}
                      stagedAssets={stagedAssets}
                      assets={assets as BrandKitAssetResponseWithTypedPurpose[]}
                      asset={{
                        file: file.originFileObj as File,
                        name: file.name,
                        type: "Font",
                        uid: file.uid,
                      }}
                    />
                  );
                }}
              />
            )}
          </Flex>
        </Card>
      )}
      {(!!videos?.length || isEditing) && (
        <Card
          title={t("brandkit.components.BrandKitAssets.videoAssets")}
          type="inner"
        >
          <Flex vertical gap={16}>
            <Flex wrap={"wrap"} gap={10}>
              {videos?.map((video) => (
                <BrandKitAssetPreview
                  brandKitId={brandKitId}
                  key={video.asset_id}
                  asset={video}
                />
              ))}
            </Flex>
            {!!maxVideosUpload && uploadVideoRef && (
              <UploadFiles
                defaultItems={assets}
                maxCount={maxVideosUpload}
                stagedItems={stagedAssets}
                setStagedItems={setStagedAssets}
                ref={uploadVideoRef}
                acceptedMimeTypes="video/mp4, .mp4"
                hint={
                  <Flex gap={10} vertical>
                    <InputHelperText>
                      {t("src.components.UploadFiles.uploadWillStart")}
                    </InputHelperText>
                    <Alert
                      message={t(
                        "brandkit.components.VideoPreview.916videoWarning"
                      )}
                      type="warning"
                      showIcon
                    />
                  </Flex>
                }
                itemRequestProperties={{
                  type: CREATE_ASSET_REQUEST_TYPE,
                  organization_id: organization?.id,
                  category: "Video",
                  source: AssetSource.UIGenerated,
                  asset: {
                    type: "Video",
                  },
                }}
                renderPreview={(
                  file,
                  stagedAssets,
                  setStagedAssets,
                  assets
                ) => {
                  return (
                    <BrandKitAssetPreview
                      isNew
                      brandKitId={brandKitId}
                      stagedAssets={stagedAssets}
                      setStagedAssets={setStagedAssets}
                      assets={assets as BrandKitAssetResponseWithTypedPurpose[]}
                      asset={{
                        file: file.originFileObj as File,
                        name: file.name,
                        type: "Video",
                        uid: file.uid,
                      }}
                    />
                  );
                }}
              />
            )}
          </Flex>
        </Card>
      )}
    </>
  );
};
