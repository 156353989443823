import {
  useOne,
  useTranslate,
  useNavigation,
  Authenticated,
} from "@refinedev/core";
import { useParams } from "react-router-dom";
import { MediaProjectResponse } from "pages/media/types";
import { Button, Flex, Card } from "antd";
import { DownloadSimple } from "@phosphor-icons/react";
import { createRef } from "react";
import { ProjectSubPageHeader } from "./ProjectSubPageHeader";
import { FatButton } from "components/FatButton";

export const DownloadMediaPage = () => {
  const { id, mediaId } = useParams();
  const t = useTranslate();
  const { show } = useNavigation();
  const linkRef = createRef<HTMLAnchorElement>();

  const { data: projectData } = useOne<MediaProjectResponse>({
    resource: `media/projects`,
    id: id,
  });
  const handleDownload = () => {
    return linkRef.current?.click();
  };

  const media = projectData?.data.media.find((item) => item.id === mediaId);
  return (
    <Authenticated key="project_download">
      <ProjectSubPageHeader
        projectId={id}
        projectTitle={projectData?.data.title}
        page="download"
      />

      <a
        ref={linkRef}
        rel="noreferrer"
        target="_blank"
        style={{ display: "none" }}
        download={media?.url}
        href={media.url}
      ></a>

      <Flex justify="start">
        <FatButton
          disabled={!media?.url}
          title={t("projects.components.DownloadButton.download")}
          key="download"
          onClick={handleDownload}
          icon={<DownloadSimple size={42} />}
        />
      </Flex>
    </Authenticated>
  );
};
