import { Typography } from "antd";
import styled from "styled-components";

interface LabelProps {
  wrap?: boolean;
}
export const Label = styled(Typography.Text)<LabelProps>`
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2px;
  font-weight: normal;
  white-space: ${(props) => (props.wrap ? "wrap" : "nowrap")};
`;
