import { Create, useForm } from "@refinedev/antd";
import { Authenticated, useNavigation, useTranslate } from "@refinedev/core";
import { Form, Input } from "antd";
import { useOrganization } from "hooks/useOrganization";
import { AssetLibrary } from "pages/media/types";
const CreateAssetLibraryPage: React.FC = () => {
  const { organization } = useOrganization({});
  const { onFinish } = useForm<AssetLibrary>({
    action: "create",
    resource: `/media/${organization?.id}/asset_libraries`,
  });
  const { edit } = useNavigation();
  const [form] = Form.useForm();
  async function handleFinish() {
    const result = await onFinish(form.getFieldsValue());
    if (result) {
      edit("media_asset_libraries", result?.data.id);
    }
  }
  const t = useTranslate();
  return (
    <Authenticated key="CreateAssetLibraryPage">
      <Create saveButtonProps={{ onClick: handleFinish }}>
        <Form form={form}>
          <Form.Item name="name" label={t("asset_libraries.name")}>
            <Input />
          </Form.Item>
        </Form>
      </Create>
    </Authenticated>
  );
};

export default CreateAssetLibraryPage;
