import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from "../TemplatePreview";

export const hexToRgba = (
  hex?: string,
  alpha = 255
): [number, number, number, number] => {
  if (!hex) return [0, 0, 0, 0];
  hex = hex.replace("#", "");

  // Extract the RGB components (first 6 characters)
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Extract the alpha component (last 2 characters) and convert to a value between 0 and 1
  if (hex.length > 6) {
    alpha = parseInt(hex.slice(6, 8), 16);
  }

  return [r, g, b, alpha] as [number, number, number, number];
};

export function rgbaToHex(rgba: [number, number, number, number] | string) {
  // workaround to simplify types from api returning tuples or string
  if (typeof rgba === "string") return rgba; // seem to be already a hex !

  const [r, g, b, a] = rgba;
  const redHex = r.toString(16).padStart(2, "0");
  const greenHex = g.toString(16).padStart(2, "0");
  const blueHex = b.toString(16).padStart(2, "0");

  // Convert alpha to a fraction (0-1), then multiply by 255 and convert to hex
  const alphaHex = a.toString(16).padStart(2, "0");

  return `#${redHex}${greenHex}${blueHex}${alphaHex}`;
}

export const getRectangleRelativeSize = (node: Element) => {
  const relativeWidth = parseFloat(
    (node.getBoundingClientRect().width / DEFAULT_WIDTH).toFixed(2)
  );
  const relativeHeight = parseFloat(
    (node.getBoundingClientRect().height / DEFAULT_HEIGHT).toFixed(2)
  );

  return {
    width: relativeWidth,
    height: relativeHeight,
  };
};

export const getFirstSentence = (text: string) => {
  if (!text) return "";

  const sentences = text.split(/[.!?]/);
  return sentences[0].trim();
};
