import { Button, Card, Col, Flex, Row, Spin, Typography } from "antd";
import { ChatTeardrop, EnvelopeSimple } from "@phosphor-icons/react";
import { Authenticated, useOne, useTranslate } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { useAntTheme } from "hooks/useAntTheme";
import { ProjectChatHistory } from "./components/ProjectChatHistory";
import { ProjectSubPageHeader } from "./components/ProjectSubPageHeader";
import { MediaProjectResponse } from "../types";

export const ProjectAnalyticsPage = () => {
  const { id } = useParams();
  const t = useTranslate();
  const { theme } = useAntTheme();
  const { data } = useOne<MediaProjectResponse>({
    resource: `media/projects`,
    id,
  });
  const project = data?.data;
  if (!project) return <Spin fullscreen />;

  return (
    <Authenticated key="projectKb">
      <ProjectSubPageHeader
        projectId={project.id}
        projectTitle={project.title}
        page="analytics"
      />
      <Flex vertical gap={32}>
        <Typography.Title level={4}>
          {t("media.projects.analytics.title")}
        </Typography.Title>

        <Row gutter={[24, 24]}>
          <Col span={20}>
            <Card
              title={
                <Flex align="center" gap={12}>
                  <ChatTeardrop size={32} weight="duotone" />
                  <Typography.Text strong style={{ fontSize: 20 }}>
                    {t("media.projects.analytics.chatHistory")}
                  </Typography.Text>
                </Flex>
              }
              styles={{
                header: { backgroundColor: theme.colorPrimaryBgHover },
              }}
              extra={[
                <Button
                  disabled
                  type="text"
                  key={"export"}
                  icon={<EnvelopeSimple size={32} weight="duotone" />}
                >
                  {t("media.projects.analytics.exportToEmail")}
                </Button>,
              ]}
            >
              <ProjectChatHistory projectId={id} enabled={true} />
            </Card>
          </Col>

          {/* <Col span={4}>
            <Card
            
            >
             </Card>
          </Col> */}
        </Row>
      </Flex>
    </Authenticated>
  );
};
