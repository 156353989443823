import { useForm as useAntForm } from "antd/es/form/Form";
import { Authenticated, useSetLocale, useTranslate } from "@refinedev/core";
import { useForm } from "@refinedev/antd";
import {
  Form,
  Input,
  Select,
  Button,
  Space,
  Typography,
  Card,
  Modal,
  Alert
} from "antd";
import { UserOutlined, DeleteOutlined } from "@ant-design/icons";
import { countries } from "countries-list";
import { useEffect, useState } from "react";
import DeleteAccount from "./DeleteAccount";
import useDeleteAccount from "./hooks/useDeleteAccount";
import { getCountryOptions } from "pages/media/utils";

const languages = ["en-US", "fr-FR"];

export const UserProfilePage = () => {
  const t = useTranslate();
  const setLocale = useSetLocale();
  const { formProps, saveButtonProps } = useForm({
    resource: "api/users",
    id: "me",
    action: "edit",
    successNotification: {
      type: "success",
      message: t("pages.users.edit.profileUpdated"),
      description: t("pages.users.edit.yourProfileHas"),
    },
    errorNotification: {
      type: "error",
      message: t("pages.users.edit.updateFailed"),
      description: t("pages.users.edit.failedToUpdate"),
    },
    onMutationSuccess(data, variables: { language: string }) {
      setLocale(variables.language);
    },
  });
  const countriesOptions = getCountryOptions();
  const languageOptions = languages.map((lang) => {
    const [languageName, countryName] = lang.split("-");
    const nativeLanguageName = t(`nativeLanguageNames.${languageName}`);
    const nativeCountryName = t(`nativeCountryNames.${countryName}`);
    return {
      label:
        nativeLanguageName +
        (nativeCountryName ? ` (${nativeCountryName})` : ""),
      value: lang,
    };
  });
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const { handleDeleteAccount, isLoading } = useDeleteAccount();
  const [form] = useAntForm();
  const [deleteAccountButtonDisabled, setDeleteAccountButtonDisabled] =
    useState(true);
  useEffect(() => {
    form.resetFields();
  }, [deleteAccountModalOpen, form]);
  return (
    <Authenticated key="user_edit">
      <Form
        {...formProps}
        initialValues={{
          ...formProps.initialValues,
        }}
        // onFieldsChange={(changedFields) => {
        //   if (changedFields[0]?.name[0] === "language") {
        //     i18n.changeLanguage(changedFields[0].value);
        //   }
        // }}
        layout="vertical"
        style={{ maxWidth: 400 }}
      >
        <Typography.Title level={3}>
          {t("pages.user.edit.profileDetails")}
        </Typography.Title>
        <Space
          direction="vertical"
          size="middle"
          style={{ display: "flex", width: "100%" }}
        >
          <Form.Item
            name="first_name"
            label={t("pages.user.edit.firstName")}
            rules={[
              {
                required: false,
                message: t("pages.user.edit.pleaseInputYourFirstName"),
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder={t("pages.user.edit.firstName")}
            />
          </Form.Item>

          <Form.Item
            name="last_name"
            label={t("pages.user.edit.lastName")}
            rules={[
              {
                required: false,
                message: t("pages.user.edit.pleaseInputYourLastName"),
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder={t("pages.user.edit.lastName")}
            />
          </Form.Item>

          <Form.Item
            name="language"
            label={t("pages.user.edit.language")}
            rules={[
              {
                required: false,
                message: t("pages.user.edit.pleaseSelectYourLanguage"),
              },
            ]}
          >
            <Select
              placeholder={t("pages.user.edit.selectLanguage")}
              options={languageOptions}
            />
          </Form.Item>

          <Form.Item
            name="country"
            label={t("pages.user.edit.country")}
            rules={[
              {
                required: false,
                message: t("pages.user.edit.pleaseSelectYourCountry"),
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={t("pages.user.edit.pleaseSelectACountry")}
              options={countriesOptions}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              {...saveButtonProps}
              type="primary"
              block
              loading={saveButtonProps.loading}
            >
              {t("pages.user.edit.saveChanges")}
            </Button>
          </Form.Item>
          <Alert 
          type="error"
          message={t("pages.user.edit.dangerZone")}
          description={
            <Button
            icon = {<DeleteOutlined />}
            type="default"
            danger
            onClick={() => setDeleteAccountModalOpen(true)}
          >
            {t("pages.user.edit.deleteAccount")}
          </Button>
          }
          />
        </Space>
      </Form>
      <Modal
        okButtonProps={{
          danger: true,
          disabled: deleteAccountButtonDisabled,
        }}
        confirmLoading={isLoading}
        okText={t("pages.user.edit.deleteAccount")}
        onOk={() => handleDeleteAccount(form.getFieldValue("password"))}
        open={deleteAccountModalOpen}
        onCancel={() => setDeleteAccountModalOpen(false)}
        title={t("pages.user.edit.deleteAccount")}
      >
        <DeleteAccount
          form={form}
          onChange={() => {
            setDeleteAccountButtonDisabled(!form.getFieldValue("password"));
          }}
        />
      </Modal>
    </Authenticated>
  );
};
