import { useTransition } from "react";
import {
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useList,
  useOne,
  useTranslate,
} from "@refinedev/core";
import {
  Alert,
  Button,
  ConfigProvider,
  Flex,
  Form,
  Input,
  InputRef,
  List,
  Modal,
  Segmented,
  Select,
  SelectProps,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMediaAssetsStorage } from "hooks/useMediaAssetsStorage";
import {
  BrandKit,
  ChatMode,
  ContactResponse,
  MediaAsset,
  MediaGenerationMode,
  MediaProject,
  MediaProjectResponse,
  PresenterProfileResponse,
  ToneResponse,
  Transitions,
} from "pages/media/types";
import {
  getLocaleCountryCode,
  secToHHMMSS,
  validateSelectedPages,
} from "pages/media/utils";
import {
  MAXIMUM_STORYBOARD_VIDEO_DURATION,
  SPEECH_WORDS_PER_MINUTE,
} from "pages/media/constants";
import { TONES } from "data/tones";
import {
  Book,
  BookBookmark,
  BookOpenUser,
  Chat,
  Files,
  Exclude,
  HeadCircuit,
  LightbulbFilament,
  Slideshow,
  Sparkle,
  Upload,
  Video,
  ChatText,
} from "@phosphor-icons/react";
import { BrandKitPreview } from "pages/media/components/BrandKitPreview";
import { useSupportedLanguages } from "../../../../hooks/useSupportedLanguages";
import {
  ArrowDownOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { BrandKitEditWithoutLayout } from "pages/media/brandkit/edit";
import { useAntTheme } from "hooks/useAntTheme";
import {
  BRANDKIT_HEIGHT_TO_WIDTH_RATIO,
  BrandKitMockup,
} from "pages/media/brandkit/components/BrandKitMockup";
import { BrandKitAssetResponseWithTypedPurpose } from "pages/media/brandkit/components/BrandKitAssets";
import { BrandKitCreateWithoutLayout } from "pages/media/brandkit/create";
import { PresenterPreview } from "pages/media/contacts/detail";
import { TransitionPreview } from "./TransitionPreview";
import { SimpleCreateContactWithoutLayout } from "pages/media/components/ContactWithPresenter/PageComponent";
import { useProjectWizardStore } from "../useProjectWizardStore";
import { useParams } from "react-router-dom";
import ContactPreStep from "./ContactPreStep";
import { PresenterProfilePreview } from "pages/media/components/PresenterProfilePreview";
import { VoiceProfilePreview } from "pages/media/components/VoiceProfilePreview";
import {
  StyledListWrapper,
  StyledSegmentedCardWrapper,
  StyledDurationSlider,
} from "./styles";
import i18next from "i18next";
import styled from "styled-components";
type ContactStepView = "gallery" | "prestep" | "list";

export type ProjectSettingsItemType =
  | "CallToActions"
  | "Duration"
  | "BrandKit"
  | "Tone"
  | "Brief"
  | "Language"
  | "Contacts"
  | "NewStoryboard"
  | "Disclaimers"
  | "PreserveDocument"
  | "Pages"
  | "Transitions"
  | "Experience";

export function ProjectSettingForm<T>(
  props: Readonly<{
    value: T | undefined;
    options?: {
      label: string;
      value: T;
    }[];
    onClose: () => void;
    title: string;
    subtitle?: ReactNode;
    extra?: ReactNode;
    icon?: ReactNode;
    type: ProjectSettingsItemType;
    projectId: string;
    submitButtonLabel?: ReactNode;
    cancelButtonLabel?: ReactNode;
    onCancel?: () => void;
    cancellable?: boolean;
    onClickContactsButton?: () => void;
    show?: Record<"transitions", boolean>;
    isWizard?: boolean;
    isEdit?: boolean;
  }>
) {
  const t = useTranslate();
  const API_URL = useApiUrl();
  const [value, setValue] = useState<T | undefined>(props.value);
  const { mutateAsync, isLoading: isMutateLoading } = useCustomMutation();
  const invalidate = useInvalidate();
  const { data: projectData } = useOne<MediaProjectResponse>({
    resource: "media/projects",
    id: props.projectId,
    queryOptions: {
      enabled: !!props.projectId,
    },
  });
  const locked = projectData?.data
    ? projectData?.data?.settings.status === "Approved" &&
      // allow edit of these settings for storyboard
      ![
        "Tone",
        "Duration",
        "Brief",
        "Language",
        "Pages",
        "PreserveDocument",
        "Contacts",
        "CallToActions",
        "BrandKit",
      ].includes(props.type)
    : true;
  const isValid = value !== null;
  const { supportedLanguagesOptions } = useSupportedLanguages();

  const requestNewMedia = async (
    projectId: string,
    contactIds: string[],
    language: string | undefined
  ) => {
    await mutateAsync({
      url: `${API_URL}/media/projects/${projectId}/media`,
      method: "post",
      values: contactIds.map((contactId) => ({
        language,
        contact_id: contactId,
      })),
    });
  };

  const handleSave = async () => {
    let values = {};
    let url = `${API_URL}/media/projects/${props.projectId}/settings`;

    if (props.type === "Duration") {
      values = {
        duration: value,
      };
    } else if (props.type === "CallToActions") {
      values = {
        cta_group_id: value,
      };
    } else if (props.type === "Brief") {
      values = { description: value };
      url = `${API_URL}/media/projects/${props.projectId}`;
    } else if (props.type === "BrandKit") {
      values = { brand_kit_id: value };
    } else if (props.type === "Language") {
      values = { language: value };
    } else if (props.type === "Tone") {
      values = { tone_id: value };
    } else if (props.type === "PreserveDocument") {
      values = { preserve_original_doc: value };
    } else if (props.type === "Pages") {
      //(value as string)?.length > 0 ? value : null
      values = { selected_pages: value };
    } else if (props.type === "Transitions") {
      values = { transitions: value };
    } else if (props.type === "Experience") {
      const experiences = value as string;

      //TODO type
      const media_generation_mode: MediaGenerationMode = experiences.includes(
        "upload"
      )
        ? "Disabled"
        : "Video";
      const chat_mode: ChatMode = experiences.includes("chat")
        ? "FullChat"
        : "Disabled";
      const include_document_animations = experiences.includes("doc");

      values = {
        media_generation_mode,
        chat_mode,
        include_document_animations,
      };
    }

    if (props.type === "Contacts") {
      const language = projectData?.data.settings.language;
      if (language) {
        // when settings are approved, we can make media creation requests
        if (
          projectData.data?.settings.status === "Approved" &&
          Array.isArray(value)
        ) {
          const existingMedia = projectData.data.media ?? [];
          const existingContacts = existingMedia.map((media) => ({
            contactId: media.contact_id,
            language: media.language,
          }));
          const newContacts = value.filter(
            (contactId) =>
              !existingContacts.some(
                (media) =>
                  media.contactId === contactId && media.language === language
              )
          );

          await requestNewMedia(
            props.projectId,
            newContacts as string[],
            language
          );
        } else {
          // MED-1044: we can't get contacts from media, so we get them
          // from the select (i.e. we're creating a new project)
          await mutateAsync({
            url,
            method: "patch",
            values: { contact_id: value },
          });
        }
      } else {
        console.error("Language is undefined in projectData.settings");
      }
    } else if (props.type === "NewStoryboard") {
      await mutateAsync({
        url: `${API_URL}/media/projects/${props.projectId}/storyboards/${value}`,
        method: "post",
        values: {},
      });
    } else {
      // other settings
      await mutateAsync({
        url,
        method: "patch",
        values,
      });
    }

    invalidate({
      resource: "media/projects",
      id: props.projectId,
      invalidates: ["detail"],
    });

    props.onClose();
  };

  const handleChange = (value: T) => {
    setValue(value);
  };

  const [form] = Form.useForm();
  return (
    <Form onFinish={handleSave} form={form} colon={false}>
      <Flex vertical gap={0}>
        <Space size={"middle"} align="center">
          {props.icon}
          <Typography.Text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
            {props.title}
          </Typography.Text>
        </Space>
        <Flex vertical gap={30}>
          <Typography.Text type="secondary">{props.subtitle}</Typography.Text>
          {props.type === "CallToActions" && (
            <ProjectSettingsFormCallToActions<T>
              value={value}
              onChange={handleChange}
              project={projectData?.data}
            />
          )}
          {props.type === "Duration" && (
            <ProjectSettingsFormDuration<T>
              value={value}
              onChange={handleChange}
            />
          )}
          {props.type === "Tone" && (
            <ProjectSettingsFormTone<T> value={value} onChange={handleChange} />
          )}
          {props.type === "Experience" && (
            <ProjectSettingsFormExperience<T>
              value={value}
              onChange={handleChange}
            />
          )}
          {props.type === "BrandKit" && (
            <ProjectSettingsFormBrandKit<T>
              project={projectData?.data}
              value={value}
              onChange={handleChange}
              showTransitions={props.show?.transitions === true}
            />
          )}
          {props.type === "Transitions" && (
            <ProjectSettingsFormTransitions<T>
              name={"transitions"}
              valuePropName="checked"
              value={value}
              initialValue={value}
              onChange={handleChange}
              project={projectData?.data}
            />
          )}
          {props.type === "Brief" && (
            <ProjectSettingsFormBrief<T>
              value={value}
              onChange={handleChange}
            />
          )}
          {props.type === "Language" && (
            <ProjectSettingsFormLanguage<T>
              value={value}
              onChange={handleChange}
              options={supportedLanguagesOptions}
            />
          )}
          {props.type === "Contacts" && (
            <ProjectSettingsFormContacts<T>
              project={projectData?.data}
              value={value}
              onChange={handleChange}
              items={[]}
              isSetup={props.isWizard}
              isEdit={props.isEdit}
              initialValue={props.value}
            />
          )}
          {props.type === "Pages" && (
            <ProjectSettingsFormSelectedPages<T>
              value={value}
              onChange={handleChange}
            />
          )}
          {props.type === "PreserveDocument" && (
            <ProjectSettingsFormPreserveDocument<T>
              label={t(
                "projects.components.ProjectSettingForm.contentExtraction"
              )}
              name={"preserve_document"}
              valuePropName="checked"
              value={value} // Assert value is of type T
              initialValue={value}
              onChange={handleChange}
            />
          )}

          {props.type === "NewStoryboard" && projectData?.data && (
            <ProjectSettingsFormLanguage<T>
              value={value}
              onChange={handleChange}
              options={supportedLanguagesOptions.filter(
                (language: { value: string }) =>
                  !projectData?.data?.storyboards
                    .map((sb) => sb.language)
                    .includes(language.value)
              )}
            />
          )}

          {props.extra}
          <Flex justify="space-between">
            {props.cancellable !== false ? (
              <Button
                onClick={props.onCancel ?? props.onClose}
                type="text"
                shape="round"
                size="large"
              >
                {props.cancelButtonLabel ?? t("buttons.cancel")}
              </Button>
            ) : (
              <div></div>
            )}
            {!locked && (
              <Button
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                loading={isMutateLoading}
                disabled={isMutateLoading || !isValid}
              >
                {props.submitButtonLabel ?? t("buttons.save")}
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Form>
  );
}

function ProjectSettingsFormCallToActions<T>(
  props: Readonly<{
    value: T | undefined;
    onChange: (value: T) => void;
    project?: MediaProjectResponse;
  }>
) {
  const {
    organizationAssets: { data, isLoading },
  } = useMediaAssetsStorage({
    organizationId: props.project?.organization_id,
    assetType: "CallToActions",
    enabled: Boolean(props.project?.organization_id),
  });

  return (
    <StyledListWrapper>
      <List<MediaAsset>
        split={false}
        size="small"
        loading={isLoading}
        dataSource={data?.data}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            onClick={() => props?.onChange(item.id as T)}
            className={props.value === item.id ? "active" : ""}
            style={{
              borderRadius: 6,
              marginBottom: 10,
              paddingLeft: 10,
            }}
          >
            {item.description || item.id}
          </List.Item>
        )}
      />
    </StyledListWrapper>
  );
}

function ProjectSettingsFormBrandKit<T>(
  props: Readonly<{
    project?: MediaProjectResponse;
    value: T | undefined;
    onChange: (value: T) => void;
    showTransitions?: boolean;
  }>
) {
  const [brandKitModal, setBrandKitModal] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [saveCallback, setSaveCallback] = useState<
    () => Promise<BrandKit | void>
  >(() => () => Promise.resolve());
  const { data, isLoading } = useList<BrandKit>({
    resource: `media/${props.project?.organization_id}/brand_kits`,
    queryOptions: {
      enabled: !!props.project?.organization_id,
    },
  });
  const t = useTranslate();
  const sortedData = useMemo(
    () => data?.data?.sort((a, b) => a.name.localeCompare(b.name)),
    [data?.data]
  );
  const [isNewBrandkit, setIsNewBrandkit] = useState(false);
  const [newBrandkit, setNewBrandkit] = useState<BrandKit | undefined>();

  const {
    mutateAsync: setProjectsTransitionType,
    isLoading: isLoadingProjectTransitionType,
  } = useCustomMutation();

  const component = () => {
    if (isTransitioning) {
      return (
        <Flex
          justify="center"
          align="center"
          style={{ width: "100%", height: "100%" }}
        >
          {" "}
          <Spin />
        </Flex>
      );
    }
    if (isNewBrandkit) {
      return <BrandKitCreateWithoutLayout setSaveCallback={setSaveCallback} />;
    } else {
      return (
        <BrandKitEditWithoutLayout
          brandKitId={newBrandkit?.id ?? (props.value as string)}
          organizationId={props.project?.organization_id}
          onSuccess={() => {
            props?.onChange(newBrandkit?.id as T);
          }}
          setSaveCallback={setSaveCallback}
        />
      );
    }
  };

  const invalidate = useInvalidate();
  const handleTransitionChange = async (value: string) => {
    if (!props.project) return;
    await setProjectsTransitionType({
      url: `media/projects/${props.project.id}/settings`,
      method: "patch",
      values: {
        transitions: value,
      },
    });
    invalidate({
      resource: "media/projects",
      id: props.project?.id,
      invalidates: ["detail"],
    });
  };

  return (
    <>
      <StyledListWrapper columnSize={750 / 3 - 20}>
        <List
          size="small"
          split={false}
          // grid={{ gutter: 16, column: 3 }}
          loading={isLoading}
          dataSource={sortedData}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              onClick={() => props?.onChange(item.id as T)}
              className={props.value === item.id ? "active" : ""}
              style={{
                alignContent: "start",
              }}
            >
              <Space align="center">
                <BrandKitPreview brandkit={item} showName={false} />
                <Typography.Text ellipsis>
                  {item.name || item.id}
                </Typography.Text>
                <Button
                  key="edit"
                  onClick={() => {
                    setBrandKitModal(true);
                  }}
                  type="text"
                  icon={<EditOutlined />}
                ></Button>
              </Space>
              <BrandKitMockup
                assets={
                  item?.assets as
                    | BrandKitAssetResponseWithTypedPurpose[]
                    | undefined
                }
                colors={item?.palette}
                width={150}
                style={{
                  margin: "10px auto",
                }}
              />
            </List.Item>
          )}
        />
      </StyledListWrapper>

      <Flex justify="center">
        <Button
          size="large"
          onClick={() => {
            setIsNewBrandkit(true);
            setBrandKitModal(true);
          }}
          shape="round"
          icon={<PlusOutlined />}
          type="default"
        >
          {t("projects.components.ProjectSettingForm.createANewBrandkit")}
        </Button>
      </Flex>

      {/* custom UI to add transition flags (Steve) */}
      {props.showTransitions && (
        <Flex vertical style={{ marginTop: 40 }} gap={10}>
          <Flex vertical>
            <Typography.Text strong>
              {t("projects.components.ProjectSettings.transition")}
            </Typography.Text>
            <Typography.Text type="secondary">
              {t("projects.components.ProjectSettings.chooseTransition")}
            </Typography.Text>
          </Flex>
          <ProjectSettingsFormTransitions<Transitions>
            loading={isLoadingProjectTransitionType}
            onChange={handleTransitionChange}
            value={props.project?.settings.transitions ?? "Basic"}
            name={"transitions"}
            valuePropName="checked"
            initialValue={props.project?.settings.transitions ?? "Basic"}
          />
        </Flex>
      )}
      <Modal
        onCancel={() => setBrandKitModal(false)}
        afterOpenChange={(open) => {
          if (!open) {
            setBrandKitModal(false);
          }
        }}
        okText={t("buttons.next")}
        onOk={() => handleModalOk()}
        title={t("projects.components.ProjectSettingForm.createBrandkit")}
        width={"85vw"}
        open={brandKitModal}
        styles={{
          body: {
            display: "flex",
          },
        }}
      >
        {component()}
      </Modal>
    </>
  );

  async function handleModalOk() {
    try {
      const response = await saveCallback();
      if (response) {
        setNewBrandkit(response);
        if (isNewBrandkit) {
          setIsTransitioning(true);
          props?.onChange(response.id as T);
          setTimeout(() => {
            setIsTransitioning(false);
          }, 500);
        } else {
          setBrandKitModal(false);
        }
        setIsNewBrandkit(false);
        return;
      }
    } catch {
      // Don't close the modal on error
      return;
    }
    setBrandKitModal(false);
  }
}

function ProjectSettingsFormExperience<T>(props: {
  value: T | undefined;
  onChange: (value: T) => void;
}) {
  const t = useTranslate();

  // TODO restore value on edit and show default value

  const items = [
    {
      icon: <Video size={60} weight="duotone" />,
      label: t("projects.components.ProjectSettingForm.videoOnly"),
      value: "video",
    },
    {
      icon: (
        <Space>
          <Video size={60} weight="duotone" />
          <Files size={60} weight="duotone" />
        </Space>
      ),
      label: t("projects.components.ProjectSettingForm.videoDoc"),
      value: "video_doc",
    },
    {
      icon: (
        <Space>
          <Video size={60} weight="duotone" />
          <Files size={60} weight="duotone" />
          <Chat size={60} weight="duotone" />
        </Space>
      ),
      label: t("projects.components.ProjectSettingForm.videoDocChat"),
      value: "video_doc_chat",
    },
    // {
    //   icon: <Upload size={60} weight="duotone" />,
    //   label: t("projects.components.ProjectSettingForm.uploadVideo"),
    //   value: "upload_video",
    // },
  ];
  // const isActive = (item: (typeof items)[number]) => {
  //   const { media_generation_mode, include_document_animations, chat_mode } =
  //     props.project.settings;
  //   switch (item.value) {
  //     case "video":
  //       return (
  //         include_document_animations === false &&
  //         media_generation_mode === "Video" &&
  //         chat_mode === "Disabled"
  //       );
  //     case "video_doc":
  //       return (
  //         include_document_animations === true &&
  //         media_generation_mode === "Video" &&
  //         chat_mode === "Disabled"
  //       );
  //     case "video_doc_chat":
  //       return (
  //         include_document_animations === true &&
  //         media_generation_mode === "Video" &&
  //         chat_mode !== "Disabled"
  //       );
  //     default:
  //       return false;
  //   }
  // };

  return (
    <StyledListWrapper>
      <List
        split={false}
        size="small"
        dataSource={items}
        renderItem={(item) => (
          <List.Item
            key={item.value}
            onClick={() => props.onChange(item.value as T)}
            className={item.value === props.value ? "active" : ""}
            style={{
              borderRadius: 6,
              minHeight: 170,
              textAlign: "center",
            }}
          >
            <div>{item.icon}</div>
            <List.Item.Meta title={item.label} />
          </List.Item>
        )}
      />
    </StyledListWrapper>
  );
}

function ProjectSettingsFormTone<T>(
  props: Readonly<{
    value: T | undefined;
    onChange: (value: T) => void;
  }>
) {
  const t = useTranslate();
  const { data, isLoading } = useList<ToneResponse>({
    resource: `media/tones`,
  });

  const items = data?.data as {
    id: string;
    description: string;
  }[];
  const knownTones = Object.keys(TONES);
  const sortedItems = items?.toSorted(function (a, b) {
    return knownTones.indexOf(a.id) - knownTones.indexOf(b.id);
  });
  const pageSize = 6;
  const activeItemIndex = knownTones.indexOf(props.value as string);

  const [showAll, setShowAll] = useState(activeItemIndex >= pageSize); //show all if current item is not in first page
  const displayedItems = showAll
    ? sortedItems
    : sortedItems?.slice(0, pageSize);

  return (
    <StyledListWrapper columnSize={750 / 2 - 20}>
      <List<{
        id: string;
      }>
        size="small"
        split={false}
        // grid={{ gutter: 16, column: 2 }}
        loading={isLoading}
        dataSource={displayedItems}
        renderItem={(item) => {
          const Icon = TONES[item.id]?.icon;
          const toneData = t(`src.data.tones.${item.id}`, {
            returnObjects: true,
            defaultValue: {},
          }) as unknown as { name: string; characteristics: string[] };

          return (
            <List.Item
              key={item.id}
              onClick={() => props.onChange(item.id as T)}
              className={props.value === item.id ? "active" : ""}
              style={{
                // marginBottom: 10,
                // paddingLeft: 10,
                paddingTop: 10,
                paddingBottom: 10,
                flexDirection: "row", // to revert custom styled styles
              }}
            >
              <List.Item.Meta
                avatar={
                  Icon ? (
                    <Icon
                      size={50}
                      weight="duotone"
                      // color={theme.colorPrimary}
                    />
                  ) : null
                }
                title={toneData ? toneData?.name : item.id}
                description={toneData?.characteristics?.join(" • ") ?? ""}
              />
            </List.Item>
          );
        }}
      />
      {!showAll && sortedItems && sortedItems.length > pageSize && (
        <Flex justify="center" style={{ width: "100%" }}>
          <Button
            type="default"
            shape="round"
            onClick={() => setShowAll(true)}
            style={{ marginTop: 20 }}
            icon={<ArrowDownOutlined />}
          >
            {t("projects.components.ProjectSettingForm.seeMoreTones")}
          </Button>
        </Flex>
      )}
    </StyledListWrapper>
  );
}

function ProjectSettingsFormBrief<T>(
  props: Readonly<{
    value: T | undefined;
    onChange: (value: T) => void;
  }>
) {
  const t = useTranslate();
  return (
    <Flex vertical gap={60}>
      <Input.TextArea
        value={props.value as string}
        onChange={(e) => props.onChange(e.target.value as T)}
        rows={4}
      />
      <Alert
        type="info"
        showIcon
        icon={<LightbulbFilament size={40} />}
        description={
          <Flex vertical gap={10}>
            <Typography.Text>
              {t("projects.components.ProjectSettingForm.example")}
            </Typography.Text>
            <Typography.Text type="secondary" copyable>
              {t("projects.components.ProjectSettingForm.weWantTo")}
            </Typography.Text>
          </Flex>
        }
      />
    </Flex>
  );
}
function ProjectSettingsFormLanguage<T>(
  props: Readonly<{
    value: T | undefined;
    onChange: (value: T) => void;
    options: {
      value: string;
      label: string;
      description?: string;
    }[];
  }>
) {
  return (
    <StyledListWrapper columnSize={750 / 2 - 20}>
      <List
        size="small"
        split={false}
        // grid={{ gutter: 16, column: 2 }}
        dataSource={props.options}
        renderItem={(item) => (
          <List.Item
            onClick={() => props.onChange(item.value as T)}
            className={props.value === item.value ? "active" : ""}
            style={{
              flexDirection: "row",
              borderRadius: 6,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            {/* avatar={item.icon} */}
            <List.Item.Meta
              avatar={
                <div
                  className={`fi-${getLocaleCountryCode(item.description)}`}
                  style={{
                    width: "48px",
                    height: "40px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              }
              title={item.label}
              description={item.description}
            />
          </List.Item>
        )}
      />
    </StyledListWrapper>
  );
}

const GradientText = styled(Typography.Title)`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colorText},
    #3a00de
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export type PromptTemplate = { id: string; prompt: string; category: string };
export const ProjectPromptTemplates = <T,>({
  onChange,
  value,
}: {
  onChange: (value: T) => void;
  value?: T;
}) => {
  const t = useTranslate();
  const { theme } = useAntTheme();
  const ns = "project-prompt-templates";
  const [prompts, setPrompts] = useState<PromptTemplate[]>();
  const [items, setItems] = useState<PromptTemplate[]>();
  const [categories, setCategories] = useState<string[]>();
  const [currentCategory, setCurrentCategory] = useState<string>();
  const [isPending, startTransition] = useTransition();

  const fetchData = React.useCallback(async () => {
    await i18next.loadNamespaces(ns);
    const promptsByCategory = t("prompts", {
      ns,
      returnObjects: true,
    }) as unknown as Record<string, PromptTemplate[]>;
    const cats = t("categories", {
      ns,
      returnObjects: true,
    }) as unknown as string[];
    const flatPrompts = Object.entries(promptsByCategory).flatMap(
      ([category, prompts]) => prompts.map((x) => ({ ...x, category }))
    );
    setPrompts(flatPrompts);
    setCategories(Object.keys(cats));
  }, [t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!currentCategory) return;

    startTransition(() => {
      setItems(prompts.filter((x) => x.category === currentCategory));
    });
  }, [currentCategory, prompts]);

  return (
    <StyledListWrapper columnSize={750 / 3 - 20}>
      <Flex vertical gap={10}>
        <Flex align="center" gap={10}>
          <ChatText size={40} weight="duotone" style={{ marginTop: 10 }} />
          <GradientText level={4}>
            {t("projects.components.ProjectCreateWizard.pickUseCase")}
          </GradientText>
        </Flex>
        {items && (
          <Flex>
            <List
              size="small"
              dataSource={items}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  className={value === item.id ? "active" : ""}
                  onClick={() => onChange(item as T)}
                  style={{
                    alignContent: "start",
                    borderStyle: "dashed",
                    backgroundColor: "transparent", //theme.colorBgContainer,
                  }}
                >
                  <List.Item.Meta
                    avatar={<ChatText size={20} weight="fill" />}
                  />
                  <Typography.Text>{item.prompt}</Typography.Text>
                </List.Item>
              )}
            />
          </Flex>
        )}

        <List
          size="small"
          loading={!categories}
          dataSource={categories}
          renderItem={(item, index) => (
            <List.Item
              key={index}
              className={item === currentCategory ? "active" : ""}
              onClick={() => setCurrentCategory(item)}
              style={{
                alignContent: "start",
              }}
            >
              <Typography.Text strong>
                {t("categories." + item, {
                  ns,
                })}
              </Typography.Text>
            </List.Item>
          )}
        />
      </Flex>
    </StyledListWrapper>
  );
};
const ProjectSettingsFormSelectedPages = <T,>({
  onChange,
  value,
}: {
  onChange: (value: T) => void;
  value?: T;
}) => {
  const t = useTranslate();
  const [includeAllPages, setIncludeAllPages] = useState(
    String(value).length === 0
  );
  const pagesInputRef = useRef<InputRef>(null);

  const validator = (_: unknown, value: string) => {
    // accept empty values to keep all pages when toggle is on
    if (includeAllPages || !value || value.length === 0)
      return Promise.resolve(true);

    const result = validateSelectedPages(value);
    if (result === false) {
      return Promise.reject(
        new Error(
          t("projects.components.ProjectCreateWizard.enterValidNumbers")
        )
      );
    }
    return Promise.resolve(result);
  };
  const handleChange = (value: boolean) => {
    setIncludeAllPages(value);
    if (value) {
      // all pages, send empty list of pages
      onChange("" as T);
    }
  };

  useEffect(() => {
    if (!includeAllPages) {
      setTimeout(() => pagesInputRef?.current?.focus(), 1000); // autofocus
    }
  }, [includeAllPages]);

  return (
    <StyledSegmentedCardWrapper>
      <Flex vertical gap={30}>
        <Form.Item>
          <Segmented<boolean>
            block
            options={[
              {
                label: (
                  <Flex vertical align="center" gap={10}>
                    <Book size={80} weight="duotone" />
                    {t(
                      "projects.components.ProjectCreateWizard.includeAllPages"
                    )}
                  </Flex>
                ),
                value: true,
              },
              {
                label: (
                  <Flex vertical align="center" gap={10}>
                    <BookBookmark size={80} weight="duotone" />
                    {t("projects.components.ProjectCreateWizard.specificPages")}
                  </Flex>
                ),
                value: false,
              },
            ]}
            onChange={handleChange}
            value={includeAllPages}
          />
        </Form.Item>

        {!includeAllPages && (
          <Flex vertical gap={20}>
            <Form.Item
              initialValue={value as string}
              style={{ marginBottom: 0 }}
              name="selectedPages"
              rules={[{ validator }]}
            >
              <Input
                ref={pagesInputRef}
                size="large"
                onChange={(event) => onChange(event.target.value as T)}
                placeholder={t(
                  "projects.components.ProjectCreateWizard.numberOfPages"
                )}
              />
            </Form.Item>

            <Alert
              type="info"
              showIcon
              icon={<LightbulbFilament size={40} />}
              description={
                <Flex vertical gap={10}>
                  <Typography.Text>
                    {t("projects.components.ProjectSettingForm.keepEmptyTo")}
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    {t(
                      "projects.components.ProjectSettingForm.example12510Numbers"
                    )}
                  </Typography.Text>
                </Flex>
              }
            />
          </Flex>
        )}
      </Flex>
    </StyledSegmentedCardWrapper>
  );
};

function ProjectSettingsFormDuration<T>(
  props: Readonly<{
    value: T | undefined;
    onChange: (value: T) => void;
  }>
) {
  const t = useTranslate();
  const { theme } = useAntTheme();

  return (
    <div style={{ marginTop: 60, marginBottom: 0 }}>
      <ConfigProvider
        theme={{
          components: {
            Slider: {
              handleSize: 24,
              handleSizeHover: 28,
              railSize: 20,
              dotSize: 24,
              handleLineWidth: 5,
            },
          },
        }}
      >
        <StyledDurationSlider
          value={props.value as number}
          max={MAXIMUM_STORYBOARD_VIDEO_DURATION}
          min={30}
          step={10}
          // marks={marks}
          onChange={(value) => props.onChange(value as T)}
          tooltip={{
            placement: "top",
            color: "transparent",
            showArrow: false,
            getPopupContainer: (triggerNode) => {
              return triggerNode;
            },
            overlayStyle: {
              // background: "none",
              // boxShadow: "none",
              border: "1px solid red",
              borderColor: theme.colorPrimary,
              borderRadius: 10,
              userSelect: "none",
            },
            open: true,
            formatter: (value) => (
              <Flex
                vertical
                style={{
                  textAlign: "center",
                  padding: "5px 20px",
                }}
              >
                <Typography.Text style={{ fontSize: 20 }}>
                  {value ? secToHHMMSS(value) : ""}
                </Typography.Text>
                {value && (
                  <Typography.Text type="secondary">
                    ≈ {Math.floor((value / 60) * SPEECH_WORDS_PER_MINUTE)}{" "}
                    {t("projects.components.ProjectSettingForm.words")}
                  </Typography.Text>
                )}
              </Flex>
            ),
          }}
        />
      </ConfigProvider>
    </div>
  );
}

type TagRender = SelectProps["tagRender"];

function ProjectSettingsFormContacts<T>(
  props: Readonly<{
    value: T | undefined;
    initialValue: T | undefined;
    onChange: (value: T) => void;
    items: {
      value: string;
      name: string;
      description: string;
    }[];
    project?: MediaProjectResponse;
    isSetup?: boolean;
    isEdit?: boolean;
  }>
) {
  const t = useTranslate();
  const { data, isLoading } = useList<ContactResponse>({
    resource: `media/${props.project?.organization_id}/contacts`,
    queryOptions: {
      enabled: !!props.project?.organization_id,
    },
  });
  const [newContact, setNewContact] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string | null>();
  const [editingContactId, setEditingContactId] = useState<string | null>();

  const tagRender: TagRender = (propsTag) => {
    const { label, value, closable, onClose } = propsTag;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        style={{ fontSize: 14 }}
        onMouseDown={onPreventMouseDown}
        closable={
          // prevent removing current values
          closable && !(props.initialValue as string[])?.includes(value)
        }
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };
  const { data: presenter, isLoading: isLoadingPresenter } =
    useOne<PresenterProfileResponse>({
      resource: `media/${props.project?.organization_id}/presenter_profiles`,
      id: data?.data?.[0]?.presenter_id,
      queryOptions: {
        enabled: Boolean(data?.data?.[0]?.presenter_id),
      },
    });
  const [contactsModal, setContactsModal] = useState(false);
  const [hasSeenContactModal, setHasSeenContactModal] = useState(false);
  useEffect(() => {
    if (isLoadingPresenter) return;
    const hasOnlyOneContact = data?.data.length === 1;
    const presenterIsDefault =
      presenter?.data?.organization_id !== props?.project?.organization_id;
    const lacksValidContact = hasOnlyOneContact && presenterIsDefault;
    if (!contactsModal && lacksValidContact && !hasSeenContactModal)
      setContactsModal(true);
    setHasSeenContactModal(true);
  }, [
    presenter?.data,
    data?.data?.length,
    isLoadingPresenter,
    props?.project?.organization_id,
    contactsModal,
  ]);
  // to add multiple contacts after settings are approved only
  // during creation / edit before settings approved, choose only the first contact
  const canSelectMultiple = props.project?.settings.status === "Approved";

  const isNew = useRef(false);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);
  const [newContactSaveCallback, setNewContactSaveCallback] = useState<
    () => void
  >(() => {});
  const selectedContact = data?.data.find(
    (contact) => contact.id === (selectedContactId ?? props.value)
  );
  let initialView: ContactStepView = "prestep";
  if (props.isEdit && !props.isSetup) {
    initialView = "list";
  } else if (props.isEdit) {
    initialView = "gallery";
  }

  const [view, setView] = useState<ContactStepView>(
    initialView as ContactStepView
  );
  const [noFace, setNoFace] = useState(false);
  const handleOnSuccess = useCallback(
    (contact) => {
      props.onChange(contact);
    },
    [props]
  );

  const handleEditContact = (id: string) => {
    setEditingContactId(id);
    setContactsModal(true);
  };

  const [rerenderKey, setRerenderKey] = useState(0);
  const { value } = props;
  const options = useMemo(() => {
    const theData = data?.data;
    return (
      theData?.map((contact) => {
        return {
          label: `${contact.firstname} ${contact.lastname}`,
          value: contact.id,
          // already selected
          disabled: (value as string[])?.includes(contact.id),
        };
      }) ?? []
    );
  }, [data, value]);
  return (
    <>
      {view === "list" && (
        <Flex vertical gap={30}>
          <Flex vertical justify="start" gap={10}>
            <Select
              size="large"
              loading={isLoading}
              options={options}
              tagRender={tagRender}
              mode={canSelectMultiple ? "multiple" : undefined}
              showSearch
              value={props.value}
              onChange={(value) => {
                props.onChange(value as unknown as T);
                setSelectedContactId(value as string);
              }}
              style={{ width: "100%" }}
              optionFilterProp="label"
            />
            <Space>
              <Button
                size="small"
                onClick={() => {
                  setNewContact(true);
                  isNew.current = true;
                  setNoFace(false);
                  setContactsModal(true);
                }}
                shape="round"
                icon={<PlusOutlined />}
                type="text"
              >
                {t("projects.components.ProjectSettingForm.orCreateA")}
              </Button>
            </Space>
          </Flex>
          {!canSelectMultiple && (
            <ContactPreviewWrapper contact={selectedContact} />
          )}
          {!props.isEdit && (
            <Button
              style={{
                maxWidth: 300,
                margin: "0 auto",
              }}
              onClick={() => setContactsModal(true)}
            >
              {t("projects.components.ProjectSettingForm.createContactInstead")}
            </Button>
          )}
        </Flex>
      )}
      {view === "prestep" && (
        <ContactPreStep
          onClickCreateContact={(noFace) => {
            setNewContact(true);
            setNoFace(!!noFace);
            if (noFace) {
              isNew.current = true;
            }
            setContactsModal(true);
          }}
          onClickSelectExisting={() => setView("gallery")}
        />
      )}
      {view === "gallery" && (
        <>
          <StyledListWrapper columnSize={750 / 3 - 20}>
            <List<ContactResponse>
              size="small"
              split={false}
              loading={isLoading}
              dataSource={data?.data}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  onClick={() => props?.onChange(item.id as T)}
                  className={props.value === item.id ? "active" : ""}
                  style={{}}
                >
                  {/* <ContactPreviewWrapper contact={item} /> */}
                  <ContactMockup
                    handleEditContact={handleEditContact}
                    contact={item}
                    style={{
                      margin: 0,
                      maxHeight: "unset",
                      alignItems: "center",
                    }}
                  />
                </List.Item>
              )}
            />
          </StyledListWrapper>
          <Flex justify="center">
            <Button
              size="large"
              onClick={() => {
                setNewContact(true);
                setNoFace(false);
                setContactsModal(true);
              }}
              shape="round"
              icon={<PlusOutlined />}
              type="default"
            >
              {t("projects.components.ProjectSettingForm.orCreateA")}
            </Button>
          </Flex>
        </>
      )}
      <Modal
        key={rerenderKey} // Force rerender
        destroyOnClose
        forceRender
        onCancel={() => {
          setContactsModal(false);
          setNewContact(false);
        }}
        afterOpenChange={(open) => {
          if (!open) {
            setRerenderKey((prev) => prev + 1);
            setContactsModal(false);
          }
        }}
        okButtonProps={{ disabled: !saveButtonEnabled }}
        okText={t("buttons.save")}
        onOk={() => {
          setContactsModal(false);
          setView(initialView);
          return newContactSaveCallback();
        }}
        title={
          newContact
            ? t("projects.components.ProjectSettingForm.addANew")
            : t("src.App.editContact")
        }
        width={"85vw"}
        open={contactsModal}
      >
        {/* HACK: without this we get the component renders in the background  which causes it to fetch things */}
        {contactsModal && (
          <SimpleCreateContactWithoutLayout
            onSuccess={handleOnSuccess}
            noFace={noFace}
            setSaveCallback={setNewContactSaveCallback}
            onValidate={(value: boolean) => setSaveButtonEnabled(value)}
            id={newContact ? undefined : editingContactId}
          />
        )}
      </Modal>
    </>
  );
}

const ContactPreviewWrapper = ({ contact }: { contact?: ContactResponse }) => {
  if (!contact) return null;

  return (
    <PresenterProfilePreview
      presenterId={contact.presenter_id}
      organizationId={contact.organization_id}
      contact={contact}
      showContactInfo={true}
    />
  );
};

function ProjectSettingsFormPreserveDocument<T>(
  props: Readonly<{
    label: string;
    name: string;
    valuePropName: string;
    value: T;
    initialValue?: T;
    onChange: (value: T) => void;
  }>
) {
  const t = useTranslate();
  const { theme } = useAntTheme();
  const handleChange = (value: boolean) => {
    props.onChange(value as T);
  };
  return (
    <StyledSegmentedCardWrapper>
      <Form.Item
        name={props.name}
        valuePropName={props.valuePropName}
        initialValue={props.initialValue}
      >
        <Segmented<boolean>
          block
          options={[
            {
              label: (
                <Flex vertical align="center" gap={10}>
                  <div style={{ position: "relative" }}>
                    <HeadCircuit size={80} weight="duotone" />
                    <Sparkle
                      size={30}
                      weight="fill"
                      color={
                        props.value === false ? theme.colorPrimary : undefined
                      }
                      style={{ position: "absolute", left: 70, top: -2 }}
                    />
                  </div>
                  {t("projects.components.ProjectSettings.abstractiveLabel")}
                  <Typography.Text type="secondary">
                    {t(
                      "projects.components.ProjectSettings.abstractiveSummary"
                    )}
                  </Typography.Text>
                </Flex>
              ),
              value: false,
            },
            {
              label: (
                <Flex vertical align="center" gap={10}>
                  <BookOpenUser size={80} weight="duotone" />
                  {t("projects.components.ProjectSettings.extractiveLabel")}
                  <Typography.Text type="secondary">
                    {t("projects.components.ProjectSettings.extractiveSummary")}
                  </Typography.Text>
                </Flex>
              ),
              value: true,
            },
          ]}
          onChange={handleChange}
          value={Boolean(props.value)} // converts also null (default backend) to false
        />
      </Form.Item>
    </StyledSegmentedCardWrapper>
  );
}

function ProjectSettingsFormTransitions<T>(props: {
  name: string;
  valuePropName: string;
  value: T;
  initialValue?: T;
  onChange: (value: T) => void;
  loading?: boolean;
  project?: MediaProjectResponse;
}) {
  const t = useTranslate();

  const handleChange = (value: string) => {
    setReloadTrigger((prev) => !prev);
    props.onChange(value as T);
  };

  const [reloadTrigger, setReloadTrigger] = useState(false);

  const { data, isLoading } = useOne<BrandKit>({
    resource: `media/${props.project?.organization_id}/brand_kits`,
    id: props.project?.settings.brand_kit_id,
    queryOptions: {
      enabled:
        !!props.project?.organization_id &&
        !!props.project?.settings.brand_kit_id,
    },
  });

  return (
    <StyledSegmentedCardWrapper>
      <Form.Item
        name={props.name}
        valuePropName={props.valuePropName}
        initialValue={props.initialValue}
      >
        <Spin spinning={props.loading === true || isLoading}>
          <Segmented<Transitions>
            block
            options={[
              {
                label: (
                  <Flex vertical align="center" gap={10}>
                    <Exclude size={80} weight="duotone" />
                    {t("projects.components.ProjectSettings.Basic")}
                    <TransitionPreview
                      brandKit={data?.data}
                      hasTransition={true}
                      reloadTrigger={reloadTrigger}
                    />
                  </Flex>
                ),
                value: "Basic",
              },
              {
                label: (
                  <Flex vertical align="center" gap={10}>
                    <Slideshow size={80} weight="duotone" />
                    {t("projects.components.ProjectSettings.NoTransition")}
                    <TransitionPreview
                      brandKit={data?.data}
                      hasTransition={false}
                      reloadTrigger={reloadTrigger}
                    />
                  </Flex>
                ),
                value: "NoTransition",
              },
            ]}
            onChange={handleChange}
            value={props.value as Transitions}
          />
        </Spin>
      </Form.Item>
    </StyledSegmentedCardWrapper>
  );
}
// const StyledListWrapper = styled.div<{
//   theme: GlobalToken;
//   columnSize?: number;
// }>`
//   .ant-list-items {
//     display: grid;
//     grid-template-columns: repeat(
//       auto-fill,
//       minmax(${({ columnSize }) => columnSize ?? 250}px, 1fr)
//     );
//     gap: 1rem;
//   }

//   .ant-list-item {
//     border: 2px solid transparent;
//     box-sizing: border-box;
//     border-color: ${({ theme }) => theme.colorSecondaryBg};
//     background-color: ${({ theme }) => theme.colorSecondaryBg};
//     // max-height: 500px;
//     // display: grid;
//     // grid-template-columns: 1fr;
//     display: flex;
//     flex-direction: column;
//     justify-content: start;
//     min-height: 100px;
//     border-radius: 6px;

//     .ant-list-item-meta .ant-list-item-meta-title {
//       font-size: 20px;
//       font-weight: normal;
//     }
//     .ant-list-item-meta-description {
//       padding-right: 5px;
//     }
//     &:hover {
//       cursor: pointer;
//       transition: 500ms;
//       background-color: ${(props) => props.theme.colorBgElevated};
//     }
//     &.active {
//       border-color: ${(props) => props.theme.colorPrimary};
//       background-color: ${(props) => props.theme.colorPrimaryBg};
//       .ant-list-item-meta-title {
//         color: ${(props) => props.theme.colorPrimary};
//       }
//       .ant-list-item-meta-avatar {
//         color: ${(props) => props.theme.colorPrimary};
//       }
//     }
//   }
// `;

// const StyledSegmentedWrapper = styled.div<{
//   theme: GlobalToken;
// }>`
//   .ant-segmented {
//     background: ${({ theme }) => theme.colorBgContainer};
//     border: 1px solid ${({ theme }) => theme.colorBorder};
//     padding: 0;
//   }
//   .ant-segmented-item {
//     padding: 2px 0;
//     color: ${({ theme }) => theme.colorTextSecondary};
//     border: 1px solid transparent;
//   }
//   .ant-segmented-thumb {
//     background-color: ${({ theme }) => theme.colorPrimaryBg};
//     border: 1px solid ${({ theme }) => theme.colorPrimary};
//   }
//   .ant-segmented-item-selected {
//     background-color: ${({ theme }) => theme.colorPrimaryBg};
//     // transition: color 0.2s;
//     color: ${({ theme }) => theme.colorText};
//     border: 1px solid ${({ theme }) => theme.colorPrimary};
//   }
// `;
// const StyledSegmentedCardWrapper = styled.div<{
//   theme: GlobalToken;
// }>`
//   .ant-segmented {
//     background: none;
//     border: none;
//     padding: 0;
//   }
//   .ant-segmented-group {
//     gap: 30px;
//   }
//   .ant-segmented-item {
//     padding: 40px 0px;
//     color: ${({ theme }) => theme.colorTextSecondary};
//     border: 1px solid ${({ theme }) => theme.colorSecondaryBg};
//     background: ${({ theme }) => theme.colorSecondaryBg};
//     border-radius: 15px;
//   }
//   .ant-segmented-thumb {
//     background-color: ${({ theme }) => theme.colorPrimaryBg};
//     border: 1px solid ${({ theme }) => theme.colorPrimary};
//   }
//   .ant-segmented-item-selected {
//     background-color: ${({ theme }) => theme.colorPrimaryBg};
//     // transition: color 0.2s;
//     // color: ${({ theme }) => theme.colorText};
//     border: 1px solid ${({ theme }) => theme.colorPrimary};
//     color: ${(props) => props.theme.colorPrimary};
//   }
//   .ant-segmented-item-label {
//     font-size: 20px;
//     white-space: normal;
//   }
// `;

// const StyledDurationSlider = styled(Slider)`
//   .ant-slider-rail {
//     background: linear-gradient(
//       to right,
//       ${({ theme }) => theme.colorSecondaryBg} 0%,
//       ${({ theme }) => theme.colorSecondaryBg} 100%
//     );
//     border-radius: 100px;
//   }
//   .ant-slider-track {
//     border-radius: 100px;
//     background: linear-gradient(
//       to right,
//       ${({ theme }) => theme.colorPrimaryBorderHover} 0%,
//       ${({ theme }) => theme.colorPrimaryBorderHover} 100%
//     );
//   }
//   &:hover {
//     .ant-slider-track {
//       // background: red;
//     }
//   }
//   .ant-slider-handle:after {
//     background: linear-gradient(
//       to right,
//       ${({ theme }) => theme.colorPrimary} 0%,
//       ${({ theme }) => theme.colorPrimary} 100%
//     ) !important;
//     border: 0;
//     box-shadow: 0 0 0 5px ${({ theme }) => theme.colorPrimaryBorderHover} !important;
//   }
//   .ant-slider-handle:hover:after {
//     box-shadow: 0 0 0 5px ${({ theme }) => theme.colorPrimaryBorderHover} !important;
//   }
// `;
export const CONTACT_PREVIEW_WIDTH = 150;
export const CONTACT_PREVIEW_HEIGHT =
  CONTACT_PREVIEW_WIDTH * BRANDKIT_HEIGHT_TO_WIDTH_RATIO;
const ContactMockup: React.FC<{
  contact: ContactResponse;
  style?: CSSProperties;
  handleEditContact?: (value: string) => void;
  width?: number;
}> = function ContactMockup({
  contact,
  style,
  handleEditContact,
  width: columnSize,
}) {
  const wizardProject = useProjectWizardStore((state) => state.project);
  const params = useParams();
  const projectId = wizardProject?.id ?? params?.id;
  const { data: project } = useOne<MediaProject>({
    resource: `media/projects`,
    id: projectId,
    queryOptions: {
      enabled: !!projectId,
    },
  });
  const organizationId = project?.data.organization_id;
  const { data: brandkitData } = useOne<BrandKit>({
    resource: `media/${organizationId}/brand_kits`,
    id: project?.data?.settings.brand_kit_id,
    queryOptions: {
      enabled: !!project?.data?.settings.brand_kit_id && !!organizationId,
    },
  });
  const brandkit = brandkitData?.data;

  const { data: presenter } = useOne<PresenterProfileResponse>({
    resource: `media/${organizationId}/presenter_profiles`,
    id: contact.presenter_id,
  });

  return (
    <Flex vertical gap={5}>
      <Flex justify="center" align="center">
        <Flex
          align="center"
          justify="center"
          style={{ position: "relative", width: columnSize - 40 }}
          gap={10}
        >
          <Space align="center" size="small">
            <Typography.Text ellipsis>
              {contact.firstname} {contact.lastname}
            </Typography.Text>
            {presenter?.data.voice_profile?.preview_asset_path && (
              <VoiceProfilePreview
                audioPath={presenter.data.voice_profile.preview_asset_path}
                size={24}
                style={{ height: 24 }}
              />
            )}
          </Space>
          <Button
            key="edit"
            onClick={(e) => {
              e.stopPropagation();
              handleEditContact(contact.id);
            }}
            type="text"
            icon={<EditOutlined style={{ fontSize: 20 }} />}
          />
        </Flex>
      </Flex>
      <Flex
        style={{
          // smaller for smaller screens
          top: 0,
          maxHeight: "100%",
          margin: "0 auto",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          ...style,
        }}
      >
        <BrandKitMockup
          noPlayer
          assets={
            brandkit?.assets as
              | BrandKitAssetResponseWithTypedPurpose[]
              | undefined
          }
          width={150}
          // colors={brandkit?.palette}
          // fake blank content to show the contact face
          colors={{
            primary_color: "#fff",
            subtitle_color: "transparent",
            title_color: "transparent",
          }}
          style={{
            margin: 0,
            transform: "unset",
          }}
        />
        <PresenterPreview
          style={{
            zIndex: 10,
            width: CONTACT_PREVIEW_WIDTH,
            position: "absolute",
          }}
          presenterId={contact.presenter_id}
          organizationId={organizationId}
          fullSize
        />
      </Flex>
    </Flex>
  );
};
