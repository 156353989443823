import { useTranslate } from "@refinedev/core";
import React, { useCallback, useEffect, useState } from "react";
import { Authenticated, useCreate, useGo } from "@refinedev/core";
import { Form, Card, Input, message, Col, Row, Flex, FormInstance } from "antd";
import styled from "styled-components";
import { useOrganization } from "hooks/useOrganization";
import { BrandKit } from "../types";
import { Create } from "@refinedev/antd";
import { colorKeys } from "./index";
import { BrandKitMockup } from "./components/BrandKitMockup";
import { ColorPickerCard } from "./components/ColorPickerCard";

// const ColorRow = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 8px;
//   gap: 8px;
// `;

export const BrandKitCreateWithoutLayout: React.FC<{
  setSaveCallback: (save: () => () => Promise<BrandKit | void>) => void;
}> = function BrandkitCreateWithoutLayout({ setSaveCallback }) {
  const t = useTranslate();
  const { organization } = useOrganization({});
  const { mutateAsync: createBrandKit } = useCreate<BrandKit>();

  const [form] = Form.useForm();

  const [colors, setColors] = useState(form.getFieldsValue().colors || {});

  const handleSave = useCallback(
    async (values: any) => {
      try {
        await form.validateFields();

        const response = await createBrandKit({
          resource: `media/${organization?.id}/brand_kits`,
          values: {
            name: values.name,
            palette: values.colors,
          },
        });

        if (!response.data) {
          throw new Error(t("media.brandkit.create.failedToCreate"));
        }
        return response.data;
      } catch (error: unknown) {
        const { errorFields } = error as {
          errorFields: { name: string[]; errors: string[] };
        };
        if (errorFields) {
          const firstErrorField = errorFields[0];
          const fieldElement = document.querySelector(
            `#${firstErrorField.name[0]}`
          );
          if (fieldElement) {
            fieldElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        } else {
          message.error(t("media.brandkit.create.failedToCreate"));
          console.error(error as Error);
        }
        throw new Error(t("media.brandkit.create.failedToCreate"));
      }
    },
    [form, createBrandKit, organization, t]
  );
  useEffect(() => {
    if (setSaveCallback) {
      setSaveCallback(
        () => async () => await handleSave(form.getFieldsValue())
      );
    }
  }, [form, handleSave, setSaveCallback]);

  return (
    <Authenticated key="brandkit_create">
      <StyledWrapper>
        <BaseBrandkitCreate
          colors={colors}
          setSaveCallback={setSaveCallback}
          form={form}
          handleSave={handleSave}
          setColors={setColors}
        />
      </StyledWrapper>
    </Authenticated>
  );
};

export const BrandKitCreate: React.FC = () => {
  const t = useTranslate();
  const { organization } = useOrganization({});
  const { mutateAsync: createBrandKit } = useCreate<BrandKit>();

  const go = useGo();
  const [form] = Form.useForm();

  const [colors, setColors] = useState(form.getFieldsValue().colors || {});

  const handleSave = async (values: any) => {
    try {
      const response = await createBrandKit({
        resource: `media/${organization?.id}/brand_kits`,
        values: {
          name: values.name,
          palette: values.colors,
        },
      });

      if (response.data) {
        go({
          to: {
            id: response.data.id,
            resource: "media_brandkits",
            action: "edit",
          },
        });
      } else {
        throw new Error(t("media.brandkit.create.failedToCreate"));
      }
    } catch (error) {
      message.error(t("media.brandkit.create.failedToCreate"));
      console.error(error);
    }
  };

  const onFinishFailed = ({ errorFields }: any) => {
    if (errorFields.length) {
      const firstErrorField = errorFields[0];
      const fieldElement = document.querySelector(
        `#${firstErrorField.name[0]}`
      );
      if (fieldElement) {
        fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  return (
    <Authenticated key="brandkit_create">
      <StyledWrapper>
        <Create
          saveButtonProps={{
            title: t("media.brandkit.create.next"),
            children: "Next",
            icon: null,
            style: { marginLeft: "auto", display: "block" },
            onClick: form.submit,
          }}
        >
          <BaseBrandkitCreate
            colors={colors}
            form={form}
            handleSave={handleSave}
            onFinishFailed={onFinishFailed}
            setColors={setColors}
          />
        </Create>
      </StyledWrapper>
    </Authenticated>
  );
};

const StyledWrapper = styled.div`
  .ant-card-actions {
    border: 0;
    > li > span > .ant-space {
      display: flex;
      padding: 24px;
      float: none !important;
      width: 100%;
      > .ant-space-item {
        width: 100%;
      }
    }
  }
`;

interface BaseBrandkitCreateProps {
  colors: [any, React.Dispatch<any>][number];
  form: FormInstance<any>;
  handleSave: (values: any) => Promise<BrandKit | void>;
  onFinishFailed?: ({ errorFields }: any) => void;
  setColors: React.Dispatch<any>;
  setSaveCallback?: (save: () => () => Promise<BrandKit | void>) => void;
}

function BaseBrandkitCreate({
  colors,
  form,
  handleSave,
  onFinishFailed,
  setColors,
  setSaveCallback,
}: BaseBrandkitCreateProps) {
  useEffect(() => {
    if (setSaveCallback) {
      setSaveCallback(
        () => async () => await handleSave(form.getFieldsValue())
      );
    }
  }, [form, handleSave]);
  const t = useTranslate();
  return (
    <Flex>
      <Flex style={{ flex: "1 0 65%", minHeight: 700 }}>
        <Form
          requiredMark
          form={form}
          onFinish={handleSave}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label={t("media.brandkit.create.name")}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Card title={t("media.brandkit.create.colorPalette")} type="inner">
            <Row gutter={[16, 16]}>
              {colorKeys.map((colorKey) => {
                const isPrimary = colorKey === "primary_color";
                return (
                  <Col
                    xl={8}
                    lg={24}
                    md={24}
                    sm={24}
                    key={colorKey}
                    style={{ width: "100%", display: "flex" }}
                  >
                    <ColorPickerCard
                      value={colors[colorKey]}
                      allowClear={!isPrimary}
                      colorKey={colorKey}
                      form={form}
                      setColors={setColors}
                      required={isPrimary}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card>
        </Form>
      </Flex>
      <BrandKitMockup
        colors={colors}
        style={{ marginLeft: 20, position: "sticky", top: 0 }}
      />
    </Flex>
  );
}
