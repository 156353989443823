import React from "react";
import { useParams } from "react-router-dom";
import { Authenticated, useList, useOne, useTranslate } from "@refinedev/core";
import {
  ProjectMediaPublishForm,
  SimpleProjectMediaPublish,
} from "./components/SimpleProjectMediaPublish";
import { useProjectDetails } from "./hooks/useProjectDetails";
import { MediaProjectPublicationBase, MediaProjectResponse } from "../types";
import { ProjectSubPageHeader } from "./components/ProjectSubPageHeader";

export const ProjectMediaSharePage: React.FC = () => {
  const { id: projectId, mediaId } = useParams<{
    id: string;
    mediaId: string;
  }>();

  const { data } = useOne<MediaProjectResponse>({
    resource: `media/projects`,
    id: projectId,
  });
  const project = data?.data;
  const t = useTranslate();
  const media = project?.media.find((x) => x.id === mediaId);
  const { data: publications, isLoading: isLoadingPublications } =
    useList<MediaProjectPublicationBase>({
      resource: `media/projects/${projectId}/publications`,
    });
  const mediaPublications = publications?.data.filter(
    // use contact_id+language as pivot instead of mediaId that can change during
    // the publication workflow in the backend
    (x) =>
      media?.id &&
      x.contact_id === media.contact_id &&
      x.language === media.language
  );
  console.debug({
    data,
    mediaId,
    projectId,
    project,
    media,
    mediaPublications,
  });
  return (
    <Authenticated key={"projectMediaShare"}>
      {project && (
        <>
          <ProjectSubPageHeader
            projectId={project.id}
            projectTitle={project.title}
            page="share"
          />
          <ProjectMediaPublishForm
            // title={t("projects.components.ProjectMedias.share")}
            project={project}
            mediaId={media?.id}
            publicationId={mediaPublications?.[0]?.project_publication_id}
          />
        </>
      )}
    </Authenticated>
  );
};
