import React from "react";
import { useParams } from "react-router-dom";
import { Authenticated } from "@refinedev/core";
import { ProjectChatHistory } from "./components/ProjectChatHistory";

export const ProjectChatHistoryPage: React.FC = () => {
  const { id: projectId } = useParams<{ id: string }>();

  return (
    <Authenticated key={"projectHistory"}>
      <ProjectChatHistory projectId={projectId} enabled={true} />
    </Authenticated>
  );
};
