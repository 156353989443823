import { PresenterProfileResponse } from "pages/media/types";
import { MediaTemplateSettings } from "types";

export enum TemplateType {
  INTRODUCTION = "Introduction",
  TRANSITION = "Transition",
  CHAPTER = "Chapter",
  CLOSING = "Closing",
}

export enum TabKeys {
  images = "Images",
  intro = "Introduction",
  transition = "Transition",
  closing = "Closing",
  background = "Background",
  text = "Text",
  presenter = "Presenter",
  effects = "Effects",
}

export enum Effects {
  fadeIn = "fadeIn",
  fadeOut = "fadeOut",
}

export type BodyView = "Color" | "Picture" | "Video";

export type NewPresenter = {
  presenter?: PresenterProfileResponse;
  size: {
    width: number;
    height: number;
  };
  position: {
    x: number;
    y: number;
  };
  contact_id?: string;
  fadeIn?: boolean;
  fadeOut?: boolean;
  avatarCircle?: boolean;
  background?: string;
};

export type NewImage = {
  id: string;
  assetId: string;
  position: { x: number; y: number };
  size: { width: number; height: number };
  path: string;
  fadeIn?: boolean;
  fadeOut?: boolean;
};

export type VideoItem = {
  enable: boolean;
  assetPath?: string;
  assetId?: string;
  view: BodyView;
};

export type TemplateState = {
  view: string;
  subtitlesAnimation: boolean;
  avatarClose: boolean;
  textStyle: {
    id?: string;
    bold: boolean;
    italic: boolean;
    underline: boolean;
    fontSize: number;
    textFont?: string;
    fontAssetId?: string;
    subtitles_color?: string;
    subtitles_stroke_color?: string;
    textBackground?: string;
    position: {
      x: number;
      y: number;
    };
    size: {
      width: number;
      height: number;
    };
    showSubtitles: boolean;
    fadeIn?: boolean;
    fadeOut?: boolean;
  };
  presenter: NewPresenter;
  titleStyle: {
    id?: string;
    bold: boolean;
    italic: boolean;
    underline: boolean;
    fontSize: number;
    titleFont?: string;
    fontAssetId?: string;
    title_color?: string;
    title_stroke_color?: string;
    titleBackground?: string;
    position: {
      x: number;
      y: number;
    };
    size: {
      width: number;
      height: number;
    };
    fadeIn?: boolean;
    fadeOut?: boolean;
  };
  currentChapter?: string;
  mediaSettings?: MediaTemplateSettings;
  intro: VideoItem;
  transition: VideoItem;
  closing: VideoItem;
  currentTemplateId?: string;
  images: NewImage[];
  background: {
    bodyColor?: string;
    headerColor?: string;
    headerImage?: string;
    headerImageAsset?: string;
    bodyImage?: string;
    bodyImageAsset?: string;
    bodyVideo?: string;
    bodyVideoAsset?: string;
    bodyView: BodyView;
    headerView: "Color" | "Picture";
  };
  updates: number;
  currentItem?: {
    type: "Image" | "Subtitles" | "Title" | "Presenter";
    id?: string;
  };
  activeTab?: TabKeys;
};
