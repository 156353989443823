import {
  useApiUrl,
  useCreate,
  useCustomMutation,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import { useParams, Link } from "react-router-dom";
import { useAntTheme } from "hooks/useAntTheme";
import { Button, Flex, notification, Space, Spin, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { StyledVerticalPanelResizeHandle } from "components/StyledPanelResizeHandle";
import { Chapters } from "./Chapters";
import { TemplatePreview } from "./TemplatePreview";
import { TemplateSettings } from "./TemplateSettings";
import { useTemplates } from "./hooks/useTemplates";
import {
  MediaTemplate,
  MediaTemplateResponse,
  MediaTemplateSettings,
} from "types";
import { useVolatileBoundStore } from "store";
import { TemplateType } from "./types";
import { useTemplateItems } from "./hooks/useTemplatesItems";
import { useCallback, useContext, useEffect } from "react";
import { AppContext } from "appContext";
import { useAutoSave } from "./hooks/useAutoSave";
import { useCustomInvalidate } from "hooks/useCustomInvalidate";

export const TemplateEditor = () => {
  const { show } = useNavigation();
  const { theme } = useAntTheme();
  const params = useParams<{ id: string; mediaId: string }>();
  const { id: projectId, mediaId } = params ?? {};
  const { dispatch: appDispatch } = useContext(AppContext);
  const { customInvalidate } = useCustomInvalidate();

  const {
    isLoadingProject,
    mediaSettings,
    organizationId,
    organizationTemplates,
    projectTitle,
  } = useTemplates({
    projectId,
  });

  const {
    avatarClose,
    currentChapter,
    currentTemplateId,
    presenter,
    resetUpdates,
    setCurrentTemplateId,
    view,
    textStyle,
    titleStyle,
    storeIntro,
    storeClosing,
  } = useVolatileBoundStore((store) => ({
    avatarClose: store.templateState.avatarClose,
    currentChapter: store.templateState.currentChapter,
    currentTemplateId: store.templateState.currentTemplateId,
    presenter: store.templateState.presenter,
    resetUpdates: store.resetUpdates,
    setCurrentTemplateId: store.setCurrentTemplateId,
    view: store.templateState.view,
    textStyle: store.templateState.textStyle,
    titleStyle: store.templateState.titleStyle,
    storeIntro: store.templateState.intro,
    storeClosing: store.templateState.closing,
  }));

  const t = useTranslate();

  const { items } = useTemplateItems();

  const [api, contextHolder] = notification.useNotification();

  const { mutateAsync: createTemplate } = useCreate<MediaTemplateResponse>();
  const { mutateAsync: updateTemplate } = useUpdate<MediaTemplateResponse>();

  const { mutateAsync: createMediaTemplatesSettings } =
    useCreate<MediaTemplateSettings>();
  const { mutateAsync: updateMediaTemplatesSettings } =
    useUpdate<MediaTemplateSettings>();

  const { mutateAsync: regenerateMedia } = useCustomMutation();
  const apiUrl = useApiUrl();

  const { isSavingTemplate } = useAutoSave(() => handleSave());

  const handleBackClick = () => {
    show("media_projects", projectId);
  };

  const generateMediaSettings = useCallback(
    (id: string): Partial<MediaTemplateSettings> => {
      const settings: Partial<MediaTemplateSettings> = { chapters: [] };

      if (view === TemplateType.INTRODUCTION) {
        settings.introduction_template_id = id;
      } else if (view === TemplateType.CLOSING) {
        settings.closing_template_id = id;
      } else if (view === TemplateType.TRANSITION && currentChapter) {
        settings.chapters.push({
          chapter_name: currentChapter.replace("Transition-", ""),
          transition_template_id: id,
        });
      } else if (view === TemplateType.CHAPTER && currentChapter) {
        const newChapter: MediaTemplateSettings["chapters"][number] = {
          chapter_name: currentChapter,
          chapter_template_id: id,
        };
        // User can remove presenter from chapter and backend doesn't allow undefined
        if (presenter.presenter) {
          newChapter.presenter_id = presenter.presenter.id;
        }

        settings.chapters.push(newChapter);
      }

      return settings;
    },
    [currentChapter, presenter.presenter, view]
  );

  const createNewMediaSettings = useCallback(
    async (id: string) => {
      const settings = generateMediaSettings(id);
      try {
        await createMediaTemplatesSettings({
          resource: `media/${organizationId}/projects/${projectId}/media/settings`,
          values: settings,
          successNotification: () => ({
            message: t(
              "components.templates.templatePreview.saveSettingsSuccess"
            ),
            type: "success",
          }),
        });
        // invalidate the media settings for this project
        // using useOne hook with queryKey ["ProjectMediaSettings", projectId] in useTemplates
        await customInvalidate(["ProjectMediaSettings", projectId]);
      } catch (e) {
        console.debug(e);
      }
    },
    [
      createMediaTemplatesSettings,
      generateMediaSettings,
      organizationId,
      projectId,
      customInvalidate,
      t,
    ]
  );

  const handleChapterIdUpdate = useCallback(
    (
      id: string,
      currentChapterObject:
        | MediaTemplateSettings["chapters"][number]
        | undefined
    ) => {
      if (currentChapterObject) {
        return mediaSettings?.chapters.map((chapter) =>
          chapter.chapter_name === currentChapter?.replace("Transition-", "")
            ? {
                ...chapter,
                chapter_template_id: id,
                ...(presenter.presenter && {
                  presenter_id: presenter.presenter.id,
                }),
              }
            : chapter
        );
      }

      const newChapter = {
        chapter_name: currentChapter?.replace("Transition-", ""),
        chapter_template_id: id,
        ...(presenter.presenter && { presenter_id: presenter.presenter.id }),
      };

      return [...mediaSettings.chapters, newChapter];
    },
    [currentChapter, presenter.presenter, mediaSettings?.chapters]
  );

  const handleTransitionIdUpdate = useCallback(
    (
      id: string,
      currentChapterObject:
        | MediaTemplateSettings["chapters"][number]
        | undefined
    ) => {
      if (currentChapterObject) {
        return mediaSettings?.chapters.map((chapter) =>
          chapter.chapter_name === currentChapter?.replace("Transition-", "")
            ? {
                ...chapter,
                transition_template_id: id,
                ...(presenter.presenter && {
                  presenter_id: presenter.presenter.id,
                }),
              }
            : chapter
        );
      }

      const newChapter = {
        chapter_name: currentChapter?.replace("Transition-", ""),
        transition_template_id: id,
      };

      return [...mediaSettings.chapters, newChapter];
    },
    [currentChapter, mediaSettings?.chapters]
  );

  const getUpdatedSettings = useCallback(
    (id: string) => {
      const currentChapterObject = mediaSettings?.chapters.find(
        (chapter) =>
          chapter.chapter_name === currentChapter?.replace("Transition-", "")
      );

      switch (view) {
        case TemplateType.CHAPTER:
          return {
            ...mediaSettings,
            chapters: handleChapterIdUpdate(id, currentChapterObject),
          };
        case TemplateType.TRANSITION:
          return {
            ...mediaSettings,
            chapters: handleTransitionIdUpdate(id, currentChapterObject),
          };
        case TemplateType.INTRODUCTION:
          return {
            ...mediaSettings,
            introduction_template_id: storeIntro.enable ? id : null,
          };
        case TemplateType.CLOSING:
          return {
            ...mediaSettings,
            closing_template_id: storeClosing.enable ? id : null,
          };
        default:
          return mediaSettings;
      }
    },
    [
      currentChapter,
      handleChapterIdUpdate,
      handleTransitionIdUpdate,
      mediaSettings,
      storeIntro.enable,
      storeClosing.enable,
      view,
    ]
  );

  const saveMediaSettings = useCallback(
    async (id: string) => {
      try {
        if (!mediaSettings) {
          await createNewMediaSettings(id);
          return;
        }
        const updatedSettings = getUpdatedSettings(id);

        await updateMediaTemplatesSettings({
          resource: `media/${organizationId}/projects`,
          id: `${projectId}/media/settings`,
          values: updatedSettings,
          successNotification: () => {
            return {
              message: t(
                "components.templates.templatePreview.updateSettingsSuccess"
              ),
              type: "success",
            };
          },
        });
        await customInvalidate(["ProjectMediaSettings", projectId]);

        console.debug("Updated settings", updatedSettings);
      } catch (e) {
        console.debug(e);
      }
    },
    [
      mediaSettings,
      createNewMediaSettings,
      customInvalidate,
      getUpdatedSettings,
      organizationId,
      projectId,
      updateMediaTemplatesSettings,
      t,
    ]
  );

  const handleSave = useCallback(async () => {
    if (view === TemplateType.INTRODUCTION && !storeIntro.assetId) {
      api.warning({
        message: t("components.templates.index.uploadVideo", { view }),
      });

      return false;
    }

    if (view === TemplateType.CLOSING && !storeClosing.assetId) {
      api.warning({
        message: t("components.templates.index.uploadVideo", { view }),
      });

      return false;
    }

    let hasFont = true;
    if (view === TemplateType.CHAPTER) {
      hasFont = !!textStyle.fontAssetId;
    } else if (view === TemplateType.TRANSITION) {
      hasFont = !!titleStyle.fontAssetId;
    }

    if (!hasFont) {
      return false;
    }

    let avatarShot = null;

    if (view === TemplateType.CHAPTER) {
      avatarShot = avatarClose ? "CloseUp" : "Medium";
    }

    const template = {
      name: currentChapter,
      items: items,
      avatar_shot: avatarShot,
    } as MediaTemplate;

    console.debug(template);

    const templateId = organizationTemplates?.find(
      (template) => template.name === currentChapter
    )?.id;

    if (templateId) {
      try {
        await updateTemplate({
          resource: `media/${organizationId}/templates`,
          id: templateId,
          values: { template },
          successNotification: () => {
            return {
              message: t("components.templates.templatePreview.updateSuccess"),
              type: "success",
            };
          },
          errorNotification: () => {
            return {
              message: t("components.templates.templatePreview.updateError"),
              type: "error",
            };
          },
        });

        setCurrentTemplateId(templateId);

        try {
          await saveMediaSettings(templateId);
          return true;
        } catch (e) {
          console.debug(e);
          return false;
        }
      } catch (e) {
        console.debug(e);
        return false;
      }
    } else {
      try {
        const response = await createTemplate({
          resource: `media/${organizationId}/templates`,
          values: { template },
          successNotification: () => {
            return {
              message: t("components.templates.templatePreview.saveSuccess"),
              type: "success",
            };
          },
          errorNotification: () => {
            return {
              message: t("components.templates.templatePreview.saveError"),
              type: "error",
            };
          },
        });
        await customInvalidate(["MediaTemplates", organizationId]);

        await saveMediaSettings(response.data.id);

        return true;
      } catch (e) {
        console.debug(e);
        return false;
      }
    }
  }, [
    api,
    avatarClose,
    createTemplate,
    customInvalidate,
    currentChapter,
    items,
    organizationId,
    organizationTemplates,
    saveMediaSettings,
    setCurrentTemplateId,
    storeClosing.assetId,
    storeIntro.assetId,
    t,
    textStyle.fontAssetId,
    titleStyle.fontAssetId,
    updateTemplate,
    view,
  ]);

  const handleApplyToAll = useCallback(async () => {
    await handleSave();

    const settingName = view.toLocaleLowerCase();

    const updatedSettings = {
      ...mediaSettings,
      [`${settingName}_template_id`]: currentTemplateId,
      chapters: mediaSettings?.chapters.map((chapter) => ({
        ...chapter,
        [`${settingName}_template_id`]: currentTemplateId,
      })),
    };

    if (!mediaSettings?.chapters) {
      updatedSettings.chapters = [];
    }

    try {
      await updateMediaTemplatesSettings({
        resource: `media/${organizationId}/projects`,
        id: `${projectId}/media/settings`,
        values: updatedSettings,
        successNotification: () => {
          return {
            message: t("components.templates.templatePreview.savedAll", {
              view,
            }),
            type: "success",
          };
        },
        errorNotification: () => {
          return {
            message: t("components.templates.templatePreview.saveError"),
            type: "error",
          };
        },
      });

      console.debug("Apply to all settings", updatedSettings);
    } catch (e) {
      console.error(e);
    }

    resetUpdates();
  }, [
    currentTemplateId,
    handleSave,
    mediaSettings,
    organizationId,
    projectId,
    resetUpdates,
    t,
    updateMediaTemplatesSettings,
    view,
  ]);

  const handleRegenerate = async () => {
    console.debug(mediaSettings);
    try {
      await regenerateMedia({
        url: `${apiUrl}/media/media/${mediaId}/regenerate`,
        method: "post",
        values: {},
        successNotification: {
          type: "success",
          message: t("projects.components.ProjectMedias.regenerateSuccess"),
        },
      });

      handleBackClick();
    } catch (e) {
      console.debug(e);
    }
  };

  useEffect(() => {
    appDispatch({ type: "setSidebarCollapsed", payload: true });
    return () => {
      appDispatch({ type: "setSidebarCollapsed", payload: false });
    };
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
      }}
    >
      {contextHolder}
      <Spin spinning={isLoadingProject} fullscreen tip={t(`loading`)} />
      <Flex vertical style={{ margin: -24 }}>
        <Flex
          style={{
            width: "100%",
            padding: "8px 24px",
            borderBottom: "1px solid",
            borderColor: theme.colorBorder,
          }}
          align="center"
          justify="space-between"
          gap={20}
        >
          <Button
            type="text"
            size="large"
            icon={<ArrowLeftOutlined />}
            onClick={handleBackClick}
          >
            {t("components.binder.index.backToProject")}
          </Button>
          <Space wrap={true}>
            <Typography.Text type="secondary">
              {t(`src.App.projects`)} /{" "}
            </Typography.Text>
            <Link
              to={"/media/projects/" + projectId}
              style={{ color: theme.colorText }}
            >
              {projectTitle}
            </Link>
            <Typography.Text type="secondary">
              / {t("components.templates.index.templatesEditor")}
            </Typography.Text>
          </Space>
          <Button type="primary" size="large" onClick={handleRegenerate}>
            {t("components.templates.index.generateMedia")}
          </Button>
        </Flex>
        {projectId && mediaId && (
          <Flex vertical gap={10}>
            <PanelGroup direction="horizontal">
              <Panel maxSize={20} minSize={15}>
                <Chapters
                  mediaId={mediaId}
                  projectId={projectId}
                  onSave={handleSave}
                />
              </Panel>
              <PanelResizeHandle>
                <StyledVerticalPanelResizeHandle />
              </PanelResizeHandle>
              <Panel defaultSize={50} minSize={40}>
                <TemplatePreview
                  projectId={projectId}
                  handleApplyToAll={handleApplyToAll}
                  isSavingTemplate={isSavingTemplate}
                />
              </Panel>
              <PanelResizeHandle>
                <StyledVerticalPanelResizeHandle />
              </PanelResizeHandle>
              <Panel defaultSize={25} minSize={20}>
                <TemplateSettings projectId={projectId} />
              </Panel>
            </PanelGroup>
          </Flex>
        )}
      </Flex>
    </div>
  );
};
