import { Button, ConfigProvider, Flex, Space, Spin, Typography } from "antd";
import styled from "styled-components";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { CaretRight } from "@phosphor-icons/react";
import { useAntTheme } from "hooks/useAntTheme";
import { ReactNode } from "react";
import { ButtonProps } from "antd/lib";

export const FatButton = ({
  icon,
  block,
  title,
  loading,
  content,
  arrow,
  color = "primary",
  style,
  ...rest
}: {
  title: string;
  content?: ReactNode;
  arrow?: boolean;
  color?: "primary" | "secondary";
} & Omit<ButtonProps, "color">) => {
  const { theme } = useAntTheme();
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorBorder:
              color === "primary"
                ? theme.colorPrimaryBorder
                : theme.colorSecondaryBorder,
            defaultBg:
              color === "primary"
                ? theme.colorPrimaryBg
                : theme.colorSecondaryBg,
          },
        },
      }}
    >
      <StyledMenuButton
        {...rest}
        style={{
          ...(block ? { width: "100%" } : null),
          ...style,
        }}
      >
        <Flex
          justify="center"
          align="center"
          style={{
            width: "100%",
            paddingTop: 10,
          }}
        >
          <Flex vertical justify="center" align="center" gap={15}>
            {icon}
            <Flex vertical align="center" gap={2}>
              <span
                style={{
                  fontSize: 20,
                  whiteSpace: "normal",
                  lineHeight: 1,
                }}
              >
                {title}
              </span>
              <Space size="small" align="center" style={{ margin: "15px 5px" }}>
                {loading && (
                  <Spin
                    indicator={<Loading3QuartersOutlined spin />}
                    size="small"
                    style={{ color: theme.colorText }}
                  />
                )}
                <Typography.Text
                  type="secondary"
                  style={{
                    fontSize: 14,
                    whiteSpace: "normal",
                    lineHeight: 1.1,
                  }}
                >
                  {content}
                </Typography.Text>
                {/* {arrow && (
                  <CaretRight
                    size={24}
                    style={{ opacity: 0.5, marginTop: 5 }}
                    weight="bold"
                  />
                )} */}
              </Space>
            </Flex>
          </Flex>
        </Flex>
      </StyledMenuButton>
    </ConfigProvider>
  );
};

const StyledMenuButton = styled(Button)`
  height: auto;
  padding: 8px 16px;
`;
